import { NormalizedCustomizerProduct, RuleWhen } from '@packages/types'
import React from 'react'

import { SelectProps } from 'common/components'

import AutoresizeSelect from './AutoresizeSelect'

type Option = { selectedValue?: RuleWhen['value']; value: RuleWhen['value']; label: string }
type OnSelect = (value: { value: string }) => void
type OnSelectMulti = (values: { value: string }[]) => void

const questionAnswers = (
  customizerProduct: NormalizedCustomizerProduct,
  questionId: string,
  exclude: string[],
  value?: RuleWhen['value'],
  answersToHighlight: string[] = []
) => {
  const question = customizerProduct.questions[questionId]

  if (Array.isArray(question?.answers)) {
    return question?.answers?.reduce<Option[]>((options, answerId) => {
      const answer = customizerProduct.answers[answerId]
      const label = answer.archived ? `${answer.name} (Archived)` : answer.name

      return exclude.includes(answer.id)
        ? options
        : [
            ...options,
            {
              selectedValue: value,
              value: answer.id,
              label,
              readonlyClassName: answersToHighlight.includes(answerId) ? 'text-primary-500' : '',
            },
          ]
    }, [])
  }

  return []
}

interface QuestionAnswersDropdownProps extends Omit<SelectProps<any, boolean>, 'value'> {
  value?: RuleWhen['value']
  customizerProduct: NormalizedCustomizerProduct
  questionId: string
  readonly?: boolean
  exclude?: string[]
  onSelect: OnSelect | OnSelectMulti
  logicalOperator?: 'and' | 'or'
  answersToHighlight?: string[]
}

const QuestionAnswersDropdown = ({
  customizerProduct,
  questionId,
  onSelect,
  readonly = false,
  isMulti,
  exclude = [],
  value,
  logicalOperator,
  answersToHighlight,
  ...rest
}: QuestionAnswersDropdownProps) => (
  <AutoresizeSelect
    readonly={readonly}
    emptyReadonlyPlaceholder={<span>empty</span>}
    isMulti={isMulti}
    options={questionAnswers(customizerProduct, questionId, exclude, value, answersToHighlight)}
    onChange={onSelect}
    value={value}
    placeholder="Answer"
    logicalOperator={logicalOperator}
    isSearchable
    {...rest}
  />
)

export default QuestionAnswersDropdown
