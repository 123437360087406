import { AnyAction } from 'redux'

import { actionTypes as coreActionTypes } from 'builder/build/core'
import { PreviewMode } from 'common/preview/types'

import { CHANGE_PREVIEW_MODE, SET_IS_PREVIEW_READY, SET_DISABLED_MODES } from './actionTypes'

interface State {
  mode: PreviewMode
  isReady: boolean
  disabledModes: PreviewMode[]
}

const initialState: State = { mode: PreviewMode.DESKTOP, isReady: false, disabledModes: [] }

const reducer = (state: State = initialState, action: AnyAction): State => {
  switch (action.type) {
    case CHANGE_PREVIEW_MODE:
      return { ...state, mode: action.payload }
    case SET_IS_PREVIEW_READY:
      return { ...state, isReady: action.payload }
    case SET_DISABLED_MODES:
      return { ...state, disabledModes: action.payload }
    case coreActionTypes.RESET_PRODUCTBUILDER:
      return initialState
    default:
      return state
  }
}

export default reducer
