import { Question } from '@packages/types'
import React from 'react'

import { HorizontalField } from 'builder/build/common/components'
import { WithInformation } from 'common/components'
import Switch from 'common/components/Switch'

interface InputColorPickerAnswerSectionProps {
  question: Question
  updateQuestion: (field: keyof Question, value: boolean) => void
}

const InputColorPickerAnswerSection = ({ question, updateQuestion }: InputColorPickerAnswerSectionProps) => {
  const handleOnChangeShowGradientPicker = (value: boolean) => {
    updateQuestion('showGradientPicker', value)
  }

  const handleOnChangeShowCurrentColorsPicker = (value: boolean) => {
    updateQuestion('showCurrentColorsPicker', value)
  }

  return (
    <div className="p-4">
      <HorizontalField>
        <label>
          <WithInformation tooltip="Gradient picker allow your user to choose the hue and select specific color from the hue spectrum.">
            Gradient picker
          </WithInformation>
        </label>
        <Switch
          checked={question.showGradientPicker}
          onChange={e => handleOnChangeShowGradientPicker(e.target.checked)}
        />
      </HorizontalField>
      <HorizontalField>
        <label>
          <WithInformation tooltip="Current color picker shows selected colors from other color picker.">
            Current colors picker
          </WithInformation>
        </label>
        <Switch
          checked={question.showCurrentColorsPicker}
          onChange={e => handleOnChangeShowCurrentColorsPicker(e.target.checked)}
        />
      </HorizontalField>
    </div>
  )
}

export default InputColorPickerAnswerSection
