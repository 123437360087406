import { Answer } from '@packages/types'
import React, { MouseEventHandler, useEffect, useRef, useState } from 'react'

import { MovablePanel, MovablePanelHeader, Panel } from 'builder/build/common/components'
import { useCustomizerDispatch } from 'builder/build/customizer'
import { Button } from 'common/components'
import { actions as customizationActions } from 'customizer/customization'

import AnswerPanelFactory from './AnswerPanelFactory'

import './AnswerPanel.scss'

export interface AnswerPanelProps {
  answer: Answer
  close: () => void
}

const AnswerPanel = ({ answer, close }: AnswerPanelProps) => {
  const [dragRef, setDragRef] = useState<HTMLDivElement>()
  const customizerDispatch = useCustomizerDispatch()
  const doneButtonRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    customizerDispatch(customizationActions.selectAnswerWhereAvailable(answer.id))
  }, [answer])

  const handleClose: MouseEventHandler<HTMLButtonElement> = () => {
    doneButtonRef.current?.focus()
    close()
  }

  return (
    <MovablePanel dragRef={dragRef}>
      <Panel className="answer-panel">
        <MovablePanelHeader setDragRef={element => setDragRef(element)}>
          <div className="popper-menu-header-section__title">{answer.name}</div>
          <Button onClick={handleClose} ref={doneButtonRef} data-testid="header-close-button" className="!h-7">
            Done
          </Button>
        </MovablePanelHeader>
        <AnswerPanelFactory answer={answer} />
      </Panel>
    </MovablePanel>
  )
}

export default AnswerPanel
