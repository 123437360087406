import { NormalizedCustomizerProduct } from '@packages/types'
import classNames from 'classnames'
import { useFormikContext } from 'formik'
import React, { useState } from 'react'

import { Button, UpdateCount, Input, Modal, ModalProps } from 'common/components'

import { UpdateTypes, ExtraPrice, PricingFormValues } from '../types'
import ExtraPricesAdjustDetails from './ExtraPricesAdjustDetails'

interface UpdateExtraPricesModalProps {
  selectedIds: string[]
  customizerProduct: NormalizedCustomizerProduct
  handleClose: () => void
  modalProps: Omit<ModalProps, 'children'>
}

const UpdateExtraPricesModal = ({
  selectedIds,
  handleClose,
  modalProps,
  customizerProduct,
}: UpdateExtraPricesModalProps) => {
  const { values, setFieldValue, isSubmitting } = useFormikContext<PricingFormValues>()

  const [updateType, setUpdateType] = useState(UpdateTypes.adjust)
  const [value, setValue] = useState(0)

  const selectedExtraPrices = values.extraPrices.filter((extraPrice: ExtraPrice) =>
    selectedIds.includes(`${extraPrice.questionId}/${extraPrice.answerId}`)
  )

  const handleSave = () => {
    const updatedExtraPrices = values.extraPrices.map(extraPrice => {
      const matchingSelectedPrice = selectedExtraPrices.find(
        selectedPrice =>
          selectedPrice.questionId === extraPrice.questionId && selectedPrice.answerId === extraPrice.answerId
      )

      if (matchingSelectedPrice) {
        const updatedPrice = updateType === UpdateTypes.set ? value : +extraPrice.price + value
        return { ...extraPrice, price: updatedPrice.toString() }
      }

      return extraPrice
    })
    setFieldValue('extraPrices', updatedExtraPrices)
    handleClose()
  }

  const isInvalidUpdate = updateType === UpdateTypes.adjust && value === 0

  return (
    <Modal onBackdropClick={handleClose} {...modalProps}>
      <Modal.CloseButton onClick={handleClose} />
      <Modal.Title>Update Extra Prices</Modal.Title>
      <div className="flex flex-col px-6 py-2 border-b border-t border-neutral-100 space-y-3">
        <div className="flex">
          <div className="h-8 flex border-neutral-50 shadow-xs rounded-md">
            <button
              className={classNames('rounded-md focus:outline-none px-3', {
                'ring-1 ring-neutral-200 bg-neutral-50': updateType === UpdateTypes.adjust,
              })}
              onClick={() => setUpdateType(UpdateTypes.adjust)}
            >
              Adjust
            </button>
            <button
              className={classNames('rounded-md focus:outline-none px-3', {
                'ring-1 ring-neutral-200 bg-neutral-50': updateType === UpdateTypes.set,
              })}
              onClick={() => setUpdateType(UpdateTypes.set)}
            >
              Set
            </button>
          </div>
          <div className="ml-auto w-36">
            <Input
              type="number"
              aria-label="Update extra price"
              onChange={e => setValue(+e.target.value)}
              defaultValue="0"
            />
          </div>
        </div>
      </div>
      <ExtraPricesAdjustDetails
        value={value}
        updateType={updateType}
        selectedExtraPrices={selectedExtraPrices}
        customizerProduct={customizerProduct}
        isInvalidUpdate={isInvalidUpdate}
      />
      <Modal.Actions className="justify-between items-center">
        <UpdateCount text="extra price" count={selectedIds.length} />
        <div className="flex space-x-3">
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            type="submit"
            form="pricing-form"
            onClick={handleSave}
            variant="primary"
            disabled={isInvalidUpdate || isSubmitting}
            isLoading={isSubmitting}
          >
            Save
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  )
}

export default UpdateExtraPricesModal
