import React from 'react'

import { Modal, ModalProps } from 'common/components'

interface OnBoardingVideoModalProps extends Omit<ModalProps, 'children'> {
  onClose: () => void
}

const OnBoardingVideoModal = ({ onClose, ...modalProps }: OnBoardingVideoModalProps) => {
  return (
    <Modal className="md:w-[951px]" {...modalProps}>
      <Modal.Details className="pb-4 mb-4">
        <Modal.CloseButton onClick={onClose} />
      </Modal.Details>
      <Modal.Image className="overflow-hidden">
        <iframe
          className="w-full h-[535px]"
          src="https://www.youtube.com/embed/_eY_dn2ldM4?rel=0&autoplay=1"
          title="Build a Jersey Customizer in Just 2 Minutes with Kickflip"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
        ></iframe>
      </Modal.Image>
      <Modal.Details className="pt-3 pb-3.5">
        Want to learn more from videos?
        <a
          href="https://www.youtube.com/@trykickflip?sub_confirmation=1"
          target="_blank"
          className="text-sm text-primary-600"
        >
          &nbsp;Subscribe to our Youtube channel
        </a>
      </Modal.Details>
    </Modal>
  )
}

export default OnBoardingVideoModal
