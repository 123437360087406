import { Product } from '@packages/types'
import { useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import React, { ChangeEventHandler, useContext, useEffect } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { StringParam, useQueryParam } from 'use-query-params'

import { Header, Page, SideMenu } from 'cms/layout'
import { TrackerContext } from 'cms/tracking/components'
import { Button, useModal, useToast, ProductLimitReachedToast } from 'common/components'
import { useIntersect } from 'common/hooks'
import { useProductExportImport, useProductService } from 'common/products'

import { ProductsBlankState, SearchBlankState } from './blankStates'
import ProductsDemoModal from './ProductsDemoModal'
import ProductsFilters, { SortKeyParam, SortOrderParam } from './ProductsFilters'
import ProductsHeader from './ProductsHeader'
import ProductsList from './ProductsList'
import ProductsTabs, { TabParam } from './ProductsTabs'
import SubscriptionConfirmationToast from './SubscriptionConfirmationToast'

const LIMIT = 20

const Products = () => {
  const [ref, inView] = useIntersect({ threshold: 0.5 })
  const [tab] = useQueryParam('tab', TabParam)
  const [search] = useQueryParam('search', StringParam)
  const [sortKey] = useQueryParam('sortKey', SortKeyParam)
  const [sortOrder] = useQueryParam('sortOrder', SortOrderParam)
  const [callback, setCallback] = useQueryParam('callback', StringParam, { updateType: 'replaceIn' })
  const match = useRouteMatch<{ brandName: string }>()
  const history = useHistory()
  const baseUrl = match.params.brandName ? `/brands/${match.params.brandName}` : ''
  const queryClient = useQueryClient()
  const { openCustomToast, openGenericErrorToast } = useToast()
  const productService = useProductService()
  const demoModal = useModal()
  const tracker = useContext(TrackerContext)
  const { importProduct } = useProductExportImport()

  const { data, fetchNextPage, isFetchingNextPage, hasNextPage, isLoading, isFetched } = useInfiniteQuery(
    [...productService.fetchAll.queryKeys, tab, search, sortKey, sortOrder],
    ({ pageParam = 0 }) =>
      productService.fetchAll({
        params: {
          archived: tab === 'archived',
          deleted: tab === 'deleted',
          sortKey,
          sortOrder,
          limit: LIMIT,
          lastIndex: pageParam,
          search,
        },
      }),
    {
      cacheTime: 0,
      keepPreviousData: true,
      getPreviousPageParam: firstPage => {
        const { lastIndex } = firstPage.pagination

        if (lastIndex - LIMIT === 0) return undefined

        return Math.max(lastIndex - LIMIT, 0)
      },
      getNextPageParam: lastPage => {
        const { lastIndex, collectionSize } = lastPage.pagination

        if (lastIndex >= collectionSize) return undefined

        return lastPage.pagination.lastIndex
      },
    }
  )

  const { mutate: autoConnect } = useMutation((productId: string) => productService.autoConnect(productId))
  const { mutate: createProduct } = useMutation(productService.create, {
    onSuccess(product) {
      autoConnect(product.id)
      history.push(`${baseUrl}/products/${product.id}/builder`)
      queryClient.invalidateQueries([...productService.fetchAll.queryKeys, tab])
      tracker.send('account_product_added')
    },
    onError(error: AxiosError & { data?: { status: number; name: string } }) {
      if (error.data?.status === 403 && error.data?.name === 'MaximumActiveProductsException') {
        return openCustomToast(props => (
          <ProductLimitReachedToast onUpgradePlan={() => history.push(`${baseUrl}/subscription`)} {...props} />
        ))
      }
      return openGenericErrorToast('Your product was not created.')
    },
  })

  const handleImportProduct: ChangeEventHandler<HTMLInputElement> = async e => {
    try {
      const product = await importProduct(e)
      autoConnect(product.id)
      history.push(`${baseUrl}/products/${product.id}/builder`)
      queryClient.invalidateQueries([...productService.fetchAll.queryKeys, tab])
    } catch (error) {
      openGenericErrorToast('The product was not imported.')
    }
  }

  useEffect(() => {
    if (inView) {
      fetchNextPage()
    }
  }, [inView])

  useEffect(() => {
    if (callback) {
      openCustomToast(props => <SubscriptionConfirmationToast {...props} />)
      setCallback(undefined)
      tracker.send('account_subscription_activated')
    }
  }, [callback])

  const products = data?.pages.reduce((prev, current) => {
    return [...prev, ...(current.results as Product[])]
  }, [] as Product[])

  const showBlankState = !isLoading && isFetched && !products?.length

  return (
    <main>
      <Header />
      <SideMenu />

      <Page className="flex flex-col">
        <ProductsHeader openDemo={demoModal.open} createProduct={createProduct} importProduct={handleImportProduct} />
        <ProductsTabs />
        <ProductsFilters />

        {!showBlankState && (
          <ProductsList products={products || []} isLoading={isLoading} isFetchingNextPage={isFetchingNextPage} />
        )}

        {showBlankState && search && <SearchBlankState />}
        {showBlankState && !search && <ProductsBlankState createProduct={createProduct} openDemo={demoModal.open} />}

        <div ref={ref} className="flex justify-center pt-10 self-center">
          {hasNextPage && !isFetchingNextPage && (
            <Button variant="default" onClick={() => fetchNextPage()}>
              Load more
            </Button>
          )}
        </div>

        {demoModal.isVisible && <ProductsDemoModal onClose={demoModal.close} {...demoModal.modalProps} />}
      </Page>
    </main>
  )
}

export default Products
