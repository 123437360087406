import { AnyAction } from 'redux'

import { actionTypes as coreTypes } from 'builder/build/core'

import * as types from './actionTypes'
import { BuilderMode, LeftPanel } from './types'

interface State {
  builderMode: BuilderMode
  leftPanel: { tab: LeftPanel; options: { initialFocusInputName?: string } }
  questionInVisualEdition: string | null
}

export const initialState: State = {
  builderMode: BuilderMode.customizer,
  leftPanel: { tab: LeftPanel.questions, options: {} },
  questionInVisualEdition: null,
}

export default (state = initialState, action: AnyAction): State => {
  switch (action.type) {
    case types.SET_BUILDER_MODE:
      return { ...state, builderMode: action.payload }
    case types.SET_LEFT_PANEL:
      return { ...state, leftPanel: action.payload }
    case coreTypes.RESET_PRODUCTBUILDER:
      return initialState
    default:
      return state
  }
}
