import { Question } from '@packages/types'
import React from 'react'

import { Select } from 'common/components'

type OutputFormatSectionProps = {
  question: Question
  onChange: (value: Question['outputFormat']) => void
}

const outputFormatOptions = {
  hex: 'Hex',
  rgb: 'RGB',
  keepUserFormat: 'Keep user format',
}

const options = [
  { label: 'Hex', value: 'hex' },
  { label: 'RGB', value: 'rgb' },
  { label: 'Keep user format', value: 'keepUserFormat' },
] as const

const OutputFormatSection = ({ question, onChange }: OutputFormatSectionProps) => {
  return (
    <div className="panel__section">
      <div className="mb-4">
        <label className="font-medium" htmlFor="output-format">
          Output format
        </label>
      </div>
      <Select
        name="output-format"
        aria-label="output-format"
        value={{
          value: question?.outputFormat == null ? 'hex' : question.outputFormat,
          label: question?.outputFormat == null ? 'Hex' : outputFormatOptions[question.outputFormat],
        }}
        onChange={option => onChange(option?.value)}
        options={options}
      />
    </div>
  )
}

export default OutputFormatSection
