import { EntityType } from '@packages/types'
import { createSelector } from 'reselect'

import { selectors as partsSelectors } from 'builder/build/parts'
import { selectors as printAreaSelectors } from 'builder/build/printAreas'
import { RootState } from 'cms/store'

import { customizerProductSelector } from '../customizerProducts/selectors'
import { Layer, PrintAreaLayer } from './types'

const propsSelector = (_: RootState, id: string) => id

export const layerTreeSelector = createSelector(
  customizerProductSelector,
  partsSelectors.partsSelector,
  printAreaSelectors.printAreasSelector,
  (customizerProduct, parts, printAreas) => {
    const layers: Layer[] = []

    customizerProduct.parts.forEach(partId => {
      const part = parts[partId]

      if (!!part.printArea) {
        const printArea = printAreas[part.printArea]
        const printAreaLayer = layers.find(layer => layer.id === printArea.id) as PrintAreaLayer

        if (!!printAreaLayer) {
          printAreaLayer.children.unshift(part)
        } else {
          layers.push({ ...printArea, children: [part] })
        }
      } else {
        layers.push(part)
      }
    })

    return layers.reverse()
  }
)

export const flattenedLayersSelector = createSelector(layerTreeSelector, layers => {
  const flattenedLayers = layers.reduce<string[]>((acc, layer) => {
    if (layer.entityType === EntityType.PrintArea) return [...acc, ...layer.children.map(({ id }) => id)]

    return [...acc, layer.id]
  }, [])

  return flattenedLayers.reverse()
})

export const layersByIdSelector = createSelector(layerTreeSelector, propsSelector, (layers, layerId) => {
  for (const layer of layers) {
    if (layer.id === layerId) return layer

    if (layer.entityType === EntityType.PrintArea) {
      const childLayer = layer.children.find(({ id }) => id === layerId)
      if (!!childLayer) return childLayer
    }
  }

  return
})
