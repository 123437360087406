import classNames from 'classnames'
import React, { useState } from 'react'

import { Alert, Button, Input, Modal, ModalProps, UpdateCount, useToast } from 'common/components'
import { ToastType } from 'common/components/toast/types'
import { trpc } from 'common/hooks/trpc'
import { UpdateTypes } from 'common/variants'

import StockAdjustDetails from './StockAdjustDetails'

interface StockAdjustModalProps {
  ids: string[]
  locationId: string
  onSuccess: () => void
  handleClose: () => void
  modalProps: Omit<ModalProps, 'children'>
}

const StockAdjustModal = ({ ids, locationId, onSuccess, handleClose, modalProps }: StockAdjustModalProps) => {
  const { openToast } = useToast()

  const [updateType, setUpdateType] = useState(UpdateTypes.adjust)
  const [value, setValue] = useState(0)

  const { mutate: bulkUpdate, isLoading: isSaving } = trpc.variant.bulkUpdateStock.useMutation({
    onSuccess: () => {
      onSuccess()
      openToast('Inventory was successfully saved.', ToastType.success)
    },
    onError: () => {
      openToast('Could not save inventory please try again.', ToastType.warning)
    },
  })

  const handleSave = () => {
    bulkUpdate(
      {
        locationId,
        ids,
        operation: updateType,
        amount: value,
      },
      { onSettled: handleClose }
    )
  }

  const isInvalidUpdate = updateType === UpdateTypes.adjust && value === 0

  return (
    <Modal onBackdropClick={handleClose} {...modalProps}>
      <Modal.CloseButton onClick={handleClose} />
      <Modal.Title>Update stocks</Modal.Title>
      <div className="flex flex-col px-6 py-2 border-b border-t border-neutral-100 space-y-3">
        <div className="flex">
          <div className="h-8 flex border-neutral-50 shadow-xs rounded-md">
            <button
              className={classNames('rounded-md focus:outline-none px-3', {
                'ring-1 ring-neutral-200 bg-neutral-50': updateType === UpdateTypes.adjust,
              })}
              onClick={() => setUpdateType(UpdateTypes.adjust)}
            >
              Adjust
            </button>
            <button
              className={classNames('rounded-md focus:outline-none px-3', {
                'ring-1 ring-neutral-200 bg-neutral-50': updateType === UpdateTypes.set,
              })}
              onClick={() => setUpdateType(UpdateTypes.set)}
            >
              Set
            </button>
          </div>
          <div className="ml-auto w-36">
            <Input type="number" aria-label="Update stock" onChange={e => setValue(+e.target.value)} defaultValue="0" />
          </div>
        </div>
      </div>
      {updateType === UpdateTypes.adjust && (
        <div className="flex flex-col px-6 pt-2 pb-2 border-b border-neutral-100">
          <Alert variant="info">Displayed final quantities may differ, they will be calculated uppon saving.</Alert>
        </div>
      )}
      <StockAdjustDetails ids={ids} locationId={locationId} updateType={updateType} value={value} />
      <Modal.Actions className="justify-between items-center">
        <UpdateCount text="stock" count={ids.length} />
        <div className="flex space-x-3">
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="primary" onClick={handleSave} disabled={isInvalidUpdate || isSaving} isLoading={isSaving}>
            Save
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  )
}

export default StockAdjustModal
