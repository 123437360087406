import { Question, Group, PrintArea } from '@packages/types'
import React from 'react'

import { TypeIcons } from 'builder/build/common/components'
import { selectors as customizerProductsSelectors } from 'builder/build/customizerProducts'
import { useSelector } from 'cms/hooks'
import { UniversalSearchList } from 'common/components'
import ArrowRight from 'icons/bold/52-Arrows-Diagrams/01-Arrows/arrow-right-1.svg'

interface QuestionListItemProps {
  query: string
  entity: Question | Group | PrintArea
  onClick: () => void
}

const EntitySearchResult = ({ query, entity, onClick }: QuestionListItemProps) => {
  const ancestors = useSelector(state => customizerProductsSelectors.ancestorsSelector(state, entity.id))

  return (
    <UniversalSearchList.Result onClick={onClick}>
      <div className="flex items-center">
        <TypeIcons entity={entity} />
        <UniversalSearchList.HighlightableText className="ml-2.5" text={entity.name} highlight={query} />
      </div>
      <UniversalSearchList.SupportingText className="flex items-center space-x-1">
        {ancestors.map((ancestor, i) => (
          <React.Fragment key={i}>
            <span>{ancestor.name}</span>
            {i < ancestors.length - 1 && <ArrowRight className="h-2 w-2 fill-neutral-300" />}
          </React.Fragment>
        ))}
      </UniversalSearchList.SupportingText>
    </UniversalSearchList.Result>
  )
}

export default EntitySearchResult
