import { PaymentStrategy, Subscription, AddonName, ECommerce, OnlineStore, OnlineStoreStatus } from '@packages/types'

export const subscriptionHasWhiteLabel = (subscription: Subscription) => {
  return (
    subscription.plan.features?.whiteLabel || subscription.addons.find(addon => addon.name === AddonName.WhiteLabel)
  )
}

export const storeIsInstalled = (subscription: Subscription, onlineStores: OnlineStore[]) => {
  const stores =
    subscription.paymentStrategy === PaymentStrategy.Shopify
      ? onlineStores.filter(({ eCommerce }) => eCommerce === ECommerce.Shopify)
      : onlineStores

  return stores.some(({ status }) => status === OnlineStoreStatus.Installed)
}

export const subscriptionHasError = (subscription: Subscription) =>
  subscription.paymentStrategy === PaymentStrategy.Stripe && subscription.stripePaymentIntentRequiringAction != null
