import { NormalizedCustomizerProduct } from '@packages/types'
import React, { useState } from 'react'

import { Modal, Button, ModalProps } from 'common/components'

import { combinationExists } from '../utils'
import SelectQuestions from './SelectQuestions'
import useTotalVariantsCount, { maximumAllowedVariants } from './useTotalVariantsCount'

export interface CreateVariantsModalProps {
  isSaving: boolean
  onCreate: (variantsCombinations: string[][]) => void
  onCancelClick: (e: React.MouseEvent) => void
  customizerProduct: NormalizedCustomizerProduct
  existingCombinations?: string[][]
  modalProps: Omit<ModalProps, 'children'>
}

const CreateVariantsModal = ({
  isSaving,
  onCancelClick,
  customizerProduct,
  onCreate,
  existingCombinations = [],
  modalProps,
}: CreateVariantsModalProps) => {
  const [combination, setCombination] = useState<(string | undefined)[]>([undefined])

  const { totalCount, isOverMax } = useTotalVariantsCount(
    combination.filter(questionId => questionId !== undefined) as string[],
    customizerProduct
  )

  const combinationAlreadyExists = combinationExists(combination, existingCombinations)

  return (
    <Modal {...modalProps}>
      <Modal.Title>Create variants</Modal.Title>
      <Modal.Details>
        Select which questions should be combined to create your variants. We will create a variant for every
        combination of answers from the selected questions.
        <br />
        <br />A maximum of {maximumAllowedVariants} variants can be created for a combination.
      </Modal.Details>
      <SelectQuestions
        totalVariantCount={totalCount}
        isOverMaxVariantCount={isOverMax}
        customizerProduct={customizerProduct}
        questionIds={combination}
        setQuestionIds={setCombination}
      />
      <Modal.Actions className="justify-between">
        {combinationAlreadyExists && (
          <Modal.ActionsMessage className="text-error-default">This combination already exists</Modal.ActionsMessage>
        )}
        <Button onClick={onCancelClick}>Cancel</Button>
        <Button
          variant="primary"
          isLoading={isSaving}
          disabled={
            combinationAlreadyExists ||
            isOverMax ||
            isSaving ||
            combination.filter(Boolean).length !== combination.length
          }
          onClick={() => onCreate([...existingCombinations, combination as string[]])}
        >
          Create
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default CreateVariantsModal
