import { TextPart } from '@packages/types'
import React from 'react'

import { hooks as partsHooks } from 'builder/build/parts'
import {
  DefaultColorSection,
  DefaultFontSection,
  DefaultFontSizeSection,
  DefaultOutlineSection,
} from 'builder/build/questions/components'

interface TextDefaultSettingsProps {
  part?: TextPart
  disableAll?: boolean
}

const TextDefaultSettings = ({ part, disableAll = false }: TextDefaultSettingsProps) => {
  const [colorAnswer, customizerSelectedColorAnswer] = partsHooks.useSelectPartAnswer(part?.color)
  const [fontAnswer, customizerSelectedFontAnswer] = partsHooks.useSelectPartAnswer(part?.font)
  const [fontSizeAnswer, customizerSelectedFontSizeAnswer] = partsHooks.useSelectPartAnswer(part?.fontSize)
  const [outlineAnswer, customizerSelectedOutlineAnswer] = partsHooks.useSelectPartAnswer(part?.outline)

  if (!part) return null

  return (
    <div className="flex items-center">
      <DefaultColorSection
        part={part}
        answer={colorAnswer ?? customizerSelectedColorAnswer}
        questionId={!colorAnswer ? part.color : undefined}
        disabled={disableAll}
      />
      <DefaultFontSection
        part={part}
        answer={fontAnswer ?? customizerSelectedFontAnswer}
        questionId={!fontAnswer ? part.font : undefined}
        disabled={disableAll}
      />
      <DefaultOutlineSection
        part={part}
        answer={outlineAnswer ?? customizerSelectedOutlineAnswer}
        questionId={!outlineAnswer ? part.outline : undefined}
        disabled={disableAll}
      />
      <DefaultFontSizeSection
        part={part}
        answer={fontSizeAnswer ?? customizerSelectedFontSizeAnswer}
        questionId={!fontSizeAnswer ? part.fontSize : undefined}
        disabled={disableAll}
      />
    </div>
  )
}

export default TextDefaultSettings
