import { Part, QuestionType } from '@packages/types'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { actions as groupsActions } from 'builder/build/groups'
import { actions as partsActions } from 'builder/build/parts'
import { useDispatch } from 'cms/hooks'

const useCreateQuestion = (part: Part, questionType: QuestionType) => {
  const dispatch = useDispatch()
  const history = useHistory()

  return useCallback(() => {
    const action = dispatch(groupsActions.createRootQuestionByType(QuestionType.Material, {}))
    const questionId = action.payload.id

    dispatch(partsActions.patchPart(part.id, { material: questionId }))

    history.push(`/questions/${questionId}`)
  }, [dispatch, part, questionType, history])
}

export default useCreateQuestion
