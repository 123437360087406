import {
  DenormalizedCustomizableLogoPart,
  DenormalizedCustomizablePart,
  DenormalizedCustomizableTextPart,
  EntityType,
  PartType,
  PrintArea,
} from '@packages/types'
import React from 'react'

import { hooks as customizerHooks } from 'builder/build/customizer'
import { twoDDisplayerActions } from 'customizer/2dDisplayer'
import { ClippingGroup, DrawingNode, MultilineTextNode, Node, TextNode } from 'customizer/2dDisplayer/types'
import { actions as customizationActions } from 'customizer/customization'

import Info from './Info'

export interface NodeInfoProps {
  entity: DenormalizedCustomizablePart | PrintArea
  node: DrawingNode | ClippingGroup
}

const getLogoTooltipMessage = (part: DenormalizedCustomizableLogoPart) => {
  if (
    !part.printArea ||
    (!part.allowedTransforms?.move && !part.allowedTransforms?.resize && !part.allowedTransforms?.rotate)
  )
    return 'Position and resize the area where the logo will appear. The logo will always be resized to fit within this area.'

  return 'Position and resize the default position of the logo. This logo is editable so your customers will be able to edit it.'
}

const getTextTooltipMessage = (part: DenormalizedCustomizableTextPart, node: TextNode) => {
  if (
    !part.printArea ||
    (!part.allowedTransforms?.move && !part.allowedTransforms?.resize && !part.allowedTransforms?.rotate)
  ) {
    if ((node as MultilineTextNode).position.shape)
      return 'Position and resize the area where the text will appear. The text will be cut off it it is too long to fit.'

    return 'Position and resize the line on which the text will appear. The text will be resized to fit.'
  }

  return 'Position and resize the default position of the text. This text is editable so your customers will be able to edit it.'
}

const getTooltipMessage = (entity: PrintArea | DenormalizedCustomizablePart, node: Node) => {
  if (entity.entityType === EntityType.PrintArea)
    return 'Position the print area to match where it would appear on your product.'

  switch (entity.type) {
    case PartType.Logo:
      return getLogoTooltipMessage(entity)
    case PartType.Text:
      return getTextTooltipMessage(entity, node as TextNode)
    default:
      return 'Position and resize the area of the component.'
  }
}

const NodeInfo = ({ entity, node }: NodeInfoProps) => {
  const customizerDispatch = customizerHooks.useCustomizerDispatch()

  const handleDoneClick = () => {
    if (entity.entityType === EntityType.PrintArea) customizerDispatch(twoDDisplayerActions.editPrintArea(null))
    else customizerDispatch(customizationActions.stopEditPart())
  }

  return <Info message={node.name} tooltip={getTooltipMessage(entity, node)} onDoneClick={handleDoneClick} />
}

export default NodeInfo
