import Moveable, { Able, MoveableManagerInterface, Renderer } from 'react-moveable'

import { pathUtils } from 'common/drawing'
import { rotatePoint } from 'utils/math'

import { PathMoveableProps } from '../types'
import { getOffsetBezier } from '../utils'

interface Renderable extends Able {
  props: readonly 'style'[]
  render: (moveable: MoveableManagerInterface<PathMoveableProps>, React: Renderer) => JSX.Element
}

const PathHandleable: Renderable = {
  name: 'path-handleable',
  props: ['style'] as const,
  events: [] as const,
  always: true,
  render: (moveable, React) => {
    const { target } = moveable.getState()

    const path = target?.getAttributeNS(null, 'd') ?? ''

    const bezier = pathUtils.pathToBezier(path)
    const offsetBezier = getOffsetBezier(bezier, moveable.getState())

    const handles = []
    const moveables = []

    for (let i = 0; i < offsetBezier.length; i = i + 2) {
      handles.push(
        <div
          key={`control-${i}`}
          id={`moveable-control-${i}`}
          className="moveable-control"
          style={{
            transform: `translate(${offsetBezier[i]}px, ${offsetBezier[i + 1]}px)`,
            position: 'absolute',
            width: '12px',
            height: '12px',
            top: `${-6}px`,
            left: `${-6}px`,
            transformOrigin: 'center center',
            borderRadius: '50%',
            zIndex: 999999,
            margin: 0,
          }}
        />
      )
      moveables.push(
        <Moveable
          key={`moveable-${i}`}
          target={`#moveable-control-${i}`}
          elementGuidelines={[
            '#moveable-control-0',
            '#moveable-control-2',
            '#moveable-control-4',
            '#moveable-control-6',
          ].filter(id => id !== `#moveable-control-${i}`)}
          verticalGuidelines={['0%', '50%', '100%']}
          horizontalGuidelines={['0%', '50%', '100%']}
          draggable
          snappable
          snapElements
          snapContainer={moveable.props.snapContainer}
          isDisplaySnapDigit={false}
          isDisplayInnerSnapDigit={false}
          snapDirections={{ center: true, middle: true }}
          elementSnapDirections={{ center: true, middle: true }}
          origin={false}
          hideDefaultLines
          onBeforeRenderStart={e => {
            e.moveable.setState({
              originalInputEvent: e.inputEvent,
            })

            e.inputEvent.target.className = `${e.inputEvent.target.className} moveable-control-active`
          }}
          onRenderEnd={e => {
            e.moveable.state.originalInputEvent.target.className =
              e.moveable.state.originalInputEvent.target.className.replace('moveable-control-active', '')
            e.moveable.setState({
              originalInputEvent: undefined,
            })
          }}
          onDragStart={() => {
            moveable.setState({ transformState: 'dragging-handle' })
          }}
          onDrag={e => {
            const target = moveable.getState().target
            if (!target) return
            const { transformOrigin, renderLines, rotation } = moveable.getState()
            const offsetX = -transformOrigin[0]
            const offsetY = -transformOrigin[1]

            const translate = rotatePoint(
              {
                x: e.beforeTranslate[0],
                y: e.beforeTranslate[1],
              },
              -rotation,
              { x: renderLines[0][0][0], y: renderLines[0][0][1] }
            )
            moveable.props.onDragHandle({
              ...e,
              target,
              beforeTranslate: [
                translate.x + offsetX - renderLines[0][0][0],
                translate.y + offsetY - renderLines[0][0][1],
              ],

              handleIndex: i,
            })
            moveable.forceUpdate()
          }}
          onDragEnd={e => {
            moveable.props.onDragEnd?.(e)
            moveable.setState({ transformState: undefined })
          }}
        />
      )
    }

    return (
      <div key="control-1">
        {handles}
        {moveables}
      </div>
    )
  },
}

export default PathHandleable
