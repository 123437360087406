import { useContext } from 'react'

import { Translation } from 'cms/translations/types'
import { apiClient, createQuery } from 'common/api'
import { Paginated } from 'common/api/types'
import { TenantContext } from 'common/tenant'

export interface CreateTranslationValues {
  code: string
  name: string
}

const useTranslationService = () => {
  const tenant = useContext(TenantContext)

  return {
    fetchAll: createQuery([tenant, 'translations'], async () => {
      const { data } = await apiClient.get<Translation[]>(`/brands/${tenant}/translations`)
      return data
    }),
    fetch: createQuery([tenant, 'translation'], async languageCode => {
      const { data } = await apiClient.get<Paginated<Translation>>(
        `/brands/${tenant}/translations?code=${languageCode}`
      )
      return data.results[0]
    }),
    create: async (translation: CreateTranslationValues) => {
      const { data } = await apiClient.post<Translation>(`/brands/${tenant}/translations`, translation)
      return data
    },
    update: async (id: string, translation: Translation) => {
      const { data } = await apiClient.post<Translation>(`/brands/${tenant}/translations/${id}`, translation)
      return data
    },
    delete: async (id: string) => {
      return apiClient.delete<void, void>(`/brands/${tenant}/translations/${id}`)
    },
  }
}

export default useTranslationService
