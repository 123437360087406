import { AppDispatch, RootState } from 'cms/store'
import { PreviewMode } from 'common/preview/types'
import { twoDDisplayerActions } from 'customizer/2dDisplayer'
import { AppDispatch as CustomizerDispatch } from 'customizer/store'

import { REMOVE_PERSONALISED_ANSWERS } from '../core/actionTypes'
import {
  UPDATE_PREVIEW,
  CHANGE_PREVIEW_MODE,
  SET_IS_PREVIEW_READY,
  SET_DISABLED_MODES,
  ADD_ANSWER_PREVIEW,
  REMOVE_ANSWER_PREVIEW,
  ALLOW_EDIT_PRINT_AREA,
} from './actionTypes'

export const updatePreview = (productBuilder: RootState['productBuilder']) => ({
  type: UPDATE_PREVIEW,
  payload: productBuilder,
})

export const removeCustomAnswers = (questionId: string) => ({
  type: REMOVE_PERSONALISED_ANSWERS,
  payload: { questionId },
})

export const addAnswerPreview = (questionId: string, answerId: string) => ({
  type: ADD_ANSWER_PREVIEW,
  payload: { questionId, answerId },
})

export const removeAnswerPreview = (questionId: string, answerId: string) => ({
  type: REMOVE_ANSWER_PREVIEW,
  payload: { questionId, answerId },
})

export const changePreviewMode = (previewMode: PreviewMode) => (dispatch: AppDispatch | CustomizerDispatch) => {
  dispatch({ type: CHANGE_PREVIEW_MODE, payload: previewMode })
  dispatch(twoDDisplayerActions.setZoom(0))
}

export const setIsPreviewReady = (isReady: boolean) => ({ type: SET_IS_PREVIEW_READY, payload: isReady })

export const setDisabledModes = (disabledModes: PreviewMode[]) => ({
  type: SET_DISABLED_MODES,
  payload: disabledModes,
})

export const allowEditPrintArea = (printAreaId: string | null) => ({
  type: ALLOW_EDIT_PRINT_AREA,
  payload: printAreaId,
})
