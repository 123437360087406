import { Question, QuestionType } from '@packages/types'
import classNames from 'classnames'
import React, { useState } from 'react'

import { TypeIcon, MultiIconsButton, Card, TypeIcons } from 'builder/build/common/components'
import Separator from 'builder/build/common/components/Separator'
import { IconButton, Input } from 'common/components'
import XIcon from 'icons/bold/01-Interface Essential/33-Form-Validation/close.svg'

interface SelectOrCreateQuestionMenuProps {
  questionType: QuestionType
  questions: Question[]
  onSelectQuestion: (question: Question) => void
  onCreateQuestion: () => void
}

const namesByType = {
  [QuestionType.Material]: 'Color',
  [QuestionType.Image]: 'Image',
  [QuestionType.Logo]: 'Logo',
  [QuestionType.Color]: 'Color',
  [QuestionType.Text]: 'Text',
  [QuestionType.Font]: 'Font',
  [QuestionType.FontSize]: 'Font size',
  [QuestionType.Value]: 'None',
  [QuestionType.Outline]: 'Outline',
} as { [key in QuestionType]: string }

const smallTextTypes = [QuestionType.Color, QuestionType.Outline, QuestionType.FontSize]

const SelectOrCreateQuestionMenu = ({
  questionType,
  questions,
  onSelectQuestion,
  onCreateQuestion,
}: SelectOrCreateQuestionMenuProps) => {
  const [filter, setFilter] = useState('')

  const questionTypeName = namesByType[questionType].toLowerCase()

  const filteredQuestions = questions.filter(question => question.name.includes(filter))

  return (
    <div className="w-[224px]">
      <div className="p-4">
        <MultiIconsButton
          className={classNames('add-button--primary add-button--full-width', {
            '!text-xs': smallTextTypes.includes(questionType),
          })}
          onClick={onCreateQuestion}
          Icon={<TypeIcon entityType="DISPLAY_TYPE" specificType={questionType} preventStyling className="w-3.5" />}
        >
          New {questionTypeName} question
        </MultiIconsButton>
      </div>
      <Separator>or</Separator>
      <div className="p-4 space-y-2">
        <div className="font-medium">Select existing question</div>
        <Input
          placeholder="Search questions..."
          value={filter}
          onChange={e => setFilter(e.target.value)}
          rightAddon={
            filter !== '' && (
              <IconButton
                small
                variant="subtle"
                className="-mr-2"
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  setFilter('')
                }}
                Icon={XIcon}
              />
            )
          }
        />
      </div>
      <div className="max-h-[400px] overflow-y-auto space-y-2">
        {filteredQuestions.map(question => {
          return (
            <Card key={question.id} className="mx-2 last:mb-2" onClick={() => onSelectQuestion(question)}>
              <TypeIcons entity={question} />
              <span className="ml-2.5">{question.name}</span>
            </Card>
          )
        })}
      </div>
    </div>
  )
}

export default SelectOrCreateQuestionMenu
