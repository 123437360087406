import React from 'react'

import { StockCell as InventoryStockCell, StockCellProps as InventoryStockCellProps } from 'cms/inventory/components'

import { FormikVariants } from '../types'
import useSharedSkus from './useSharedSkus'

export interface StockCellProps extends Omit<InventoryStockCellProps, 'sharedCount'> {
  locationId: string
  sku: string
  formik: FormikVariants
}

const StockCell = ({ locationId, sku, formik, ...props }: StockCellProps) => {
  const skuSharedData = useSharedSkus(locationId, sku, formik)

  return <InventoryStockCell {...props} sharedCount={skuSharedData?.variantIds.length} />
}

export default StockCell
