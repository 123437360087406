import lang from '@packages/data/badwords.json'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import classNames from 'classnames'
import { useFormik } from 'formik'
import React from 'react'

import { Page, SettingsHeader, SettingsSideMenu } from 'cms/layout'
import { Button, Card, InputField, TextArea, useToast } from 'common/components'
import { ToastType } from 'common/components/toast/types'

import { useWordFilterService } from '../hooks'
import { WordFilterFormValues } from '../types'

const WordFilter = () => {
  const queryClient = useQueryClient()
  const wordFilterService = useWordFilterService()
  const { openToast, openGenericErrorToast } = useToast()
  const { data: wordFilter, isLoading } = useQuery(wordFilterService.fetch.queryKeys, wordFilterService.fetch)

  const { mutate: updateWordFilter, isLoading: isUpdating } = useMutation(wordFilterService.update, {
    onSuccess: () => {
      openToast('Word filters were successfully saved!', ToastType.success)
      queryClient.invalidateQueries(wordFilterService.fetch.queryKeys)
    },
    onError: () => openGenericErrorToast('There was an error updating the word filters.'),
  })

  const formik = useFormik<WordFilterFormValues>({
    initialValues: { words: wordFilter ? wordFilter.words.join('\n') : '' },
    onSubmit: (values, { setSubmitting }) => {
      const wordArray = values.words ? values.words.split('\n') : []
      return updateWordFilter({ words: wordArray }, { onSettled: () => setSubmitting(false) })
    },
    enableReinitialize: true,
  })

  const isActive = !!wordFilter?.isActive

  return (
    <main>
      <SettingsHeader />
      <SettingsSideMenu />

      <Page>
        <Page.Header>
          <h1>Bad words</h1>
        </Page.Header>

        <Page.Section>
          <Page.Aside
            title="Bad words filter"
            description="Activate the bad words filter to prevent certain words from being used in text questions on your products. "
          >
            {!isLoading && (
              <span className="text-xs text-neutral-600">
                Currently:{' '}
                <span className={classNames({ 'text-success-default': isActive, 'text-error-default': !isActive })}>
                  {isActive ? 'Enabled' : 'Disabled'}
                </span>
                .
              </span>
            )}
          </Page.Aside>

          <Page.Content className="flex justify-end">
            <Button onClick={() => updateWordFilter({ isActive: !isActive })}>{isActive ? 'Disable' : 'Enable'}</Button>
          </Page.Content>
        </Page.Section>

        <Page.Separator />

        <Page.Section>
          <Page.Aside
            className="flex-1"
            title="List"
            description="Add one word or idiom per line or use our default list. (Search with ctrl+f or cmd+f.)"
          />

          <Page.Content>
            <Card className="flex-col w-full">
              <form onSubmit={formik.handleSubmit} noValidate autoComplete="off">
                <Card.Section>
                  <InputField className="mb-6 w-full">
                    <TextArea
                      minRows={18}
                      maxRows={18}
                      autoComplete="off"
                      id="words"
                      name="words"
                      value={formik.values.words}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={!isActive || isUpdating}
                    />
                  </InputField>
                </Card.Section>

                <Card.Footer className="justify-between">
                  <Button
                    type="button"
                    className="w-fit"
                    onClick={() => formik.setFieldValue('words', lang.words.join('\n'))}
                    disabled={!isActive || isUpdating}
                  >
                    Apply default
                  </Button>

                  <div className="flex space-x-3">
                    <Button
                      type="button"
                      className="w-fit"
                      onClick={() => formik.resetForm()}
                      disabled={!isActive || !formik.dirty || isUpdating}
                    >
                      Discard
                    </Button>

                    <Button
                      variant="primary"
                      type="submit"
                      className="w-fit"
                      isLoading={isUpdating}
                      disabled={!isActive || !formik.dirty || isUpdating}
                    >
                      Save
                    </Button>
                  </div>
                </Card.Footer>
              </form>
            </Card>
          </Page.Content>
        </Page.Section>
      </Page>
    </main>
  )
}

export default WordFilter
