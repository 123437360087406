import { Product } from '@packages/types'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { clamp } from 'lodash'
import React, { useRef, useContext, useEffect, useState } from 'react'

import { utils as coreUtils } from 'builder/build/core'
import { updateProductName } from 'builder/build/customizerProducts/actions'
import GlobalRouterContext from 'builder/common/GlobalRouterContext'
import { useDispatch } from 'cms/hooks'
import { useToast, Input } from 'common/components'
import { ToastType } from 'common/components/toast/types'
import { trpc } from 'common/hooks/trpc'
import { useProductService } from 'common/products'
import classMerge from 'utils/classMerge'

const fiveCharactersWidth = 40
const fiftyCharactersWidth = 380

const TopBarProductNameEntryPoint = () => {
  const { match } = useContext(GlobalRouterContext)
  return coreUtils.isPathDemoProduct(match.path) ? <TopBarDemoName /> : <TopBarProductName />
}

const TopBarProductName = () => {
  const productService = useProductService()
  const { match } = useContext(GlobalRouterContext)

  const { data: product, isLoading: isLoadingProduct } = useQuery(
    [...productService.fetch.queryKeys, match.params.productId],
    () => productService.fetch(match.params.productId)
  )

  const updateName = (name: string) => productService.update(match.params.productId, { name })

  return <TopBarName product={product} isLoadingProduct={isLoadingProduct} onUpdateName={updateName} />
}

const TopBarDemoName = () => {
  const dispatch = useDispatch()
  const { match } = useContext(GlobalRouterContext)

  const { data: demo, isLoading: isLoadingDemo } = trpc.demoProduct.get.useQuery(match.params.productId, {
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  })

  const updateName = (name: string) => {
    dispatch(updateProductName(name))
  }

  return <TopBarName product={demo} isLoadingProduct={isLoadingDemo} onUpdateName={updateName} isDemo />
}

const TopBarName = ({
  product,
  isLoadingProduct,
  onUpdateName,
  isDemo,
}: {
  product: { name: string; demo?: boolean } | undefined
  isLoadingProduct: boolean
  onUpdateName?: (name: string) => Promise<Product> | any
  isDemo?: boolean
}) => {
  const productService = useProductService()
  const queryClient = useQueryClient()
  const { openToast } = useToast()
  const { match } = useContext(GlobalRouterContext)

  const inputRef = useRef<HTMLInputElement>(null)
  const sizerRef = useRef<HTMLDivElement>(null)

  const [inputValue, setInputValue] = useState('')
  const [inputWidth, setInputWidth] = useState(fiveCharactersWidth)

  const { mutate: updateName } = useMutation(
    (name: string) => {
      return onUpdateName ? onUpdateName(name) : Promise.reject()
    },
    {
      onSuccess: (_result, name) => {
        if (isDemo) return
        openToast(`Product was successfully renamed to ${name}!`, ToastType.success)
        queryClient.invalidateQueries([...productService.fetch.queryKeys, match.params.productId])
      },
      onError: (error: AxiosError, name) => {
        if (error.status == 422) {
          openToast('Unable to update product name on your shop', ToastType.warning)
        } else {
          openToast(`A product named ${name} already exists`, ToastType.warning)
          updateInputValue(product!.name)

          if (!isDemo) return
          queryClient.invalidateQueries([...productService.fetch.queryKeys, match.params.productId])
        }
      },
    }
  )

  const updateInputValue = (value: string) => {
    sizerRef.current!.textContent = value
    const textWidth = sizerRef.current!.scrollWidth + 2
    setInputWidth(clamp(textWidth, fiveCharactersWidth, fiftyCharactersWidth))
    setInputValue(value)
  }

  const handleBlur = async (e: React.FocusEvent<HTMLInputElement, Element>) => {
    const newName = e.target.value

    const isNameInvalid = newName !== product!.name && newName.match(/_DELETED_/)

    if (isNameInvalid) openToast('Product name cannot include _DELETED_', ToastType.warning)
    if (!newName || isNameInvalid) return updateInputValue(product!.name)
    if (newName !== product!.name && onUpdateName) updateName(newName)
  }

  useEffect(() => {
    if (product?.name) updateInputValue(product.name)
  }, [product?.name])

  if (isLoadingProduct || !product) return null

  return (
    <div className="flex ml-2 lg:ml-4 z-[2]">
      <Input
        ref={inputRef}
        placeholder="Name"
        aria-label="Product name"
        autoComplete="off"
        value={inputValue}
        onChange={e => updateInputValue(e.target.value)}
        onBlur={handleBlur}
        className={classMerge('border-opacity-0 focus:border-opacity-100 pl-1 pr-1', {
          '!text-neutral-800 !bg-white': isDemo,
          'hover:border-opacity-100': !isDemo,
        })}
        inputClassName={isDemo ? 'text-neutral-800 bg-white' : ''}
        style={{ width: inputWidth }}
        disabled={isDemo}
      />
      <div ref={sizerRef} className="invisible absolute top-0 left-0 h-0 overflow-scroll whitespace-pre" />
    </div>
  )
}

export default TopBarProductNameEntryPoint
