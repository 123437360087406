import classNames from 'classnames'
import React from 'react'

import { WithInformation } from 'common/components'
import Checkmark from 'icons/bold/01-Interface Essential/33-Form-Validation/check-1.svg'

export interface NodeInfoProps {
  message: React.ReactNode
  tooltip: string
  onDoneClick: () => void
}

const Info = ({ message, tooltip, onDoneClick }: NodeInfoProps) => {
  return (
    <div
      className={classNames(
        'bg-neutral-500 text-white shadow-xs pr-1 py-1 rounded-full text-xs whitespace-nowrap font-medium flex items-center max-w-xs',
        {
          'pl-2': !tooltip,
        }
      )}
    >
      {tooltip && (
        <WithInformation tooltip={tooltip} className="flex mr-2" iconClassName="fill-white opacity-80">
          {null}
        </WithInformation>
      )}
      <span className="overflow-hidden whitespace-nowrap text-ellipsis">{message}</span>
      <div
        className="ml-4 shrink-0 rounded-full bg-white w-5 h-5 flex items-center hover:cursor-pointer justify-center text-tertiary-green-500 hover:bg-tertiary-green-500 hover:text-white transition-colors"
        onClick={() => onDoneClick()}
      >
        <Checkmark className="w-2 h-2 stroke-current fill-current stroke-[1.5px]" />
      </div>
    </div>
  )
}

export default Info
