import { Asset, Answer, supportedImageExtensions } from '@packages/types'
import React from 'react'

import { ActivatableTextInput } from 'builder/build/common/components'
import { FileUploader } from 'common/assets/components'

type GeneralAnswerFields = keyof Pick<Answer, 'hasCode' | 'code' | 'showDescription' | 'description' | 'thumbnail'>

interface GeneralSettingsSection {
  answer: Answer
  onUpdate: <T extends GeneralAnswerFields>(field: T, value: Answer[T]) => void
}

const GeneralSettingsSection = ({ answer, onUpdate }: GeneralSettingsSection) => {
  return (
    <>
      <div className="panel__section">
        <div className="panel__group">
          <div className="panel__row">
            <span className="panel__field-label panel__field-label--bold">Thumbnail</span>
          </div>
          <div className="panel__row panel__row--no-margin-bottom">
            <FileUploader
              file={answer.thumbnail}
              dropText="Drop image, or"
              onChange={value => onUpdate('thumbnail', value as Asset | null)}
              extensions={supportedImageExtensions}
            />
          </div>
        </div>
      </div>
      <hr className="panel__divider" />
      <div className="panel__section">
        <ActivatableTextInput
          isRichText
          id="text-description"
          title="Description"
          value={answer.description}
          isActive={answer.showDescription}
          onChange={e => onUpdate('description', e.target.value)}
          titleClassName="panel__field-label--bold"
          onActivationChange={checked => onUpdate('showDescription', checked)}
          textarea
        />
      </div>
      <hr className="panel__divider" />
      <div className="panel__section">
        <ActivatableTextInput
          title="Production code"
          id="production-code"
          value={answer.code || ''}
          isActive={!!answer.hasCode}
          titleClassName="panel__field-label--bold"
          onChange={e => onUpdate('code', e.target.value)}
          onActivationChange={checked => onUpdate('hasCode', checked)}
        />
      </div>
    </>
  )
}

export default GeneralSettingsSection
