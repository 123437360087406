import { useFormik } from 'formik'
import React, { useMemo } from 'react'
import { useHistory } from 'react-router'

import { UnsavedChangesModal, useNavigationBlockingModal, useToast } from 'common/components'
import { ToastType } from 'common/components/toast/types'
import { trpc } from 'common/hooks/trpc'
import { Variant } from 'common/variants'
import LoadingIcon from 'icons/custom/builder-loading.svg'

import { InventoryPersistence } from '../../types/dataTable'
import { VariantFormValues } from '../types'
import { getFormValues, formValuesToMutationInput } from '../utils'
import useInfiniteFormReset from './useInfiniteFormReset'
import VariantsTable, { VariantsTableProps } from './VariantsTable'

export interface VariantsFormProps
  extends Omit<VariantsTableProps, 'formik' | 'isSaving' | 'fetchMore' | 'variantIds' | 'isFetching'> {
  productId: string
  customizerProductId: string
  persistence: InventoryPersistence['state']
}

const LIMIT = 100

const VariantsForm = ({
  locationId,
  productId,
  variantsCombination = [],
  customizerProductId,
  customizerProduct,
  onChangeCombinationClick,
  scrollParentRef,
  dataTable,
  persistence,
}: VariantsFormProps) => {
  const { openToast, openGenericErrorToast } = useToast()
  const trpcContext = trpc.useContext()
  const history = useHistory()

  const getStockFilter = () => {
    if (persistence.stock?.value === undefined) return undefined

    return persistence.stock as {
      type: 'gte-lte' | 'eq' | 'gt' | 'lt' | 'na'
      value: string | string[]
    }
  }

  const {
    data: variantsPagesData,
    isLoading: isLoadingVariants,
    isFetching: isFetchingVariants,
    fetchNextPage,
  } = trpc.variant.get.useInfiniteQuery(
    {
      productId: productId,
      questionIds: variantsCombination as string[],
      customizerProductId: customizerProductId,
      limit: LIMIT,
      location: locationId,
      ...{ ...persistence, stock: getStockFilter() },
    },
    {
      enabled: location != null,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      getPreviousPageParam: firstPage => {
        const { lastIndex } = firstPage.pagination

        if (lastIndex - LIMIT === 0) return undefined

        return Math.max(lastIndex - LIMIT, 0)
      },
      getNextPageParam: lastPage => {
        const { lastIndex, collectionSize } = lastPage.pagination

        if (lastIndex >= collectionSize) return undefined

        return lastPage.pagination.lastIndex
      },
    }
  )

  const variantsData: Variant[] = useMemo(
    () =>
      variantsPagesData
        ? variantsPagesData.pages.reduce((variants: Variant[], page) => {
            return [...variants, ...page.results]
          }, [])
        : [],
    [variantsPagesData]
  )

  const { data: variantIds = [] } = trpc.variant.getIdsByCombination.useQuery(
    {
      productId: productId,
      questionIds: variantsCombination as string[],
      customizerProductId: customizerProductId,
      location: locationId,
      ...{ ...persistence, stock: getStockFilter() },
    },
    { enabled: location != null, select: data => data[0]?.ids, refetchOnMount: true }
  )

  const { mutate: updateVariants } = trpc.variant.updateMany.useMutation({
    onSuccess: () => {
      formik.setStatus('submitted')
      trpcContext.variant.getSkuSharedData.invalidate()
      trpcContext.variant.get.invalidate({
        productId: productId,
        questionIds: variantsCombination as string[],
        customizerProductId: customizerProductId,
      })
      trpcContext.variant.getNewCount.invalidate({
        productId: productId,
        questionIds: variantsCombination as string[],
        customizerProductId: customizerProductId,
      })

      openToast('Variants were successfully saved.', ToastType.success)
    },
    onError: () => {
      openGenericErrorToast('Could not save variants please try again.')
    },
  })

  const formik = useFormik<VariantFormValues>({
    initialValues: variantsData ? getFormValues(locationId, variantsData) : { variants: {}, inventoryItems: {} },
    onSubmit: values => {
      updateVariants(formValuesToMutationInput(locationId, values, formik.initialValues))
    },
  })

  useInfiniteFormReset(formik, locationId, variantsData)

  const unsavedChangesModal = useNavigationBlockingModal(history, [formik.dirty])

  if (isLoadingVariants)
    return (
      <div className="h-80 flex flex-col items-center justify-center">
        <LoadingIcon className="fill-primary-600 w-6" />
      </div>
    )

  return (
    <>
      <VariantsTable
        formik={formik}
        locationId={locationId}
        customizerProduct={customizerProduct}
        variantsCombination={variantsCombination}
        onChangeCombinationClick={onChangeCombinationClick}
        scrollParentRef={scrollParentRef}
        dataTable={dataTable}
        fetchMore={fetchNextPage}
        variantIds={variantIds}
        isFetching={isFetchingVariants}
      />
      {unsavedChangesModal.isVisible && (
        <UnsavedChangesModal
          onLeaveClick={() => {
            unsavedChangesModal.close()
            unsavedChangesModal.forceNavigate()
          }}
          onStayClick={unsavedChangesModal.close}
          {...unsavedChangesModal.modalProps}
        />
      )}
    </>
  )
}

export default VariantsForm
