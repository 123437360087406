import { StripeSubscription } from '@packages/types'
import React from 'react'

import { Alert } from 'common/components'

import StripeAuthenticateButton from './StripeAuthenticateButton'

export interface StripePaymentErrorAlertProps {
  subscription: StripeSubscription
}

const showAuthenticateButton = (subscription: StripeSubscription) =>
  subscription.stripePaymentIntentRequiringAction?.status === 'requires_action'

const getErrorMessage = (subscription: StripeSubscription) => {
  if (subscription.stripePaymentIntentRequiringAction !== null) {
    if (subscription.stripePaymentIntentRequiringAction.status === 'requires_payment_method')
      return 'An attempt to process your payment failed. Please update your payment details.'

    if (
      subscription.stripePaymentIntentRequiringAction.last_payment_error?.code ===
      'payment_intent_authentication_failure'
    )
      return 'The provided payment details has failed authentication. You can provide new payment details or attempt to authenticate this card again.'

    return 'The provided card requires authentication.'
  }

  return 'An attempt to process your payment failed. Please update your payment details.'
}

const StripePaymentErrorAlert = ({ subscription }: StripePaymentErrorAlertProps) => {
  return (
    <Alert variant="error" className="basis-full mb-6">
      <Alert.Title>Payment error</Alert.Title>
      <Alert.Details>{getErrorMessage(subscription)}</Alert.Details>
      {showAuthenticateButton(subscription) && (
        <Alert.Actions>
          <StripeAuthenticateButton subscription={subscription} />
        </Alert.Actions>
      )}
    </Alert>
  )
}

export default StripePaymentErrorAlert
