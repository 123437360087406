import { NormalizedCustomizerProduct } from '@packages/types'
import { intersection } from 'lodash'
import { useEffect, useMemo, useState } from 'react'

import { FilterGroup } from 'common/components'

const useQuestionsAnswersFilters = (
  availableQuestionIds: string[],
  availableAnswerIds: string[],
  customizerProduct: NormalizedCustomizerProduct
) => {
  const [activeQuestionFilters, setActiveQuestionFilters] = useState<string[]>([])
  const [activeAnswerFilters, setActiveAnswersFilters] = useState<string[]>([])

  const handleToggleQuestionFilter = (questionId: string): void => {
    if (activeQuestionFilters.includes(questionId))
      return setActiveQuestionFilters(activeFilters =>
        activeFilters.filter(existingQuestionId => existingQuestionId !== questionId)
      )

    setActiveQuestionFilters(activeFilters => [...activeFilters, questionId])
  }

  const questionFilters = availableQuestionIds.map(questionId => ({
    name: questionId,
    text: customizerProduct.questions[questionId]?.name,
  }))

  const handleToggleAnswerFilter = (questionId: string): void => {
    if (activeAnswerFilters.includes(questionId))
      return setActiveAnswersFilters(activeFilters =>
        activeFilters.filter(existingAnswerId => existingAnswerId !== questionId)
      )

    return setActiveAnswersFilters(activeFilters => [...activeFilters, questionId])
  }

  const answerFilters = useMemo(
    () =>
      availableAnswerIds.reduce((answerFilters: FilterGroup[], answerId) => {
        if (!customizerProduct.answers[answerId]) return answerFilters

        const questionIds = activeQuestionFilters.length === 0 ? availableQuestionIds : activeQuestionFilters

        const questionIdsWithTheAnswer = questionIds.filter(
          questionId => customizerProduct.questions[questionId].answers.includes(answerId)!
        )

        if (questionIdsWithTheAnswer.length > 0) {
          return questionIdsWithTheAnswer.reduce((answerFilters, questionId) => {
            let newAnswerFilters = answerFilters

            let group = answerFilters.find(group => group.id === questionId)

            if (!group) {
              group = {
                id: customizerProduct.questions[questionId].id,
                name: customizerProduct.questions[questionId].name,
                items: [],
              }
              newAnswerFilters = [...newAnswerFilters, group]
            }

            group.items.push({
              name: answerId,
              text: `${customizerProduct.answers[answerId].name}${
                questionIdsWithTheAnswer.length > 1 ? ' (shared)' : ''
              }`,
            })

            return newAnswerFilters
          }, answerFilters)
        }
        return answerFilters
      }, []),
    [availableAnswerIds, customizerProduct.answers, activeQuestionFilters]
  )

  useEffect(() => {
    const validActiveQuestionIds = activeQuestionFilters.filter(
      questionId => !!questionFilters.find(questionFilter => questionFilter.name === questionId)
    )

    if (validActiveQuestionIds.length !== activeQuestionFilters.length) setActiveQuestionFilters(validActiveQuestionIds)
  }, [questionFilters, activeQuestionFilters])

  useEffect(() => {
    const validActiveAnswerIds = activeAnswerFilters.filter(
      answerId => !!answerFilters.find(answerFilter => !!answerFilter.items.find(filter => filter.name === answerId))
    )

    if (validActiveAnswerIds.length !== activeAnswerFilters.length) setActiveAnswersFilters(validActiveAnswerIds)
  }, [answerFilters, activeAnswerFilters])

  return {
    questionFilterProps: {
      name: 'Question',
      filters: questionFilters,
      activeFilters: activeQuestionFilters,
      toggleFilter: handleToggleQuestionFilter,
      searchable: true,
      clearFilter: () => setActiveQuestionFilters([]),
    },
    answerFilterProps: {
      name: 'Answer',
      filters: answerFilters,
      toggleFilter: handleToggleAnswerFilter,
      activeFilters: activeAnswerFilters,
      searchable: true,
      clearFilter: () => setActiveAnswersFilters([]),
    },
    clearAllFilters: () => {
      setActiveQuestionFilters([])
      setActiveAnswersFilters([])
    },
    applyQuestionFilter: (questionIds: string[]) =>
      activeQuestionFilters.length === 0 ? questionIds : intersection(activeQuestionFilters, questionIds),
    applyAnswerFilter: (answerIds: string[]) =>
      activeAnswerFilters.length === 0 ? answerIds : intersection(activeAnswerFilters, answerIds),
  }
}

export default useQuestionsAnswersFilters
