import { createSelector } from 'reselect'

import { selectors as customizationSelectors } from 'customizer/customization'

export const startingPointConfigurationSelector = createSelector(
  customizationSelectors.customizationSelector,
  ({ questions, answers }) => {
    return Object.values(questions).reduce((conf, question) => {
      if (question.isMultiAnswer) {
        conf[question.id] = question.selectedAnswers?.filter(answerId => !answers[answerId].isPersonalisation) ?? null
      } else {
        conf[question.id] =
          question.selectedAnswer && !answers[question.selectedAnswer].isPersonalisation
            ? question.selectedAnswer
            : null
      }

      return conf
    }, {})
  }
)
