import { DenormalizedProductVersion, ProductVersionOrigin, VersionOrigin, DenormalizedProduct } from '@packages/types'
import { useMutation, useQuery } from '@tanstack/react-query'
import React, { useContext } from 'react'
import { useHistory, useParams } from 'react-router'

import { useProductService } from 'common/products'
import { TenantContext } from 'common/tenant'

import HistoryManager from './HistoryManager'

const ProductHistory = () => {
  const history = useHistory()
  const params = useParams<{ brandName?: string; productId: string }>()
  const tenant = useContext(TenantContext)
  const baseUrl = params.brandName ? `/brands/${params.brandName}` : ''
  const productService = useProductService()

  const goToBuilder = () => history.push(`${baseUrl}/products/${params.productId}/builder`)

  const goToProducts = () => history.push(`${baseUrl}/products`)

  const getIframeSrc = (versions: DenormalizedProduct['versions'], selectedVersion: string | null) => {
    const versionId = versions.find(({ id }) => id === selectedVersion)!.customizerProduct
    const url = `${window.location.origin}/customize/${params.productId}?version=${versionId}`
    return params.brandName ? `${url}&tenant=${tenant}` : url
  }

  const { data: product, isLoading } = useQuery([productService.fetch.queryKeys, params.productId], () =>
    productService.fetch(params.productId)
  )

  const { mutateAsync: restoreVersion, isLoading: isRestoring } = useMutation(
    ({ productId, versionId }: { productId: string; versionId: string }) =>
      productService.restoreVersion(productId, versionId)
  )

  if (!params.productId) return null

  return (
    <HistoryManager<DenormalizedProduct, VersionOrigin | ProductVersionOrigin>
      item={product as DenormalizedProduct}
      texts={{ item: 'product', items: 'products', ref: 'product builder' }}
      onReturnAction={goToBuilder}
      onBackAction={goToProducts}
      restoreVersion={async (productId, versionId) => {
        await restoreVersion({ productId, versionId })
      }}
      isVersionLastLive={(version: DenormalizedProductVersion, ref: DenormalizedProductVersion) =>
        version?.customizerProduct === ref?.customizerProduct
      }
      isLoading={isLoading || isRestoring}
      getIframeSrc={getIframeSrc}
    />
  )
}

export default ProductHistory
