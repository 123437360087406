import { CustomizerProductSubmitAction } from '@packages/types'
import classNames from 'classnames'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router'

import { useCustomizerDispatch, useCustomizerSelector } from 'builder/build/customizer'
import { useDispatch, useSelector } from 'cms/hooks'
import { IconButton } from 'common/components'
import { withFlag } from 'common/users/components'
import { actions as quoteActions, selectors as quoteSelectors } from 'customizer/quote'
import EyeIcon from 'icons/core-solid/interface-essential/interface-edit-view.svg'

import { updateSubmitAction } from '../actions'
import { submitActionSelector } from '../selectors'

const SubmitActionRightPanel = () => {
  const customizerDispatch = useCustomizerDispatch()
  const isQuoteFormVisible = useCustomizerSelector(quoteSelectors.quoteFormVisibilitySelector)
  const submitAction = useSelector(submitActionSelector)
  const dispatch = useDispatch()
  const location = useLocation()

  const handleSubmitActionClick = (newSubmitAction: CustomizerProductSubmitAction) => {
    if (isQuoteFormVisible) customizerDispatch(quoteActions.closeForm())
    dispatch(updateSubmitAction(newSubmitAction))
  }

  const handlePreviewModalClick = () => {
    customizerDispatch(isQuoteFormVisible ? quoteActions.closeForm() : quoteActions.openForm())
  }

  useEffect(() => {
    if (!location.pathname.includes('/submit-action') && isQuoteFormVisible) {
      customizerDispatch(quoteActions.closeForm())
    }
  }, [location.pathname])

  const defaultButtonClasses =
    'px-3 py-2 h-9 flex justify-center items-center rounded-[4px] border focus:outline-none text-sm leading-[14px] font-normal'

  return (
    <div className="w-full h-full" aria-label="submit action configuration">
      <div className="panel panel--right w-full">
        <div className="flex flex-col px-6 py-6 space-y-2">
          <span className="font-medium text-sm">Submit Action</span>
          <div className="flex justify-between shadow-xs rounded-[4px]">
            <button
              className={classNames(defaultButtonClasses, {
                'bg-neutral-50 border-neutral-200': submitAction === CustomizerProductSubmitAction.AddToCart,
                'border-white': submitAction !== CustomizerProductSubmitAction.AddToCart,
              })}
              onClick={() => handleSubmitActionClick(CustomizerProductSubmitAction.AddToCart)}
            >
              Add to cart
            </button>
            <button
              className={classNames(defaultButtonClasses, {
                'bg-neutral-50 border-neutral-200': submitAction === CustomizerProductSubmitAction.GetAQuote,
                'border-white': submitAction !== CustomizerProductSubmitAction.GetAQuote,
              })}
              onClick={() => handleSubmitActionClick(CustomizerProductSubmitAction.GetAQuote)}
            >
              Get a quote
            </button>
          </div>
        </div>
        {submitAction === CustomizerProductSubmitAction.GetAQuote && (
          <>
            <hr className="border-neutral-100" />
            <div className="flex flex-col px-6 py-6 space-y-2">
              <div className="flex justify-between items-center">
                <span className="text-sm font-medium">Quote request form</span>
                <IconButton
                  aria-label="Preview quote request form"
                  variant="subtle"
                  Icon={EyeIcon}
                  onClick={handlePreviewModalClick}
                />
              </div>
              <span className="text-xs text-neutral-400">
                Display the form shown to the user when they request a quote.
              </span>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default withFlag({
  Component: SubmitActionRightPanel,
  feature: 'quote_phase_1',
})
