import { Question } from '@packages/types'
import { omit } from 'lodash'
import { AnyAction } from 'redux'

import { actionTypes as answerTypes } from 'builder/build/answers'
import { getDeletionUpdatedForceView, getReorderingUpdatedForceView } from 'builder/build/core/utils'
import { actionTypes as viewsTypes } from 'builder/build/settings'

import * as types from './actionTypes'

export interface State {
  [key: string]: Question
}

export default (state: State = {}, action: AnyAction): State => {
  switch (action.type) {
    case types.CREATE_QUESTION:
      return { ...state, [action.payload.id]: action.payload }
    case types.DELETE_QUESTION:
      return Object.keys(state).reduce((newState, id) => {
        if (id === action.payload.questionId) return newState
        return { ...newState, [id]: state[id] }
      }, {})
    case types.MOVE_ANSWER: {
      if (!action.payload.questionId || !action.payload.answerId) return state

      const question = state[action.payload.questionId]
      const answers = question.answers.filter(answer => answer !== action.payload.answerId)
      answers.splice(action.payload.index, 0, action.payload.answerId)

      return { ...state, [action.payload.questionId]: { ...question, answers } }
    }
    case types.ADD_ANSWERS: {
      const question = state[action.payload.questionId]

      return {
        ...state,
        [action.payload.questionId]: {
          ...question,
          answers: [...question.answers, ...action.payload.answerIds],
        },
      }
    }
    case types.LINK_QUESTION: {
      const question = state[action.payload.questionId]

      return {
        ...state,
        [question.id]: {
          ...question,
          linkedQuestionId: action.payload.linkedQuestionId,
        },
      }
    }
    case types.UNLINK_QUESTION: {
      const question = state[action.payload.questionId]
      return { ...state, [question.id]: omit({ ...question }, 'linkedQuestionId') as typeof question }
    }
    case answerTypes.COPY_ANSWERS: {
      const question = state[action.payload.questionId]

      return {
        ...state,
        [action.payload.questionId]: {
          ...question,
          answers: [...question.answers, ...action.payload.newAnswerIds],
        },
      }
    }
    case answerTypes.ADD_ANSWER: {
      if (!action.payload.questionId) return state

      const question = state[action.payload.questionId]

      return {
        ...state,
        [action.payload.questionId]: {
          ...question,
          answers: [...question.answers, action.payload.id],
        },
      }
    }
    case types.REMOVE_ANSWER: {
      const { questionId, answerId } = action.payload
      const question = state[questionId]

      return {
        ...state,
        [questionId]: {
          ...question,
          answers: question.answers.filter(id => id !== answerId),
        },
      }
    }
    case types.REMOVE_ANSWERS: {
      const { questionId, answerIds } = action.payload
      const question = state[questionId]

      return {
        ...state,
        [questionId]: {
          ...question,
          answers: question.answers.filter(id => !answerIds.includes(id)),
        },
      }
    }
    case types.SET_MIN_SELECTIONS: {
      const { questionId, minSelections } = action.payload
      const question = state[questionId]

      return {
        ...state,
        [questionId]: {
          ...question,
          minSelections,
        },
      }
    }
    case types.SET_MAX_SELECTIONS: {
      const { questionId, maxSelections } = action.payload
      const question = state[questionId]
      return {
        ...state,
        [questionId]: {
          ...question,
          maxSelections,
        },
      }
    }
    case answerTypes.UNLINK_ANSWER: {
      const question = state[action.payload.questionId]
      const { questionId } = action.payload

      return {
        ...state,
        [questionId]: {
          ...question,
          answers: question.answers.map(id => (id === action.payload.answerId ? action.payload.newAnswerId : id)),
        },
      }
    }
    case viewsTypes.REORDER_VIEWS: {
      const { originalIndex, newIndex } = action.payload

      return Object.keys(state).reduce((newState, id) => {
        const questionForceView = state[id].forceView

        return {
          ...newState,
          [id]: {
            ...state[id],
            forceView: getReorderingUpdatedForceView(originalIndex, newIndex, questionForceView),
          },
        }
      }, {})
    }
    case viewsTypes.DELETE_VIEW: {
      const { viewIndex } = action.payload

      return Object.keys(state).reduce((newState, id) => {
        const questionForceView = state[id].forceView

        return {
          ...newState,
          [id]: {
            ...state[id],
            forceView: getDeletionUpdatedForceView(viewIndex, questionForceView),
          },
        }
      }, {})
    }
    default:
      return state
  }
}
