import { FontType } from '@packages/types'
import React from 'react'
import { MenuProps, components } from 'react-select'

import { Tabs } from 'common/components'
import UploadIcon from 'icons/bold/05-Internet-Networks-Servers/08-Upload-Download/upload-thick-bottom.svg'

import { FontOption } from '../types'

export interface FontDropdownMenuProps extends MenuProps<FontOption, false> {
  fontType: FontType
  onTabClick: (fontType: FontType) => void
  onUploadClick: () => void
}

const FontDropdownMenu = ({ fontType, onTabClick, onUploadClick, children, ...props }: FontDropdownMenuProps) => {
  return (
    <components.Menu {...props}>
      <div className="flex flex-col">
        <Tabs className="mt-2 mx-3">
          <Tabs.Tab onClick={() => onTabClick(FontType.Google)} isSelected={fontType === FontType.Google}>
            Google
          </Tabs.Tab>
          <Tabs.Tab onClick={() => onTabClick(FontType.Custom)} isSelected={fontType === FontType.Custom}>
            My fonts
          </Tabs.Tab>
        </Tabs>
      </div>
      {fontType === FontType.Custom && (
        <>
          <div
            className="font-sm flex items-center px-3 py-2 cursor-pointer hover:bg-neutral-100 border-neutral-100 border-b text-primary-600"
            onClick={onUploadClick}
          >
            <UploadIcon className="h-[12px] w-[12px] mr-2 text-primary-600 fill-primary-600 mt-0.5" />
            Upload your font
          </div>
        </>
      )}
      {children}
    </components.Menu>
  )
}

export default FontDropdownMenu
