import { Middleware, Store } from 'redux'

import { twoDDisplayerActionTypes } from 'customizer/2dDisplayer'

const actionsToDispatchToBuilder = [
  twoDDisplayerActionTypes.UPDATE_PRINT_AREA,
  twoDDisplayerActionTypes.SWITCH_VIEW,
  twoDDisplayerActionTypes.SET_ZOOM,
]

const previewToBuilderMiddleware =
  (builderStore: Store): Middleware =>
  () =>
  next =>
  action => {
    const actionToDispatch = actionsToDispatchToBuilder.find(type => type === action.type)
    if (actionToDispatch) builderStore.dispatch(action)
    return next(action)
  }

export default previewToBuilderMiddleware
