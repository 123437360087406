import { DenormalizedCustomizablePart } from '@packages/types'
import React, { useEffect } from 'react'

import { hooks as customizerHooks } from 'builder/build/customizer'
import { selectors as previewSelectors } from 'builder/build/preview'
import { useSelector } from 'cms/hooks'
import { twoDDisplayerActions, twoDDisplayerSelectors } from 'customizer/2dDisplayer'
import { Node } from 'customizer/2dDisplayer/types'

import PartEditor from './PartEditor'
import PrintAreaEditor from './PrintAreaEditor'

export type EditorType = React.FunctionComponent<{
  node: Node
  part: DenormalizedCustomizablePart
  currentView: number
}>

const Editor = () => {
  const customizerDispatch = customizerHooks.useCustomizerDispatch()
  const isPreviewReady = useSelector(previewSelectors.isPreviewReadySelector)
  const editedPartId = customizerHooks.useCustomizerSelector(twoDDisplayerSelectors.editedPartSelector)!
  const editedPrintAreaId = customizerHooks.useCustomizerSelector(twoDDisplayerSelectors.editedPrintAreaSelector)!

  const isEditing = customizerHooks.useCustomizerSelector(twoDDisplayerSelectors.isEditingSelector)

  useEffect(() => {
    isEditing
      ? customizerDispatch(twoDDisplayerActions.disableZoom())
      : customizerDispatch(twoDDisplayerActions.enableZoom())

    return () => {
      customizerDispatch(twoDDisplayerActions.enableZoom())
    }
  }, [isEditing])

  if (!isPreviewReady) return null

  if (editedPartId) return <PartEditor partId={editedPartId} />

  if (editedPrintAreaId) return <PrintAreaEditor printAreaId={editedPrintAreaId} />

  return null
}

export default Editor
