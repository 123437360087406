import {
  Answer,
  DenormalizedPart,
  EntityType,
  Question,
  DenormalizedCustomizableQuestion,
  PartType,
} from '@packages/types'
import { createSelector } from 'reselect'

import { selectors as answersSelectors } from 'builder/build/answers'
import { selectors as coreSelectors } from 'builder/build/core'
import { selectors as questionsSelectors } from 'builder/build/questions'
import { RootState } from 'cms/store'
import { constants } from 'common/customizerProducts'
import { twoDDisplayerSelectors } from 'customizer/2dDisplayer'
import { RootState as CustomizerRootState } from 'customizer/store'
import isNullOrEmpty from 'utils/isNullOrEmpty'

const DEFAULT_DIMENSIONS = constants.defaultDimensions

export const dimensionsSelector = createSelector(
  coreSelectors.productBuilderSelector,
  ({ customizerProduct }) => customizerProduct?.dimensions
)

export const dimensionsWithDefaultSelector = createSelector(dimensionsSelector, dimensions => ({
  ...DEFAULT_DIMENSIONS,
  ...dimensions,
}))

export const currentViewDrawingNodeSelectorWithoutRestrictions = (state: CustomizerRootState, partId: string) => {
  const part = state.customization.parts[partId]

  if (!part || part.type === PartType.Image) return []

  let stateWithAnswer = { ...state }

  const questionId = part.type === PartType.Logo ? part.logo : part.text

  if (questionId && state.customization.questions[questionId].selectedAnswer == null) {
    stateWithAnswer = {
      ...stateWithAnswer,
      customization: {
        ...stateWithAnswer.customization,
        questions: {
          ...stateWithAnswer.customization.questions,
          [questionId]: {
            ...stateWithAnswer.customization.questions[questionId],
            selectedAnswer: stateWithAnswer.customization.questions[questionId].answers[0],
            restrictions: {},
          },
        },
      },
    }
  }

  return twoDDisplayerSelectors.currentViewDrawingNodeSelector(stateWithAnswer, partId)
}

export const entityDefaultAnswerSelector = (
  state: RootState,
  entity: DenormalizedPart | DenormalizedCustomizableQuestion | Question | Answer
) => {
  if (isNullOrEmpty(entity)) return null
  if (entity.entityType === EntityType.Answer) return entity

  const answerId = questionsSelectors.defaultAnswerIdSelector(state, entity.id)
  return answersSelectors.answerByIdSelector(state, { id: answerId as string })
}

export const visibilitySelector = createSelector(
  coreSelectors.partSelector,
  (_state: RootState, _id: string, currentView: number) => currentView,
  (part, currentView) => {
    return !part?.position?.views?.[currentView]?.hidden
  }
)
