import React from 'react'

import { Checkbox, Tooltip } from 'common/components'
import { BulkSelection } from 'common/hooks'
import { Variant } from 'common/variants'

import { useSharedSkus } from '../../hooks'

export interface SelectRowCellProps {
  variant: Variant
  bulkSelection: BulkSelection
}

const SelectRowCell = ({ variant, bulkSelection }: SelectRowCellProps) => {
  const sharedSkus = useSharedSkus(variant.inventoryItem.sku)
  return (
    <Tooltip
      content={`Select all variants with SKU ${variant.inventoryItem.sku}`}
      disabled={sharedSkus?.variantIds.length === 0}
    >
      <Checkbox
        aria-label="Select row"
        tabIndex={-1}
        checked={bulkSelection.isSelected(variant.id)}
        onChange={e => {
          const variantIds = sharedSkus?.variantIds ?? [variant.id]

          if (e.target.checked) return bulkSelection.select(variantIds)

          bulkSelection.deselect(variantIds)
        }}
      />
    </Tooltip>
  )
}

export default SelectRowCell
