import { Placement } from '@floating-ui/react-dom'
import { EntityType, PartType, QuestionType, QuestionInputType, GroupType } from '@packages/types'
import { useTransition, animated } from '@react-spring/web'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { selectors as coreSelectors } from 'builder/build/core'
import { useCustomizerDispatch } from 'builder/build/customizer'
import { useDispatch, useSelector } from 'cms/hooks'
import { Button } from 'common/components'
import { constants } from 'common/customizerProducts'
import { trpc } from 'common/hooks/trpc'
import { actions as customizationActions } from 'customizer/customization'
import CloseIcon from 'icons/bold/01-Interface Essential/33-Form-Validation/close.svg'

import { createQuestionByType, createPartByType, createGroup } from '../actions'
import { bulkOrderGroupSelector } from '../selectors'
import DisplayTypesStep from './DisplayTypesStep'
import QuestionTypesStep from './QuestionTypesStep'

import './NewQuestionMenu.scss'

const { rootIdSelector } = coreSelectors
const RESTRICTED_TYPES = constants.questions.restrictedTypesByInputType

const getInputTypeNonePhrase = (displayType: string | null) => {
  switch (displayType) {
    case QuestionType.Image:
      return 'Add product images that can be selected with rules'
    case QuestionType.Text:
      return 'Add text on your product'
    case QuestionType.Font:
      return 'Add fonts applied on a text that can be selected with rules'
    case QuestionType.FontSize:
      return 'Add font sizes applied on a text that can be selected with rules'
    case QuestionType.Color:
      return 'Add colors applied on a text that can be selected with rules'
    case QuestionType.Material:
      return 'Add colors applied on a product image that can be selected with rules'
    case QuestionType.Logo:
      return 'Add logos that can be selected with rules'
    case QuestionType.Outline:
      return 'Add outlines applied on a text that can be selected with rules'
    default:
    case QuestionType.Value:
      return 'Please select a display type'
  }
}

const getPhraseStart = (questionType: string | null) => {
  switch (questionType) {
    case GroupType.Folder:
      return 'Create a group to organize your questions'
    case GroupType.BulkOrder:
      return 'Create a bulk order to allow your customers to order in bulk'
    case QuestionInputType.Text:
      return 'Create a question to let your customers write text'
    case QuestionInputType.File:
      return 'Create a question to let your customers upload a file'
    default:
      return 'Create a multiple choice question'
  }
}

const getPhraseEnd = (displayType: string | null) => {
  switch (displayType) {
    case QuestionType.Image:
      return ' where each answer is a product image'
    case QuestionType.FontSize:
      return ' where each answer is a font size applied on a text'
    case QuestionType.Font:
      return ' where each answer is a font applied on a text'
    case QuestionType.Color:
      return ' where each answer is a color applied on a text'
    case QuestionType.Material:
      return ' where each answer is a color applied on a product image'
    case QuestionType.Logo:
      return ' where each answer is a logo'
    case QuestionType.Text:
      return ' and display it on the product'
    case QuestionType.Outline:
      return ' where each answer is a text outline'
    default:
    case QuestionType.Value:
      return ''
  }
}

const getPhrase = (questionType: string | null, displayType: string | null) => {
  if (!questionType && !displayType) return 'Please select what you want to create'
  if (questionType === EntityType.Group) return 'Create a group to organize your questions'
  if (questionType === QuestionInputType.None) return getInputTypeNonePhrase(displayType)

  return `${getPhraseStart(questionType)}${getPhraseEnd(displayType)}`
}
interface NewQuestionMenuProps {
  groupId?: string
  onClose: () => void
  forcedQuestionType?: QuestionInputType | null
  gifPlacement: Placement
}

const NewQuestionMenu = ({ groupId, onClose, forcedQuestionType = null, gifPlacement }: NewQuestionMenuProps) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const customizerDispatch = useCustomizerDispatch()
  const [selectedQuestionType, setSelectedQuestionType] = useState<QuestionInputType | GroupType | null>(
    forcedQuestionType
  )
  const [selectedDisplayType, setSelectedDisplayType] = useState<QuestionType | null>(null)
  const phrase = getPhrase(selectedQuestionType, selectedDisplayType)
  const rootId = useSelector(rootIdSelector)
  const bulkOrderGroup = useSelector(bulkOrderGroupSelector)
  const isGroup = selectedQuestionType === GroupType.Folder || selectedQuestionType === GroupType.BulkOrder

  const phraseTransitions = useTransition(phrase, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  const { mutate: createActivityLog } = trpc.activityLog.create.useMutation()

  const selectQuestionType = (type: QuestionInputType | GroupType) => {
    const isRestricted =
      selectedDisplayType && RESTRICTED_TYPES[type as QuestionInputType]?.includes(selectedDisplayType)
    if (isRestricted || isGroup) setSelectedDisplayType(null)

    setSelectedQuestionType(type)
  }

  const handleCreate = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()

    onClose()

    if (isGroup) {
      const { payload } = dispatch(createGroup(groupId, undefined, selectedQuestionType))
      return history.push(`/groups/${payload.id}`)
    }

    createActivityLog({ type: 'createQuestion' })

    switch (selectedDisplayType) {
      case QuestionType.Image: {
        const { payload } = dispatch(createPartByType(PartType.Image, groupId, selectedQuestionType!))
        return history.push(`/questions/${payload.image}`)
      }
      case QuestionType.Text: {
        const { payload } = dispatch(createPartByType(PartType.Text, groupId, selectedQuestionType!))
        customizerDispatch(customizationActions.editPart(payload.id))
        return history.push(`/questions/${payload.text}`)
      }
      case QuestionType.Logo: {
        const { payload } = dispatch(createPartByType(PartType.Logo, groupId, selectedQuestionType!))
        customizerDispatch(customizationActions.editPart(payload.id))
        return history.push(`/questions/${payload.logo}`)
      }
      default:
        const { payload } = dispatch(
          createQuestionByType(groupId, selectedDisplayType!, { inputType: selectedQuestionType! })
        )
        return history.push(`/questions/${payload.id}`)
    }
  }

  return (
    <div className="new-question-menu">
      <div onClick={onClose} className="new-question-menu__close">
        <CloseIcon />
      </div>
      {!forcedQuestionType && (
        <QuestionTypesStep
          title="1. Select an input type"
          selectedQuestionType={selectedQuestionType}
          onSelect={type => selectQuestionType(type)}
          isInGroup={rootId !== groupId}
          isInBulkOrderGroup={groupId === bulkOrderGroup?.id}
          gifPlacement={gifPlacement}
          hasBulkOrderGroup={!!bulkOrderGroup}
        />
      )}
      <DisplayTypesStep
        title={forcedQuestionType ? 'Select a display type' : '2. Select a display type'}
        selectedQuestionType={selectedQuestionType}
        selectedDisplayType={selectedDisplayType}
        onSelect={type => setSelectedDisplayType(type)}
        gifPlacement={gifPlacement}
      />
      <div className="new-question-menu__footer">
        <div className="new-question-menu__footer-message">
          {phraseTransitions((style, item) => {
            return (
              <animated.div
                style={{
                  position: 'absolute',
                  top: '0',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  ...style,
                }}
              >
                {item}
              </animated.div>
            )
          })}
        </div>
        <Button
          variant="primary"
          disabled={!isGroup && (!selectedQuestionType || !selectedDisplayType)}
          onClick={handleCreate}
        >
          Create
        </Button>
      </div>
    </div>
  )
}

export default NewQuestionMenu
