import React from 'react'
import { useQueryParam } from 'use-query-params'

import { BlankState } from 'common/components'

import { TabParam } from '../ProductsTabs'
import ActiveBlankState from './ActiveBlankState'
import ArchivedBlankState from './ArchivedBlankState'
import DeletedBlankState from './DeletedBlankState'
import ExploreDemos from './ExploreDemos'

export interface ProductsBlankStateProps {
  createProduct: () => void
  openDemo: () => void
}

const ProductsBlankState = ({ createProduct, openDemo }: ProductsBlankStateProps) => {
  const [tab, setTab] = useQueryParam('tab', TabParam)

  return (
    <div className="flex flex-col items-start justify-center pt-16 space-y-16">
      <BlankState className="w-full md:w-[400px] self-center">
        {tab === 'deleted' && <DeletedBlankState onClick={() => setTab('active')} />}
        {tab === 'archived' && <ArchivedBlankState onClick={() => setTab('active')} />}
        {tab === 'active' && <ActiveBlankState onClick={() => createProduct()} />}
      </BlankState>

      {tab === 'active' && <ExploreDemos openDemo={openDemo} />}
    </div>
  )
}

export default ProductsBlankState
