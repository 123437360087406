import React from 'react'

import { TopBar, TopBarSection, TopBarBackButton, TopBarTabs } from 'builder/topBar/components'
import Skater from 'icons/custom/skater-rotate.svg'

const Loader = () => {
  return (
    <div className="h-screen w-screen z-[999] fixed flex flex-col bg-neutral-50 top-0">
      <TopBar>
        <TopBarSection className="pl-5">
          <TopBarBackButton />
        </TopBarSection>
        <TopBarTabs />
      </TopBar>
      <div className="h-full flex flex-col items-center justify-center pb-48">
        <Skater width={40} aria-label="Skater loading" />
      </div>
    </div>
  )
}

export default Loader
