import React from 'react'

import {
  ProductSettingsCanvasSizeSection,
  ProductSettingsTitleSection,
  ProductSettingsViewsSection,
} from 'builder/build/settings/components'

const ProductSettingsPanel = () => {
  return (
    <div className="h-full">
      <div className="flex flex-col flex-grow">
        <ProductSettingsTitleSection />
        <hr className="border-neutral-100" />
        <ProductSettingsViewsSection />
        <hr className="border-neutral-100" />
        <ProductSettingsCanvasSizeSection />
      </div>
    </div>
  )
}

export default ProductSettingsPanel
