import React from 'react'

import { TranslationText } from 'cms/translations/types'

import TranslationRow from './TranslationRow'
import TranslationRowWithPlaceholders from './TranslationRowWithPlaceholders'
import TranslationRowWithRichText from './TranslationRowWithRichText'

interface TranslationListItemProps {
  text: TranslationText
  value: string
  onChange: (value: string) => void
}

const TranslationListItem = React.forwardRef<HTMLLabelElement, TranslationListItemProps>((props, ref) => {
  if (props.text.hasPlaceholders) return <TranslationRowWithPlaceholders ref={ref} {...props} />
  if (props.text.isRichText) return <TranslationRowWithRichText ref={ref} {...props} />
  return <TranslationRow ref={ref} {...props} />
})

export default TranslationListItem
