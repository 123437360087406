import * as normalize from '@packages/normalizer'
import { pick } from 'lodash'

import { actionTypes as coreTypes } from 'builder/build/core'
import { constants } from 'common/customizerProducts'

const { Normalizer } = normalize.customizerProduct
const { REPLACE_DRAFT, UPDATE_PRISTINE, UPDATE_PRODUCT_NAME, START_EDITING, RESET_PRODUCTBUILDER } = coreTypes

export const updatePristine = pristine => ({ type: UPDATE_PRISTINE, payload: pristine })

export const startEditing = ({ productName, customizerProduct }) => {
  const payload = {
    rules: {},
    questions: {},
    parts: {},
    groups: {},
    answers: {},
    printAreas: {},
    ...Normalizer.run(customizerProduct, 'customizerProducts'),
  }

  return {
    type: START_EDITING,
    payload: {
      customizerProduct: payload,
      denormalized: JSON.parse(JSON.stringify(customizerProduct)),
      productName,
    },
  }
}

export const updateProductName = productName => ({
  type: UPDATE_PRODUCT_NAME,
  payload: { productName },
})

export const syncWithPublishedVersion =
  ({ customizerProduct }) =>
  dispatch => {
    const payload = {
      rules: {},
      questions: {},
      parts: {},
      groups: {},
      answers: {},
      printAreas: {},
      ...Normalizer.run(customizerProduct, 'customizerProducts'),
    }

    dispatch({
      type: coreTypes.PATCH,
      payload: {
        customizerProducts: [pick(customizerProduct, constants.customizerProduct.readOnlyProperties)],
      },
    })
    return dispatch(updatePristine(payload))
  }

export const reset = () => ({ type: RESET_PRODUCTBUILDER })

export const updateThemeConfig = (themeName, id, key, value) => ({
  type: coreTypes.UPDATE_THEME_CONFIGURATION,
  payload: { theme: themeName, id, key, value },
})

export const deleteThemeConfig = (themeName, id, key) => ({
  type: coreTypes.DELETE_THEME_CONFIGURATION,
  payload: { theme: themeName, id, key },
})

export const replaceDraft = product => ({
  type: REPLACE_DRAFT,
  payload: {
    id: product.customizerProduct.id,
    _id: product.customizerProduct.id,
    isDraft: product.customizerProduct.isDraft,
  },
})
