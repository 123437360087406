import { Equation, EquationLine, EquationLineType, NormalizedCustomizerProduct, Question } from '@packages/types'
import { FieldArray, FormikProvider, setNestedObjectValues, useFormik } from 'formik'
import { get } from 'lodash'
import React, { useEffect, useMemo } from 'react'

import { Input, Button, InputField, HelperText, Modal, Tooltip, Switch, ModalProps } from 'common/components'
import { renderEquation } from 'common/pricing/utils'
import InfoIcon from 'icons/bold/01-Interface Essential/14-Alerts/information-circle.svg'
import AddIcon from 'icons/bold/01-Interface Essential/43-Remove-Add/add.svg'
import isNullOrEmpty from 'utils/isNullOrEmpty'

import EquationModalLine from './ModalLine'
import { equationSchema } from './utils'

export interface EquationModalProps extends Omit<ModalProps, 'children'> {
  baseData?: Equation
  customizerProduct: NormalizedCustomizerProduct
  saveEquation: (equation: Equation) => void
  currenciesInputProps: { leftAddon?: string; rightAddon?: string }
  questionFilterOptions: (question: Question) => boolean
  onClose: () => void
}

const EquationModal = ({
  baseData,
  customizerProduct,
  saveEquation,
  currenciesInputProps,
  questionFilterOptions,
  onClose,
  ...modalProps
}: EquationModalProps) => {
  const formik = useFormik({
    initialValues: baseData || {
      lines: [
        { value: '', operator: '+', type: EquationLineType.Question, answersMap: {} },
        { value: '', operator: '+', type: EquationLineType.Question, answersMap: {} },
      ],
      options: {
        schema: '',
        min: undefined,
        max: undefined,
        visible: true,
      },
    },
    onSubmit: saveEquation,
    validationSchema: equationSchema,
    validateOnChange: true,
  })

  useEffect(() => {
    if (!modalProps.isOpen) formik.resetForm()
  }, [modalProps.isOpen])

  const excludedQuestions = useMemo(() => {
    const questions = Object.values(customizerProduct.questions)
    if (questions == null) return []
    return questions.filter(q => !questionFilterOptions(q)).map(question => question.id)
  }, [customizerProduct.questions, questionFilterOptions])

  const headerRef = React.useRef<HTMLDivElement>(null)
  const footerRef = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (headerRef.current && footerRef.current) {
      const headerHeight = headerRef.current.clientHeight
      const footerHeight = footerRef.current.clientHeight
      const totalHeight = headerHeight + footerHeight
      const content = document.querySelector('.modal-content') as HTMLElement
      if (content) content.style.maxHeight = `calc(90vh - ${totalHeight}px)`
    }
  }, [headerRef, footerRef])

  return (
    <Modal
      {...modalProps}
      className="md:max-w-[440px] !overflow-auto"
      onBackdropClick={() => (baseData ? onClose() : null)}
    >
      <FormikProvider value={formik}>
        <div ref={headerRef}>
          <Modal.Title>{baseData ? 'Edit equation' : 'Create equation'}</Modal.Title>
          <Modal.Details className="!pt-0 !pb-4">
            <p className="text-xs !text-neutral-400">Select question(s) or number(s) to add to the equation.</p>
          </Modal.Details>
          <Modal.Actions className="justify-between">
            <label className="font-medium text-xs flex gap-2 items-center">
              Display cumulative price
              <Tooltip content="The cumulative price will be shown on the last question of the equation. Cumulative price won't be shown on text fields.">
                <InfoIcon className="w-3.5 h-3.5 fill-neutral-400" />
              </Tooltip>
            </label>
            <Switch
              id="display-cumulative"
              checked={formik.values?.options?.visible ?? false}
              onChange={e => formik.setFieldValue('options.visible', e.target.checked)}
            />
          </Modal.Actions>
        </div>
        <Modal.Divider className="!border-t-0" />
        <div className="modal-content overflow-x-auto">
          <Modal.Details className="!pt-4 !pb-3">
            <FieldArray
              name="lines"
              render={arrayHelpers => {
                const { lines } = formik.values

                return (
                  <div>
                    {lines.map((line: EquationLine, index) => (
                      <EquationModalLine
                        key={index}
                        index={index}
                        line={line}
                        arrayHelpers={arrayHelpers}
                        excludedQuestions={excludedQuestions}
                        customizerProduct={customizerProduct}
                      />
                    ))}
                    <div className="mt-3">
                      <Button
                        data-testid="add-line"
                        className="!px-2"
                        type="button"
                        disabled={formik.values.lines.length >= 5}
                        onClick={() =>
                          arrayHelpers.push({
                            value: '',
                            type: EquationLineType.Question,
                            operator: '+',
                          })
                        }
                      >
                        <Tooltip disabled={formik.values.lines.length < 5} content="You can only add up to 5 lines">
                          <span className="flex items-center">
                            <AddIcon className="w-2.5 h-2.5 mr-2" />
                            Add line
                          </span>
                        </Tooltip>
                      </Button>
                    </div>
                  </div>
                )
              }}
            />
          </Modal.Details>
          <Modal.Actions className="flex-row !items-start !py-2">
            <div className="font-medium text-xs text-neutral-400 w-28 h-8 flex items-center">Result (=)</div>
            <div className="flex-col">
              <div className="flex gap-3">
                <InputField className="group">
                  <Input
                    id="min"
                    name="options.min"
                    type="number"
                    min={0}
                    className="h-8 font-medium text-neutral-300"
                    inputClassName="text-neutral-900 pl-1"
                    value={formik.values?.options?.min ?? ''}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    hasError={
                      !!get(formik.touched, 'options.min') &&
                      (!!get(formik.errors, 'options.min') ||
                        (!!get(formik.errors, 'options.minMax') && !!get(formik.touched, 'options.max')))
                    }
                    placeholder="0"
                    leftAddon={
                      <>
                        <span className="mr-1">Min</span>
                        <span>{currenciesInputProps.leftAddon}</span>
                      </>
                    }
                    rightAddon={currenciesInputProps.rightAddon}
                  />
                  {get(formik.touched, 'options.min') && get(formik.errors, 'options.min') && (
                    <HelperText hasError>{get(formik.errors, 'options.min')}</HelperText>
                  )}
                </InputField>
                <InputField className="group">
                  <Input
                    id="max"
                    name="options.max"
                    type="number"
                    min={0}
                    className="h-8 font-medium text-neutral-300"
                    inputClassName="text-neutral-900 pl-1"
                    value={formik.values?.options?.max ?? ''}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    hasError={
                      !!get(formik.touched, 'options.max') &&
                      (!!get(formik.errors, 'options.max') ||
                        (!!get(formik.errors, 'options.minMax') && !!get(formik.touched, 'options.min')))
                    }
                    placeholder="∞"
                    leftAddon={
                      <>
                        <span className="mr-1">Max</span>
                        <span>{currenciesInputProps.leftAddon}</span>
                      </>
                    }
                    rightAddon={currenciesInputProps.rightAddon}
                  />
                  {get(formik.touched, 'options.max') && get(formik.errors, 'options.max') && (
                    <HelperText hasError>{get(formik.errors, 'options.max')}</HelperText>
                  )}
                </InputField>
              </div>
              {get(formik.touched, 'options.min') &&
                get(formik.touched, 'options.max') &&
                get(formik.errors, 'options.minMax') && (
                  <HelperText className="mt-2 inline-block" hasError>
                    {get(formik.errors, 'options.minMax')}
                  </HelperText>
                )}
            </div>
          </Modal.Actions>
          <Modal.Actions className="flex justify-stretch !bg-neutral-50 text-neutral-400 fill-neutral-600 !py-4">
            <div className=" w-full">
              <span className="font-medium text-xs block mb-[2px]">Your equation</span>
              <span className="block">
                (
                <span className="text-neutral-800 font-medium" data-testid="equation-render">
                  {renderEquation(formik.values, customizerProduct)}
                </span>
                )<span> = Extra price ({currenciesInputProps.leftAddon || currenciesInputProps.rightAddon})</span>
              </span>
            </div>
          </Modal.Actions>
        </div>
        <div ref={footerRef}>
          <Modal.Actions>
            <Button
              id="new-equation-cancel"
              className="mr-auto"
              onClick={async () => {
                formik.resetForm()
                onClose()
              }}
              type="button"
            >
              Cancel
            </Button>
            <Button
              id="submit-equation"
              data-testid="submit-equation"
              variant="primary"
              type="button"
              onClick={async () => {
                const errors = await formik.validateForm({ ...formik.values })

                if (isNullOrEmpty(errors)) {
                  saveEquation(formik.values)
                  onClose()
                } else {
                  const touched = setNestedObjectValues(errors, true) as typeof formik.touched & {
                    options?: { minMax: boolean; min: boolean; max: boolean }
                  }

                  if (touched?.options?.minMax) {
                    touched.options.min = true
                    touched.options.max = true
                  }

                  formik.setTouched(touched)
                }
              }}
            >
              {baseData ? 'Done' : 'Add'}
            </Button>
          </Modal.Actions>
        </div>
      </FormikProvider>
    </Modal>
  )
}

export default EquationModal
