import { PartType, QuestionType } from '@packages/types'
import { isEqual, omit, pickBy, uniq } from 'lodash'

import { actionTypes as coreActionTypes } from 'builder/build/core'
import { constants } from 'common/customizerProducts'
import {
  reducer as customization,
  actionTypes as customizationActionTypes,
  applyRules,
  applyLinkedQuestions,
} from 'customizer/customization'
import { getQuestionRestrictedAnswers } from 'customizer/customization/utils'
import MczrIcon from 'img/logo-part.png'

import { UPDATE_PREVIEW, ADD_ANSWER_PREVIEW, REMOVE_ANSWER_PREVIEW, ALLOW_EDIT_PRINT_AREA } from './actionTypes'

const isAnswerValid = (newQuestion, newAnswers, answerId) => {
  const answerExistsInQuestion = newQuestion.answers.includes(answerId)
  const answer = newAnswers[answerId]

  return !!answer && (answer.isPersonalisation || (answerExistsInQuestion && !answer.archived))
}

const getQuestionAnswer = (originalQuestion, newQuestion, newAnswers, defaultConfiguration) => {
  const { selectedAnswer, selectedAnswers, previewSelectedAnswer } = originalQuestion || {}
  const defaultAnswer = defaultConfiguration?.[newQuestion.linkedQuestionId ?? newQuestion.id]

  if (previewSelectedAnswer) return previewSelectedAnswer

  if (newQuestion.isMultiAnswer) {
    let validSelectedAnswers = selectedAnswers?.filter(answerId => isAnswerValid(newQuestion, newAnswers, answerId))

    const hasMaxSelections = typeof newQuestion.maxSelections === 'number' && !isNaN(newQuestion.maxSelections)

    validSelectedAnswers = hasMaxSelections
      ? validSelectedAnswers?.slice(0, newQuestion.maxSelections)
      : validSelectedAnswers

    if (!!validSelectedAnswers && validSelectedAnswers.length > 0) {
      return validSelectedAnswers
    }

    if (Array.isArray(defaultAnswer)) {
      const validDefaultAnswers = defaultAnswer?.filter(answerId => isAnswerValid(newQuestion, newAnswers, answerId))
      if (!!validDefaultAnswers && validDefaultAnswers.length > 0) {
        return validDefaultAnswers
      }
    }

    return undefined
  }

  if (!!originalQuestion) {
    if (originalQuestion.linkedQuestionId && !newQuestion.linkedQuestionId) {
      return defaultAnswer
    }

    const restrictedAnswers = getQuestionRestrictedAnswers(originalQuestion)
    const hasAvailableAnswers = !!newQuestion.answers.find(
      answerId => !restrictedAnswers.includes(answerId) && !newAnswers[answerId].archived
    )

    if (!hasAvailableAnswers && !newAnswers[selectedAnswer]?.isPersonalisation) {
      return undefined
    }
  }

  if (isAnswerValid(newQuestion, newAnswers, selectedAnswer)) {
    return selectedAnswer
  }

  if (isAnswerValid(newQuestion, newAnswers, defaultAnswer)) {
    return defaultAnswer
  }

  return newQuestion.answers.find(answerId => !newAnswers[answerId]?.archived)
}

const getQuestionRestrictions = (originalQuestion, rulesChanged, answerChanged) => {
  if (!originalQuestion?.restrictions || rulesChanged || answerChanged) return {}

  return originalQuestion.restrictions
}

const getQuestionAnswers = (originalQuestion, newQuestion, answers) => {
  if (!originalQuestion) return newQuestion.answers

  return uniq([
    ...newQuestion.answers,
    ...originalQuestion.answers.filter(
      answerId =>
        (answers[answerId]?.isPersonalisation === true && !answers[answerId]?.updateOnPreviewEdit) ||
        answerId === originalQuestion.previewSelectedAnswer
    ),
  ])
}

const getAnswersWithPersonalisations = (oldAnswers, newAnswers) => ({
  ...newAnswers,
  ...pickBy(oldAnswers, answer => answer.isPersonalisation === true && !answer.updateOnPreviewEdit),
})

const getQuestionPreviewSelectedAnswer = (originalQuestion, newQuestion) => {
  if (originalQuestion == null || newQuestion == null || !originalQuestion.previewSelectedAnswer) return null

  return newQuestion.answers.includes(originalQuestion.previewSelectedAnswer)
    ? undefined
    : originalQuestion.previewSelectedAnswer
}

const reducer = (state, action) => {
  switch (action.type) {
    case ADD_ANSWER_PREVIEW:
      return {
        ...state,
        questions: {
          ...state.questions,
          [action.payload.questionId]: {
            ...state.questions[action.payload.questionId],
            selectedAnswer: action.payload.answerId,
            previewSelectedAnswer: action.payload.answerId,
            previousSelectedAnswer: state.questions[action.payload.questionId].selectedAnswer,
            answers: [...state.questions[action.payload.questionId].answers, action.payload.answerId],
          },
        },
      }
    case REMOVE_ANSWER_PREVIEW:
      const { previousSelectedAnswer, previewSelectedAnswer, ...question } = state.questions[action.payload.questionId]

      return {
        ...state,
        questions: {
          ...state.questions,
          [action.payload.questionId]: {
            ...question,
            selectedAnswer: previousSelectedAnswer,
            answers: question.answers.filter(answerId => answerId !== previewSelectedAnswer),
          },
        },
      }

    case customizationActionTypes.EDIT_PART: {
      const part = action.payload.part

      if (state.parts[part.id].type !== PartType.Logo || state.parts[part.id].printArea == null) {
        return state
      }

      return {
        ...state,
        answers: { ...state.answers, [part.position.id]: part.position },
        questions: {
          ...state.questions,
          [part.logo]: {
            ...state.questions[part.logo],
            placeholder: { url: MczrIcon, isNotFromCDN: true },
          },
        },
      }
    }
    case customizationActionTypes.STOP_EDIT_PART: {
      if (action.payload.part.type !== PartType.Logo || action.payload.part.printArea == null) return state

      const part = state.parts[action.payload.part.id]

      return {
        ...state,
        questions: { ...state.questions, [part.logo]: omit(state.questions[part.logo], ['placeholder']) },
      }
    }
    case ALLOW_EDIT_PRINT_AREA:
      return {
        ...state,
        printAreas: Object.keys(state.printAreas).reduce((printAreas, printAreaId) => {
          return {
            ...printAreas,
            [printAreaId]: {
              ...state.printAreas[printAreaId],
              editable: printAreaId === action.payload,
            },
          }
        }, {}),
      }
    case coreActionTypes.REMOVE_PERSONALISED_ANSWERS: {
      const { questionId } = action.payload
      const question = state.questions[questionId]
      const personalisationAnswers = getAnswersWithPersonalisations(state.answers, [])

      if (personalisationAnswers && !Object.values(personalisationAnswers).length) return state

      return {
        ...state,
        answers: pickBy(state.answers, answer => !answer.isPersonalisation || !question.answers.includes(answer.id)),
        questions: {
          ...state.questions,
          [questionId]: {
            ...question,
            answers: Object.values(pickBy(question.answers, answer => !personalisationAnswers[answer])),
            selectedAnswer: !personalisationAnswers[question.selectedAnswer] ? question.selectedAnswer : undefined,
          },
        },
      }
    }
    case UPDATE_PREVIEW:
      const rulesChanged = action.payload.rules !== state.rules

      let shouldApplyRules = rulesChanged

      const newAnswers = getAnswersWithPersonalisations(state.answers, action.payload.answers)

      const nextState = {
        ...state,
        customizerProduct: action.payload.customizerProduct,
        rules: action.payload.rules,
        groups: action.payload.groups,
        parts: Object.keys(action.payload.parts).reduce((parts, partId) => {
          const part = action.payload.parts[partId]
          return { ...parts, [part.id]: { ...part, editable: true } }
        }, {}),
        answers: newAnswers,
        questions: Object.keys(action.payload.questions).reduce((questions, questionId) => {
          const originalQuestion = state.questions[questionId]
          const newQuestion = action.payload.questions[questionId]
          const { defaultConfiguration } = action.payload.customizerProduct

          const nextAnswer = getQuestionAnswer(originalQuestion, newQuestion, newAnswers, defaultConfiguration)

          const answerChanged = Array.isArray(nextAnswer)
            ? !isEqual(originalQuestion?.selectedAnswers, nextAnswer)
            : originalQuestion?.selectedAnswer !== nextAnswer

          shouldApplyRules = shouldApplyRules || answerChanged

          const previewSelectedAnswer = getQuestionPreviewSelectedAnswer(
            state.questions[questionId],
            action.payload.questions[questionId]
          )

          questions[questionId] = {
            ...action.payload.questions[questionId],
            ...(state.questions[questionId]?.type === QuestionType.Logo && state.questions[questionId]?.placeholder
              ? { placeholder: state.questions[questionId].placeholder }
              : {}),
            ...(previewSelectedAnswer != null ? { previewSelectedAnswer } : {}),
            ...(state.questions[questionId]?.previousSelectedAnswer
              ? { previousSelectedAnswer: state.questions[questionId].previousSelectedAnswer }
              : {}),
            restrictions: getQuestionRestrictions(state.questions[questionId], rulesChanged, answerChanged),
            answers: getQuestionAnswers(
              state.questions[questionId],
              action.payload.questions[questionId],
              state.answers
            ),
            ...(newQuestion.isMultiAnswer
              ? { selectedAnswers: Array.isArray(nextAnswer) ? nextAnswer : [] }
              : { selectedAnswer: Array.isArray(nextAnswer) ? undefined : nextAnswer }),
          }

          return questions
        }, {}),
        printAreas: Object.keys(action.payload.printAreas).reduce((printAreas, printAreaId) => {
          return {
            ...printAreas,
            [printAreaId]: {
              ...action.payload.printAreas[printAreaId],
              editable: state.printAreas[printAreaId]?.editable,
            },
          }
        }, {}),
      }

      return shouldApplyRules ? applyLinkedQuestions(applyRules(nextState)) : applyLinkedQuestions(nextState)
    default:
      return customization(state, action)
  }
}

export default reducer
