import { OnlineStore, CustomizerProduct, StartingPoint } from '@packages/types'
import { isEqual } from 'lodash'

import { utils as customizerProductsUtils } from 'builder/build/customizerProducts'
import { selectors as startingPointSelectors } from 'customizer/startingPoints'
import isNullOrEmpty from 'utils/isNullOrEmpty'

export const getStartingPointWarningMessage = (startingPoint: StartingPoint, customizerProduct?: CustomizerProduct) => {
  let configurationWithRulesApplied: Record<string, object> | undefined

  if (customizerProduct) {
    const customizationState = customizerProductsUtils.buildCustomizationState(
      customizerProduct,
      startingPoint.configuration
    )

    configurationWithRulesApplied = startingPointSelectors.startingPointConfigurationSelector(customizationState as any)
  }

  if (startingPoint.upToDate || !configurationWithRulesApplied) return null

  const configurationWithRulesAppliedKeys = Object.keys(configurationWithRulesApplied)
  const startingPointConfigurationKeys = Object.keys(startingPoint.configuration)

  if (configurationWithRulesAppliedKeys.length !== startingPointConfigurationKeys.length) return 'product'

  const questionsWithoutAnswer = configurationWithRulesAppliedKeys.find((key: string) => {
    if (
      customizerProduct &&
      configurationWithRulesApplied &&
      !isEqual(configurationWithRulesApplied[key], startingPoint.configuration[key])
    ) {
      const question = customizerProduct.questions.find(question => question.id === key)
      const questionHasActiveAnswer = question?.answers.find(
        answer => answer.id === startingPoint.configuration[question!.id] && !answer.archived
      )

      if (!questionHasActiveAnswer) return true
    }
    return false
  })

  return isNullOrEmpty(questionsWithoutAnswer) ? 'rule' : 'product'
}

export const getStartingPointBasePrice = (onlineStore: OnlineStore, startingPoint: StartingPoint) =>
  onlineStore.productsData[startingPoint.productId]?.pricing?.basePrice ?? 0
