import React, { useRef } from 'react'

import { Input, IconButton } from 'common/components'
import SearchIcon from 'icons/bold/01-Interface Essential/25-Search/search-1.svg'
import Remove from 'icons/bold/01-Interface Essential/43-Remove-Add/remove.svg'

interface UniverSearchInputProps {
  value: string
  name?: string
  onChange: (value: string) => void
  onFocus: () => void
}

const UniversalSearchInput = React.forwardRef<HTMLDivElement, UniverSearchInputProps>(
  ({ value, name = 'search', onChange, onFocus }, ref) => {
    const inputRef = useRef<HTMLInputElement>(null)

    const preventBlur = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.preventDefault()

    return (
      <div
        className="mr-2 cursor-pointer focus-within:cursor-text"
        onClick={() => {
          if (document.activeElement !== inputRef.current) inputRef.current?.focus()
        }}
        ref={ref}
      >
        <Input
          ref={inputRef}
          type="search"
          name={name}
          placeholder="Search..."
          className="!px-0 hover:shadow"
          inputClassName="transition-all h-full cursor-pointer focus:cursor-text w-[70px] xl:w-full"
          value={value}
          onFocus={onFocus}
          onChange={e => onChange(e.target.value)}
          leftAddon={
            <div className="w-7 h-full pl-3 flex items-center" onMouseDown={preventBlur}>
              <SearchIcon className="fill-neutral-400" />
            </div>
          }
          rightAddon={
            <div className="w-9 h-full pr-3 -mr-1 flex items-center" onMouseDown={preventBlur}>
              {value && (
                <IconButton
                  small
                  variant="subtle"
                  aria-label="Clear search"
                  Icon={Remove}
                  onClick={() => onChange('')}
                />
              )}
            </div>
          }
        />
      </div>
    )
  }
)

export default UniversalSearchInput
