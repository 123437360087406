import { TextVerticalAlign } from '@packages/types'
import React from 'react'

import { useDispatch } from 'cms/hooks'
import { BoxTransformer } from 'common/components'
import { MultilineTextNode } from 'customizer/2dDisplayer/types'

import { applyMultilineTextEdition, applyPrintAreaMultilineTextEdition } from '../../actions'
import TargetClipContainer from '../containers/TargetClipContainer'
import MultilineText from '../nodes/MultilineText'
import Renderable from './MultilineRenderable'
import NodeInfo from './NodeInfo'
import { EditorProps } from './types'
import useBoxEditor from './useBoxEditor'

const getOrigin = (node: MultilineTextNode) => {
  if (node.allowedTransforms?.resize) return 'center'

  switch (node.position.verticalAlign) {
    case TextVerticalAlign.Top:
      return 'top center'
    case TextVerticalAlign.Bottom:
      return 'bottom center'
    case TextVerticalAlign.Middle:
    default:
      return 'center'
  }
}

const MultilineTextEditor = ({ node, entity, currentView }: EditorProps<MultilineTextNode>) => {
  const { targetRef, Target, getTransformedBoundingBox, onDrag, onResize, onRotate, scale } = useBoxEditor(
    {
      x: node.position.x,
      y: node.position.y,
      width: node.position.boundingBox?.maxWidth,
      scale: node.scale,
      rotation: node.position.rotation ?? 0,
      origin: getOrigin(node),
    },
    ['width']
  )

  const dispatch = useDispatch()

  const handleTransformEnd = () => {
    const boundingBox = getTransformedBoundingBox()

    'printArea' in entity && entity.printArea
      ? dispatch(applyPrintAreaMultilineTextEdition(entity.id, boundingBox))
      : dispatch(applyMultilineTextEdition(entity.id, boundingBox, currentView))
  }

  return (
    <>
      <TargetClipContainer>
        <Target>
          <MultilineText
            text={node.text.value}
            color={node.color.hex}
            fontFamily={node.font.family}
            fontSize={parseFloat(node.font.size) * scale}
            fontAssets={node.font.assets}
            fontType={node.font.fontType}
            textAlign={node.position.textAlign}
            verticalAlign={node.position.verticalAlign}
            outlineColor={node.outline.hex}
            outlineWidth={`${parseFloat(node.outline.width) * scale}`}
          />
        </Target>
      </TargetClipContainer>
      <BoxTransformer
        ables={!node.allowedTransforms?.resize ? [Renderable] : []}
        target={targetRef}
        onDrag={onDrag}
        onResize={onResize}
        onRotate={onRotate}
        onTransformEnd={handleTransformEnd}
        renderDirections={['e', 'w']}
        allowedResizes={['width']}
        className={!node.allowedTransforms?.resize ? 'dashed' : ''}
        onBeforeResize={e => {
          if (node.position.verticalAlign === TextVerticalAlign.Top) e.setFixedDirection([e.startFixedDirection[0], -1])
          if (node.position.verticalAlign === TextVerticalAlign.Bottom)
            e.setFixedDirection([e.startFixedDirection[0], 1])
        }}
        props={
          !node.allowedTransforms?.resize
            ? {
                [Renderable.name]: true,
                verticalAlign: node.position.verticalAlign,
              }
            : {
                info: <NodeInfo entity={entity} node={node} />,
              }
        }
      />
    </>
  )
}

export default MultilineTextEditor
