import { Asset } from '@packages/types'
import axios from 'axios'
import { useContext } from 'react'

import { apiClient, createQuery } from 'common/api'
import { TenantContext } from 'common/tenant'

const useAssetService = () => {
  const tenant = useContext(TenantContext)
  return {
    fetchPublic: createQuery(['image'], async (url: string) => {
      const response = await axios.get(url, { responseType: 'blob' })
      return response.data
    }),
    upload: async (files: Blob[]) => {
      const formData = new FormData()
      files.forEach(file => formData.append('blob', file))

      const { data } = await apiClient.post<Asset[]>(`/brands/${tenant}/assets/upload`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })

      return data
    },
  }
}

export default useAssetService
