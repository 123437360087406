import * as normalize from '@packages/normalizer'
import {
  Rule,
  Answer,
  AnswerType,
  CustomizableQuestion,
  DenormalizedCustomizableQuestion,
  LogoView,
  Part,
  TextView,
  QuestionInputType,
  PartType,
  Group,
  GroupType,
  DenormalizedCustomizablePart,
  DenormalizedCustomizableGroup,
  ColorView,
  CustomizableCustomizerProduct,
} from '@packages/types'
import { intersection } from 'lodash'
import { createSelector } from 'reselect'

import { constants } from 'common/customizerProducts'
import { RootState } from 'customizer/store'
import isNullOrEmpty from 'utils/isNullOrEmpty'

import {
  getPartFromQuestion,
  getFirstLevelAncestorId,
  isBehindTheScene,
  getQuestionRestrictedAnswers,
  questionHasAvailableAnswers,
  stepHasQuestionWithAvailableAnswers,
} from './utils'

const { Denormalizer } = normalize.customizerProduct

export const isBulkOrderRuleValid = (rule: Rule, groups: Record<string, Group>) => {
  const bulkOrderGroup = Object.values(groups).find(group => group.type === GroupType.BulkOrder)
  if (!bulkOrderGroup) return true

  const areSomeWhenInBulkOrder = rule.when?.some(({ path }) => bulkOrderGroup?.children.includes(path[1]))
  const areAllThenInBulkOrder = rule.then?.every(({ questionId }) => bulkOrderGroup?.children.includes(questionId))

  return !areSomeWhenInBulkOrder || (areSomeWhenInBulkOrder && areAllThenInBulkOrder)
}

export enum SummaryType {
  All = 'all',
  BulkOrderQuestionsOnly = 'bulk-order-questions-only',
  NonBulkOrderQuestionsOnly = 'non-bulk-order-questions-only',
}

const propsSelector = (_: RootState, props = { summaryType: SummaryType.All }) => props

export const customizationSelector = ({ customization }: RootState) => customization

export const printAreasSelector = createSelector(customizationSelector, customization => customization.printAreas)

export const idSelector = (_: RootState, { id }: { id: string }) => id

export const productIdSelector = createSelector(customizationSelector, ({ productId }) => productId)

export const productNameSelector = createSelector(customizationSelector, ({ productName }) => productName)

export const viewCountSelector = createSelector(
  customizationSelector,
  ({ customizerProduct }) => customizerProduct.views
)

export const currentViewSelector = createSelector(
  (state: RootState) => state.displayer,
  ({ currentView }) => currentView
)

export const customizerProductIdSelector = createSelector(
  customizationSelector,
  ({ customizerProduct }) => customizerProduct.id
)

export const rulesSelector = createSelector(customizationSelector, customization => customization.rules)

export const customizerProductSelector = createSelector(customizationSelector, customization => {
  return Denormalizer.run(
    {
      groups: {},
      answers: {},
      parts: {},
      questions: {},
      rules: {},
      printAreas: {},
      ...(customization as Partial<RootState['customization']>),
    },
    customization.customizerProduct,
    'customizerProducts'
  ) as CustomizableCustomizerProduct
})

export const partsSelector = createSelector(customizationSelector, customization => {
  return Denormalizer.run(customization, Object.values(customization.parts), 'parts') as DenormalizedCustomizablePart[]
})

export const orderedPartsSelector = createSelector(
  customizerProductSelector,
  customizerProduct => customizerProduct.parts
)

export const normalizedPartsSelector = createSelector(customizationSelector, customization => customization.parts)

export const groupsSelector = createSelector(customizationSelector, customization => {
  return Denormalizer.run(
    customization,
    Object.values(customization.groups),
    'groups'
  ) as DenormalizedCustomizableGroup[]
})

export const normalizedGroupsSelector = createSelector(customizationSelector, customization => customization.groups)

export const normalizedQuestionsSelector = createSelector(
  customizationSelector,
  customization => customization.questions
)

export const treeSelector = createSelector(customizerProductSelector, customizerProduct => customizerProduct.tree)

export const storeIdSelector = ({ storeId }: RootState) => storeId

export const tenantSelector = ({ tenant }: RootState) => tenant

export const configurationSelector = createSelector(customizationSelector, customization => {
  return Denormalizer.run(
    customization,
    Object.values(customization.questions),
    'questions'
  ) as DenormalizedCustomizableQuestion[]
})

export const selectedAnswerIdSelector = createSelector(
  customizationSelector,
  (_: RootState, questionId?: string) => questionId,
  (customization, questionId) => {
    return questionId ? customization.questions[questionId]?.selectedAnswer : null
  }
)

export const getAnswerSummary = (answer: Answer) => {
  switch (answer.type) {
    case AnswerType.Text:
      return answer.value
    case AnswerType.Logo:
      if (!answer.isPersonalisation) return answer.name

      const logoView = answer.views?.[0] as LogoView | undefined
      return logoView?.logo?.url ? logoView.logo.url : answer.name
    default:
      return answer.name
  }
}

export const summarySelector = createSelector(
  configurationSelector,
  normalizedGroupsSelector,
  propsSelector,
  (configuration, groups, { summaryType }) => {
    const bulkOrderGroup = Object.values(groups).find(group => group.type === GroupType.BulkOrder)

    return configuration.reduce<{ key: string; value: string | string[] }[]>((summary, question) => {
      if (summaryType === SummaryType.BulkOrderQuestionsOnly && !bulkOrderGroup?.children.includes(question.id)) {
        return summary
      }

      if (summaryType === SummaryType.NonBulkOrderQuestionsOnly && bulkOrderGroup?.children.includes(question.id)) {
        return summary
      }

      if (question.isInSummary) {
        if (question.isMultiAnswer && question.selectedAnswers && question.selectedAnswers.length > 0) {
          summary.push({
            key: question.name,
            value: question.selectedAnswers.map(answer => getAnswerSummary(answer) || ''),
          })
        } else if (question.selectedAnswer) {
          summary.push({
            key: question.name,
            value: getAnswerSummary(question.selectedAnswer) || '',
          })
        }
      }

      return summary
    }, [])
  }
)

const getAnswerValue = (answer: Answer) => {
  switch (answer.type) {
    case AnswerType.Text:
      return answer.value
    case AnswerType.Logo:
      if (answer.hasCode && answer.code) return answer.code
      if (!answer.isPersonalisation) return answer.name
      return (answer.views?.[0] as LogoView | undefined)?.logo?.url
    default:
      return answer.hasCode && answer.code ? answer.code : answer.name
  }
}

export const ordersheetSelector = createSelector(configurationSelector, questions =>
  questions.reduce<
    {
      key: string
      values?: string[]
      value?: string
      ref: { questionId: string; answerIds?: string[]; answerId?: string }
    }[]
  >((ordersheet, question) => {
    if (question.isMultiAnswer) {
      const answers = question.selectedAnswers!
      if (!question.isInOrdersheet || answers?.length === 0) return ordersheet

      ordersheet.push({
        key: question.hasCode && question.code ? question.code : question.name,
        values: answers.map(answer => getAnswerValue(answer) || ''),
        ref: {
          questionId: question.id,
          answerIds: answers.map(({ id }) => id),
        },
      })
    } else {
      const answer = question.selectedAnswer
      const isPersonalisedFontSize = answer?.type === AnswerType.FontSize && answer?.isPersonalisation

      if (!question.isInOrdersheet || !answer || isPersonalisedFontSize) return ordersheet

      ordersheet.push({
        key: question.hasCode && question.code ? question.code! : question.name,
        value: getAnswerValue(answer),
        ref: {
          questionId: question.id,
          answerId: answer.id,
        },
      })
    }

    return ordersheet
  }, [])
)

export const getFirstAvailableAnswer = (
  question: CustomizableQuestion,
  answers: Record<string, Answer>,
  preferredConfiguration: string | null
) => {
  const restrictedAnswers = getQuestionRestrictedAnswers(question)
  if (!restrictedAnswers.includes(preferredConfiguration)) return preferredConfiguration
  return question.answers.find(answerId => !restrictedAnswers.includes(answerId) && !answers[answerId].archived) || null
}

export const isCurrentAnswerRestricted = (question: CustomizableQuestion) => {
  const restrictedAnswers = getQuestionRestrictedAnswers(question)
  return question.isMultiAnswer
    ? intersection(restrictedAnswers, question.selectedAnswers).length > 0
    : restrictedAnswers.includes(question.selectedAnswer)
}

export const validRulesSelector = createSelector(rulesSelector, customizationSelector, (rules = {}, customization) => {
  return Object.values(rules).filter(rule => !rule.archived && isBulkOrderRuleValid(rule, customization.groups))
})

export const designConfigurationSelector = createSelector(configurationSelector, configuration => {
  return configuration.reduce<Record<string, string | string[]>>((result, question) => {
    if (question.isMultiAnswer) {
      result[question.id] = question.selectedAnswers?.map(({ id }) => id) || []
    } else if (question.selectedAnswer) {
      result[question.id] = question.selectedAnswer.id
    }
    return result
  }, {})
})

export const personalisationsSelector = createSelector(configurationSelector, partsSelector, (configuration, parts) => {
  const personalisations = configuration.reduce<Record<string, Answer>>((result, question) => {
    if (question.isMultiAnswer) {
      question.selectedAnswers?.forEach(answer => {
        if (answer.isPersonalisation) result[answer.id] = answer
      })
    } else if (question.selectedAnswer?.isPersonalisation === true) {
      result[question.selectedAnswer.id] = question.selectedAnswer
    }
    return result
  }, {})

  return parts.reduce((result, part) => {
    const fields = constants.parts.questionFields[part.type].map(({ field }) => field)
    fields.forEach(field => {
      const answer = part[field] as Answer | undefined
      if (answer?.isPersonalisation === true) {
        result[answer.id] = answer
      }
    })
    return result
  }, personalisations)
})

export const partsWithPrintSelector = createSelector(orderedPartsSelector, parts => {
  return parts.filter(part => {
    const textAnswer = (part.text as DenormalizedCustomizableQuestion | undefined)?.selectedAnswer
    const text = (textAnswer?.views?.[0] as TextView | undefined)?.text
    const logoAnswer = (part.logo as DenormalizedCustomizableQuestion | undefined)?.selectedAnswer
    const logo = (logoAnswer?.views?.[0] as LogoView | undefined)?.logo
    return !isNullOrEmpty(part.printArea) && (logo || !isNullOrEmpty(text))
  })
})

export const isReadySelector = createSelector(customizationSelector, ({ isReady }) => isReady)

export const partForceHighlightGroupSelector = createSelector(
  normalizedPartsSelector,
  (_: RootState, partId: string) => partId,
  (parts, partId) => {
    return parts[partId]?.forceHighlight ? parts[partId].highlightGroup : null
  }
)

export const highlightablePartsSelector = createSelector(
  normalizedPartsSelector,
  (_: RootState, questionIds: string[]) => questionIds,
  (parts, questionIds) => {
    const highlightableParts = Object.values(parts).filter(part => part.forceHighlight)

    return questionIds.reduce<string[]>((result, questionId) => {
      const highlightablePart = getPartFromQuestion(questionId, highlightableParts) as Part

      if (highlightablePart && !result.includes(highlightablePart.highlightGroup)) {
        result.push(highlightablePart.highlightGroup)
      }

      return result
    }, [])
  }
)

export const firstLevelAncestorSelector = createSelector(
  normalizedGroupsSelector,
  normalizedQuestionsSelector,
  treeSelector,
  (_: RootState, entityId: string) => entityId,
  (groups, questions, root, entityId): Group | CustomizableQuestion | undefined => {
    const treeParentId = getFirstLevelAncestorId(Object.values(groups), entityId, root.id)

    return groups[treeParentId] || questions[treeParentId]
  }
)

export const isCustomizingDesignSelector = createSelector(
  customizationSelector,
  ({ isCustomizingDesign }) => isCustomizingDesign
)

export const customizerTitleSelector = createSelector(
  customizerProductSelector,
  customizerProduct => customizerProduct.tree?.name
)

export const selectedAnswerIdsSelector = (state: RootState, questionIds: string[]) => {
  return questionIds.reduce((selectedAnswers: string[], questionId) => {
    const question = state.customization.questions[questionId]

    if (question?.isMultiAnswer && question.selectedAnswers) {
      return [...selectedAnswers, ...question.selectedAnswers]
    } else if (question?.selectedAnswer) {
      return [...selectedAnswers, question.selectedAnswer]
    }

    return selectedAnswers
  }, [])
}

export const interactedQuestionsSelector = createSelector(customizationSelector, ({ questions }) =>
  Object.values(questions)
    .filter(question => !!question.hadInteraction)
    .map(({ id }) => id)
)

export const designColorsSelector = createSelector(
  normalizedGroupsSelector,
  configurationSelector,
  (_state: RootState, id: string) => id,
  (groups, questions, currentQuestionId) => {
    const currentQuestion = questions.find(question => question.id === currentQuestionId)
    const otherColorPickerQuestions = questions.filter(
      question => question.inputType === QuestionInputType.ColorPicker && !isBehindTheScene(groups, question)
    )

    return otherColorPickerQuestions.reduce<string[]>((result, question) => {
      const colors = question.answers
        .filter(answer => answer.isPersonalisation && answer.id !== currentQuestion?.selectedAnswer?.id)
        .map(answer => (answer.views as ColorView[] | undefined)?.find(view => view.color)?.color || '#FFFFFF')
        .filter(color => !result.find(value => value.toUpperCase() === color.toUpperCase()))

      return [...result, ...colors]
    }, [])
  }
)

export const questionsRequiringInteractionSelector = createSelector(
  normalizedGroupsSelector,
  customizationSelector,
  interactedQuestionsSelector,
  (groups, { questions }, interactedQuestions) => {
    return Object.values(questions).filter(
      question =>
        questionHasAvailableAnswers(question) &&
        question.isInteractionRequired &&
        !interactedQuestions.includes(question.id) &&
        !isBehindTheScene(groups, question)
    )
  }
)

export const wordFilteredQuestionsSelector = createSelector(customizationSelector, ({ questions }) =>
  Object.values(questions).filter(question => !!question.hasFilteredWord)
)

export const getStepIdFromPartSelector = createSelector(
  customizationSelector,
  (_state: RootState, props: { partId: string }) => props.partId,
  ({ parts, questions, groups }, partId) => {
    const part = parts[partId]

    const groupsArray = Object.values(groups)

    for (const { field } of constants.parts.questionFields[part.type]) {
      const id = part[field]!
      const questionId = questions[id]?.linkedQuestionId ?? id
      const group = groupsArray.find(group => group.children.includes(questionId))

      if (group != null && stepHasQuestionWithAvailableAnswers(questions[questionId])) return questionId
    }

    return null
  }
)

export const partFromQuestionIdSelector = createSelector(
  partsSelector,
  (_: RootState, questionId: string) => questionId,
  (parts, questionId) => {
    return parts.find(part => {
      const fields = constants.parts.questionFields[part.type]
      return fields.find(({ field }) => part[field]?.id === questionId)
    })
  }
)

export const isQuestionEditableSelector = createSelector(
  partFromQuestionIdSelector,
  currentViewSelector,
  (part, view) => {
    if (!part || part.type === PartType.Image) return false

    if (part.type === PartType.Text && part.text) {
      const viewData = (part.text as DenormalizedCustomizableQuestion).selectedAnswer?.views?.[view]
      const cleanedValue = ((viewData as TextView | undefined)?.text || '').replace(/\s/g, '')
      if (cleanedValue === '') return false
    }

    return !!(
      part.printArea?.productPreview.designView === view &&
      part.allowedTransforms &&
      Object.values(part.allowedTransforms).includes(true)
    )
  }
)

export const submitActionSelector = createSelector(customizerProductSelector, ({ submitAction }) => submitAction)

export const isSubmitEnabledSelector = createSelector(customizationSelector, ({ isSubmitEnabled }) => isSubmitEnabled)
