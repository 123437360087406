import { PrintArea } from '@packages/types'
import React, { useEffect, useMemo, useRef, useState } from 'react'

import { MaskSection, MovablePanel, MovablePanelHeader, Panel } from 'builder/build/common/components'
import { useCustomizerDispatch } from 'builder/build/customizer'
import { selectors as partsSelectors, actions as partsActions } from 'builder/build/parts'
import { actions as previewActions, selectors as previewSelectors } from 'builder/build/preview'
import { useDispatch, useSelector } from 'cms/hooks'
import { Tooltip, IconButton, Button, useModal, Modal } from 'common/components'
import { PreviewMode } from 'common/preview/types'
import { twoDDisplayerActions } from 'customizer/2dDisplayer'
import BinIcon from 'icons/regular/01-Interface Essential/23-Delete/bin.svg'

import { setMask, deletePrintArea } from '../actions'
import {
  TitleSection,
  DesignViewSection,
  PrintAreaDimensionsSection,
  PrintAreaPreviewSection,
  ShowPrintAreaSection,
  ViewsSection,
} from './sections'

interface PrintAreaPanelProps {
  printArea: PrintArea
  onClose: () => void
}

const PrintAreaPanel = ({ printArea, onClose }: PrintAreaPanelProps) => {
  const deleteButtonRef = useRef<HTMLButtonElement>(null)
  const doneButtonRef = useRef<HTMLButtonElement>(null)
  const [dragRef, setDragRef] = useState<HTMLDivElement>()
  const dispatch = useDispatch()
  const customizerDispatch = useCustomizerDispatch()
  const deleteModal = useModal()
  const parts = useSelector(partsSelectors.partsAsArraySelector)
  const previewMode = useSelector(previewSelectors.previewModeSelector)
  const initialPreviewMode = useMemo(() => previewMode, [])

  const handleApplyMask = (masks: string[]) => {
    dispatch(setMask(printArea.id, masks))
  }

  const handleChangeView = (view: number) => customizerDispatch(twoDDisplayerActions.switchView(view))

  const handleDoneClick = () => {
    doneButtonRef.current?.focus()
    onClose()
  }

  const handleDeleteClick = () => {
    deleteButtonRef.current?.focus()
    deleteModal.open()
  }

  const handleDelete = () => {
    deleteModal.close()
    onClose()
    dispatch(partsActions.removePrintAreaFromParts(printArea.id, parts))
    dispatch(deletePrintArea(printArea.id))
  }

  useEffect(() => {
    customizerDispatch(previewActions.allowEditPrintArea(printArea.id))
    customizerDispatch(previewActions.changePreviewMode(PreviewMode.PRODUCT))
    dispatch(previewActions.changePreviewMode(PreviewMode.PRODUCT))
    dispatch(previewActions.setDisabledModes([PreviewMode.DESKTOP, PreviewMode.MOBILE]))

    return () => {
      customizerDispatch(previewActions.allowEditPrintArea(null))
      dispatch(previewActions.setDisabledModes([]))
      dispatch(previewActions.changePreviewMode(initialPreviewMode))
      customizerDispatch(previewActions.changePreviewMode(initialPreviewMode))
    }
  }, [])

  return (
    <MovablePanel dragRef={dragRef}>
      <Panel>
        <MovablePanelHeader setDragRef={setDragRef}>
          <div className="popper-menu-header-section__title">{printArea.name}</div>
          <div className="icon-button__group">
            <Tooltip content="Delete">
              <IconButton
                ref={deleteButtonRef}
                className="!h-7 !w-7 !p-0.5"
                onClick={handleDeleteClick}
                data-testid="header-close-button"
                iconClassName="text-error-default ml-[2px] w-4 h-4"
                Icon={BinIcon}
              />
            </Tooltip>
            <Button ref={doneButtonRef} className="!h-7" onClick={handleDoneClick} data-testid="header-close-button">
              Done
            </Button>
          </div>
        </MovablePanelHeader>
        <div className="panel__content">
          <TitleSection printArea={printArea} />
          <hr className="panel__divider" />
          <div className="panel__section">
            <DesignViewSection printArea={printArea} onChangeView={handleChangeView} />
            <PrintAreaPreviewSection printArea={printArea} />
          </div>
          <hr className="panel__divider" />
          <PrintAreaDimensionsSection printArea={printArea} />
          <hr className="panel__divider" />
          <div className="panel__section">
            <ShowPrintAreaSection printArea={printArea} />
          </div>
          <hr className="panel__divider" />
          <div className="panel__section">
            <MaskSection
              onApply={handleApplyMask}
              entityMasks={printArea.productPreview.masks}
              maskMessage={`Your logo will be masked if it goes \nout of the selected components.`}
            />
          </div>
          <hr className="panel__divider" />
          <div className="panel__section">
            <ViewsSection printArea={printArea} />
          </div>
        </div>
      </Panel>
      {deleteModal.isVisible && (
        <Modal onBackdropClick={deleteModal.close} {...deleteModal.modalProps}>
          <Modal.CloseButton onClick={deleteModal.close} />
          <Modal.Title>Delete {printArea.name}</Modal.Title>

          <Modal.Details>
            Are you sure you want to delete the print area {printArea.name}? This action cannot be undone.
          </Modal.Details>

          <Modal.Actions>
            <Button type="button" variant="default" className="px-4" onClick={deleteModal.close}>
              Cancel
            </Button>
            <Button type="button" variant="error" className="px-4" onClick={handleDelete}>
              Delete
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </MovablePanel>
  )
}

export default PrintAreaPanel
