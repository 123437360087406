import { FontView } from '@packages/types'
import React, { useEffect } from 'react'

import { FontService } from 'utils/loaders'

export interface FontThumbnailProps {
  fontView: FontView
}

const FontThumbnail = ({ fontView }: FontThumbnailProps) => {
  const { assets, font, fontType } = fontView

  useEffect(() => {
    FontService.loadFont({ fontFamily: font, type: fontType, asset: assets })
  }, [font])

  return (
    <span
      role="presentation"
      aria-label="font preview"
      className="w-6 h-6 mr-2 shrink-0 rounded bg-white border border-solid border-neutral-75 flex items-center justify-center"
    >
      <div style={{ fontFamily: font }}>
        <span>aA</span>
      </div>
    </span>
  )
}

export default FontThumbnail
