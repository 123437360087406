import React from 'react'
import { Route } from 'react-router-dom'

import { LoginContainer } from 'common/components'

import ChangePasswordError from './ChangePasswordError'
import ChangePasswordForm from './ChangePasswordForm'
import ChangePasswordSuccess from './ChangePasswordSuccess'
import CreateAccountError from './CreateAccountError'
import CreateAdminAccountForm from './CreateAdminAccountForm'
import CreateInviteAccountForm from './CreateInviteAccountForm'
import ForgotPasswordForm from './ForgotPasswordForm'
import LoginForm from './LoginForm'
import ResetPasswordEmailSent from './ResetPasswordEmailSent'

export const Login = () => {
  return (
    <LoginContainer>
      <Route exact path="/login" component={LoginForm} />
      <Route exact path="/login/forgot" component={ForgotPasswordForm} />
      <Route exact path="/login/forgot/email-sent/:email" component={ResetPasswordEmailSent} />
      <Route exact path="/login/resetpassword-error" component={ChangePasswordError} />
      <Route exact path="/login/resetpassword-success" component={ChangePasswordSuccess} />
      <Route exact path="/login/createaccount-error" component={CreateAccountError} />
      <Route exact path="/login/resetpassword/:token" component={ChangePasswordForm} />
      <Route exact path="/login/accept-invite/:token" component={CreateInviteAccountForm} />
      <Route exact path="/login/accept-admin-invite/:token" component={CreateAdminAccountForm} />
    </LoginContainer>
  )
}

export default Login
