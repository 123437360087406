import React, { useCallback, useRef } from 'react'
import { useSelector } from 'react-redux'

import { selectors as coreSelectors } from 'builder/build/core'
import isNullOrEmpty from 'utils/isNullOrEmpty'
import ImageLoader from 'utils/loaders/ImageLoader'

export const ImageSizeContext = React.createContext<{ checkDimensions: (url: string) => Promise<boolean> }>(
  {} as { checkDimensions: (url: string) => Promise<boolean> }
)

const ImageSizeContextProvider = ({ children }: React.PropsWithChildren) => {
  const customizerProduct = useSelector(coreSelectors.customizerProductSelector)

  const imageDimensionMatches = useRef<{ [key: string]: { width: number; height: number } }>({})
  const checkDimensions = useCallback(
    async (url: string) => {
      const { width, height } = customizerProduct.dimensions

      const checkMatch = (imageSize: { width: number; height: number }) => {
        if (+imageSize.width !== +width || +imageSize.height !== +height) return false
        return true
      }

      if (isNullOrEmpty(url)) return true

      if (imageDimensionMatches.current[url]) return checkMatch(imageDimensionMatches.current[url])

      imageDimensionMatches.current[url] = await ImageLoader.getSize(url)

      return checkMatch(imageDimensionMatches.current[url])
    },
    [customizerProduct.dimensions?.width, customizerProduct.dimensions?.height]
  )

  return <ImageSizeContext.Provider value={{ checkDimensions }}>{children}</ImageSizeContext.Provider>
}

export default ImageSizeContextProvider
