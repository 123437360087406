import React, { useState, useEffect } from 'react'
import { ColorChangeHandler, SketchPicker, SketchPickerProps } from 'react-color'

import { useDocumentMoveHandlers } from 'common/hooks'

interface PatchSketchPickerProps extends SketchPickerProps {
  color: string
  onChange: ColorChangeHandler
}

const PatchSketchPicker = ({ color, onChange, ...props }: PatchSketchPickerProps) => {
  const [internalColor, setInternalColor] = useState(color)
  const { isMoving, ...documentMoveHandlers } = useDocumentMoveHandlers()

  useEffect(() => {
    if (color !== internalColor) setInternalColor(color)
  }, [color])

  return (
    <div {...documentMoveHandlers}>
      <SketchPicker
        {...props}
        color={internalColor}
        onChange={({ hex }) => setInternalColor(hex)}
        onChangeComplete={onChange}
      />
    </div>
  )
}

export default PatchSketchPicker
