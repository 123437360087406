import React from 'react'
import { Link } from 'react-router-dom'

import { Button, PublicFormFooter } from 'common/components'

import AuthFormContainer from './AuthFormContainer'
import AuthFormDescription from './AuthFormDescription'
import AuthFormTitle from './AuthFormTitle'
import AuthLink from './AuthLink'

const ChangePasswordError = () => (
  <AuthFormContainer>
    <AuthFormTitle>Sorry, your link has expired</AuthFormTitle>
    <AuthFormDescription>Please try to reset your password again.</AuthFormDescription>

    <PublicFormFooter>
      <Link to="/login/forgot">
        <Button variant="primary">Reset password</Button>
      </Link>
      <AuthLink to="/login">Back to login</AuthLink>
    </PublicFormFooter>
  </AuthFormContainer>
)

export default ChangePasswordError
