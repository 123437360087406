import React from 'react'

import { Button, Card, FullPageModal, ModalProps } from 'common/components'

export interface CancellationFeedbackModalProps extends Omit<ModalProps, 'children'> {
  onClose: () => void
}

const CancellationFeedbackModal = ({ onClose, ...modalProps }: CancellationFeedbackModalProps) => {
  return (
    <FullPageModal onClose={onClose} {...modalProps}>
      <div className="flex justify-center items-center w-full h-full">
        <Card className="w-[519px] flex flex-col">
          <Card.Section className="md:flex-col space-y-4 text-sm font-medium">
            <h2 className="font-h1">We're sorry to see you go.</h2>
            <p>You're always welcome to come back and pick up where you left off.</p>
            <p>
              Thank you for being a part of our community! If you have any question or feedback, we would love to{' '}
              <a
                aria-label="open intercom"
                className="text-primary-600 cursor-pointer"
                onClick={e => {
                  e.preventDefault()
                  window.Intercom('show')
                }}
              >
                hear from you.
              </a>
            </p>
          </Card.Section>
          <Card.Separator />
          <Card.Footer>
            <Button variant="primary" className="w-full" onClick={onClose}>
              Close
            </Button>
          </Card.Footer>
        </Card>
      </div>
    </FullPageModal>
  )
}

export default CancellationFeedbackModal
