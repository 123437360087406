import { NormalizedCustomizerProduct, Rule, RuleThen, RuleThenType } from '@packages/types'
import classNames from 'classnames'
import React from 'react'

import { bulkOrderGroupChildrenSelector } from 'builder/build/groups/selectors'
import { useSelector } from 'cms/hooks'
import { AutoresizeSelect, ProductQuestionsDropdown, QuestionAnswersDropdown } from 'common/components'

type IsAvailableParams = {
  rule: Rule
  customizerProduct: NormalizedCustomizerProduct
}

type Action = {
  label: string
  value: RuleThenType
  default: {
    type: RuleThenType
    answerId?: string
    payload?: string[]
  }
  isAvailable: (params: IsAvailableParams) => boolean
}

const actions: Action[] = [
  {
    label: 'should be',
    value: RuleThenType.RestrictAllBut,
    default: { type: RuleThenType.RestrictAllBut, answerId: undefined },
    isAvailable: () => true,
  },
  {
    label: 'should not be',
    value: RuleThenType.AddRestriction,
    default: { type: RuleThenType.AddRestriction, answerId: undefined },
    isAvailable: ({ rule, customizerProduct }) => {
      const question = customizerProduct.questions[rule.then[0]?.questionId]
      return !question?.isMultiAnswer
    },
  },
  {
    label: 'should be unavailable',
    value: RuleThenType.DisableQuestion,
    default: { type: RuleThenType.DisableQuestion },
    isAvailable: () => true,
  },
  {
    label: 'should be one of',
    value: RuleThenType.SetAuthorizedAnswers,
    default: { type: RuleThenType.SetAuthorizedAnswers, payload: [] },
    isAvailable: () => true,
  },
  {
    label: 'should not be one of',
    value: RuleThenType.SetRestrictedAnswers,
    default: { type: RuleThenType.SetRestrictedAnswers, payload: [] },
    isAvailable: () => true,
  },
]

export interface RightHandProps {
  readonly?: boolean
  updateAction: (predicateIndex: number, then: RuleThen) => void
  customizerProduct: NormalizedCustomizerProduct
  rule: Rule
  questionsToHighlight?: string[]
  answersToHighlight?: string[]
}

const RightHand = ({
  rule,
  readonly,
  customizerProduct,
  updateAction,
  questionsToHighlight,
  answersToHighlight,
}: RightHandProps) => {
  const bulkOrderGroupChildren = useSelector(bulkOrderGroupChildrenSelector)

  const availableActions = actions.filter(action => action.isAvailable({ customizerProduct, rule }))

  const updateAnswer = ({ value }: { value: string }) => updateAction(0, { ...rule.then[0], answerId: value })

  const changeAction = (value: Action) => updateAction(0, { ...value.default, questionId: rule.then[0].questionId })

  const updateQuestion = ({ value }: { value: string }) => {
    const newQuestion = customizerProduct.questions[value]

    updateAction(0, {
      ...rule.then[0],
      questionId: value,
      type: newQuestion?.isMultiAnswer ? RuleThenType.RestrictAllBut : RuleThenType.AddRestriction,
    })
  }

  const updateAnswers = (values: { value: string }[]) => {
    updateAction(0, { ...rule.then[0], payload: values?.map(({ value }) => value) ?? [] })
  }

  if (!rule.then[0]) return null

  const blacklistedOptionIds = rule.when.map(({ path }) => path[1])

  if (rule.when.some(({ path }) => bulkOrderGroupChildren.includes(path[1]))) {
    Object.keys(customizerProduct.questions).forEach(id => {
      if (!bulkOrderGroupChildren.includes(id)) blacklistedOptionIds.push(id)
    })
  }

  const question = customizerProduct.questions[rule.then[0]?.questionId]
  const isMulti =
    question?.isMultiAnswer ||
    [RuleThenType.SetAuthorizedAnswers, RuleThenType.SetRestrictedAnswers].includes(rule.then[0].type)

  return (
    <>
      <ProductQuestionsDropdown
        customizerProduct={customizerProduct}
        readonly={readonly}
        className={classNames({ 'mb-2': !readonly })}
        key="action-option-picker"
        exclude={blacklistedOptionIds}
        value={rule.then[0].questionId}
        onSelect={updateQuestion}
        questionsToHighlight={questionsToHighlight}
      />
      <AutoresizeSelect
        autoresize
        readonly={readonly}
        key="action-picker"
        className={classNames('action', { 'mb-2': !readonly })}
        value={rule.then[0].type}
        options={availableActions}
        onChange={changeAction}
        floatingPlaceholder={false}
      />
      {rule.then[0].type !== RuleThenType.DisableQuestion && (
        <QuestionAnswersDropdown
          customizerProduct={customizerProduct}
          className={classNames({ 'mb-2': !readonly })}
          isMulti={isMulti}
          readonly={readonly}
          key="choice-picker"
          questionId={rule.then[0].questionId}
          value={isMulti ? rule.then[0].payload : rule.then[0].answerId}
          onSelect={isMulti ? updateAnswers : updateAnswer}
          logicalOperator={rule.then[0].type === RuleThenType.RestrictAllBut ? 'and' : 'or'}
          answersToHighlight={answersToHighlight}
        />
      )}
    </>
  )
}

export default RightHand
