import { CustomizerProductSubmitAction } from '@packages/types'
import React, { useState } from 'react'

import { CheckboxGroup, Popover, usePopover, IconButton, Label, CheckboxButtonGroup } from 'common/components'
import { twoDDisplayerActionTypes } from 'customizer/2dDisplayer'
import { actionTypes as questionPanelTypes } from 'customizer/questionPanel'
import { actionTypes as quoteTypes } from 'customizer/quote'
import SettingsIcon from 'icons/core-solid/interface-essential/interface-setting-cog.svg'

const defaultLoadingStateOptions = [
  { id: 'customizer', value: 'customizer', label: 'Customizer', checked: false },
  { id: 'submitAction', value: 'submitAction', label: 'Submit action', checked: false },
]

interface CustomizerOptionsControlProps {
  iframeRef: React.RefObject<HTMLIFrameElement>
  disabled?: boolean
  hasDraft?: boolean
  selectedVersion?: 'live' | 'draft' | null
  onVersionChange: (version: 'live' | 'draft') => void
  children?: React.ReactNode
  isVersionDisabled?: boolean
}

const CustomizerOptionsControl = ({
  iframeRef,
  disabled,
  hasDraft,
  selectedVersion,
  onVersionChange,
  children = null,
  isVersionDisabled,
}: CustomizerOptionsControlProps) => {
  const popover = usePopover({ offsetConfig: 8 })
  const [loadingStateOptions, setLoadingStateOptions] = useState(defaultLoadingStateOptions)

  const handleLoadingStatesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const customizerApp = iframeRef.current?.contentWindow?.customizerApp

    if (!customizerApp) return

    const submitAction = customizerApp.store.getState().customization.customizerProduct
      .submitAction as CustomizerProductSubmitAction

    setLoadingStateOptions(
      loadingStateOptions.map(option => {
        return option.value === e.target.value ? { ...option, checked: e.target.checked } : option
      })
    )

    if (e.target.value === 'customizer') {
      customizerApp.store.dispatch({
        type: twoDDisplayerActionTypes.UPDATE_RENDER_STATE,
        payload: { value: !e.target.checked },
      })
    } else {
      customizerApp.store.dispatch(
        submitAction === CustomizerProductSubmitAction.GetAQuote
          ? { type: quoteTypes.SET_REQUEST_STATUS, payload: e.target.checked ? 'loading' : 'idle' }
          : { type: questionPanelTypes.SET_IS_SUBMITTING, payload: e.target.checked }
      )
    }
  }

  return (
    <>
      <IconButton
        {...popover.referenceProps}
        disabled={disabled}
        Icon={SettingsIcon}
        aria-label="Options"
        type="button"
      />

      <Popover {...popover.floatingProps} isOpen={popover.isOpen} className="w-[250px]">
        <div className="p-4">
          <Label className="font-medium">Version</Label>
          <div className="flex mb-4 mt-3">
            <CheckboxButtonGroup className="flex-1">
              <CheckboxButtonGroup.Option
                isChecked={selectedVersion === 'live'}
                onClick={() => onVersionChange('live')}
                disabled={disabled || isVersionDisabled}
              >
                Live
              </CheckboxButtonGroup.Option>
              <CheckboxButtonGroup.Option
                isChecked={selectedVersion === 'draft'}
                onClick={() => onVersionChange('draft')}
                disabled={disabled || !hasDraft || isVersionDisabled}
              >
                Draft
              </CheckboxButtonGroup.Option>
            </CheckboxButtonGroup>
          </div>
          <Label className="font-medium">Loading states</Label>
          <CheckboxGroup
            className="mt-3"
            disabled={disabled}
            name="loadingStates"
            options={loadingStateOptions}
            onChange={handleLoadingStatesChange}
          />
          {children}
        </div>
      </Popover>
    </>
  )
}

export default CustomizerOptionsControl
