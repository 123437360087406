import { useContext } from 'react'
import { GroupBase } from 'react-select'
import { LoadOptions } from 'react-select-async-paginate'

import { apiClient } from 'common/api'
import { ProductFetchAllResponse } from 'common/products/hooks/useProductService'
import { TenantContext } from 'common/tenant'

const PRODUCT_LIMIT = 10

const useLoadProductOptions = () => {
  const tenant = useContext(TenantContext)

  const loadOptions: LoadOptions<
    unknown,
    GroupBase<unknown>,
    {
      page: number
    }
  > = async (search, loadedOptions) => {
    const { data } = await apiClient.get<ProductFetchAllResponse>(`/brands/${tenant}/products`, {
      params: { search, lastIndex: loadedOptions.length, limit: PRODUCT_LIMIT },
    })

    const options = data.results.map(product => ({ value: product.id, label: product.name }))

    return {
      options: options,
      hasMore: data.pagination.lastIndex < data.pagination.collectionSize,
    }
  }

  return loadOptions
}

export default useLoadProductOptions
