import React from 'react'

import { BlankState } from 'common/components'
import MoneyIcon from 'icons/bold/08-Money-Payments-Finance/02-Money/accounting-coins-stack.svg'

const EmptyPricing = () => {
  return (
    <div className="flex justify-center">
      <BlankState className="mt-[20%]">
        <BlankState.Icon Icon={MoneyIcon} />
        <BlankState.Title>No online store</BlankState.Title>
        <BlankState.Details>You need to connect an online store in order to create your pricing</BlankState.Details>
      </BlankState>
    </div>
  )
}

export default EmptyPricing
