import { Subscription } from '@packages/types'
import { useQuery } from '@tanstack/react-query'
import React from 'react'

import { useProductService } from 'common/products'

interface SubscriptionCardProductLimitProps {
  subscription: Subscription
}

const SubscriptionCardProductLimit = ({ subscription }: SubscriptionCardProductLimitProps) => {
  const productService = useProductService()

  const { data, isLoading } = useQuery(
    productService.count.queryKeys,
    () => productService.count({ archived: false }),
    { enabled: !!subscription.plan.features?.productLimit }
  )

  if (!subscription.plan.features?.productLimit) {
    return <span aria-label="number of product left">Unlimited</span>
  }

  return isLoading ? (
    <span className="w-8 h-4 rounded-lg animate-pulse bg-neutral-50" />
  ) : (
    <span aria-label="number of product left" className="text-neutral-900 text-sm flex space-x-1">
      <span className="text-primary-600">{data?.count}</span>
      <span>/</span>
      <span>{subscription.plan.features?.productLimit}</span>
    </span>
  )
}

export default SubscriptionCardProductLimit
