import classNames from 'classnames'
import React, { useEffect, useState } from 'react'

import AddCircle from 'icons/bold/01-Interface Essential/43-Remove-Add/add-circle-alternate.svg'
import Remove from 'icons/bold/01-Interface Essential/43-Remove-Add/remove.svg'

import Button from '../Button'
import IconButton from '../IconButton'
import { Checkbox, CheckboxGroup, Input } from '../inputs'
import { Popover, usePopover } from '../popover'

export type Filter = { name: string; text: string; className?: string; iconClassName?: string }

export type FilterGroup = { id: string; name: string; items: Filter[] }

export type GroupOrFilter = Filter | FilterGroup

const isGroup = (groupOrFilter: GroupOrFilter): groupOrFilter is FilterGroup =>
  (groupOrFilter as FilterGroup).items !== undefined

export type CheckboxFilterProps = {
  name: string
  filters: GroupOrFilter[]
  activeFilters: string[]
  toggleFilter: (name: string) => void
  clearFilter: () => void
  searchable?: boolean
  Icon?: React.ElementType
  selectableGroups?: boolean
  activateFilters?: (names: string[]) => void
  deactivateFilters?: (names: string[]) => void
  disabled?: boolean
}

const CheckboxFilter = ({
  name,
  filters,
  activeFilters,
  activateFilters = () => {},
  deactivateFilters = () => {},
  toggleFilter,
  clearFilter,
  searchable = false,
  selectableGroups = false,
  disabled = false,
  Icon,
}: CheckboxFilterProps) => {
  const popover = usePopover({
    placement: 'bottom',
    useArrow: true,
    offsetConfig: 12,
    autoUpdateOnAnimationFrame: true,
  })

  const [search, setSearch] = useState('')
  const filterWithSearch = (filter: Filter) => filter.text.toLowerCase().includes(search.toLowerCase())

  const parsedFilters = filters.reduce(
    (
      { global, groups, flattenedFilters }: { global: Filter[]; groups: FilterGroup[]; flattenedFilters: Filter[] },
      groupOrFilter
    ) => {
      if (isGroup(groupOrFilter)) {
        const filteredGroupItems = search === '' ? groupOrFilter.items : groupOrFilter.items.filter(filterWithSearch)

        const newFlattenedFilters = [...flattenedFilters, ...groupOrFilter.items]

        if (filteredGroupItems.length === 0) return { global, groups, flattenedFilters: newFlattenedFilters }

        return {
          global,
          groups: [...groups, { ...groupOrFilter, items: filteredGroupItems }],
          flattenedFilters: newFlattenedFilters,
        }
      }

      const newFlattenedFilters = [...flattenedFilters, groupOrFilter]

      if (search === '' || filterWithSearch(groupOrFilter))
        return { global: [...global, groupOrFilter], groups, flattenedFilters: newFlattenedFilters }

      return { global, groups, flattenedFilters: newFlattenedFilters }
    },
    { global: [], groups: [], flattenedFilters: [] }
  )

  useEffect(() => {
    if (!popover.isOpen) setSearch('')
  }, [popover.isOpen])

  const activeCheckboxFilters = activeFilters
    .map(filterName => parsedFilters.flattenedFilters.find(filter => filter.name === filterName))
    .filter(Boolean) as Filter[]

  const id = `add-${name.toLowerCase().split(' ').join('-')}-filters`

  return (
    <div
      className={classNames(
        'shadow-xs border h-7 rounded-xl font-medium flex items-center overflow-hidden focus-within:border-primary-200 focus-within:ring border-neutral-100',
        {
          'border-dashed': activeFilters.length === 0,
          'border-neutral-50 border border-solid bg-neutral-50 text-neutral-200 fill-neutral-200': disabled,
        }
      )}
    >
      <Button
        variant="text"
        className="!text-xs mx-1.5 text-neutral-400 "
        icon={Icon ? <Icon className="w-3 h-3 fill-current" /> : <AddCircle className="w-3 h-3 fill-current" />}
        disabled={disabled}
        {...popover.referenceProps}
      >
        {name}
      </Button>
      {activeCheckboxFilters.length !== 0 && (
        <>
          <div className="h-full w-[1px] bg-neutral-75" />
          <div className="flex items-center bg-neutral-50 h-full pl-2 pr-0.5">
            {activeCheckboxFilters.length < 4 ? (
              activeCheckboxFilters.map(({ name, text }, i) => (
                <React.Fragment key={name}>
                  <span
                    className="text-xs text-primary-500 max-w-[160px] overflow-hidden whitespace-nowrap text-ellipsis"
                    data-testid={`Active ${name} filter`}
                    title={text}
                  >
                    {text}
                  </span>
                  {i !== activeFilters.length - 1 && <span className="text-neutral-400 mr-1">,</span>}
                </React.Fragment>
              ))
            ) : (
              <span className="flex text-xs text-primary-500" data-testid="Active filter">
                <span
                  className="max-w-[160px] overflow-hidden whitespace-nowrap text-ellipsis"
                  data-testid={`Active ${name} filter`}
                  title={activeCheckboxFilters[0].text}
                >
                  {activeCheckboxFilters[0].text}
                </span>{' '}
                and {activeFilters.length - 1} more
              </span>
            )}
            <IconButton
              small
              aria-label="Clear checkbox filter"
              variant="subtle"
              Icon={Remove}
              onClick={e => {
                e.stopPropagation()
                e.preventDefault()
                clearFilter()
              }}
              disabled={disabled}
            />
          </div>
        </>
      )}

      <Popover isOpen={popover.isOpen} {...popover.floatingProps} aria-labelledby={id}>
        <div id={id} className="px-4 pt-4 mb-1 font-medium">
          Add {name.toLowerCase()} filters
        </div>
        {searchable && (
          <div className="px-4 mt-2 mb-2">
            <Input
              placeholder="Search..."
              aria-label="Clear search"
              value={search}
              onChange={e => setSearch(e.target.value)}
              rightAddon={
                search !== '' && <IconButton variant="text" small Icon={Remove} onClick={() => setSearch('')} />
              }
            />
          </div>
        )}
        <div className="min-w-[220px] max-w-[320px] flex flex-col pb-4 max-h-[500px] overflow-y-auto">
          {parsedFilters.global.length > 0 && (
            <div className="mt-2 relative">
              <div className="px-4">
                <CheckboxGroup
                  name={name}
                  onChange={e => toggleFilter(e.target.id)}
                  options={parsedFilters.global.map(filter => ({
                    label: filter.text,
                    id: filter.name,
                    checked: activeFilters.includes(filter.name),
                    className: 'text-xs',
                  }))}
                />
              </div>
            </div>
          )}
          {parsedFilters.groups.length > 0 && (
            <div className="flex flex-col space-y-2" role="list">
              {parsedFilters.groups.map(group => {
                const allSelected = group.items.every(filter => activeFilters.includes(filter.name))
                const partiallySelected =
                  !allSelected && group.items.some(filter => activeFilters.includes(filter.name))
                return (
                  <div className="relative" role="listitem" key={`checkbox-group-${group.id}`}>
                    <label className="text-xs font-medium py-1 border-neutral-100 px-4 sticky top-0 bg-white flex space-x-2 items-center">
                      {selectableGroups && (
                        <Checkbox
                          id={group.id}
                          indeterminate={partiallySelected}
                          checked={allSelected}
                          onChange={e => {
                            e.target.checked
                              ? activateFilters(group.items.map(filter => filter.name))
                              : deactivateFilters(group.items.map(filter => filter.name))
                          }}
                        />
                      )}
                      <span>{group.name}</span>
                    </label>
                    <div className="group px-4 py-2">
                      <CheckboxGroup
                        name={name}
                        className="text-neutral-500"
                        onChange={e => toggleFilter(e.target.id)}
                        options={group.items.map(filter => ({
                          label: filter.text,
                          id: filter.name,
                          checked: activeFilters.includes(filter.name),
                          className: '!text-xs',
                        }))}
                      />
                    </div>
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </Popover>
    </div>
  )
}

export default CheckboxFilter
