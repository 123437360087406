import { StartingPoint } from '@packages/types'
import { useContext } from 'react'

import { apiClient, createQuery } from 'common/api'
import { TenantContext } from 'common/tenant'

export interface StartingPointFetchAllOptions {
  params?: Partial<{
    limit: number
    lastIndex: number
    productId: string
    fields: string[]
    filter: {
      productId: string
    }
  }>
}

export type StartingPointFetchAllResponse = {
  pagination: {
    collectionSize: number
    lastIndex: number
    resultSize: number
  }
  results: StartingPoint[]
}

const useStartingPointService = () => {
  const tenant = useContext(TenantContext)
  return {
    fetchAll: createQuery([tenant, 'startingpoints'], async (options?: StartingPointFetchAllOptions) => {
      const { data } = await apiClient.get<StartingPointFetchAllResponse | StartingPoint[]>(
        `/brands/${tenant}/startingpoints`,
        options
      )

      return data
    }),
    fetch: createQuery([tenant, 'startingpoint'], async (id: string) => {
      const { data } = await apiClient.get<StartingPoint>(`/brands/${tenant}/startingpoints/${id}`)

      return data
    }),
    create: async (body: Partial<StartingPoint>) => {
      const { data } = await apiClient.post<StartingPoint>(`/brands/${tenant}/startingpoints`, body)

      return data
    },
    update: async (id: string, body: Partial<StartingPoint>) => {
      const { data } = await apiClient.post<StartingPoint>(`/brands/${tenant}/startingpoints/${id}`, body)

      return data
    },
    delete: async (id: string) => {
      const { data } = await apiClient.delete<{ deleted: string }>(`/brands/${tenant}/startingpoints/${id}`)

      return data
    },
  }
}

export default useStartingPointService
