import {
  Answer,
  AnswerType,
  ColorView,
  ImageView,
  FontView,
  LogoView,
  OutlineView,
  MaterialView,
} from '@packages/types'
import React from 'react'

import ColorThumbnail from './ColorThumbnail'
import FontThumbnail from './FontThumbnail'
import ImageThumbnail from './ImageThumbnail'

export interface AnswerThumbnailProps {
  answer: Answer
}

const AnswerThumbnail = ({ answer }: AnswerThumbnailProps) => {
  if (answer.thumbnail) return <ImageThumbnail url={answer.thumbnail.url} />

  switch (answer.type) {
    case AnswerType.Image: {
      const imageView = (answer.views as ImageView[]).find(({ image }) => image != null)

      return <ImageThumbnail url={imageView?.image?.url} />
    }
    case AnswerType.Logo: {
      const logoView = (answer.views as LogoView[]).find(({ logo }) => logo != null)

      return <ImageThumbnail url={logoView?.logo?.url} />
    }
    case AnswerType.Color:
    case AnswerType.Outline:
    case AnswerType.Material: {
      const view = (answer.views as ColorView[] | OutlineView[] | MaterialView[])[0]

      return <ColorThumbnail color={view?.color} />
    }
    case AnswerType.Font: {
      const views = answer.views as FontView[]
      const fontView = views.find(({ font }) => font != null) ?? (answer?.views?.[0] as FontView)

      return <FontThumbnail fontView={fontView} />
    }
    default:
      return null
  }
}

export default AnswerThumbnail
