import { DenormalizedTheme, ThemeBuilderConfig } from '@packages/types'
import { at, uniq } from 'lodash'
import React, { useCallback, useMemo } from 'react'

import { Tooltip } from 'common/components'
import InfoIcon from 'icons/bold/01-Interface Essential/14-Alerts/information-circle.svg'
import classMerge from 'utils/classMerge'

import { colorKeysAsArray } from '../utils'
import SettingsContainer from './SettingsContainer'
import ThemeSetting from './ThemeSetting'

interface ThemeSettingsPanelProps {
  theme: DenormalizedTheme
  settingSection: string
  settings: ThemeBuilderConfig['settings']
}

const DescriptionTooltip = ({ description }: { description?: string }) => {
  return (
    <Tooltip content={description} disabled={!description}>
      {description && <InfoIcon className="w-4 h-4 fill-neutral-500" />}
    </Tooltip>
  )
}

const ThemeSettingsPanel = ({ theme, settingSection, settings }: ThemeSettingsPanelProps) => {
  const colorKeys = useMemo(() => colorKeysAsArray(settings), [settings])

  const curriedGetAllColors = useCallback(
    (values: Record<string, { color: string; title: string }>) => {
      return uniq(at(values, colorKeys)).filter(val => val != null)
    },
    [colorKeys, theme.settings]
  )

  const section = settings[settingSection]

  const hasSubSections = section.subSections && section.subSections.length > 0

  if (!section) return null

  if (hasSubSections) {
    return (
      <div className="border-solid border-l-[1px] border-neutral-75">
        <SettingsContainer>
          <div className="font-medium text-base px-4 mt-4 mb-6 gap-2 flex items-center">
            <span>{section?.name}</span>
            <DescriptionTooltip description={section?.description} />
          </div>

          <div className="flex flex-col">
            {section.subSections.map((subSection, index) => {
              const isLast = index === section.subSections.length - 1
              const isFirst = index === 0

              return (
                <div
                  key={index}
                  className={classMerge('flex flex-col space-y-3 pb-6', {
                    'pt-6': !isFirst,
                    'border-b border-neutral-100': !isLast,
                  })}
                >
                  <span className="font-medium ml-4">{subSection.name}</span>
                  {subSection.items.map(key => (
                    <ThemeSetting
                      key={key}
                      settingKey={key}
                      sectionKey={settingSection}
                      setting={section.settings[key]}
                      getAllColors={curriedGetAllColors}
                    />
                  ))}
                </div>
              )
            })}
          </div>
        </SettingsContainer>
      </div>
    )
  }

  return (
    <div className="border-solid border-l-[1px] border-neutral-75">
      <SettingsContainer>
        <div className="font-medium text-base px-4 mt-4 mb-6 gap-2 flex items-center">
          <span>{section.name}</span>
          <DescriptionTooltip description={section.description} />
        </div>
        <div className="flex flex-col space-y-4">
          {Object.keys(section.settings).map(key => (
            <div className="flex flex-col space-y-4" key={key}>
              <ThemeSetting
                key={key}
                settingKey={key}
                sectionKey={settingSection}
                setting={section.settings[key]}
                getAllColors={curriedGetAllColors}
              />
            </div>
          ))}
        </div>
      </SettingsContainer>
    </div>
  )
}

export default ThemeSettingsPanel
