import { useContext } from 'react'

import { apiClient, createQuery } from 'common/api'
import { TenantContext } from 'common/tenant'
import getAPIUrl from 'utils/getAPIUrl'

import { ConnectedStartingPoint } from '../types'

export type ConnectParams = { onlineStoreId: string; shopUrl: string }

const usePrestashopProxyService = () => {
  const tenant = useContext(TenantContext)

  return {
    connect: async ({ onlineStoreId, shopUrl }: ConnectParams) => {
      const customizerUrl = `${location.protocol}//${tenant}.${DOMAIN}${location.port ? `:${location.port}` : ''}`
      const payload = { onlineStoreId, shopUrl, apiUrl: getAPIUrl(), customizerUrl }

      const { data } = await apiClient.post<void>(`/brands/${tenant}/prestashop/connect`, payload)
      return data
    },
    test: createQuery(['test-prestashop'], async (onlineStoreId: string) => {
      const { data } = await apiClient.get<void>(
        `/brands/${tenant}/prestashop/proxy/${onlineStoreId}?path=${encodeURIComponent(
          `/index.php?fc=module&module=mycustomizer&controller=test&id_lang=1`
        )}`
      )
      return data
    }),
    fetchStartingPoint: createQuery(
      [tenant, 'fetch-prestashop-starting-point'],
      async (onlineStoreId: string, startingPointId: string, productId: string) => {
        const path = encodeURIComponent(
          `/index.php?fc=module&module=mycustomizer&controller=startingpoint&id_lang=1&mczrProductId=${productId}&startingPointId=${startingPointId}&onlineStoreId=${onlineStoreId}`
        )

        const { data } = await apiClient.get<{ data: ConnectedStartingPoint }>(
          `/brands/${tenant}/prestashop/proxy/${onlineStoreId}?path=${path}`
        )
        return data.data
      }
    ),
    removeStartingPoint: async (onlineStoreId: string, startingPointId: string) => {
      const path = encodeURIComponent(
        `/index.php?fc=module&module=mycustomizer&controller=startingpoint&id_lang=1&startingPointId=${startingPointId}&onlineStoreId=${onlineStoreId}`
      )
      const { data } = await apiClient.delete<void>(`/brands/${tenant}/prestashop/proxy/${onlineStoreId}?path=${path}`)
      return data
    },
  }
}

export default usePrestashopProxyService
