import { Answer, AnswerType, FontSizeView, OutlineView } from '@packages/types'
import React from 'react'

import { AnswerThumbnail } from 'builder/build/answers/components'

interface AnswerCardThumbnailProps {
  answer: Answer
}

const AnswerCardThumbnail = ({ answer }: AnswerCardThumbnailProps) => {
  if (answer.type === AnswerType.FontSize) {
    const views = answer.views as FontSizeView[] | undefined
    const view = views?.find(answerView => (answerView as FontSizeView).size != null) || views?.[0]

    return <div className="flex mr-2 items-center text-neutral-900">{view?.size}</div>
  }

  if (answer.type === AnswerType.Outline) {
    const views = answer.views as OutlineView[] | undefined
    const view = views?.[0]

    return (
      <div className="flex mr-2 items-center text-neutral-900">
        <AnswerThumbnail answer={answer} />
        {view?.width}px
      </div>
    )
  }

  return <AnswerThumbnail answer={answer} />
}

export default AnswerCardThumbnail
