import { OutlineAnswer, OutlineView } from '@packages/types'
import React from 'react'
import { SketchPickerProps } from 'react-color'

import { constants as builderConstants } from 'builder/build/common'
import { PatchSketchPicker, PatchTextInput } from 'builder/build/common/components'
import { selectors as customizerSelectors, hooks as customizerHooks } from 'builder/build/customizer'
import { ViewButtons } from 'builder/build/customizer/components'
import { useDispatch } from 'cms/hooks'
import { min } from 'utils/validators'

import { patchAnswer, patchAnswerViews } from '../actions'
import { GeneralSettingsSection, TitleSection } from './sections'

interface OutlineAnswerPanelProps {
  answer: OutlineAnswer
}

export const OutlineAnswerPanel = ({ answer }: OutlineAnswerPanelProps) => {
  const dispatch = useDispatch()
  const currentView = customizerHooks.useCustomizerSelector(customizerSelectors.currentViewSelector) ?? 0
  const { width, color } = (answer.views?.[currentView] as OutlineView | undefined) || {}

  const updateAnswer: GeneralSettingsSection['onUpdate'] = (field, value) => {
    dispatch(patchAnswer(answer, { [field]: value }))
  }

  const updateAnswerViews = (values: Partial<OutlineView>) => dispatch(patchAnswerViews(answer, { ...values }))

  const updateOutlineWidth = (value: number) => {
    dispatch(patchAnswer(answer, { views: { [currentView]: { width: value } } }))
  }

  return (
    <div className="panel__content" aria-label="Outline answer panel">
      <TitleSection answer={answer} />
      <hr className="panel__divider" />
      <div className="panel__section">
        <div className="mb-4">
          <ViewButtons currentView={currentView} label={<label className="font-medium mr-auto">Width in view</label>} />
        </div>
        <PatchTextInput
          className="outline-width__input"
          filled={false}
          value={width ?? 0}
          onChange={event => updateOutlineWidth(event.target.valueAsNumber)}
          type="number"
          min="0"
          validator={min(0)}
          rightAddon="px"
        />
      </div>
      <hr className="panel__divider" />
      <div className="panel__section">
        <PatchSketchPicker
          styles={builderConstants.colorPickerStyle as SketchPickerProps['styles']}
          disableAlpha
          color={color || ''}
          onChange={color => updateAnswerViews({ color: color.hex })}
        />
      </div>
      <hr className="panel__divider" />
      <GeneralSettingsSection answer={answer} onUpdate={updateAnswer} />
    </div>
  )
}

export default OutlineAnswerPanel
