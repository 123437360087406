import { TextPart } from '@packages/types'
import React from 'react'

import { viewsSelector } from 'builder/build/core/selectors'
import { useCustomizerSelector } from 'builder/build/customizer'
import { ViewButtons } from 'builder/build/customizer/components'
import { currentViewSelector } from 'builder/build/customizer/selectors'
import { actions as partsActions } from 'builder/build/parts'
import { toggleTextEngraving } from 'builder/build/parts/actions'
import { useDispatch, useSelector } from 'cms/hooks'
import { Switch, IconButton, usePopover, Popover, Slider, Button, NumberInput, CircleSlider } from 'common/components'
import SettingsIcon from 'icons/core-solid/interface-essential/interface-setting-cog.svg'

export interface EngravingSectionProps {
  part: TextPart
}

const EngravingSection = ({ part }: EngravingSectionProps) => {
  const dispatch = useDispatch()

  const view = useCustomizerSelector(currentViewSelector)
  const views = useSelector(viewsSelector)

  const engravingSettingsPopover = usePopover({
    placement: 'left',
    shiftConfig: { mainAxis: true, crossAxis: false, padding: 16 },
  })

  const handleTextEngravingToggle = () => dispatch(toggleTextEngraving(part.id))

  const handleLightDirectionChange = (lightDirection: { x: number; y: number }) => {
    const lightDirections =
      part.modifiers?.engraving?.lightDirections ?? Array.from(new Array(views)).map(() => ({ x: 0, y: 0 }))

    const newLightDirections = [...lightDirections]
    newLightDirections[view] = lightDirection

    dispatch(partsActions.updateEngraving(part.id, { lightDirections: newLightDirections }))
  }

  const handleUpdateEngravingDepth = (depth: number) => dispatch(partsActions.updateEngraving(part.id, { depth }))

  const handleUpdateEngravingSharpness = (sharpness: number) =>
    dispatch(partsActions.updateEngraving(part.id, { sharpness }))

  const lightDirection = part.modifiers?.engraving?.lightDirections?.[view] ?? { x: 0, y: 0 }

  return (
    <>
      <hr className="panel__divider" />
      <div className="panel__section">
        <div className="panel__row panel__row--no-margin-bottom">
          <label className="panel__field-label">Engraving</label>
          <div className="flex items-center space-x-1">
            <IconButton
              small
              Icon={SettingsIcon}
              {...engravingSettingsPopover.referenceProps}
              disabled={!part.modifiers?.engraving?.enabled}
            />
            <Switch onChange={handleTextEngravingToggle} checked={part.modifiers?.engraving?.enabled} />
          </div>
        </div>

        <Popover
          isOpen={engravingSettingsPopover.isOpen}
          {...engravingSettingsPopover.floatingProps}
          className="w-[252px] builder z-40"
        >
          <div className="flex basis-[40px] border-b border-neutral-100 items-center px-2">
            <div className="mx-auto overflow-hidden text-ellipsis whitespace-nowrap">Edit engraving</div>
          </div>
          <label className="px-4 mt-4 h-4 flex items-center">Depth</label>
          <div className="px-4">
            <Slider
              min={0}
              max={1}
              step={0.1}
              value={part.modifiers?.engraving?.depth ?? 0.5}
              onChange={handleUpdateEngravingDepth}
            />
          </div>
          <label className="px-4 mt-4 h-4 flex items-center">Sharpness</label>
          <div className="px-4">
            <Slider
              min={0}
              max={1}
              step={0.1}
              value={part.modifiers?.engraving?.sharpness ?? 0.5}
              onChange={handleUpdateEngravingSharpness}
            />
          </div>
          <label className="border-t border-neutral-100 px-4 pt-4 flex items-center">Light direction</label>
          <div className="px-4 mt-4">
            <ViewButtons currentView={view} label={<div className="mr-2">View</div>} />
          </div>
          <div className="flex justify-between p-4">
            <CircleSlider
              value={lightDirection}
              onChange={handleLightDirectionChange}
              className="bg-neutral-700"
              thumbClassName="bg-secondary-orange-100"
              thumbStyle={value => {
                const shadowX = value.x * 8
                const shadowY = value.y * 8
                return {
                  boxShadow: `${shadowX}px ${shadowY}px 24px 10px rgba(255,255,255, 1)`,
                }
              }}
            />
            <div className="flex flex-col space-y-2 w-24">
              <div className="flex space-x-2 items-center">
                <label htmlFor="x">x</label>
                <NumberInput
                  id="x"
                  small
                  value={Math.trunc(lightDirection.x * 10) / 10}
                  rightAddon="%"
                  onChange={e => {
                    handleLightDirectionChange({ x: +e.target.value, y: +lightDirection.y })
                  }}
                />
              </div>
              <div className="flex space-x-2 items-center">
                <label htmlFor="y">y</label>
                <NumberInput
                  id="y"
                  small
                  value={Math.trunc(lightDirection.y * 10) / 10}
                  rightAddon="%"
                  onChange={e => handleLightDirectionChange({ x: lightDirection.x, y: +e.target.value })}
                />
              </div>
            </div>
          </div>
          <div className="flex justify-end basis-[53px] border-t border-neutral-100 items-center px-2">
            <Button variant="primary" onClick={() => engravingSettingsPopover.close()}>
              Done
            </Button>
          </div>
        </Popover>
      </div>
    </>
  )
}

export default EngravingSection
