import { UseMutateFunction } from '@tanstack/react-query'
import { useFormik } from 'formik'
import React from 'react'
import * as yup from 'yup'

import { Translation, CountryLanguage } from 'cms/translations/types'
import { Button, InputField, Modal, ModalProps, Select } from 'common/components'

import { CreateTranslationValues } from '../hooks/useTranslationService'

const validationSchema = yup.object().shape({
  language: yup.string().required('Required'),
})

interface AddLanguageModalProps extends Omit<ModalProps, 'children'> {
  languages: CountryLanguage[]
  onAdd: UseMutateFunction<Translation, unknown, CreateTranslationValues>
  onClose: () => void
}

const AddLanguageModal = ({ onAdd, onClose, languages, ...modalProps }: AddLanguageModalProps) => {
  const formik = useFormik({
    initialValues: { language: '' },
    validationSchema: validationSchema,
    validateOnMount: true,
    onSubmit: (values, { setSubmitting }) => {
      const selectedLanguage = languages.find(({ iso639_1 }) => iso639_1 === values.language)
      onAdd({ code: values.language, name: selectedLanguage!.name }, { onSettled: () => setSubmitting(false) })
    },
  })

  const languageOptions = languages.map(lang => ({
    label: `${lang.name} / ${lang.nativeName}`,
    value: lang.iso639_1,
  }))

  return (
    <Modal onBackdropClick={onClose} size="small" {...modalProps}>
      <form onSubmit={formik.handleSubmit} noValidate>
        <Modal.CloseButton onClick={onClose} />
        <Modal.Title>Add language</Modal.Title>

        <Modal.Details>
          <InputField>
            <Select
              id="language"
              name="language"
              isSearchable
              menuPlacement="auto"
              menuPortalTarget={document.body}
              menuPosition="fixed"
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              onChange={option => formik.setFieldValue('language', option?.value)}
              value={languageOptions.find(({ value }) => value === formik.values.language)}
              options={languageOptions}
              hasError={formik.touched.language && formik.errors.language != null}
            />
          </InputField>
        </Modal.Details>
        <Modal.Actions>
          <Button
            type="button"
            variant="default"
            className="px-4"
            onClick={onClose}
            aria-label="Cancel"
            disabled={formik.isSubmitting}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="primary"
            className="px-4"
            isLoading={formik.isSubmitting}
            disabled={formik.isSubmitting || !formik.isValid}
            aria-label="Add"
          >
            Add
          </Button>
        </Modal.Actions>
      </form>
    </Modal>
  )
}

export default AddLanguageModal
