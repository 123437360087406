import * as normalize from '@packages/normalizer'
import { CustomizerProduct, Group } from '@packages/types'
import { Location } from 'history'
import { matchPath } from 'react-router-dom'
import { createSelector } from 'reselect'

import { utils as coreUtils, selectors as coreSelectors } from 'builder/build/core'
import { selectors as groupsSelectors } from 'builder/build/groups'
import { selectors as questionsSelectors } from 'builder/build/questions'
import { RootState } from 'cms/store'
import { constants } from 'common/customizerProducts'

import { getGroupParent, getFirstLevelAncestorId } from './utils'

const { Denormalizer } = normalize.customizerProduct

export const partsSelector = (state: RootState) => {
  return Denormalizer.run(state.productBuilder, state.productBuilder.customizerProduct.parts, 'parts')
}

export const productBuilderSelector = ({ productBuilder }: RootState) => productBuilder

export const customizerProductSelector = ({ productBuilder }: RootState) => productBuilder.customizerProduct

export const denormalizedCustomizerProductSelector = createSelector(
  productBuilderSelector,
  (productBuilder): CustomizerProduct => {
    return Denormalizer.run(productBuilder, productBuilder.customizerProduct, 'customizerProducts')
  }
)

export const selectBuilderEditedPart = (state: { displayer: { builderEditedPart?: string | null } }) =>
  state.displayer.builderEditedPart

export const behindTheSceneQuestionIdsSelector = createSelector(productBuilderSelector, productBuilder => {
  const { groups, questions, customizerProduct } = productBuilder

  const groupsArray = Object.values(groups)

  const groupsInTree = groupsArray.filter(group => {
    return customizerProduct.tree === group.id || !!groupsArray.find(({ children }) => children.includes(group.id))
  })

  return Object.values(questions)
    .filter(
      question =>
        groupsInTree.find(group => group.children.includes(question.id)) == null &&
        constants.questions.editableTypes.includes(question.type)
    )
    .map(question => question.id)
})

export const isGroupSelectedFromLocation = (groupId: string, location: Location) => {
  return (
    matchPath(location.pathname, {
      path: `/groups/${groupId}`,
      exact: true,
    }) != null
  )
}

export const groupParentExceptRootSelector = (state: RootState, id: string) => {
  const rootId = coreSelectors.rootIdSelector(state)
  const parent = getGroupParent(state.productBuilder, id)
  return parent?.id !== rootId ? parent : null
}

export const entitiesSelector = ({ productBuilder }: RootState, ids: string[]) => {
  return ids.map(id => coreUtils.getEntityById(productBuilder, id))
}

export const parentSelector = createSelector(
  productBuilderSelector,
  (entityId: string) => entityId,
  (productBuilder, entityId) => getGroupParent(productBuilder, entityId)
)

export const firstLevelAncestorSelector = createSelector(
  groupsSelectors.groupsSelector,
  questionsSelectors.questionsSelector,
  (state: RootState) => productBuilderSelector(state).customizerProduct.tree,
  (_state: RootState, entityId: string) => entityId,
  (groups, questions, rootId, entityId) => {
    const treeParentId = getFirstLevelAncestorId(Object.values(groups), entityId, rootId)

    return groups[treeParentId] || questions[treeParentId]
  }
)

export const ancestorsSelector = createSelector(
  productBuilderSelector,
  (_state: RootState, entityId: string) => entityId,
  (productBuilder, entityId) => {
    const ancestors = []
    let groupParent = getGroupParent(productBuilder, entityId) as Group | undefined

    while (groupParent && groupParent.id !== productBuilder.customizerProduct.tree) {
      ancestors.unshift(groupParent)
      groupParent = getGroupParent(productBuilder, groupParent.id)
    }

    return ancestors
  }
)

export const themeConfigSelector = (state: RootState, { themeName, id }: { themeName: string; id: string }) => {
  return state.productBuilder.customizerProduct.themesConfiguration?.[themeName]?.[id]
}
