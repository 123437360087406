import { Answer, AnswerType } from '@packages/types'
import React from 'react'

import { Panel } from 'builder/build/common/components'
import isNullOrEmpty from 'utils/isNullOrEmpty'

import FontAnswerPanel from './FontAnswerPanel'
import FontSizeAnswerPanel from './FontSizeAnswerPanel'
import ImageAnswerPanel from './ImageAnswerPanel'
import LogoAnswerPanel from './LogoAnswerPanel'
import MaterialAnswerPanel from './MaterialAnswerPanel'
import OptionAnswerPanel from './OptionAnswerPanel'
import OutlineAnswerPanel from './OutlineAnswerPanel'
import TextAnswerPanel from './TextAnswerPanel'

interface AnswerPanelFactoryProps {
  answer?: Answer | null
}

const AnswerPanelFactory = ({ answer }: AnswerPanelFactoryProps) => {
  if (isNullOrEmpty(answer)) return <Panel />

  switch (answer.type) {
    case AnswerType.Font:
      return <FontAnswerPanel answer={answer} />
    case AnswerType.FontSize:
      return <FontSizeAnswerPanel answer={answer} />
    case AnswerType.Image:
      return <ImageAnswerPanel answer={answer} />
    case AnswerType.Logo:
      return <LogoAnswerPanel answer={answer} />
    case AnswerType.Material:
    case AnswerType.Color:
      return <MaterialAnswerPanel answer={answer} />
    case AnswerType.Value:
      return <OptionAnswerPanel answer={answer} />
    case AnswerType.Text:
      return <TextAnswerPanel answer={answer} />
    case AnswerType.Outline:
      return <OutlineAnswerPanel answer={answer} />
    default:
      return null
  }
}

export default AnswerPanelFactory
