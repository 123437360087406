import { FormikProps } from 'formik'
import React, { useEffect, useRef } from 'react'

import { Modal, Button, IconButton, Input, InputField, Label, HelperText } from 'common/components'
import XIcon from 'icons/bold/01-Interface Essential/33-Form-Validation/close.svg'
import AddIcon from 'icons/custom/streamlinehq-interface-add-1-interface-essential-250.svg'

interface AddUsersEmailsProps {
  formik: FormikProps<{ emails: string[] }>
}

const AddUsersEmails = ({ formik }: AddUsersEmailsProps) => {
  const { values, handleChange, handleBlur, setFieldValue, errors, touched } = formik

  const inputRefs = useRef<(HTMLDivElement | null)[]>([])

  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, values.emails.length)
  }, [values.emails.length])

  const addEmailField = () => {
    const newEmails = [...values.emails, '']
    setFieldValue('emails', newEmails)
  }

  const removeEmailField = (index: number) => {
    const newEmails = [...values.emails]
    newEmails.splice(index, 1)
    setFieldValue('emails', newEmails)
    if (Array.isArray(touched.emails)) {
      touched.emails.splice(index, 1)
    }
  }

  const hasErrors = (index: number) => {
    return Array.isArray(errors.emails) && Array.isArray(touched.emails)
      ? Boolean(errors.emails?.[index] && touched.emails?.[index])
      : false
  }

  useEffect(() => {
    const firstErrorIndex = Array.isArray(formik?.errors?.emails)
      ? formik?.errors?.emails?.findIndex(error => error === 'Email is already in use by another user.')
      : -1

    if (firstErrorIndex !== -1) {
      inputRefs.current[firstErrorIndex]?.scrollIntoView()
    }
  }, [formik?.errors?.emails])

  return (
    <Modal.Details className="border-t border-neutral-100 pt-6">
      <div className="flex flex-col space-y-4">
        <Label htmlFor="email">Email</Label>
        {values.emails.map((email, i) => (
          <div key={`add-email-${i}`} ref={el => (inputRefs.current[i] = el)}>
            <div className="flex space-x-2">
              <div className="grow">
                <InputField>
                  <Input
                    placeholder="Enter user email"
                    id="email"
                    name={`emails[${i}]`}
                    value={email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={hasErrors(i)}
                  />
                </InputField>
              </div>
              {values.emails.length > 1 && (
                <IconButton
                  smallIcon
                  Icon={XIcon}
                  aria-label="delete"
                  iconClassName="w-3 h-3"
                  onClick={() => removeEmailField(i)}
                />
              )}
            </div>
            {hasErrors(i) && <HelperText hasError>{errors.emails?.[i]}</HelperText>}
          </div>
        ))}
        <div className="w-full flex justify-between">
          <Button icon={<AddIcon className="w-2.5 h-2.5" />} onClick={addEmailField}>
            Add another
          </Button>
        </div>
      </div>
    </Modal.Details>
  )
}

export default AddUsersEmails
