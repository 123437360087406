import classNames from 'classnames'
import React, { useEffect, useRef } from 'react'

export type CheckboxProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  indeterminate?: boolean
}

const Checkbox = ({ className, disabled, indeterminate = false, ...rest }: CheckboxProps) => {
  const ref = useRef<HTMLInputElement | null>(null)
  useEffect(() => {
    if (ref.current && indeterminate !== null) {
      ref.current.indeterminate = indeterminate
    }
  }, [indeterminate, rest.checked])

  return (
    <input
      ref={ref}
      disabled={disabled}
      className={classNames(
        'form-checkbox border-neutral-100 h-4 w-4 focus:outline-none focus:ring-primary-100 focus:ring-offset-0 focus:ring rounded',
        className,
        {
          'hover:border-primary-600 text-primary-600': !disabled,
          'bg-neutral-50 text-primary-100': disabled,
          'cursor-pointer shadow-xs': !disabled,
        }
      )}
      type="checkbox"
      role="checkbox"
      {...rest}
    />
  )
}

export default Checkbox
