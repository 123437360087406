import { constants } from 'common/customizerProducts'

const DEFAULT_DIMENSIONS = constants.defaultDimensions

export const productSettingsSelector = state => {
  const productPartId = state.productBuilder.customizerProduct.tree
  const product = state.productBuilder.parts[productPartId] || []
  const dimensions = {
    ...DEFAULT_DIMENSIONS,
    ...state.productBuilder.customizerProduct.dimensions,
  }

  return {
    ...product,
    ...dimensions,
    views: state.productBuilder.customizerProduct.views,
  }
}
