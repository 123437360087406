import { Group, GroupType } from '@packages/types'
import React from 'react'

import { BulkOrderPanel } from './bulkOrder'
import { FolderPanel } from './folder'

interface GroupPanelFactoryProps {
  group: Group
}

const GroupPanelFactory = ({ group }: GroupPanelFactoryProps) => {
  if (group.type === GroupType.BulkOrder) {
    return <BulkOrderPanel group={group} />
  }

  return <FolderPanel group={group} />
}

export default GroupPanelFactory
