import { generateId } from '@packages/unique-string'
import classNames from 'classnames'
import React, { useState } from 'react'

import { StockAdjustDetails } from 'cms/inventory/components'
import { Button, UpdateCount, Input, Modal, ModalProps, useToast } from 'common/components'
import { ToastType } from 'common/components/toast/types'
import { trpc } from 'common/hooks/trpc'
import { UpdateTypes } from 'common/variants'
import DownArrow from 'icons/bold/52-Arrows-Diagrams/01-Arrows/arrow-down-1.svg'

import { FormikVariants } from '../types'
import { useSharedSkus } from '../variantsForm'

interface StockAdjustModalProps {
  ids: string[]
  handleClose: () => void
  modalProps: Omit<ModalProps, 'children'>
  locationId: string
  formik: FormikVariants
  onSuccess: () => void
}

const UpdateSKUsModal = ({ ids, locationId, formik, onSuccess, handleClose, modalProps }: StockAdjustModalProps) => {
  const [isDetailsSectionExpanded, setIsDetailsSectionExpanded] = useState(false)
  const [value, setValue] = useState(0)

  const { openToast } = useToast()

  const [sku, setSku] = useState('')

  const skuSharedData = useSharedSkus(locationId, sku, formik)

  const { mutate: bulkUpdate, isLoading: isSaving } = trpc.variant.updateMany.useMutation({
    onSuccess: () => {
      onSuccess()
      openToast('Variants was successfully saved.', ToastType.success)
    },
    onError: () => {
      openToast('Could not save variants please try again.', ToastType.warning)
    },
  })

  const handleSave = () => {
    if (skuSharedData.inventoryItem && skuSharedData.variantIds.length > 0) {
      return bulkUpdate(
        {
          locationId,
          variantUpdates: ids.map(id => ({ id, inventoryItem: skuSharedData.inventoryItem })),
          inventoryItemUpdates: [],
        },
        { onSettled: handleClose }
      )
    }

    const inventoryItemId = generateId('new')
    const inventoryItem = { id: inventoryItemId, sku, stock: value }

    return bulkUpdate(
      {
        locationId,
        variantUpdates: ids.map(id => ({ id, inventoryItem: inventoryItem })),
        inventoryItemUpdates: [],
      },
      { onSettled: handleClose }
    )
  }

  return (
    <Modal onBackdropClick={handleClose} {...modalProps}>
      <Modal.CloseButton onClick={handleClose} />
      <Modal.Title>Update SKUs</Modal.Title>
      <Modal.Details className="shrink-0">
        <Input onChange={e => setSku(e.target.value)} />
      </Modal.Details>
      {skuSharedData.inventoryItem && skuSharedData.variantIds.length > 0 && (
        <div className="flex flex-col px-6 pt-2 pb-2 border-t border-neutral-100">
          <div className="flex justify-between items-center">
            <div>The variants stock will be synced with SKU {sku}</div>
            <span>
              <span className="font-medium">Total: </span>
              <span>{skuSharedData.inventoryItem.stock === null ? 'N/A' : skuSharedData.inventoryItem.stock}</span>
            </span>
          </div>
        </div>
      )}
      {skuSharedData.variantIds.length === 0 && sku !== '' && (
        <>
          <div
            className={classNames('flex flex-col px-6 py-2 border-t border-neutral-100 space-y-3', {
              'border-b': isDetailsSectionExpanded,
            })}
          >
            <div className="flex items-center">
              <div>Set new stock</div>
              <div className="ml-4 w-36">
                <Input
                  type="number"
                  aria-label="Update stock"
                  onChange={e => setValue(+e.target.value)}
                  defaultValue="0"
                />
              </div>
              <div className="ml-auto" onClick={() => setIsDetailsSectionExpanded(isExpanded => !isExpanded)}>
                <Button
                  variant="text"
                  icon={
                    <DownArrow
                      className={classNames('w-2.5 h-2.5 transition-transform', {
                        'rotate-180': isDetailsSectionExpanded,
                      })}
                    />
                  }
                  iconPosition="right"
                >
                  Details
                </Button>
              </div>
            </div>
          </div>

          {isDetailsSectionExpanded && (
            <StockAdjustDetails value={value} locationId={locationId} ids={ids} updateType={UpdateTypes.set} />
          )}
        </>
      )}

      <Modal.Actions className="justify-between items-center">
        <UpdateCount text="variant" count={ids.length} />
        <div className="flex space-x-3">
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="primary" onClick={handleSave} disabled={isSaving} isLoading={isSaving}>
            Save
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  )
}

export default UpdateSKUsModal
