import { Question, QuestionInputType } from '@packages/types'
import React, { Suspense, useState } from 'react'

import { Panel, PatchTextInput } from 'builder/build/common/components'
import { useThemeSection } from 'builder/build/core'
import { useDispatch } from 'cms/hooks'

import { patchQuestion } from '../actions'
import {
  QuestionSettingsSection,
  DescriptionSection,
  AnswerListSection,
  SwitchViewSection,
  OrderSheetSettingsSection,
  DisplayTypeSection,
  InputTextAnswerSection,
  TitleSection,
  HeaderSection,
  InputColorPickerAnswerSection,
} from './sections'
import OutputFormatSection from './sections/OutputFormatSection'

export interface QuestionPanelProps {
  question: Question
}

const QuestionPanel = ({ question }: QuestionPanelProps) => {
  const [tab, setTab] = useState<'question' | 'settings'>('question')
  const ThemeSettingsSection = useThemeSection('settings')
  const InputSection = useThemeSection('input')

  const dispatch = useDispatch()

  const updateQuestion = (field: keyof Question, value: any) => {
    dispatch(patchQuestion(question.id, { [field]: value }))
  }

  const handleChangeOutputFormat = (value: Question['outputFormat']) => {
    dispatch(patchQuestion(question.id, { outputFormat: value }))
  }

  return (
    <Panel side="right">
      <HeaderSection selectedTab={tab} setTab={setTab} question={question} />
      <div className="panel__content">
        {tab === 'question' && (
          <>
            <TitleSection question={question} />
            <hr className="panel__divider" />
            {question.inputType !== QuestionInputType.Text && (
              <>
                <AnswerListSection question={question} />
                <hr className="panel__divider" />
              </>
            )}
            {question.inputType === QuestionInputType.Text && (
              <>
                <div className="panel__section">
                  <div className="panel__row panel__row--no-margin-bottom">
                    <PatchTextInput
                      placeholder="Enter placeholder"
                      value={question.placeholder || ''}
                      onChange={e => updateQuestion('placeholder', e.target.value)}
                    />
                  </div>
                </div>
                <hr className="panel__divider" />
              </>
            )}
            {InputSection && (
              <Suspense fallback={null}>
                <InputSection
                  question={question}
                  settings={
                    question.inputType === QuestionInputType.Text ? (
                      <InputTextAnswerSection question={question} updateQuestion={updateQuestion} />
                    ) : question.inputType === QuestionInputType.ColorPicker ? (
                      <InputColorPickerAnswerSection question={question} updateQuestion={updateQuestion} />
                    ) : null
                  }
                >
                  {question.inputType === QuestionInputType.Dropdown && question.isInteractionRequired && (
                    <div className="panel__row panel__row--no-margin-bottom mt-6">
                      <PatchTextInput
                        placeholder="Enter placeholder"
                        value={question.placeholder || ''}
                        onChange={e => updateQuestion('placeholder', e.target.value)}
                      />
                    </div>
                  )}
                </InputSection>
              </Suspense>
            )}
            <hr className="panel__divider" />
            <DisplayTypeSection question={question} />
          </>
        )}
        {tab === 'settings' && (
          <>
            <DescriptionSection question={question} />
            <hr className="panel__divider" />
            {ThemeSettingsSection && question.inputType !== QuestionInputType.ColorPicker && (
              <>
                <Suspense fallback={null}>
                  <ThemeSettingsSection step={question} />
                </Suspense>
                <hr className="panel__divider" />
              </>
            )}
            <SwitchViewSection entity={question} />
            <hr className="panel__divider" />
            <QuestionSettingsSection question={question} />
            <hr className="panel__divider" />
            {question.inputType === QuestionInputType.ColorPicker && (
              <>
                <OutputFormatSection question={question} onChange={handleChangeOutputFormat} />
                <hr className="panel__divider" />
              </>
            )}
            <OrderSheetSettingsSection question={question} />
          </>
        )}
      </div>
    </Panel>
  )
}

export default QuestionPanel
