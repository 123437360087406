import { Answer, AnswerType, ImageView } from '@packages/types'
import { useState, useEffect, useCallback, useContext } from 'react'

import { ImageSizeContext } from 'builder/build/components'
import { useSelector } from 'cms/hooks'
import isNullOrEmpty from 'utils/isNullOrEmpty'

import { getDescendantAnswersSelector } from '../selectors'

const selectAnswersUrls = (answers: Answer[] = [], viewIndex?: number) =>
  answers.reduce((urls: string[], answer) => {
    if (answer.type !== AnswerType.Image) return urls

    if (answer.views && viewIndex != null) {
      const view = answer.views[viewIndex] as ImageView

      if (view && !isNullOrEmpty(view.image?.url)) return [...urls, view.image!.url]
    } else if (answer.views) {
      return [
        ...urls,
        ...(answer.views as ImageView[])
          ?.filter(view => !isNullOrEmpty(view?.image?.url))
          .map(view => view.image!.url as string),
      ]
    }

    return urls
  }, [])

const useHasImagesWithWrongDimensions = (entityId: string, view?: number) => {
  const { checkDimensions } = useContext(ImageSizeContext)

  const answers = useSelector(state => getDescendantAnswersSelector(state, entityId))
  const [hasUnmatchedAnswers, setHasUnmatchedAnswers] = useState(false)

  const urls = selectAnswersUrls(answers, view)

  const updateHasUnmatchedAnswers = useCallback(
    async (urls: string[]) => {
      const result = await urls.reduce(async (hasUnmatchedAnswersPromise, url) => {
        const hasUnmatchedAnswers = await hasUnmatchedAnswersPromise

        const result = await checkDimensions(url)
        return result === false || hasUnmatchedAnswers
      }, Promise.resolve(false))

      setHasUnmatchedAnswers(result)
    },
    [checkDimensions]
  )

  useEffect(() => {
    if (answers != null && answers.length > 0) {
      setTimeout(() => updateHasUnmatchedAnswers(urls), 30)
    }
  }, [...urls, updateHasUnmatchedAnswers])

  return hasUnmatchedAnswers
}

export default useHasImagesWithWrongDimensions
