import { DenormalizedQuote } from '@packages/types'
import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { ArrayParam, DateParam, NumberParam, StringParam, withDefault } from 'use-query-params'

import { Header, Page, SideMenu } from 'cms/layout'
import { useOnlineStoreService } from 'cms/onlineStores'
import { Paginated } from 'common/api/types'
import { Button, Tooltip } from 'common/components'
import {
  useDataTable,
  useDataTableQuery,
  useTableDateFilters,
  useTableFilters,
  useTablePagination,
  useTableSearch,
  useTableSort,
} from 'common/hooks/dataTable'
import { trpc } from 'common/hooks/trpc'
import { useProductService } from 'common/products'
import { withFlag } from 'common/users/components'
import { FeatureFlag } from 'common/users/components/withFlag'
import AddIcon from 'icons/bold/01-Interface Essential/43-Remove-Add/add.svg'

import { QuotesFilters, QuotesSortKeys } from '../../types'
import { defaultPersistenceState } from '../../utils'
import QuotesFilterList from './QuotesFilterList'
import QuotesTable from './QuotesTable'

const Quotes = () => {
  const location = useLocation()
  const history = useHistory()
  const { brandName } = useParams<{ brandName?: string }>()
  const productService = useProductService()
  const onlineStoreService = useOnlineStoreService()

  const LIMIT = 25

  const persistence = useDataTableQuery(defaultPersistenceState, {
    startDate: DateParam,
    endDate: DateParam,
    expirationStartDate: StringParam,
    expirationEndDate: StringParam,
    lastIndex: withDefault(NumberParam, 0),
    count: withDefault(NumberParam, LIMIT),
    filter: withDefault(StringParam, ''),
    sortKey: StringParam,
    sortOrder: StringParam,
    stores: withDefault(ArrayParam, []),
    status: withDefault(ArrayParam, []),
  })

  const baseUrl = brandName ? `/brands/${brandName}` : ''

  const goToFirstPage = () => persistence.setState(state => ({ ...state, lastIndex: 0 }))

  const dataTable = useDataTable(persistence, [
    useTableSearch({ onChange: goToFirstPage }),
    useTableSort<QuotesSortKeys>({ onChange: goToFirstPage }),
    useTablePagination(),
    useTableFilters<QuotesFilters>({ onChange: goToFirstPage }),
    useTableDateFilters({ onChange: goToFirstPage }),
  ])

  const { data: productsCount, isLoading: isLoadingProductsCount } = useQuery(
    [...productService.count.queryKeys, 'active'],
    () => productService.count({ archived: false }),
    {
      select: data => data.count,
    }
  )

  const { data: onlineStoresCount, isLoading: isLoadingOnlineStoresCount } = useQuery(
    [...onlineStoreService.count.queryKeys, 'active'],
    () => onlineStoreService.count({}),
    {
      select: data => data.count,
    }
  )

  const { data, isLoading, isFetching } = trpc.quote.list.useQuery(
    {
      ...persistence.state,
      cursor: persistence.state.lastIndex,
      limit: persistence.state.count,
    },
    {
      keepPreviousData: true,
      onSuccess: result => {
        dataTable.setCollectionSize(result.pagination.collectionSize)
        dataTable.setResultSize(result.pagination.resultSize)
      },
    }
  )

  const handleRowClick = (quoteId: string) =>
    history.push(`${baseUrl}/quotes/${quoteId}`, { persistence: persistence.state })

  const isBlank = !isLoading && !data?.results.length && !location.search

  const isCreateQuoteDisabled =
    isLoadingProductsCount || isLoadingOnlineStoresCount || productsCount === 0 || onlineStoresCount === 0

  const toolTipMessage =
    isLoadingProductsCount || productsCount === 0
      ? 'You have to build a product in order to create a quote.'
      : 'You have to create a store in order to create a quote.'

  return (
    <main>
      <Header />
      <SideMenu />
      <Page>
        <Page.Header>
          <h1>Quotes</h1>
          <FeatureFlag feature="quote_phase_2">
            <Tooltip disabled={!isCreateQuoteDisabled} content={toolTipMessage}>
              <Button
                aria-label="create quote"
                onClick={() => history.push(`${baseUrl}/quotes/create`)}
                variant="default"
                icon={<AddIcon className="w-3 h-3" />}
                disabled={isCreateQuoteDisabled}
              >
                Quote
              </Button>
            </Tooltip>
          </FeatureFlag>
        </Page.Header>

        {!isBlank && (
          <QuotesFilterList clearAll={persistence.reset} hasAppliedFilters={!!location.search} {...dataTable} />
        )}

        <QuotesTable
          data={data as Paginated<DenormalizedQuote> | undefined}
          isLoading={isLoading}
          isFetching={isFetching}
          onClearFilters={persistence.reset}
          hasAppliedFilters={!!location.search}
          onRowClick={handleRowClick}
          {...dataTable}
        />
      </Page>
    </main>
  )
}

export default withFlag({
  Component: Quotes,
  feature: 'quote_phase_1',
})
