import { LogoPart, Part, PrintArea, TextPart } from '@packages/types'
import { omit } from 'lodash'
import { AnyAction } from 'redux'

import { utils as coreUtils } from 'builder/build/core'
import { actionTypes as printAreaTypes } from 'builder/build/printAreas'
import { actionTypes as questionsTypes } from 'builder/build/questions'
import { constants } from 'common/customizerProducts'

import * as types from './actionTypes'

export interface PartsState {
  [key: string]: Part
}

const setMask = (state: PartsState, { masks, partId }: { masks: []; partId: string }): PartsState => {
  const part = state[partId] as LogoPart | TextPart
  const { modifiers = {} } = part

  return {
    ...state,
    [partId]: { ...part, modifiers: { ...modifiers, masks } },
  }
}

const togglePartMultiply = (state: PartsState, payload: { partId: string }): PartsState => {
  const part = state[payload.partId] as LogoPart
  const { modifiers = {} } = part

  return {
    ...state,
    [payload.partId]: {
      ...part,
      modifiers: {
        ...modifiers,
        blendMode: modifiers.blendMode === 'multiply' ? '' : 'multiply',
      },
    },
  }
}

const toggleTextNeon = (state: PartsState, payload: { partId: string }): PartsState => {
  const part = state[payload.partId] as TextPart
  const { modifiers = {} } = part

  return {
    ...state,
    [payload.partId]: {
      ...part,
      modifiers: { ...modifiers, neon: !modifiers.neon },
    },
  }
}

const defaultEngraving = {
  enabled: false,
  depth: 0.5,
  sharpness: 0.5,
}

const toggleTextEngraving = (state: PartsState, payload: { partId: string }): PartsState => {
  const part = state[payload.partId] as TextPart
  const { modifiers = {} } = part
  const engraving = modifiers.engraving
    ? { ...modifiers.engraving, enabled: !modifiers.engraving.enabled }
    : defaultEngraving

  return {
    ...state,
    [payload.partId]: {
      ...part,
      modifiers: { ...modifiers, engraving },
    },
  }
}

const updateEngraving = (
  state: PartsState,
  payload: { partId: string; enabled?: boolean; depth?: number; sharpness?: number }
): PartsState => {
  const { partId, ...rest } = payload
  const part = state[payload.partId] as TextPart
  const { modifiers = {} } = part
  const engraving = modifiers.engraving ?? defaultEngraving

  return {
    ...state,
    [payload.partId]: {
      ...part,
      modifiers: { ...modifiers, engraving: { ...engraving, ...rest } },
    },
  }
}

const toggleFilter = (state: PartsState, payload: { partId: string; filter: string; params: unknown }): PartsState => {
  const part = state[payload.partId] as LogoPart
  const { modifiers = {} } = part
  const filters = modifiers.filters || []

  return {
    ...state,
    [payload.partId]: {
      ...part,
      modifiers: {
        ...modifiers,
        filters: filters.find(filter => filter.type === payload.filter)
          ? filters.filter(filter => filter.type !== payload.filter)
          : [...filters, { type: payload.filter, params: payload.params }],
      },
    },
  }
}

const createPart = (state: PartsState, payload: Part): PartsState => {
  return { ...state, [payload.id]: payload }
}

const deletePart = (state: PartsState, payload: { partId: string }): PartsState => {
  return Object.values(state).reduce((newState, part) => {
    if (payload.partId === part.id) return newState

    const newPart = { ...part }

    if ('modifiers' in newPart && newPart.modifiers?.masks)
      newPart.modifiers = {
        ...newPart.modifiers,
        masks: newPart.modifiers.masks.filter((entityId: string) => entityId !== payload.partId),
      }

    if (newPart.highlightGroup === payload.partId) newPart.highlightGroup = newPart.id

    return { ...newState, [newPart.id]: newPart }
  }, {})
}

const removeQuestion = (state: PartsState, payload: { questionId: string; defaultAnswerId: string }): PartsState => {
  const { questionId, defaultAnswerId } = payload
  const part = coreUtils.getPartFromQuestion(questionId, Object.values(state))
  if (!part) return state

  const questionFields = constants.parts.questionFields[part.type].map(({ field }) => field)
  const field = questionFields.find(field => part[field] === questionId)

  return defaultAnswerId
    ? { ...state, [part.id]: { ...part, [field!]: defaultAnswerId } }
    : { ...state, [part.id]: omit(part, field!) as Part }
}

const createPrintArea = (state: PartsState, payload: { partId?: string; printArea: PrintArea }): PartsState => {
  if (!payload.partId) return state

  const part = state[payload.partId] as LogoPart | TextPart

  return {
    ...state,
    [payload.partId]: {
      ...part,
      printArea: payload.printArea.id,
      allowedTransforms: { move: false, resize: false, rotate: false },
    },
  }
}

const removePrintArea = (state: PartsState, payload: { partId: string }): PartsState => {
  const part = state[payload.partId] as LogoPart | TextPart

  return {
    ...state,
    [payload.partId]: omit(part, ['printArea', 'allowedTransforms']) as typeof part,
  }
}

export default (state: PartsState = {}, action: AnyAction): PartsState => {
  switch (action.type) {
    case types.CREATE_PART:
      return createPart(state, action.payload)
    case types.SET_MASK:
      return setMask(state, action.payload)
    case types.TOGGLE_MULTIPLY:
      return togglePartMultiply(state, action.payload)
    case types.DELETE_PART:
      return deletePart(state, action.payload)
    case types.TOGGLE_FILTER:
      return toggleFilter(state, action.payload)
    case types.TOGGLE_TEXT_NEON:
      return toggleTextNeon(state, action.payload)
    case types.UPDATE_ENGRAVING:
      return updateEngraving(state, action.payload)
    case types.TOGGLE_TEXT_ENGRAVING:
      return toggleTextEngraving(state, action.payload)
    case questionsTypes.DELETE_QUESTION:
      return removeQuestion(state, action.payload)
    case printAreaTypes.CREATE_PRINT_AREA:
      return createPrintArea(state, action.payload)
    case types.REMOVE_PRINT_AREA:
      return removePrintArea(state, action.payload)
    default:
      return state
  }
}
