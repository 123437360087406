import { ShopifySubscription } from '@packages/types'
import { capitalize } from 'lodash'
import React from 'react'

import { Card } from 'common/components'

import PaymentCardSkeleton from '../skeletons/PaymentCardSkeleton'

interface ShopifyPaymentCardProps {
  subscription?: ShopifySubscription
}

const ShopifyPaymentCard = ({ subscription }: ShopifyPaymentCardProps) => {
  if (!subscription) return <PaymentCardSkeleton />

  return (
    <Card className="flex flex-1 flex-col">
      <Card.Section className="space-x-6">
        <div className="flex flex-col flex-grow space-y-4">
          <span className="text-neutral-400 text-xs leading-5">eCommerce platform</span>
          <span className="text-neutral-900 text-sm">{capitalize(subscription.paymentStrategy)}</span>
        </div>
      </Card.Section>
      {subscription.shopifyDomain && (
        <>
          <Card.Separator />
          <Card.Section className="space-x-6">
            <div className="flex flex-col flex-grow space-y-4">
              <span className="text-neutral-400 text-xs leading-5">Online store</span>
              <span className="text-neutral-900 text-sm">{subscription.shopifyDomain}</span>
            </div>
          </Card.Section>
        </>
      )}
    </Card>
  )
}

export default ShopifyPaymentCard
