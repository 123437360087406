import React from 'react'

import { Button, Modal, ModalProps } from 'common/components'

interface RestoreProductModalProps extends Omit<ModalProps, 'children'> {
  productName: string
  onRestore: () => void
  onClose: () => void
}

const RestoreProductModal = ({ productName, onRestore, onClose, ...modalProps }: RestoreProductModalProps) => {
  return (
    <Modal onBackdropClick={onClose} {...modalProps}>
      <Modal.CloseButton onClick={onClose} />
      <Modal.Title>Restore product</Modal.Title>
      <Modal.Details>
        Are you sure you want to restore <em>{productName}</em>?
      </Modal.Details>
      <Modal.Actions>
        <Button variant="default" className="px-4" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="primary"
          className="px-4"
          onMouseDown={e => e.preventDefault()}
          aria-label="confirm restore"
          onClick={() => {
            onRestore()
            onClose()
          }}
        >
          Restore
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default RestoreProductModal
