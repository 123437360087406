import { DenormalizedGroup } from '@packages/types'
import classNames from 'classnames'
import React from 'react'
import { useHistory } from 'react-router'

import { useSelector, useDispatch } from 'cms/hooks'
import { Button, IconButton, Modal, Popover, useModal, usePopover } from 'common/components'
import MoreIcon from 'icons/bold/01-Interface Essential/03-Menu/navigation-menu-vertical.svg'
import AddIcon from 'icons/bold/01-Interface Essential/43-Remove-Add/add.svg'

import { deleteGroup } from '../../actions'
import { parentSelector } from '../../selectors'
import NewQuestionMenu from '../NewQuestionMenu'

export interface GroupActionsPopoverProps {
  group: DenormalizedGroup
  onNewItem: () => void
}

const GroupActionsPopover = ({ group, onNewItem }: GroupActionsPopoverProps) => {
  const history = useHistory()

  const parent = useSelector(state => parentSelector(state, group.id))
  const dispatch = useDispatch()

  const deleteGroupModal = useModal()
  const moreDropdownButton = usePopover({ placement: 'bottom-start' })
  const addDropdownButton = usePopover({
    placement: 'right-end',
    shiftConfig: { mainAxis: true, crossAxis: false, padding: 16 },
  })

  const handleDelete = () => {
    history.push(parent ? `/groups/${parent.id}` : '/')
    setTimeout(() => dispatch(deleteGroup(group.id)), 500)
    deleteGroupModal.close()
  }

  return (
    <>
      <div
        className={classNames(
          'ml-auto h-full items-center flex-shrink-0 flex-auto flex group-hover/item:scale-x-100 group-hover/item:w-auto group-hover/item group-hover/item:visible',
          {
            'w-0 scale-x-0 invisible': !moreDropdownButton.isOpen && !addDropdownButton.isOpen,
          }
        )}
      >
        <IconButton
          aria-label={`${group.name} actions`}
          variant="subtle"
          small
          Icon={MoreIcon}
          {...moreDropdownButton.referenceProps}
        />
        <Popover isOpen={moreDropdownButton.isOpen} {...moreDropdownButton.floatingProps}>
          <Popover.Action
            key="delete"
            className="text-tertiary-red-700"
            onClick={() => {
              moreDropdownButton.close()
              deleteGroupModal.open()
            }}
          >
            Delete
          </Popover.Action>
        </Popover>
        <IconButton
          variant="subtle"
          small
          iconClassName={classNames('transition-transform', {
            'rotate-45': addDropdownButton.isOpen,
          })}
          Icon={AddIcon}
          {...addDropdownButton.referenceProps}
        />
        <Popover {...addDropdownButton.floatingProps} isOpen={addDropdownButton.isOpen}>
          <NewQuestionMenu
            groupId={group.id}
            onClose={() => {
              addDropdownButton.close()
              onNewItem()
            }}
            gifPlacement="right-start"
          />
        </Popover>
      </div>
      {deleteGroupModal.isVisible && (
        <Modal onBackdropClick={deleteGroupModal.close} {...deleteGroupModal.modalProps} aria-label="Delete group">
          <Modal.CloseButton onClick={deleteGroupModal.close} />
          <Modal.Title>Delete {group.name}</Modal.Title>

          <Modal.Details>
            Are you sure you want to delete the group {group.name}? This action cannot be undone.
          </Modal.Details>

          <Modal.Actions>
            <Button type="button" variant="default" className="px-4" onClick={deleteGroupModal.close}>
              Cancel
            </Button>
            <Button type="button" variant="error" className="px-4" onClick={handleDelete}>
              Delete
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </>
  )
}

export default GroupActionsPopover
