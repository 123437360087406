import { useMutation } from '@tanstack/react-query'
import React from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'

import { ForgotPasswordFormValues } from 'cms/users/types'
import { NetworkError } from 'common/api'
import { Alert, Button, PublicFormFooter, useToast } from 'common/components'
import { ToastType } from 'common/components/toast/types'

import { useAuthService } from '../hooks'
import AuthFormContainer from './AuthFormContainer'
import AuthFormTitle from './AuthFormTitle'

interface RouteParams {
  email: string
}

export type ResetPasswordEmailSentProps = RouteComponentProps<RouteParams>

const ResetPasswordEmailSent = ({ match }: ResetPasswordEmailSentProps) => {
  const { openToast } = useToast()
  const authService = useAuthService()

  const { mutate, isError } = useMutation<void, NetworkError, ForgotPasswordFormValues>(
    values => authService.sendResetPasswordRequest(values.email),
    {
      onSuccess: () => openToast("We've sent you another email", ToastType.default),
    }
  )

  const handleResend = () => mutate({ email: match.params.email })

  return (
    <AuthFormContainer>
      <AuthFormTitle>Reset password email sent!</AuthFormTitle>
      {isError && (
        <Alert variant="error" className="mb-8">
          <Alert.Body>
            <Alert.Title>Oops… Something went wrong.</Alert.Title>
            <Alert.Details>
              <span>
                Try to refresh the page. If the problem persists, please contact our
                <a className="ml-1 text-primary-500" href="mailto:help@gokickflip.com" target="_blank">
                  customer support team
                </a>
                .
              </span>
            </Alert.Details>
          </Alert.Body>
        </Alert>
      )}

      <span className="text-neutral-600">
        <span className="mr-1">
          If you haven't received an email in 5 minutes, please check your spam or junk folder or
        </span>
        <button onClick={handleResend} type="button" className="text-primary-600 font-p1">
          resend
        </button>
        .
      </span>

      <PublicFormFooter>
        <Link to="/login" className="w-full">
          <Button variant="primary" className="w-full">
            Back to login
          </Button>
        </Link>
      </PublicFormFooter>
    </AuthFormContainer>
  )
}

export default ResetPasswordEmailSent
