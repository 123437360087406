import { isEqual, keyBy } from 'lodash'

import { InventoryItem, Variant } from 'common/variants'

import { FormikVariants, VariantFormValue, VariantFormValues, VariantUpdate } from './types'
export const combinationExists = (combination: (string | undefined)[], existingCombinations: string[][]) => {
  return !!existingCombinations.find(existingCombination =>
    isEqual([...combination].sort(), [...existingCombination].sort())
  )
}

export const getInventoryItem = (formik: FormikVariants, variant: VariantFormValue) => {
  const inventoryItemId = formik.values.variants[variant.id]?.inventoryItem

  return formik.values.inventoryItems[inventoryItemId]
}

export const getInitialInventoryItem = (formik: FormikVariants, variant: VariantFormValue) => {
  const inventoryItemId = formik.initialValues.variants[variant.id]?.inventoryItem

  return formik.initialValues.inventoryItems[inventoryItemId]
}

export const getInventoryItemFormValue = (locationId: string, inventoryItem: InventoryItem) => {
  return {
    id: inventoryItem.id,
    sku: inventoryItem.sku,
    ...inventoryItem.inventoryLevelByLocationId[locationId],
  }
}

export const getFormValues = (locationId: string, variants: Variant[]): VariantFormValues => ({
  variants: keyBy(
    variants?.map(variant => ({
      ...variant,
      inventoryItem: variant.inventoryItem.id,
    })),
    'id'
  ),
  inventoryItems: keyBy(
    variants?.map(variant => {
      return getInventoryItemFormValue(locationId, variant.inventoryItem)
    }),
    'id'
  ),
})

export const formValuesToMutationInput = (
  locationId: string,
  values: VariantFormValues,
  initialValues: VariantFormValues
) => {
  return {
    locationId,
    variantUpdates: Object.values(values.variants).reduce((variantUpdates: VariantUpdate[], variant) => {
      const update: VariantUpdate = {
        id: variant.id,
      }
      const continueSellingChanged = variant.continueSelling !== initialValues.variants[variant.id].continueSelling
      const inventoryItemChanged = !isEqual(variant.inventoryItem, initialValues.variants[variant.id].inventoryItem)

      if (!continueSellingChanged && !inventoryItemChanged) return variantUpdates

      if (continueSellingChanged) {
        update.continueSelling = variant.continueSelling
      }
      if (inventoryItemChanged) {
        update.inventoryItem = {
          stock: values.inventoryItems[variant.inventoryItem].stock,
          sku: values.inventoryItems[variant.inventoryItem].sku,
        }
      }
      return [...variantUpdates, update]
    }, []),

    inventoryItemUpdates: Object.values(values.inventoryItems)
      .filter(
        inventoryItem =>
          !inventoryItem.id.includes('new-') && !isEqual(inventoryItem, initialValues.inventoryItems[inventoryItem.id])
      )
      .map(inventoryItem => ({ id: inventoryItem.id, sku: inventoryItem.sku, stock: inventoryItem.stock })),
  }
}

export const filterUnusedInventoryItems = ({ variants, inventoryItems }: VariantFormValues) => {
  const variantsList = Object.values(variants)
  const usedInventoryItems = Object.values(inventoryItems).reduce((inventoryItems, inventoryItem) => {
    if (!inventoryItem.id.startsWith('new')) return { ...inventoryItems, [inventoryItem.id]: inventoryItem }

    return variantsList.find(variant => variant.inventoryItem === inventoryItem.id) == null
      ? inventoryItems
      : { ...inventoryItems, [inventoryItem.id]: inventoryItem }
  }, {})

  return { variants, inventoryItems: usedInventoryItems }
}

export const getDirtyVariantIds = (formik: FormikVariants) => {
  if (!formik.dirty) return []

  return Object.keys(formik.values.variants).filter(variantId => {
    const variant = formik.values.variants[variantId]
    return (
      variant.inventoryItem !== formik.initialValues.variants[variant.id].inventoryItem ||
      formik.values.inventoryItems[variant.inventoryItem].stock !==
        formik.initialValues.inventoryItems[variant.inventoryItem].stock
    )
  })
}

export const getHiddenDirtyVariantIds = (dirtyVariantIds: string[], variantIds: string[]) => {
  return dirtyVariantIds.filter(variantId => !variantIds.includes(variantId))
}
