import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import React, { useEffect, useRef } from 'react'
import { useHistory, useLocation } from 'react-router'

import { useModal } from 'common/components'
import { useUserService } from 'common/users'
import { getBearerToken, isBearerTokenExpired } from 'utils/jwtUtils'

import { useAuthService } from '../hooks'
import SessionExpiredModal from './SessionExpiredModal'

interface LoggedInRoutesProps {
  children: React.ReactNode
}

const LoggedInRoutes = ({ children }: LoggedInRoutesProps) => {
  const history = useHistory()
  const location = useLocation()
  const expiredSessionTimeout = useRef<NodeJS.Timeout | null>(null)
  const sessionExpiredModal = useModal()

  const authService = useAuthService()
  const userService = useUserService()
  const queryClient = useQueryClient()

  const redirectToLogin = () => {
    localStorage.removeItem('bearer')
    sessionStorage.setItem('redirectAfterLogin', location.pathname)
    history.push('/login')
  }

  const onClientError = (error: unknown) => {
    if ((error as { status: number } | undefined)?.status === 401 && isBearerTokenExpired()) {
      localStorage.removeItem('bearer')
      sessionExpiredModal.open()
    }
  }

  const setExpiryCallback = () => {
    const bearerToken = getBearerToken()
    const msUntilSessionExpired = bearerToken!.exp * 1000 - Date.now()

    expiredSessionTimeout.current = setTimeout(() => {
      localStorage.removeItem('bearer')
      sessionExpiredModal.open()
    }, msUntilSessionExpired)
  }

  const { data: user } = useQuery([], userService.fetchCurrent, {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    onError: () => redirectToLogin(),
  })

  const {
    mutate: login,
    isLoading: isLoginIn,
    isError: hasLoginFailed,
  } = useMutation(authService.login, {
    onSuccess: data => {
      localStorage.setItem('bearer', data.token)
      sessionExpiredModal.close()
      setExpiryCallback()
    },
    retry: false,
  })

  useEffect(() => {
    if (isBearerTokenExpired()) {
      redirectToLogin()
    } else {
      setExpiryCallback()
      queryClient.getQueryCache().config.onError = onClientError
      queryClient.getMutationCache().config.onError = onClientError
    }

    return () => {
      if (expiredSessionTimeout.current) clearTimeout(expiredSessionTimeout.current)
      queryClient.getQueryCache().config.onError = undefined
      queryClient.getMutationCache().config.onError = undefined
    }
  }, [])

  if (!user) return null

  return (
    <>
      {sessionExpiredModal.isVisible && (
        <SessionExpiredModal
          {...sessionExpiredModal.modalProps}
          onLogin={password => login({ email: user.email, password })}
          isLoginIn={isLoginIn}
          hasLoginFailed={hasLoginFailed}
        />
      )}

      {children}
    </>
  )
}

export default LoggedInRoutes
