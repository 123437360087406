import { NormalizedCustomizerProduct } from '@packages/types'
import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import * as yup from 'yup'

import {
  ProductQuestionsDropdown,
  QuestionAnswersDropdown,
  Input,
  Button,
  Popover,
  InputField,
  usePopover,
  HelperText,
} from 'common/components'
import AddIcon from 'icons/bold/01-Interface Essential/43-Remove-Add/add.svg'
import isNullOrEmpty from 'utils/isNullOrEmpty'

import { ExtraPrice, ExtraPricesType } from '../types'

export const extraPriceSchema = yup.object().shape({
  questionId: yup.string().typeError('Please select a valid question').required('Required'),
  answerId: yup.string().typeError('Please select a valid answer').required('Required'),
  price: yup
    .number()
    .required('Required')
    .typeError('Please enter a valid price')
    .min(0, 'Price must be positive')
    .noWhitespace(),
})

interface AddExtraPricePopoverProps {
  customizerProduct: NormalizedCustomizerProduct
  existingExtraPrices: ExtraPricesType
  addExtraPrice: (extraPrice: ExtraPrice) => void
  currenciesInputProps: { leftAddon?: string; rightAddon?: string }
}

const AddExtraPricePopover = ({
  customizerProduct,
  existingExtraPrices,
  addExtraPrice,
  currenciesInputProps,
}: AddExtraPricePopoverProps) => {
  const popover = usePopover({
    placement: 'bottom',
    useArrow: true,
    shiftConfig: { mainAxis: false, crossAxis: true, padding: 54 },
  })

  const formik = useFormik({
    // @ts-expect-error: type is inferred from onSubmit but onSubmit is post validation (required fields will be present).
    initialValues: { questionId: undefined, answerId: undefined, price: '' },
    onSubmit: addExtraPrice,
    validationSchema: extraPriceSchema,
    validateOnChange: true,
  })

  const alreadySelectedAnswers = existingExtraPrices
    .filter(extra => extra.questionId === formik.values.questionId)
    .map(({ answerId }) => answerId)

  const excludedQuestions = Object.values(customizerProduct.questions)
    .filter(
      question =>
        question.answers.filter(
          answerId =>
            !!existingExtraPrices.find(extra => extra.questionId === question.id && extra.answerId === answerId)
        ).length === question.answers.length
    )
    .map(question => question.id)

  useEffect(() => {
    if (!popover.isOpen) formik.resetForm()
  }, [popover.isOpen])

  return (
    <>
      <Button icon={<AddIcon className="w-2.5 h-2.5" />} type="button" {...popover.referenceProps}>
        Add extra price
      </Button>
      <Popover {...popover.floatingProps} isOpen={popover.isOpen}>
        <form>
          <div className="flex flex-col p-2">
            <InputField className="mb-3">
              <ProductQuestionsDropdown
                customizerProduct={customizerProduct}
                exclude={excludedQuestions}
                excludeEmptyQuestions
                excludeQuestionsWithNoRemainingAnswers
                menuPortalTarget={document.body}
                styles={{ menuPortal: provided => ({ ...provided, zIndex: 9999 }) }}
                value={formik.values.questionId}
                onSelect={({ value }) => {
                  formik.setFieldValue('questionId', value)
                }}
                hasError={!!formik.errors.questionId && !!formik.touched.questionId}
              />
              {formik.touched.price && formik.errors.questionId && (
                <HelperText hasError>{formik.errors.questionId}</HelperText>
              )}
            </InputField>
            <InputField className="mb-3">
              <QuestionAnswersDropdown
                customizerProduct={customizerProduct}
                exclude={alreadySelectedAnswers}
                value={formik.values.answerId}
                questionId={formik.values.questionId}
                menuPortalTarget={document.body}
                styles={{ menuPortal: provided => ({ ...provided, zIndex: 9999 }) }}
                onSelect={({ value }: { value: string }) => formik.setFieldValue('answerId', value)}
                hasError={!!formik.errors.answerId && !!formik.touched.answerId}
              />
              {formik.touched.price && formik.errors.answerId && (
                <HelperText hasError>{formik.errors.answerId}</HelperText>
              )}
            </InputField>
            <InputField className="mb-3">
              <Input
                id="price"
                name="price"
                placeholder="0"
                onChange={formik.handleChange}
                value={formik.values.price}
                {...currenciesInputProps}
                hasError={!!formik.touched.price && !!formik.errors.price}
              />
              {formik.touched.price && formik.errors.price && <HelperText hasError>{formik.errors.price}</HelperText>}
            </InputField>
            <div className="flex justify-end">
              <Button
                id="new-extraprice-cancel"
                className="mr-2"
                onClick={async () => {
                  const errors = await formik.validateForm(formik.values)
                  formik.setTouched({ questionId: true, answerId: true, price: true })
                  if (isNullOrEmpty(errors)) {
                    const questionId = formik.values.questionId
                    const answerId = formik.values.answerId

                    addExtraPrice(formik.values)
                    formik.resetForm()
                    formik.setFieldValue('questionId', questionId)
                    formik.setFieldValue(
                      'answerId',
                      customizerProduct.questions[questionId].answers.find(
                        questionAnswerId =>
                          !alreadySelectedAnswers.includes(questionAnswerId) && questionAnswerId !== answerId
                      )
                    )
                  }
                }}
                type="button"
              >
                Add another
              </Button>
              <Button
                id="new-extraprice-add"
                variant="primary"
                type="button"
                onClick={async () => {
                  const errors = await formik.validateForm(formik.values)
                  formik.setTouched({ questionId: true, answerId: true, price: true })
                  if (isNullOrEmpty(errors)) {
                    addExtraPrice(formik.values)
                    popover.close()
                  }
                }}
              >
                Add
              </Button>
            </div>
          </div>
        </form>
      </Popover>
    </>
  )
}

export default AddExtraPricePopover
