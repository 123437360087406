import classNames from 'classnames'
import React from 'react'

import { Tooltip } from 'common/components'
import Tag from 'common/components/Tag'
import EmptyIcon from 'icons/bold/13-Images-Photography/17-Timer/timer-0.svg'

const ContinueSellingTag = ({ className }: { className?: string }) => (
  <Tooltip content="Continue selling when out of stock">
    <Tag className={classNames('bg-tertiary-green-100 text-tertiary-green-700', className)}>
      <EmptyIcon className="text-tertiary-green-700 w-3 h-3 fill-current" aria-label="Continue selling" />
    </Tag>
  </Tooltip>
)

export default ContinueSellingTag
