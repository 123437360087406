import { BulkOrder } from '@packages/types'
import React, { useState } from 'react'

import { Panel } from 'builder/build/common/components'

import HeaderSection, { Tab } from '../HeaderSection'
import BulkOrderSettingsTab from './BulkOrderSettingsTab'
import BulkOrderTab from './BulkOrderTab'

export interface BulkOrderPanelProps {
  group: BulkOrder
}

const BulkOrderPanel = ({ group }: BulkOrderPanelProps) => {
  const [tab, setTab] = useState<Tab>('group')

  return (
    <Panel side="right">
      <HeaderSection group={group} selectedTab={tab} setTab={setTab} tabLabel="Bulk order" />
      <div className="panel__content">
        {tab === 'group' && <BulkOrderTab group={group} />}
        {tab === 'settings' && <BulkOrderSettingsTab bulkOrderGroup={group} />}
      </div>
    </Panel>
  )
}

export default BulkOrderPanel
