import axios from 'axios'

import { createQuery } from 'common/api'

import { Font } from '../types'
import { googleFontUrl } from '../utils'

const useFontService = () => ({
  fetchAllGoogleFonts: createQuery(['google-fonts'], async () => {
    const response = await axios.get<{ items: Font[] }>(googleFontUrl)
    return response.data.items
  }),
})

export default useFontService
