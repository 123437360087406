import { QuestionType, QuestionInputType, TextPart, TextQuestion } from '@packages/types'
import React, { Suspense, useState } from 'react'

import { Panel, PatchTextInput, MaskSection } from 'builder/build/common/components'
import { useThemeSection } from 'builder/build/core'
import { hooks as customizerHooks } from 'builder/build/customizer'
import { actions as partsActions, hooks as partsHooks } from 'builder/build/parts'
import { DisplaySettingsSection } from 'builder/build/parts/components'
import { useDispatch, useSelector } from 'cms/hooks'
import { Label, InputField, UncontrolledTextArea } from 'common/components'
import { customizerProductsUtils } from 'common/customizerProducts'
import { twoDDisplayerSelectors } from 'customizer/2dDisplayer'

import { patchQuestion } from '../actions'
import { useIsClosing } from '../hooks'
import { linkedQuestionsSelector } from '../selectors'
import {
  AnswerListSection,
  QuestionSettingsSection,
  DescriptionSection,
  SwitchViewSection,
  OrderSheetSettingsSection,
  InputTextAnswerSection,
  BlendingOptionSection,
  LinkedQuestionSection,
  PrintAreaSection,
  DisplayTypeSection,
  TitleSection,
  HeaderSection,
  AddQuestionButton,
} from './sections'
import EngravingSection from './sections/EngravingSection'

export interface TextQuestionPanelProps {
  question: TextQuestion
  part: TextPart
}

const TextQuestionPanel = ({ question, part }: TextQuestionPanelProps) => {
  const dispatch = useDispatch()
  const [tab, setTab] = useState<'question' | 'settings'>('question')
  const isClosing = useIsClosing(question.id)
  const isEditing = customizerHooks.useCustomizerSelector(twoDDisplayerSelectors.isEditingSelector)
  const isMainAndLinkedQuestion = useSelector(state => linkedQuestionsSelector(state, question.id)).length > 0

  const ThemeSettingsSection = useThemeSection('settings')
  const InputSection = useThemeSection('input')

  const updateQuestion = (field: keyof TextQuestion, value: any) => {
    dispatch(patchQuestion(question.id, { [field]: value }))
  }

  const handleMaskApply = (maskIds: string[]) => {
    dispatch(partsActions.setMask(part.id, maskIds))
  }

  const [colorAnswer] = partsHooks.useSelectPartAnswer(part?.color)
  const [fontAnswer] = partsHooks.useSelectPartAnswer(part?.font)
  const [fontSizeAnswer] = partsHooks.useSelectPartAnswer(part?.fontSize)
  const [outlineAnswer] = partsHooks.useSelectPartAnswer(part?.outline)
  const [positionAnswer] = partsHooks.useSelectPartAnswer(part?.position)
  const isMultiline = customizerProductsUtils.isMultilineTextAnswer(positionAnswer)

  return (
    <Panel side="right">
      <HeaderSection selectedTab={tab} setTab={setTab} question={question} showPrintTab />
      <div className="panel__content">
        {tab === 'question' && (
          <>
            <TitleSection question={question} />
            <hr className="panel__divider" />

            {![QuestionInputType.None, QuestionInputType.Text].includes(question.inputType) && (
              <>
                <AnswerListSection question={question} />
                <hr className="panel__divider" />
              </>
            )}
            {question.inputType === QuestionInputType.Text && (
              <>
                <div className="panel__section">
                  <InputField>
                    <Label className="font-medium">Placeholder</Label>
                    {isMultiline && (
                      <UncontrolledTextArea
                        placeholder="Enter placeholder"
                        value={question.placeholder || ''}
                        onChange={value => updateQuestion('placeholder', value)}
                        className="py-[5px]"
                      />
                    )}
                    {!isMultiline && (
                      <PatchTextInput
                        placeholder="Enter placeholder"
                        value={question.placeholder || ''}
                        onChange={e => updateQuestion('placeholder', e.target.value)}
                      />
                    )}
                  </InputField>
                </div>

                <hr className="panel__divider" />
              </>
            )}
            {InputSection && (
              <Suspense fallback={null}>
                <InputSection
                  question={question}
                  settings={
                    question.inputType === QuestionInputType.Text && (
                      <InputTextAnswerSection question={question} updateQuestion={updateQuestion} />
                    )
                  }
                />
              </Suspense>
            )}
            <hr className="panel__divider" />
            <DisplayTypeSection
              question={question}
              settings={
                <>
                  <DisplaySettingsSection part={part} />
                  <hr className="panel__divider" />
                  {!part.printArea && (
                    <>
                      <div className="panel__section">
                        <MaskSection
                          partId={part.id}
                          entityMasks={part.modifiers?.masks || []}
                          onApply={handleMaskApply}
                          isDisabled={isEditing}
                          maskMessage={`Your text will be masked if it goes \nout of the selected layers.`}
                        />
                      </div>
                      <hr className="panel__divider" />
                    </>
                  )}
                  <EngravingSection part={part} />
                  <hr className="panel__divider" />
                  <BlendingOptionSection
                    part={part}
                    isDisabled={isEditing}
                    multiplyMessage="This blends the text with the image under it."
                    allowNeon
                    neonMessage="Adds neon effect to your text. (Neon effect won't be applied in print files.)"
                  />
                </>
              }
            >
              <PrintAreaSection part={part} question={question} isClosing={isClosing} />
            </DisplayTypeSection>
            {(!!colorAnswer || !!fontAnswer || !!fontSizeAnswer || !!outlineAnswer) && (
              <>
                <hr className="panel__divider" />
                <div className="py-6">
                  <div className="font-medium mb-4 mx-4">Create</div>
                  <div className="mx-2 flex flex-col space-y-4">
                    {colorAnswer != null && (
                      <AddQuestionButton part={part} type={QuestionType.Color} menuPosition="bottom-end" />
                    )}
                    {fontAnswer != null && (
                      <AddQuestionButton part={part} type={QuestionType.Font} menuPosition="bottom-end" />
                    )}
                    {fontSizeAnswer != null && (
                      <AddQuestionButton part={part} type={QuestionType.FontSize} menuPosition="bottom-end" />
                    )}
                    {outlineAnswer != null && (
                      <AddQuestionButton part={part} type={QuestionType.Outline} menuPosition="bottom-end" />
                    )}
                  </div>
                </div>
              </>
            )}
          </>
        )}
        {tab === 'settings' && (
          <>
            <DescriptionSection question={question} />
            <hr className="panel__divider" />
            {ThemeSettingsSection && (
              <>
                <Suspense fallback={null}>
                  <ThemeSettingsSection step={question} />
                </Suspense>
                <hr className="panel__divider" />
              </>
            )}
            <LinkedQuestionSection
              disabled={isMainAndLinkedQuestion}
              question={question}
              linkedQuestionMessage="Link this question to another question so both have the same answer. The main question's answer will be displayed."
            />
            <hr className="panel__divider" />
            <SwitchViewSection entity={question} />

            <hr className="panel__divider" />
            <QuestionSettingsSection question={question} />
            <hr className="panel__divider" />
            <OrderSheetSettingsSection question={question} />
          </>
        )}
      </div>
    </Panel>
  )
}

export default TextQuestionPanel
