import { EntityType, Group, GroupType, Part, Question, QuestionInputType, QuestionType } from '@packages/types'
import { generateId, generateNextString } from '@packages/unique-string'

import { actionTypes as coreTypes, selectors as coreSelectors } from 'builder/build/core'
import { actions as partsActions, utils as partsUtils } from 'builder/build/parts'
import {
  actions as questionsActions,
  selectors as questionsSelectors,
  actionTypes as questionsTypes,
} from 'builder/build/questions'
import { AppDispatch, RootState } from 'cms/store'
import { constants } from 'common/customizerProducts'

import { MOVE_BEHIND_THE_SCENE, CREATE_GROUP, DELETE_GROUP, ADD_CHILD, MOVE_CHILD, UPDATE_GROUP } from './actionTypes'
import {
  isMoveAllowedSelector,
  rootGroupIdSelector,
  parentSelector,
  groupByIdSelector,
  groupsSelector,
  groupsAsArraySelector,
} from './selectors'

const { rootIdSelector } = coreSelectors
const { questionByIdSelector, questionsSelector } = questionsSelectors
const { getPartQuestions } = partsUtils
const { patchPart } = partsActions

const QUESTION_PART_FIELDS = constants.questions.partFields

export class AddInputTypeNoneToSceneException extends Error {
  constructor(message?: string) {
    super(message)
    this.name = 'AddInputTypeNoneToSceneException'
  }
}

export class AddLinkedQuestionToSceneException extends Error {
  constructor(message?: string) {
    super(message)
    this.name = 'AddLinkedQuestionToSceneException'
  }
}

export const moveBehindTheScene = (questionId: string) => ({
  type: MOVE_BEHIND_THE_SCENE,
  payload: questionId,
})

export const moveToQuestionPanel = (questionId: string) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    return dispatch(addChild(rootGroupIdSelector(getState()), questionId))
  }
}

export const createPartByType = (type: string, groupId: string | undefined, inputType: string) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const action = dispatch(partsActions.createPartAndMainQuestionByType(type, inputType))

    getPartQuestions(action.payload, getState().productBuilder.questions).forEach(question => {
      if (question.inputType === QuestionInputType.None || groupId == null) {
        dispatch(moveBehindTheScene(question.id))
      } else {
        dispatch(addChild(groupId, question.id))
      }
    })

    return action
  }
}

export const createRootQuestionByType = (type: string, initialData: Partial<Question>) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const rootId = rootIdSelector(getState())

    return dispatch(createQuestionByType(rootId, type, initialData))
  }
}

export const createQuestionByType = (parentId: string | undefined, type: string, initialData: Partial<Question>) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const newName = generateNextString(Object.values(questionsSelector(getState())), 'name', 'Untitled Question')

    const action = dispatch(questionsActions.createQuestionByType(type, { ...initialData, name: newName }))

    if (action.payload.inputType === QuestionInputType.None || parentId == null) {
      dispatch(moveBehindTheScene(action.payload.id))
    } else {
      dispatch(addChild(parentId, action.payload.id))
    }

    return action
  }
}

export const createGroup = (parentId?: string, index?: number, type = GroupType.Folder) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const isBulkOrder = type === GroupType.BulkOrder

    const newName = generateNextString(
      Object.values(groupsSelector(getState())),
      'name',
      isBulkOrder ? 'Untitled Bulk Order' : 'Untitled Group'
    )
    const id = generateId('GROUP')

    const action = {
      type: CREATE_GROUP,
      payload: {
        id,
        name: newName,
        entityType: EntityType.Group,
        type,
        children: [],
        quantity: isBulkOrder ? { name: 'Quantity', min: 1, max: 0 } : undefined,
        addItemLabel: isBulkOrder ? 'Add another item' : undefined,
        itemLabel: isBulkOrder ? 'Item' : undefined,
        isSummaryShownOnAddToCart: isBulkOrder ? true : undefined,
      },
    }

    dispatch(action)
    if (parentId) dispatch(addChild(parentId, id, index))

    return action
  }
}

export const deleteGroup = (groupId: string) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState()
    const group = groupByIdSelector(state, { id: groupId })
    const parent = parentSelector(state, groupId)

    if (!parent) return

    dispatch(patchGroup(parent.id, { children: [...parent.children, ...group.children] }))
    dispatch({ type: DELETE_GROUP, payload: groupId })
  }
}

export const createQuestionFromDefaultAnswer = (part: Part, questionType: QuestionType, answerId: string) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const questionId = dispatch(
      questionsActions.createQuestionByTypeWithNoDefaultAnswer(questionType, { name: part.name })
    ).payload.id

    const rootId = rootIdSelector(getState())

    dispatch({ type: questionsTypes.ADD_ANSWERS, payload: { questionId, answerIds: [answerId] } })
    dispatch({ type: ADD_CHILD, payload: { parentId: rootId, childId: questionId } })
    dispatch(patchPart(part.id, { [QUESTION_PART_FIELDS[questionType]]: questionId }))

    return questionId
  }
}

export const addChild = (parentId: string, childId: string, index?: number) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const question = questionByIdSelector(getState(), { id: childId })

    if (question?.inputType === QuestionInputType.None) throw new AddInputTypeNoneToSceneException()
    if (question?.linkedQuestionId) throw new AddLinkedQuestionToSceneException()

    return dispatch({ type: ADD_CHILD, payload: { parentId, childId, index } })
  }
}

export const moveChild = (
  source: { parentId: string; index: number },
  destination: { parentId: string; index: number }
) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    if (!destination || !isMoveAllowedSelector(getState(), { destination, source })) return
    dispatch({ type: MOVE_CHILD, payload: { source, destination } })
  }
}

export const moveNextTo = (idToMove: string, referenceId: string) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const referenceGroup = groupsAsArraySelector(getState()).find(group => group.children.includes(referenceId))!
    dispatch({
      type: ADD_CHILD,
      payload: {
        parentId: referenceGroup.id,
        childId: idToMove,
        index: referenceGroup.children.indexOf(referenceId) + 1,
      },
    })
  }
}

export const patchGroup = (groupId: string, patch: Partial<Group>) => {
  return { type: coreTypes.PATCH, payload: { groups: [{ id: groupId, ...patch }] } }
}

export const updateGroup = (id: string, update: Partial<Group>) => {
  return { type: UPDATE_GROUP, payload: { id, update } }
}
