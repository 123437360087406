import { AnyAction } from 'redux'

import { reducer as answersReducer } from 'builder/build/answers'
import { actionTypes as coreTypes } from 'builder/build/core'
import createCustomizerProductEntityReducer from 'builder/build/core/createCustomizerProductEntityReducer'
import { reducer as customizerProduct } from 'builder/build/customizerProducts'
import { reducer as groupsReducer } from 'builder/build/groups'
import { reducer as navigation } from 'builder/build/navigation'
import { reducer as partsReducer } from 'builder/build/parts'
import { reducer as preview } from 'builder/build/preview'
import { reducer as printAreasReducer } from 'builder/build/printAreas'
import { reducer as questionsReducer } from 'builder/build/questions'
import { reducer as rulesReducer } from 'builder/build/rules'

const groups = createCustomizerProductEntityReducer('groups')(groupsReducer)
const parts = createCustomizerProductEntityReducer('parts')(partsReducer)
const questions = createCustomizerProductEntityReducer('questions')(questionsReducer)
const answers = createCustomizerProductEntityReducer('answers')(answersReducer)
const rules = createCustomizerProductEntityReducer('rules')(rulesReducer)
const printAreas = createCustomizerProductEntityReducer('printAreas')(printAreasReducer)

export interface PristineState {
  customizerProducts: ReturnType<typeof customizerProduct> | Record<string, unknown>
  groups: ReturnType<typeof groups>
  parts: ReturnType<typeof parts>
  questions: ReturnType<typeof questions>
  answers: ReturnType<typeof answers>
  rules: ReturnType<typeof rules>
  printAreas: ReturnType<typeof printAreas>
}

const defaultPristineState: PristineState = {
  customizerProducts: {},
  answers: {},
  groups: {},
  parts: {},
  questions: {},
  rules: {},
  printAreas: {},
}

const pristine = (state = defaultPristineState, action: AnyAction): PristineState => {
  switch (action.type) {
    case coreTypes.UPDATE_PRISTINE:
      return action.payload
    case coreTypes.START_EDITING:
      return action.payload.customizerProduct ? action.payload.customizerProduct : state
    case coreTypes.RESET_PRODUCTBUILDER:
      return defaultPristineState
    default:
      return state
  }
}

const productName = (state = '', action: AnyAction): string => {
  switch (action.type) {
    case coreTypes.START_EDITING:
    case coreTypes.UPDATE_PRODUCT_NAME:
      return action.payload.productName ? action.payload.productName : state
    case coreTypes.RESET_PRODUCTBUILDER:
      return ''
    default:
      return state
  }
}

export default {
  customizerProduct,
  answers,
  groups,
  parts,
  questions,
  rules,
  printAreas,
  pristine,
  productName,
  navigation,
  preview,
}
