import { NormalizedCustomizerProduct, Question } from '@packages/types'
import React from 'react'

import { SelectProps } from 'common/components'

import AutoresizeSelect from './AutoresizeSelect'

type Option = { value: string; label: string; data: Question }

export interface ProductQuestionsDropdownProps extends Omit<SelectProps<any, boolean>, 'value'> {
  customizerProduct: NormalizedCustomizerProduct
  value?: string
  onSelect: ({ value }: { value: string }) => void
  exclude?: string[]
  excludedParts?: string[]
  alreadySelectedAnswers?: string[]
  readonly?: boolean
  isClearable?: boolean
  excludeEmptyQuestions?: boolean
  excludeQuestionsWithNoRemainingAnswers?: boolean
  questionsToHighlight?: string[]
}

const hasRemainingAnswers = (question: Question, alreadySelectedAnswers: string[]) => {
  return question.answers?.find(answer => !alreadySelectedAnswers.includes(answer))
}

export const customizerProductQuestions = (
  customizerProduct: NormalizedCustomizerProduct,
  exclude: string[],
  alreadySelectedAnswers: string[],
  excludeEmptyQuestions?: boolean,
  excludeQuestionsWithNoRemainingAnswers?: boolean,
  questionsToHighlight?: string[]
) => {
  const { questions } = customizerProduct

  return Object.values(questions).reduce<Option[]>((options, question) => {
    if (
      exclude.includes(question.id) ||
      (excludeEmptyQuestions && question.answers.length === 0) ||
      (excludeQuestionsWithNoRemainingAnswers && !hasRemainingAnswers(question, alreadySelectedAnswers))
    ) {
      return options
    }

    return [
      ...options,
      {
        value: question.id,
        label: question.name,
        data: question,
        readonlyClassName: questionsToHighlight?.includes(question.id) ? 'text-primary-500' : '',
      },
    ]
  }, [])
}

const ProductQuestionsDropdown = ({
  customizerProduct,
  value,
  onSelect,
  exclude = [],
  alreadySelectedAnswers = [],
  readonly,
  isClearable,
  excludeEmptyQuestions,
  excludeQuestionsWithNoRemainingAnswers,
  questionsToHighlight = [],
  ...rest
}: ProductQuestionsDropdownProps) => {
  return (
    <AutoresizeSelect
      readonly={readonly}
      emptyReadonlyPlaceholder={<span>empty</span>}
      options={customizerProductQuestions(
        customizerProduct,
        exclude,
        alreadySelectedAnswers,
        excludeEmptyQuestions,
        excludeQuestionsWithNoRemainingAnswers,
        questionsToHighlight
      )}
      isClearable={isClearable}
      onChange={onSelect}
      value={value}
      placeholder="Question"
      isSearchable
      {...rest}
    />
  )
}

export default ProductQuestionsDropdown
