import { AnswerType, EntityType, PartType, QuestionInputType, QuestionType } from '@packages/types'
import { generateId, generateNextString } from '@packages/unique-string'
import { cloneDeep } from 'lodash'

import { actions as answersActions, selectors as answersSelectors } from 'builder/build/answers'
import { actionTypes as coreTypes, utils as coreUtils } from 'builder/build/core'
import { utils as printAreasUtils, selectors as printAreasSelectors } from 'builder/build/printAreas'
import { actions as questionsActions, selectors as questionsSelectors } from 'builder/build/questions'
import { constants } from 'common/customizerProducts'

import * as types from './actionTypes'
import { partFromQuestionIdSelector, partsSelector, partByIdSelector } from './selectors'

const MAIN_QUESTIONS = constants.parts.mainQuestionFields
const QUESTION_FIELDS = constants.parts.questionFields
const QUESTION_PART_FIELDS = constants.questions.partFields
const PART_NEEDING_ASSIGMENT = constants.questions.partNeedingAssignmentByType

const { questionsSelector, questionsAsArraySelector, questionByIdSelector } = questionsSelectors
const { setDefaultAnswer, copyAnswers, removeAnswers, patchQuestion, deleteQuestion, duplicateQuestion } =
  questionsActions
const {
  createAnswer,
  createTextPositionAnswer,
  createLogoPositionAnswer,
  createPrintAreaAnswer,
  centerLogoPrintAreaAnswer,
  createPrintAreaTextAnswer,
  centerTextPrintAreaAnswer,
  deleteAnswer,
  changeAnswersType,
  duplicateAnswer,
} = answersActions
const { answerByIdSelector } = answersSelectors

export const updateQuestionDisplayType = (question, newDisplayType) => {
  return (dispatch, getState) => {
    const questions = questionsAsArraySelector(getState())

    const answerIdsToCopy = question.answers.filter(
      answerId => questions.find(({ id, answers }) => answers.includes(answerId) && question.id !== id) != null
    )

    if (answerIdsToCopy.length > 0) {
      dispatch(copyAnswers(question.id, answerIdsToCopy))
      dispatch(removeAnswers(question.id, answerIdsToCopy))
    }

    const updatedQuestion = questionByIdSelector(getState(), { id: question.id })

    if (question.inputType !== QuestionInputType.File) {
      dispatch(changeAnswersType(updatedQuestion.answers, newDisplayType))
    }

    if (![QuestionType.Image, QuestionType.Logo, QuestionType.Text, QuestionType.Value].includes(question.type)) {
      const part = partFromQuestionIdSelector(getState(), question.id)

      if (part) {
        const fieldData = constants.parts.questionFields[part.type].find(({ type }) => type === question.type)
        let defaultAnswerId = null

        if (
          [
            QuestionType.Font,
            QuestionType.FontSize,
            QuestionType.Color,
            QuestionType.Outline,
            QuestionType.Material,
          ].includes(question.type)
        ) {
          defaultAnswerId = dispatch(answersActions.createAnswer(question.type)).payload.id
        }

        dispatch(patchPart(part.id, { [fieldData.field]: defaultAnswerId }))
      }
    }

    if ([QuestionType.Image, QuestionType.Logo, QuestionType.Text].includes(question.type)) {
      const part = partFromQuestionIdSelector(getState(), question.id)
      if (part) dispatch(deletePart(part.id))
    }

    if ([QuestionType.Image, QuestionType.Logo, QuestionType.Text].includes(newDisplayType)) {
      dispatch(createPartByType(newDisplayType, question.id))
    }

    const patch = { type: newDisplayType }

    if (newDisplayType === QuestionType.Text) {
      patch.placeholder = ''
      patch.inputCharacters = ''
    }

    dispatch(patchQuestion(question.id, patch))
  }
}

export const deletePart = partId => ({ type: types.DELETE_PART, payload: { partId } })

export const deletePartAndMainQuestion = partId => {
  return (dispatch, getState) => {
    const state = getState()
    const part = partByIdSelector(state, { id: partId })
    const mainQuestionField = QUESTION_PART_FIELDS[MAIN_QUESTIONS[part.type]]
    const mainQuestionId = part[mainQuestionField]

    if (mainQuestionId) dispatch(deleteQuestion(mainQuestionId))
    dispatch(deletePart(partId))

    dispatch({ type: types.DELETE_PART, payload: { partId } })
  }
}

export const patchPart = (partId, patch) => {
  return { type: coreTypes.PATCH, payload: { parts: [{ id: partId, ...patch }] } }
}

export const setMask = (partId, masks) => {
  return { type: types.SET_MASK, payload: { partId, masks } }
}

export const toggleMultiply = partId => {
  return { type: types.TOGGLE_MULTIPLY, payload: { partId } }
}

export const toggleTextNeon = partId => {
  return { type: types.TOGGLE_TEXT_NEON, payload: { partId } }
}

export const toggleTextEngraving = partId => {
  return { type: types.TOGGLE_TEXT_ENGRAVING, payload: { partId } }
}

export const toggleFilter = (partId, filter, params = {}) => {
  return { type: types.TOGGLE_FILTER, payload: { partId, filter, params } }
}

export const updateEngraving = (partId, update) => {
  return { type: types.UPDATE_ENGRAVING, payload: { partId, ...update } }
}

export const createPartAndMainQuestionByType = (type, inputType) => {
  return (dispatch, getState) => {
    const createName = typeName => {
      return generateNextString(Object.values(partsSelector(getState())), 'name', `Untitled ${typeName.toLowerCase()}`)
    }

    const name = createName(type)

    const {
      payload: { id: mainQuestionId },
    } = dispatch(questionsActions.createQuestionByType(type, { name, inputType }))

    return dispatch(createPartByType(type, mainQuestionId))
  }
}

export const createPartByType = (type, mainQuestionId) => {
  return dispatch => {
    switch (type) {
      case PartType.Image:
        return dispatch(createImage(mainQuestionId))
      case PartType.Logo:
        return dispatch(createLogo(mainQuestionId))
      case PartType.Text:
        return dispatch(createText(mainQuestionId))
    }
  }
}

export const createPart = part => ({ type: types.CREATE_PART, payload: part })

export const createImage = imageQuestionId => {
  return dispatch => {
    const id = generateId('PART')

    const {
      payload: { id: materialAnswerId },
    } = dispatch(createAnswer(AnswerType.Material, { color: '#ffffff' }))

    return dispatch(
      createPart({
        id,
        entityType: EntityType.Part,
        type: PartType.Image,
        image: imageQuestionId,
        material: materialAnswerId,
        highlightGroup: id,
        focusable: true,
      })
    )
  }
}

export const createText = textQuestionId => {
  return dispatch => {
    const id = generateId('PART')

    const {
      payload: { id: positionAnswerId },
    } = dispatch(createTextPositionAnswer())

    const {
      payload: { id: colorAnswerId },
    } = dispatch(createAnswer(AnswerType.Color))

    const {
      payload: { id: outlineAnswerId },
    } = dispatch(createAnswer(AnswerType.Outline))

    const {
      payload: { id: fontAnswerId },
    } = dispatch(createAnswer(AnswerType.Font))

    const {
      payload: { id: fontSizeAnswerId },
    } = dispatch(createAnswer(AnswerType.FontSize))

    return dispatch(
      createPart({
        id,
        entityType: EntityType.Part,
        type: PartType.Text,
        text: textQuestionId,
        position: positionAnswerId,
        font: fontAnswerId,
        fontSize: fontSizeAnswerId,
        color: colorAnswerId,
        outline: outlineAnswerId,
        highlightGroup: id,
        focusable: true,
      })
    )
  }
}

export const createLogo = logoQuestionId => {
  return dispatch => {
    const {
      payload: { id: positionAnswerId },
    } = dispatch(createLogoPositionAnswer())

    const id = generateId('PART')

    return dispatch(
      createPart({
        id,
        entityType: EntityType.Part,
        type: PartType.Logo,
        logo: logoQuestionId,
        position: positionAnswerId,
        highlightGroup: id,
        focusable: true,
      })
    )
  }
}

export const removePrintAreaFromParts = (printAreaId, parts) => {
  return (dispatch, getState) => {
    const state = getState()
    const questions = questionsSelector(state)

    parts.forEach(part => {
      if (printAreaId !== part.printArea) return

      const isQuestionPosition = Object.values(questions).includes(part.position)
      const printAreaAnswerId = part.position
      let positionAnswerId

      const originalAnswer = answerByIdSelector(state, { id: printAreaAnswerId })
      const printArea = printAreasSelectors.printAreaSelector(state, printAreaId)

      switch (part.type) {
        case PartType.Logo:
          positionAnswerId = dispatch(
            createLogoPositionAnswer(printAreasUtils.convertToLogoPosition(printArea, originalAnswer))
          ).payload.id
          break
        case PartType.Text:
          positionAnswerId = dispatch(
            createTextPositionAnswer(printAreasUtils.convertToTextPosition(printArea, originalAnswer))
          ).payload.id
          break
      }

      if (isQuestionPosition) {
        dispatch(setDefaultAnswer(part.position, positionAnswerId))
      } else {
        dispatch(patchPart(part.id, { position: positionAnswerId }))
      }

      dispatch({
        type: types.REMOVE_PRINT_AREA,
        payload: {
          partId: part.id,
          printAreaId,
        },
      })

      dispatch(deleteAnswer(printAreaAnswerId))
    })
  }
}

export const centerPartPrintAreaPosition = (part, printArea) => {
  return (dispatch, getState) => {
    const positionQuestion = questionsSelector(getState())[part.position]

    if (part.type === PartType.Logo)
      return dispatch(
        centerLogoPrintAreaAnswer(positionQuestion ? positionQuestion.selectedAnswer : part.position, printArea)
      )

    if (part.type === PartType.Text)
      return dispatch(
        centerTextPrintAreaAnswer(positionQuestion ? positionQuestion.selectedAnswer : part.position, printArea)
      )
  }
}

export const convertPartPositionToPrintAreaPosition = (part, printArea) => {
  return (dispatch, getState) => {
    const isQuestionPosition = Object.values(questionsSelector(getState())).includes(part.position)

    let printAreaAnswerId

    const originalAnswer = answersSelectors.answerByIdSelector(getState(), { id: part.position })

    if (part.type === PartType.Logo)
      printAreaAnswerId = dispatch(createPrintAreaAnswer(printArea, originalAnswer)).payload.id

    if (part.type === PartType.Text)
      printAreaAnswerId = dispatch(createPrintAreaTextAnswer(printArea, originalAnswer)).payload.id

    if (isQuestionPosition) return dispatch(setDefaultAnswer(part.position, printAreaAnswerId))

    dispatch(patchPart(part.id, { position: printAreaAnswerId }))
    return dispatch(deleteAnswer(part.position))
  }
}

export const removeQuestionFromPart = (part, question) => {
  return dispatch => {
    const data = QUESTION_FIELDS[PART_NEEDING_ASSIGMENT[question.type]].find(({ type }) => type === question.type)

    const defaultAnswerId = dispatch(
      createAnswer(question.type, question.type === QuestionType.Material ? { color: '#ffffff' } : {})
    ).payload.id

    return dispatch(patchPart(part.id, { [data.field]: defaultAnswerId }))
  }
}

export const assignQuestionToADifferentPart = (part, question) => {
  return (dispatch, getState) => {
    const data = QUESTION_FIELDS[PART_NEEDING_ASSIGMENT[question.type]].find(({ type }) => type === question.type)
    const state = getState()
    const previousPartWithQuestion = partFromQuestionIdSelector(state, question.id)

    if (previousPartWithQuestion) {
      const defaultAnswerId = dispatch(createAnswer(question.type)).payload.id
      dispatch(patchPart(previousPartWithQuestion.id, { [data.field]: defaultAnswerId }))
    }

    dispatch(patchPart(part.id, { [data.field]: question.id }))
  }
}

export const duplicatePart = (id, shouldCopyAnswers) => {
  return (dispatch, getState) => {
    const state = getState()
    const duplicatedPartId = generateId('PART')
    const duplicatedPart = {
      ...cloneDeep(partByIdSelector(state, { id })),
      id: duplicatedPartId,
      highlightGroup: duplicatedPartId,
    }

    QUESTION_FIELDS[duplicatedPart.type].forEach(({ field }) => {
      const entity = coreUtils.getEntityById(state.productBuilder, duplicatedPart[field])

      duplicatedPart[field] =
        entity.entityType === EntityType.Answer
          ? dispatch(duplicateAnswer(entity.id))
          : dispatch(duplicateQuestion(entity.id, shouldCopyAnswers))
    })

    dispatch(createPart(duplicatedPart))

    return duplicatedPart[QUESTION_PART_FIELDS[MAIN_QUESTIONS[duplicatedPart.type]]]
  }
}

export const updatePartPosition = (partId, position) => (dispatch, getState) => {
  const part = partByIdSelector(getState(), { id: partId })
  dispatch(answersActions.patchAnswer({ id: part.position }, { position }))
}
