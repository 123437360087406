import { OnlineStore, OnlineStoreStatus, StartingPoint, DenormalizedProduct } from '@packages/types'
import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { ItemContent, Virtuoso } from 'react-virtuoso'

import { Loader } from 'builder/common/components'
import GlobalRouterContext, { MatchParams } from 'builder/common/GlobalRouterContext'
import { Page } from 'builder/components'
import {
  TopBar,
  TopBarProductName,
  TopBarProductActions,
  TopBarBackButton,
  TopBarTabs,
  TopBarSection,
} from 'builder/topBar/components'
import { useOnlineStoreService } from 'cms/onlineStores'
import { Alert, Button } from 'common/components'
import { useProductService } from 'common/products'
import AddIcon from 'icons/bold/01-Interface Essential/43-Remove-Add/add.svg'

import useStartingPointService, { StartingPointFetchAllResponse } from '../hooks/useStartingPointService'
import { getStartingPointWarningMessage } from '../utils'
import EmptyConnect from './EmptyConnect'
import StoreCard from './StoreCard'

interface VirtuosoContext {
  onlineStores: OnlineStore[]
  product: DenormalizedProduct
}

const LIMIT = 20

const itemContent: ItemContent<StartingPoint, VirtuosoContext> = (index, startingPoint, { product, onlineStores }) => {
  const getWarningMessage = (startingPoint: StartingPoint) =>
    getStartingPointWarningMessage(startingPoint, product!.live)

  return (
    <StoreCard
      product={product}
      key={`store-card-${index}`}
      warningMessage={getWarningMessage(startingPoint)}
      startingPoint={startingPoint}
      onlineStores={onlineStores}
    />
  )
}

const Connect = ({ history, location, match }: RouteComponentProps<MatchParams>) => {
  const productService = useProductService()
  const onlineStoreService = useOnlineStoreService()
  const startingPointService = useStartingPointService()

  const { data: product, isLoading: productIsLoading } = useQuery(
    [...productService.fetch.queryKeys, match.params.productId, { fields: ['live'] }],
    async () => {
      const result = await productService.fetch(match.params.productId, { params: { fields: ['live'] } })
      return result as DenormalizedProduct
    }
  )

  const { data: onlineStores, isLoading: onlineStoresIsLoading } = useQuery(
    [...onlineStoreService.fetchAll.queryKeys, 'installed'],
    onlineStoreService.fetchAll,
    {
      initialData: [],
      select: data => data.filter(({ status }) => status === OnlineStoreStatus.Installed),
    }
  )

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading: startingPointsIsLoading,
  } = useInfiniteQuery(
    [...startingPointService.fetchAll.queryKeys, match.params.productId],
    ({ pageParam = 0 }) =>
      startingPointService.fetchAll({
        params: {
          productId: match.params.productId,
          limit: LIMIT,
          lastIndex: pageParam,
        },
      }) as Promise<StartingPointFetchAllResponse>,
    {
      getPreviousPageParam: firstPage => {
        const { lastIndex } = firstPage.pagination

        if (lastIndex - LIMIT === 0) return undefined

        return Math.max(lastIndex - LIMIT, 0)
      },
      getNextPageParam: lastPage => {
        const { lastIndex, collectionSize } = lastPage.pagination

        if (lastIndex >= collectionSize) return undefined

        return lastPage.pagination.lastIndex
      },
    }
  )

  const allStartingPoints =
    data?.pages.reduce((prev, current) => [...prev, ...current.results], [] as StartingPoint[]) || []

  const startingPoints = allStartingPoints.filter(({ isDefault }) => !isDefault)
  const defaultStartingPoint = allStartingPoints.find(({ isDefault }) => isDefault)

  const isLoading = productIsLoading || onlineStoresIsLoading || startingPointsIsLoading
  const hasCustomOnlineStore = !!onlineStores.find(onlineStore => onlineStore.eCommerce === 'custom-store')

  const handleAddStartingPoint = () =>
    history.push(`${match.url.split('/').slice(0, -1).join('/')}/startingpoints/create`)

  const handleLoadMore = () => {
    if (hasNextPage && !isFetchingNextPage) fetchNextPage()
  }

  const isEmpty = !product || onlineStores.length === 0 || allStartingPoints.length === 0
  const hasNoOnlineStores = onlineStores.length === 0

  return (
    <GlobalRouterContext.Provider value={{ history, location, match }}>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="flex flex-col h-screen">
          <TopBar>
            <TopBarSection className="pl-5">
              <TopBarBackButton />
              <TopBarProductName />
              <TopBarProductActions />
            </TopBarSection>
            <TopBarTabs />
          </TopBar>
          <Page className="flex-grow">
            {isEmpty && <EmptyConnect hasNoOnlineStores={hasNoOnlineStores} />}
            {!isEmpty && (
              <Virtuoso<StartingPoint, VirtuosoContext>
                className="flex justify-center"
                useWindowScroll
                data={startingPoints}
                endReached={handleLoadMore}
                context={{ product, onlineStores }}
                totalCount={startingPoints.length}
                itemContent={itemContent}
                components={{
                  Header: () => (
                    <>
                      <h1 className="mb-4">Connect</h1>
                      <hr className="h-[1px] w-full border-neutral-100 mb-4" />
                      <div className="mt-8 flex flex-col">
                        {hasCustomOnlineStore && (
                          <Alert variant="info" className="mb-4">
                            <Alert.Body>
                              <Alert.Details className="flex justify-between">
                                You have a custom online store and need to embed your starting points on your pages.
                                <Alert.Link
                                  onClick={() =>
                                    window.open(
                                      'https://help.mycustomizer.com/en/articles/4586872-custom-integration',
                                      '_blank'
                                    )
                                  }
                                >
                                  Learn more
                                </Alert.Link>
                              </Alert.Details>
                            </Alert.Body>
                          </Alert>
                        )}

                        {defaultStartingPoint && (
                          <StoreCard
                            product={product}
                            warningMessage={getStartingPointWarningMessage(defaultStartingPoint, product!.live)}
                            startingPoint={defaultStartingPoint}
                            onlineStores={onlineStores}
                          />
                        )}

                        <hr className="h-[1px] w-full border-neutral-100 mb-8 mt-8" />

                        <div className="flex justify-between pr-1 mb-8">
                          <div>
                            <h2 className="font-medium mb-1">Product starting point</h2>
                            <p className="text-xs">
                              Create starting points to let your clients begin their customization with a design they
                              like.
                            </p>
                          </div>
                          <Button
                            onClick={handleAddStartingPoint}
                            icon={<AddIcon className="w-[10px] h-[10px] fill-current text-current mr-1" />}
                          >
                            Design starting point
                          </Button>
                        </div>
                      </div>
                    </>
                  ),
                  Footer: () => <div className="h-12" />,
                }}
              />
            )}
          </Page>
        </div>
      )}
    </GlobalRouterContext.Provider>
  )
}

export default Connect
