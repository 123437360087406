import {
  PartType,
  PrintArea,
  AllowedTransform,
  Question,
  TextPart,
  LogoPart,
  PrintAreaLogoPositionAnswer,
  PrintAreaTextPositionAnswer,
} from '@packages/types'
import classNames from 'classnames'
import React, { useState } from 'react'
import { useHistory, useLocation, useRouteMatch } from 'react-router'

import { actions as answersActions } from 'builder/build/answers'
import { answersSelector } from 'builder/build/answers/selectors'
import { MultiIconsButton, Card, VerticalFields, HorizontalField, TypeIcons } from 'builder/build/common/components'
import Separator from 'builder/build/common/components/Separator'
import { useCustomizerDispatch } from 'builder/build/customizer'
import { bulkOrderGroupSelector } from 'builder/build/groups/selectors'
import { actions as partsActions } from 'builder/build/parts'
import { selectors as printAreasSelectors, actions as printAreasActions } from 'builder/build/printAreas'
import { PrintAreaPanel } from 'builder/build/printAreas/components'
import { useDispatch, useSelector } from 'cms/hooks'
import { Tooltip, IconButton, Switch, usePopover, Popover, Alert, Input } from 'common/components'
import { actions as customizationActions } from 'customizer/customization'
import PrintAreaIcon from 'icons/bold/01-Interface Essential/30-Print/print-picture.svg'
import XIcon from 'icons/bold/01-Interface Essential/33-Form-Validation/close.svg'
import ResizeIcon from 'icons/bold/01-Interface Essential/53-Resize/expand-diagonal-3.svg'
import MoveIcon from 'icons/bold/52-Arrows-Diagrams/02-Diagrams/diagram-all-direction-expand.svg'
import ArrowCornerIcon from 'icons/core-solid/interface-essential/interface-arrows-bend-down-right-1.svg'
import RotateIcon from 'icons/custom/rotate.svg'
import isNullOrEmpty from 'utils/isNullOrEmpty'

import './PrintAreaSection.scss'

interface SelectPrintAreaSubMenuProps {
  printAreas: PrintArea[]
  onCreatePrintArea: React.MouseEventHandler<HTMLButtonElement>
  onChange: (printAreaId: string) => void
}

const SelectPrintAreaSubMenu = ({ printAreas, onCreatePrintArea, onChange }: SelectPrintAreaSubMenuProps) => {
  const [filter, setFilter] = useState('')

  const filteredPrintArea = printAreas.filter(printArea => printArea.name.includes(filter))

  return (
    <div className="w-[224px]">
      <div className="p-4">
        <MultiIconsButton
          className="add-button--primary add-button--full-width"
          onClick={onCreatePrintArea}
          Icon={<PrintAreaIcon className="w-3.5" />}
        >
          Add print area
        </MultiIconsButton>
      </div>
      <Separator>or</Separator>
      <div className="p-4 pb-2 space-y-2">
        <div className="font-medium">Select existing print area</div>
        <Input
          placeholder="Search print areas..."
          value={filter}
          onChange={e => setFilter(e.target.value)}
          rightAddon={
            filter !== '' && (
              <IconButton
                small
                variant="subtle"
                className="-mr-2"
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  setFilter('')
                }}
                Icon={XIcon}
              />
            )
          }
        />
      </div>
      <div className="flex flex-col max-h-[400px] overflow-y-auto space-y-2 pb-2">
        {filteredPrintArea.map(printArea => (
          <Card className="mx-2" key={printArea.id} onClick={() => onChange(printArea.id)}>
            <PrintAreaIcon className="select-print-area__icon mr-2" />
            <span>{printArea.name}</span>
          </Card>
        ))}
      </div>
    </div>
  )
}

interface PrintAreaSectionProps {
  part: LogoPart | TextPart
  question: Question
  isClosing?: boolean
}

const PrintAreaSection = ({ part, question, isClosing }: PrintAreaSectionProps) => {
  const dispatch = useDispatch()
  const customizerDispatch = useCustomizerDispatch()
  const history = useHistory()
  const location = useLocation()

  const match = useRouteMatch('/questions/:id/print-area')

  const printAreas = useSelector(printAreasSelectors.printAreasSelector)
  const printArea = printAreas[part.printArea!]
  const dropdownButton = usePopover({ placement: 'bottom-end' })
  const answers = useSelector(answersSelector)
  const bulkOrderGroup = useSelector(bulkOrderGroupSelector)
  const position = answers[part.position!] as PrintAreaLogoPositionAnswer | PrintAreaTextPositionAnswer
  const disableTransforms = part.type === PartType.Text && !(position as PrintAreaTextPositionAnswer).position?.shape
  const isBulkOrderQuestion = !!bulkOrderGroup?.children.includes(question.id)
  const isPrintAreaOpen = match?.isExact
  const setIsPrintAreaOpen = (isOpen: boolean) => {
    if (isOpen) return history.push(location.pathname + '/print-area')
    history.push(location.pathname.replace('/print-area', ''))
  }

  const handleCreatePrintArea: React.MouseEventHandler<HTMLButtonElement> = async event => {
    dropdownButton.close()
    event.stopPropagation()
    customizerDispatch(customizationActions.stopEditPart())
    const { payload } = dispatch(printAreasActions.addPrintArea(part.id))
    dispatch(partsActions.convertPartPositionToPrintAreaPosition(part, payload.printArea))
    setIsPrintAreaOpen(true)
  }

  const handleSelectPrintArea = (id: string) => {
    customizerDispatch(customizationActions.stopEditPart())
    dropdownButton.close()

    if (!part.printArea) {
      dispatch(partsActions.convertPartPositionToPrintAreaPosition(part, printAreas[id]))
      dispatch(
        partsActions.patchPart(part.id, {
          printArea: id,
          allowedTransforms: { move: false, resize: false, rotate: false },
        })
      )
    }
  }

  const toggleAllowedTransforms = (transformName: AllowedTransform) => {
    dispatch(
      partsActions.patchPart(part.id, {
        allowedTransforms: { [transformName]: !part.allowedTransforms?.[transformName] },
      })
    )
  }

  const handleUpdateAllowedMove = () => {
    toggleAllowedTransforms(AllowedTransform.Move)
  }

  const handleUpdateAllowedResize = () => {
    toggleAllowedTransforms(AllowedTransform.Resize)

    if (part.type === PartType.Text) {
      dispatch(
        answersActions.patchAnswer(position, {
          position: part.allowedTransforms?.resize
            ? { maxWidth: 250, maxHeight: 250 }
            : { maxWidth: null, maxHeight: null },
        })
      )
    }
  }

  const handleUpdateAllowedRotate = () => {
    toggleAllowedTransforms(AllowedTransform.Rotate)
  }

  const handleRemovePrintArea: React.MouseEventHandler<HTMLButtonElement> = event => {
    event.stopPropagation()
    setIsPrintAreaOpen(false)
    customizerDispatch(customizationActions.stopEditPart())
    dispatch(partsActions.removePrintAreaFromParts(printArea.id, [part]))
  }

  return (
    <>
      <div className="panel__row panel__row--small-margin-top panel__row--no-margin-bottom">
        <div className="apply-on-icon">
          <ArrowCornerIcon className="fill-neutral-600" />
          Apply on
        </div>
        {printArea ? (
          <div
            className={classNames('apply-on-with-question apply-on-with-question--interactable bg-neutral-75 h-8', {
              'apply-on-with-question--selected': isPrintAreaOpen,
            })}
            title={printArea.name}
            onClick={() => setIsPrintAreaOpen(true)}
          >
            <div className="type-icon__icon-wrapper">
              <TypeIcons entity={printArea} />
            </div>
            <div className="apply-on-with-question__label">{printArea.name}</div>
            <IconButton variant="subtle" onClick={handleRemovePrintArea} Icon={XIcon} small className="mr-1" />
          </div>
        ) : isNullOrEmpty(printAreas) ? (
          <MultiIconsButton
            variant="subtle"
            Icon={<PrintAreaIcon className="w-5 p-1 rounded bg-neutral-75" />}
            onClick={handleCreatePrintArea}
          >
            Print area
          </MultiIconsButton>
        ) : (
          <>
            <MultiIconsButton
              variant="subtle"
              Icon={<PrintAreaIcon className="w-5 p-1 rounded bg-neutral-75" />}
              {...dropdownButton.referenceProps}
            >
              Print area
            </MultiIconsButton>
            <Popover {...dropdownButton.floatingProps} isOpen={dropdownButton.isOpen} className="builder">
              <SelectPrintAreaSubMenu
                printAreas={Object.values(printAreas)}
                onChange={handleSelectPrintArea}
                onCreatePrintArea={handleCreatePrintArea}
              />
            </Popover>
          </>
        )}
      </div>
      {part.printArea != null && (
        <>
          {isBulkOrderQuestion && (
            <Alert variant="info" className="mt-4">
              <Alert.Body>Bulk order experience</Alert.Body>
              <Alert.Details className="mb-4">All items will have the same position and size.</Alert.Details>
            </Alert>
          )}

          <VerticalFields className="mt-6">
            <label>Allow {part.type} editing</label>
            <Tooltip
              content="These settings are only possible with multiline text, for now."
              disabled={!disableTransforms}
            >
              <HorizontalField>
                <label className="panel__field-label">
                  <MoveIcon />
                  Move
                </label>
                <Switch
                  disabled={disableTransforms}
                  checked={!!part.allowedTransforms?.move}
                  onChange={handleUpdateAllowedMove}
                />
              </HorizontalField>
            </Tooltip>
            <Tooltip
              content="These settings are only possible with multiline text, for now."
              disabled={!disableTransforms}
            >
              <HorizontalField>
                <label className="panel__field-label">
                  <ResizeIcon />
                  Resize
                </label>
                <Switch
                  disabled={disableTransforms}
                  checked={!!part.allowedTransforms?.resize}
                  onChange={handleUpdateAllowedResize}
                />
              </HorizontalField>
            </Tooltip>
            <Tooltip
              content="These settings are only possible with multiline text, for now."
              disabled={!disableTransforms}
            >
              <HorizontalField>
                <label className="panel__field-label">
                  <RotateIcon />
                  Rotate
                </label>
                <Switch
                  disabled={disableTransforms}
                  checked={!!part.allowedTransforms?.rotate}
                  onChange={handleUpdateAllowedRotate}
                />
              </HorizontalField>
            </Tooltip>
          </VerticalFields>
        </>
      )}
      {printArea && isPrintAreaOpen && !isClosing && (
        <PrintAreaPanel printArea={printArea} onClose={() => setIsPrintAreaOpen(false)} />
      )}
    </>
  )
}

export default PrintAreaSection
