import React from 'react'
import { ActionMeta } from 'react-select'

import { SelectAsync } from 'common/components'

import { useLoadProductOptions } from '../hooks'
interface ProductAsyncSelectProps {
  value?: { label: string; value: string }
  onChange: (newValue: unknown, actionMeta: ActionMeta<unknown>) => void
}

const ProductAsyncSelect = ({ value, onChange }: ProductAsyncSelectProps) => {
  const loadOptions = useLoadProductOptions()

  return (
    <SelectAsync
      className="w-full md:w-4/5"
      blurInputOnSelect
      defaultOptions
      isSearchable
      value={value}
      onChange={onChange}
      loadOptions={loadOptions}
    />
  )
}

export default ProductAsyncSelect
