import { useMemo } from 'react'

import { useToast } from 'common/components'
import { ToastType } from 'common/components/toast/types'
import { trpc } from 'common/hooks/trpc'
import { InventorySortKeys, Variant } from 'common/variants'

import { variantsToFormValues } from './utils'

const LIMIT = 100

const useInventory = (persistenceState: {
  combinations: string[]
  stock?: {
    type: 'gte-lte' | 'eq' | 'gt' | 'lt' | 'na'
    value?: string | string[] | undefined
  }
  filter: string
  sortKey?: InventorySortKeys | undefined
  sortOrder?: 'descending' | 'ascending' | undefined
}) => {
  const { openToast } = useToast()

  const trpcContext = trpc.useContext()
  const { data: location } = trpc.inventory.location.getDefault.useQuery()

  const getStockFilter = () => {
    if (persistenceState.stock?.value === undefined) return undefined

    return persistenceState.stock as {
      type: 'gte-lte' | 'eq' | 'gt' | 'lt' | 'na'
      value: string | string[]
    }
  }

  const getVariantsInput = useMemo(
    () => ({
      location: location?.id,
      limit: LIMIT,
      ...{ ...persistenceState, stock: getStockFilter() },
    }),
    [location, persistenceState]
  )

  const {
    data: variantsData,
    isLoading: isLoadingInventory,
    isFetching: isFetchingInventory,
    fetchNextPage,
  } = trpc.variant.get.useInfiniteQuery(getVariantsInput, {
    enabled: location != null,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    getPreviousPageParam: firstPage => {
      const { lastIndex } = firstPage.pagination

      if (lastIndex - LIMIT === 0) return undefined

      return Math.max(lastIndex - LIMIT, 0)
    },
    getNextPageParam: lastPage => {
      const { lastIndex, collectionSize } = lastPage.pagination

      if (lastIndex >= collectionSize) return undefined

      return lastPage.pagination.lastIndex
    },
  })

  const { data: variantIdsByGroup = [] } = trpc.variant.getIdsByCombination.useQuery(
    {
      location: location?.id,
      filter: persistenceState.filter,
      combinations: persistenceState.combinations,
      stock: getStockFilter(),
    },
    { enabled: location != null }
  )

  const { data: groups = [] } = trpc.variant.getAllCombinationsAsFilters.useQuery(undefined, {
    enabled: location != null,
  })

  const { mutate: updateInventoryLevels } = trpc.inventory.item.updateStocks.useMutation({
    onSuccess: () => {
      trpcContext.variant.get.invalidate()
      openToast('Inventory was successfully saved.', ToastType.success)
    },
    onError: () => {
      openToast('Could not save inventory please try again.', ToastType.warning)
    },
  })

  const variants: Variant[] = useMemo(
    () =>
      variantsData
        ? variantsData.pages.reduce((variants: Variant[], page) => {
            return [...variants, ...page.results]
          }, [])
        : [],
    [variantsData]
  )

  const inventoryItems = useMemo(
    () => (location ? variantsToFormValues(location.id, variants) : {}),
    [location?.id, variants]
  )

  return {
    variants,
    inventoryItems,
    location,
    isLoadingInventory,
    isFetchingInventory,
    fetchNextPage,
    updateInventoryLevels,
    groups,
    variantIdsByGroup,
    getVariantsInput,
  }
}

export default useInventory
