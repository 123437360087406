import classNames from 'classnames'
import React from 'react'
import { useSelector } from 'react-redux'

import { selectors as coreSelectors } from 'builder/build/core'
import { twoDDisplayerActions } from 'customizer/2dDisplayer'

import { useCustomizerDispatch } from '../hooks'

export interface ViewButtonsProps {
  currentView: number
  label?: React.ReactNode
}

const ViewButtons = ({ currentView, label }: ViewButtonsProps) => {
  const customizerDispatch = useCustomizerDispatch()
  const numberOfViews = useSelector(coreSelectors.viewsSelector)

  const switchView = (index: number) => {
    if (index !== currentView) {
      customizerDispatch(twoDDisplayerActions.switchView(index))
    }
  }

  return (
    <div className="flex items-center h-8">
      {label ?? <label className="mr-4">Views</label>}
      <div className="flex space-x-1">
        {Array.from(Array(numberOfViews)).map((_, index) => (
          <button
            key={`view-button_${index}`}
            onClick={() => switchView(index)}
            role="radio"
            aria-label={`Switch to view ${index + 1}`}
            aria-checked={index === currentView ? 'true' : 'false'}
            className={classNames('w-6 h-6 flex items-center justify-center border rounded-md', {
              'border-primary-500 text-primary-500 border-2 font-medium': index === currentView,
              'border-neutral-100 border-solid': index !== currentView,
            })}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  )
}

export default ViewButtons
