import React from 'react'

import {
  DatesFilter,
  SearchFilter,
  TagsFilter,
  ClearFiltersButton,
  FilterContainer,
  FilterList,
  CheckboxFilter,
} from 'common/components'
import FilterIcon from 'icons/bold/01-Interface Essential/15-Filter/filter-2.svg'

import { PaymentStatus, Status, OrdersFilter, OrdersFilters } from '../types'
import OnlineStoresFilter from './OnlineStoresFilter'

export const STATUS_FILTERS: OrdersFilter<Status>[] = [
  {
    name: Status.IN_PRODUCTION,
    text: 'In production',
    className: 'fill-primary-700 bg-primary-100 text-primary-700',
  },
  {
    name: Status.FULFILLED,
    text: 'Fulfilled',
    className: 'fill-tertiary-green-700 bg-tertiary-green-100 text-tertiary-green-700',
  },
  {
    name: Status.CANCELLED,
    text: 'Canceled',
    className: 'fill-tertiary-red-700 bg-tertiary-red-100 text-tertiary-red-700',
  },
  {
    name: Status.PENDING,
    text: 'Pending',
    className: 'fill-secondary-orange-700 bg-warning-light bg-secondary-orange-100 text-secondary-orange-700',
  },
]

export const PAYMENT_STATUS_FILTERS: OrdersFilter<PaymentStatus>[] = [
  {
    name: PaymentStatus.UNPAID,
    text: 'Unpaid',
    className: 'fill-tertiary-red-700 bg-tertiary-red-100 text-tertiary-red-700',
  },
  {
    name: PaymentStatus.PAID,
    text: 'Paid',
    className: 'fill-tertiary-green-700 bg-tertiary-green-100 text-tertiary-green-700',
  },
  {
    name: PaymentStatus.Partially_paid,
    text: 'Partially paid',
    className: 'fill-secondary-orange-700 bg-warning-light bg-secondary-orange-100 text-secondary-orange-700',
  },
  {
    name: PaymentStatus.Authorized,
    text: 'Authorized',
    className: 'fill-tertiary-green-700 bg-tertiary-green-100 text-tertiary-green-700',
  },
  {
    name: PaymentStatus.REFUNDED,
    text: 'Refunded',
    className: 'fill-primary-700 bg-primary-100 text-primary-700',
  },
  {
    name: PaymentStatus.Partially_refunded,
    text: 'Partially refunded',
    className: 'fill-primary-700 bg-primary-100 text-primary-700',
  },
]

export const TEST_FILTER: OrdersFilter<any>[] = [
  {
    name: 'isTest',
    text: 'Test order',
    className: 'border border-neutral-300 text-neutral-800 px-[5px] py-[3px]',
  },
]

export interface OrdersFilterListProps extends OrdersFilters {
  toggleFilter: (type: keyof OrdersFilters, name: string) => void
  clearFilters: (type: keyof OrdersFilters) => void
  setSearch: (value: string) => void
  search?: string
  startDate?: Date | null
  setStartDate: (value?: Date | null) => void
  endDate?: Date | null
  setEndDate: (value?: Date | null) => void
  clearAll: () => void
  hasAppliedFilters?: boolean
}

const OrdersFilterList = ({
  status,
  paymentStatus,
  onlineStores,
  various,
  toggleFilter,
  clearFilters,
  setSearch,
  search,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  clearAll,
  hasAppliedFilters,
}: OrdersFilterListProps) => {
  return (
    <FilterContainer>
      <FilterList>
        <SearchFilter name="Order" setSearch={setSearch} search={search} />
        <DatesFilter
          name="Created"
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
        <TagsFilter
          name="Status"
          filters={STATUS_FILTERS}
          activeFilters={status}
          toggleFilter={name => toggleFilter('status', name)}
          clearFilter={() => clearFilters('status')}
        />
        <TagsFilter
          name="Payment"
          filters={PAYMENT_STATUS_FILTERS}
          activeFilters={paymentStatus}
          toggleFilter={name => toggleFilter('paymentStatus', name)}
          clearFilter={() => clearFilters('paymentStatus')}
        />
        <OnlineStoresFilter
          clearFilters={() => clearFilters('onlineStores')}
          toggleOnlineStore={name => toggleFilter('onlineStores', name)}
          selectedOnlineStores={onlineStores}
        />
        <CheckboxFilter
          name=""
          Icon={FilterIcon}
          filters={TEST_FILTER}
          activeFilters={various}
          toggleFilter={name => toggleFilter('various', name)}
          clearFilter={() => clearFilters('various')}
        />
      </FilterList>
      <ClearFiltersButton onClick={clearAll} disabled={!hasAppliedFilters} />
    </FilterContainer>
  )
}

export default OrdersFilterList
