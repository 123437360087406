import { PrintAreaMeasurementUnit } from '@packages/types'
import { Bezier } from 'bezier-js'

import { utils as printAreasUtils, constants } from 'common/printAreas'
import { toRads, rotatePoint } from 'utils/math'

const { convertToPixels } = printAreasUtils

export const convertToLogoPosition = (printArea, originalAnswer) => {
  const originalAnswerPosition = originalAnswer.position

  const printAreaInPixels = convertToPixels(printArea)

  const designView = printAreaInPixels.productPreview.views[printAreaInPixels.productPreview.designView]

  const printAreaX = designView.x
  const printAreaY = designView.y

  const { x: rotatedX, y: rotatedY } = rotatePoint(
    { x: originalAnswerPosition.x + printAreaX, y: originalAnswerPosition.y + printAreaY },
    toRads(designView.rotation),
    { x: printAreaX, y: printAreaY }
  )

  return {
    maxWidth: originalAnswerPosition.maxWidth,
    maxHeight: originalAnswerPosition.maxHeight,
    rotation: originalAnswerPosition.rotation + designView.rotation,
    x: rotatedX,
    y: rotatedY,
  }
}

export const convertToPrintAreaLogoPosition = (printArea, originalAnswer) => {
  const originalAnswerPosition = originalAnswer.views[printArea.productPreview.designView]

  const printAreaInPixels = convertToPixels(printArea)

  const designView = printAreaInPixels.productPreview.views[printAreaInPixels.productPreview.designView]

  const printAreaX = printAreaInPixels.productPreview.views[printAreaInPixels.productPreview.designView].x
  const printAreaY = printAreaInPixels.productPreview.views[printAreaInPixels.productPreview.designView].y

  const { x: rotatedX, y: rotatedY } = rotatePoint(
    { x: originalAnswerPosition.x - printAreaX, y: originalAnswerPosition.y - printAreaY },
    toRads(-designView.rotation)
  )

  return {
    maxWidth: originalAnswerPosition.maxWidth,
    maxHeight: originalAnswerPosition.maxHeight,
    x: rotatedX,
    y: rotatedY,
    rotation: originalAnswerPosition.rotation - designView.rotation,
  }
}

export const convertToPrintAreaTextPosition = (printArea, originalAnswer) => {
  const originalAnswerPosition = originalAnswer.views[printArea.productPreview.designView]

  const printAreaInPixels = convertToPixels(printArea)

  const designView = printAreaInPixels.productPreview.views[printAreaInPixels.productPreview.designView]

  const printAreaX = designView.x
  const printAreaY = designView.y

  const { x: rotatedX, y: rotatedY } = rotatePoint(
    { x: originalAnswerPosition.x - printAreaX, y: originalAnswerPosition.y - printAreaY },
    toRads(-designView.rotation)
  )

  const rotatedBezier = []

  for (let i = 0; i < originalAnswerPosition.bezier.length; i = i + 2) {
    const x = originalAnswerPosition.bezier[i]
    const y = originalAnswerPosition.bezier[i + 1]

    const { x: rotatedX, y: rotatedY } = rotatePoint(
      { x: x - printAreaX, y: y - printAreaY },
      toRads(-designView.rotation)
    )

    rotatedBezier.push(rotatedX)
    rotatedBezier.push(rotatedY)
  }

  return {
    shape: originalAnswerPosition.shape,
    maxWidth: originalAnswerPosition.maxWidth,
    maxHeight: originalAnswerPosition.maxHeight,
    rotation: originalAnswerPosition.rotation - designView.rotation,
    textAlign: originalAnswerPosition.textAlign,
    verticalAlign: originalAnswerPosition.verticalAlign,
    x: rotatedX,
    y: rotatedY,
    bezier: rotatedBezier,
  }
}

export const convertToTextPosition = (printArea, originalAnswer) => {
  const originalAnswerPosition = originalAnswer.position

  const printAreaInPixels = convertToPixels(printArea)

  const designView = printAreaInPixels.productPreview.views[printAreaInPixels.productPreview.designView]

  const printAreaX = designView.x
  const printAreaY = designView.y

  const { x: rotatedX, y: rotatedY } = rotatePoint(
    { x: originalAnswerPosition.x + printAreaX, y: originalAnswerPosition.y + printAreaY },
    toRads(designView.rotation),
    { x: printAreaX, y: printAreaY }
  )

  const rotatedBezier = []

  for (let i = 0; i < originalAnswerPosition.bezier.length; i = i + 2) {
    const x = originalAnswerPosition.bezier[i]
    const y = originalAnswerPosition.bezier[i + 1]

    const { x: rotatedX, y: rotatedY } = rotatePoint(
      { x: x + printAreaX, y: y + printAreaY },
      toRads(designView.rotation),
      { x: printAreaX, y: printAreaY }
    )

    rotatedBezier.push(rotatedX)
    rotatedBezier.push(rotatedY)
  }

  return {
    shape: originalAnswerPosition.shape,
    maxWidth: originalAnswerPosition.maxWidth,
    maxHeight: originalAnswerPosition.maxHeight,
    rotation: originalAnswerPosition.rotation + designView.rotation,
    textAlign: originalAnswerPosition.textAlign,
    verticalAlign: originalAnswerPosition.verticalAlign,
    x: rotatedX,
    y: rotatedY,
    bezier: rotatedBezier,
  }
}

export const centerPrintAreaLogoPosition = (position, printArea) => {
  const printAreaInPixels = convertToPixels(printArea)
  const designViewScale = printAreaInPixels.productPreview.views[printAreaInPixels.productPreview.designView].scale
  const designViewWidth = designViewScale * (printAreaInPixels.width + 2 * printAreaInPixels.bleed)
  const designViewHeight = designViewScale * (printAreaInPixels.height + 2 * printAreaInPixels.bleed)

  return {
    ...position,
    x: designViewWidth / 2,
    y: designViewHeight / 2,
  }
}

export const centerPrintAreaTextPosition = (position, printArea) => {
  const printAreaInPixels = convertToPixels(printArea)
  const designViewScale = printAreaInPixels.productPreview.views[printAreaInPixels.productPreview.designView].scale

  const designViewWidth = designViewScale * (printAreaInPixels.width + 2 * printAreaInPixels.bleed)
  const designViewHeight = designViewScale * (printAreaInPixels.height + 2 * printAreaInPixels.bleed)

  const printAreaCenter = {
    x: designViewWidth / 2,
    y: designViewHeight / 2,
  }

  const bezierBoundingBox = new Bezier(
    ...[
      position.bezier[0],
      position.bezier[1],
      position.bezier[2],
      position.bezier[3],
      position.bezier[6],
      position.bezier[7],
      position.bezier[4],
      position.bezier[5],
    ]
  ).bbox()

  const xDiff = printAreaCenter.x - bezierBoundingBox.x.mid
  const yDiff = printAreaCenter.y - bezierBoundingBox.y.mid

  return {
    x: printAreaCenter.x,
    y: printAreaCenter.y,
    bezier: position.bezier.map((pos, i) => pos + (i % 2 !== 0 ? yDiff : xDiff)),
  }
}

const updateMeasurementUnitToPixels = printArea => {
  const printAreaInPixels = convertToPixels(printArea)
  const designViewScale = printAreaInPixels.productPreview.views[printAreaInPixels.productPreview.designView].scale
  const designViewWidth = designViewScale * (printAreaInPixels.width + 2 * printAreaInPixels.bleed)

  const newScale = Math.round((designViewWidth / (printArea.width + 2 * printArea.bleed)) * 1000) / 1000

  return {
    ...printArea,
    measurementUnit: PrintAreaMeasurementUnit.Pixels,
    productPreview: {
      ...printArea.productPreview,
      views: printArea.productPreview.views.map(view => ({
        ...view,
        scale: view.scale * (newScale / designViewScale),
      })),
    },
  }
}

const updateMeasurementUnitFromPixels = (measurementUnit, printArea) => {
  const printAreaInPixels = convertToPixels({ ...printArea, measurementUnit })

  const designViewScale = printAreaInPixels.productPreview.views[printAreaInPixels.productPreview.designView].scale
  const designViewWidth = designViewScale * (printArea.width + 2 * printArea.bleed)

  const newScale = Math.round((designViewWidth / (printAreaInPixels.width + 2 * printAreaInPixels.bleed)) * 1000) / 1000

  return {
    ...printArea,
    measurementUnit: measurementUnit,
    productPreview: {
      ...printArea.productPreview,
      views: printArea.productPreview.views.map(view => ({
        ...view,
        scale: view.scale * (newScale / designViewScale),
      })),
    },
  }
}

export const updateMeasurementUnit = (measurementUnit, printArea) => {
  if (measurementUnit === PrintAreaMeasurementUnit.Pixels) return updateMeasurementUnitToPixels(printArea)

  if (printArea.measurementUnit === PrintAreaMeasurementUnit.Pixels)
    return updateMeasurementUnitFromPixels(measurementUnit, printArea)

  return {
    ...printArea,
    measurementUnit: measurementUnit,
    productPreview: {
      ...printArea.productPreview,
      views: printArea.productPreview.views.map(view => ({
        ...view,
        scale:
          Math.round(
            (view.scale / constants.CONVERSIONS_RATES_TO_INCHES[printArea.measurementUnit]) *
              constants.CONVERSIONS_RATES_TO_INCHES[measurementUnit] *
              1000
          ) / 1000,
      })),
    },
  }
}

export const updateDPI = (dpi, printArea) => {
  if (printArea.measurementUnit === PrintAreaMeasurementUnit.Pixels) return printArea

  const dpiRatio = printArea.dpi / dpi

  return {
    ...printArea,
    dpi: dpi,
    productPreview: {
      ...printArea.productPreview,
      views: printArea.productPreview.views.map(view => ({
        ...view,
        scale: Math.round(view.scale * dpiRatio * 1000) / 1000,
      })),
    },
  }
}
