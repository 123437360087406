import React from 'react'

import { Table } from 'common/components'

const TotalHeader = () => {
  return (
    <Table.HeaderCellTooltip
      content={
        <span className="w-48">
          Total quantity. Sum of <span className="font-medium">committed</span> and{' '}
          <span className="font-medium">available</span>.
        </span>
      }
    >
      Total
    </Table.HeaderCellTooltip>
  )
}

export default TotalHeader
