import { Question } from '@packages/types'
import React from 'react'

import { useIsClosing } from '../../hooks'
import AnswerList from './AnswerList'
import AnswerListTitle from './AnswerListTitle'

interface AnswerListSectionProps {
  question: Question
  allowImport?: boolean
}

const AnswerListSection = ({ question, allowImport = true }: AnswerListSectionProps) => {
  const isClosing = useIsClosing(question.id)

  return (
    <div className="py-6">
      <AnswerListTitle question={question} allowImport={allowImport} isClosing={isClosing} />
      <AnswerList question={question} className="answer-list--no-padding" isClosing={isClosing} />
    </div>
  )
}

export default AnswerListSection
