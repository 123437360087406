import classNames from 'classnames'
import React from 'react'

import { Table } from 'common/components'

import { FormikVariants, VariantFormValue } from '../types'
import { getInventoryItem } from '../utils'
import useSharedQuantities from './useSharedQuantities'

export interface AvailableCellProps {
  variant: VariantFormValue
  formik: FormikVariants
  locationId: string
}

const AvailableCell = ({ locationId, variant, formik }: AvailableCellProps) => {
  const committedData = useSharedQuantities(locationId, variant, formik)

  const { committed, stock } = getInventoryItem(formik, variant)

  if (stock == null) return <Table.EmptyCellIndicator />

  if (committedData != null)
    return (
      <div className={classNames({ 'text-error-default': committedData.available <= 0 })}>
        {committedData.available}
      </div>
    )

  return (
    <div className={classNames('flex space-x-1', { 'text-error-default': stock - committed <= 0 })}>
      {stock - committed}
    </div>
  )
}

export default AvailableCell
