import React, { MutableRefObject, useContext } from 'react'
import Moveable, { Able, Draggable as DefaultDraggable, Snappable, makeMoveable } from 'react-moveable'

import { pathUtils } from 'common/drawing'
import { getCenterFromPoints } from 'utils/math'

import ContainerContext from '../containers/ContainerContext'
import Infoable from './ables/Infoable'
import PathDraggable from './ables/PathDraggable'
import PathHandleable from './ables/PathHandleable'
import PathRenderable from './ables/PathRenderable'
import PathScalable from './ables/PathScalable'
import Scrimable from './ables/Scrimable'
import { PathMoveableProps } from './types'
import useKeyboard from './useKeyboard'
import './Transformer.scss'

interface PathTransformerProps {
  target: MutableRefObject<SVGPathElement | null>
  onTransform?: (bezier: number[]) => void
  onTransformEnd?: () => void
  info?: React.ReactNode
  portalEl?: HTMLElement
}

type CustomMoveableProps = PathMoveableProps

type CustomMoveableType = Moveable<CustomMoveableProps>
const CustomMoveable = makeMoveable([
  PathRenderable,
  PathDraggable,
  DefaultDraggable,
  Snappable as Able,
  PathScalable,
]) as unknown as typeof Moveable<CustomMoveableProps>

const PathTransformer = ({ target, onTransform, onTransformEnd, info }: PathTransformerProps) => {
  const moveableRef = React.useRef<CustomMoveableType>(null)

  useKeyboard(moveableRef)

  const { containerRef } = useContext(ContainerContext)

  const updateBezier = (newBezier: number[]) => {
    onTransform?.(newBezier)
  }

  return (
    <CustomMoveable
      ref={moveableRef}
      target={target}
      className="moveable-custom"
      dragTarget=".moveable-control-drag-target"
      draggable
      hideDefaultLines
      viewContainer={document.body}
      ables={[PathHandleable, Infoable]}
      origin={false}
      snapContainer={containerRef}
      scalable
      useResizeObserver
      props={{
        [PathDraggable.name]: true,
        [Infoable.name]: true,
        [PathHandleable.name]: true,
        info,
        [Scrimable.name]: true,
      }}
      useMutationObserver
      onDrag={e => {
        const bezier = pathUtils.pathToBezier(e.target.getAttributeNS(null, 'd') ?? '')
        const newBezier = bezier.map((coord, i) => (i % 2 === 0 ? coord + e.delta[0] : coord + e.delta[1]))
        const path = pathUtils.bezierToPath(newBezier)
        e.target.setAttributeNS(null, 'd', path)
        updateBezier(newBezier)
      }}
      onDragHandle={e => {
        const bezier = pathUtils.pathToBezier(e.target.getAttributeNS(null, 'd') ?? '')
        const newBezier = [...bezier]
        newBezier[e.handleIndex] = e.beforeTranslate[0]
        newBezier[e.handleIndex + 1] = e.beforeTranslate[1]
        const path = pathUtils.bezierToPath(newBezier)

        e.target.setAttributeNS(null, 'd', path)
        updateBezier(newBezier)
      }}
      onScaleStart={e => {
        e.setFixedDirection([0, 0])
      }}
      keepRatio
      onScale={e => {
        if (isNaN(e.delta[0]) || isNaN(e.delta[1])) return

        const bezier = pathUtils.pathToBezier(e.target.getAttributeNS(null, 'd') ?? '')

        const center = getCenterFromPoints([
          {
            x: bezier[0],
            y: bezier[1],
          },
          {
            x: bezier[2],
            y: bezier[3],
          },
          {
            x: bezier[4],
            y: bezier[5],
          },
          {
            x: bezier[6],
            y: bezier[7],
          },
        ])

        const newBezier = []

        for (let i = 0; i < bezier.length; i = i + 2) {
          const newX = (bezier[i] - center.x) * e.delta[0] + center.x
          const newY = (bezier[i + 1] - center.y) * e.delta[1] + center.y
          newBezier[i] = newX
          newBezier[i + 1] = newY
        }

        const path = pathUtils.bezierToPath(newBezier)

        e.target.setAttributeNS(null, 'd', path)
        updateBezier(newBezier)
      }}
      onDragEnd={onTransformEnd}
      onScaleEnd={onTransformEnd}
      snappable
      snapDirections={{ top: true, left: true, bottom: true, right: true, center: true, middle: true }}
      verticalGuidelines={['0%', '50%', '100%']}
      horizontalGuidelines={['0%', '50%', '100%']}
    />
  )
}

export default PathTransformer
