import { createSelector } from 'reselect'

import { selectors as coreSelectors } from 'builder/build/core'
import createCustomizerProductEntitySelectors from 'builder/build/core/createCustomizerProductEntitySelectors'
import { RootState } from 'cms/store'

const { printAreasSelector, printAreasAsArraySelector } = createCustomizerProductEntitySelectors('printAreas')

export { printAreasSelector, printAreasAsArraySelector }

export const printAreaSelector = createSelector(
  coreSelectors.productBuilderSelector,
  (_: RootState, id?: string) => id,
  (productBuilder, id) => {
    return id ? productBuilder.printAreas[id] : null
  }
)
