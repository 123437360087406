import { useFlags } from 'flagsmith/react'
import React from 'react'
import { StringParam, useQueryParam, withDefault } from 'use-query-params'

import { Tabs } from 'common/components'
import { useCurrentUser } from 'common/users'

export type ProductTabs = 'active' | 'archived'

export const TabParam = withDefault(StringParam, 'active')

const ProductsTabs = () => {
  const [tab, setTab] = useQueryParam('tab', TabParam, { updateType: 'replaceIn' })
  const { isMCZRUser } = useCurrentUser()
  const flags = useFlags(['product_delete'])

  return (
    <Tabs>
      <Tabs.Tab onClick={() => setTab('active')} isSelected={!tab || tab == 'active'}>
        Active
      </Tabs.Tab>
      <Tabs.Tab onClick={() => setTab('archived')} isSelected={tab == 'archived'}>
        Archived
      </Tabs.Tab>
      {isMCZRUser && flags.product_delete.enabled && (
        <Tabs.Tab onClick={() => setTab('deleted')} isSelected={tab == 'deleted'}>
          Deleted
        </Tabs.Tab>
      )}
    </Tabs>
  )
}

export default ProductsTabs
