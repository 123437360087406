import { PrintArea } from '@packages/types'
import React from 'react'

import { HorizontalField } from 'builder/build/common/components'
import { useDispatch } from 'cms/hooks'
import { Switch } from 'common/components'

import { patchPrintArea } from '../../actions'

interface VisibilitySettingProps {
  currentView: number
  printArea: PrintArea
}

const VisibilitySetting = ({ currentView, printArea }: VisibilitySettingProps) => {
  const dispatch = useDispatch()

  const handleChange = (field: string, value: boolean, view: number) =>
    dispatch(
      patchPrintArea(printArea, {
        productPreview: {
          views: {
            [view]: { [field]: value },
          },
        },
      })
    )

  return (
    <HorizontalField>
      <label>Show in view {currentView + 1}</label>
      <Switch
        disabled={printArea.productPreview.designView === currentView}
        checked={printArea.productPreview.views[currentView]?.hidden === false}
        onChange={e => handleChange('hidden', !e.target.checked, currentView)}
      />
    </HorizontalField>
  )
}

export default VisibilitySetting
