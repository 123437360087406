import React from 'react'
import { StringParam, useQueryParam } from 'use-query-params'

import { BlankState } from 'common/components'
import SkateboardIcon from 'icons/custom/bold-skateboard.svg'

import { TabParam } from '../ProductsTabs'

const SearchBlankState = () => {
  const [tab] = useQueryParam('tab', TabParam)
  const [search, setSearch] = useQueryParam('search', StringParam, { updateType: 'replaceIn' })

  return (
    <div className="flex items-center justify-center pt-16">
      <BlankState className="w-full md:w-[400px]">
        <BlankState.Icon Icon={SkateboardIcon} className="text-neutral-300" />
        <BlankState.Title>No product to display for {search}</BlankState.Title>
        <BlankState.Details>
          Change search term or the product you're searching is maybe in {tab === 'active' ? 'archived' : 'active'} tab
        </BlankState.Details>
        <BlankState.Action aria-label="clear search" onClick={() => setSearch(null)}>
          Clear search
        </BlankState.Action>
      </BlankState>
    </div>
  )
}

export default SearchBlankState
