import { createSelector } from 'reselect'

import { selectors as coreSelectors } from 'builder/build/core'

import ruleAsNaturalLanguage from './ruleAsNaturalLanguage'

const ruleStateSelector = createSelector(
  coreSelectors.normalizedCustomizerProductSelector,
  ({ answers, questions, parts, rules }) => ({ answers, questions, parts, rules })
)

export const rulesAsNaturalLanguageSelector = createSelector(ruleStateSelector, state => {
  const rules = Object.values(state.rules)

  return rules.reduce<Record<string, string>>((rulesAsNaturalLanguage, rule) => {
    rulesAsNaturalLanguage[rule.id] = ruleAsNaturalLanguage(state, rule)

    return rulesAsNaturalLanguage
  }, {})
})
