import classNames from 'classnames'
import { times } from 'lodash'
import React, { forwardRef, useState } from 'react'

import { Card, CardList, ReorderDetails } from 'builder/build/common/components'
import { hooks as customizerHooks, selectors as customizerSelectors } from 'builder/build/customizer'
import { useDispatch, useSelector } from 'cms/hooks'
import { Button, IconButton, Modal, useModal } from 'common/components'
import { twoDDisplayerActions } from 'customizer/2dDisplayer'
import XIcon from 'icons/bold/01-Interface Essential/33-Form-Validation/close.svg'
import AddIcon from 'icons/bold/01-Interface Essential/43-Remove-Add/add.svg'
import CameraRotateIcon from 'icons/regular/13-Images-Photography/13-Camera-Settings/camera-settings-rotate.svg'

import { deleteView, reorderViews, addView } from '../actions'
import { productSettingsSelector } from '../selectors'

const { currentViewSelector } = customizerSelectors
const { useCustomizerDispatch, useCustomizerSelector } = customizerHooks

export interface ProductViewCardProps {
  id: string
  selected: boolean
  onDelete?: (e: React.MouseEvent) => void
  handleClick: () => void
  isDragged: boolean
  isDragOverlay: boolean
}

export const ProductViewCard = forwardRef<HTMLDivElement, ProductViewCardProps>(
  ({ id, isDragOverlay, isDragged, selected, onDelete, handleClick, ...props }, ref) => {
    return (
      <Card
        className={classNames('group bg-white', {
          '!text-primary-600 bg-neutral-50': selected,
          'opacity-50': isDragged,
          'opacity-80': isDragOverlay,
          'mx-2': !isDragOverlay,
        })}
        ref={ref}
        onClick={handleClick}
        {...props}
      >
        <CameraRotateIcon className="w-5 h-5 p-1" />
        <span className="ml-2">View {id}</span>
        {!!onDelete && (
          <div className="ml-auto opacity-0 group-hover:opacity-100">
            <IconButton variant="subtle" Icon={XIcon} onClick={onDelete} small aria-label="Delete view" />
          </div>
        )}
      </Card>
    )
  }
)

const ProductSettingsViewsSection = () => {
  const dispatch = useDispatch()
  const customizerDispatch = useCustomizerDispatch()
  const currentView = useCustomizerSelector(currentViewSelector) ?? 0
  const productSettings = useSelector(productSettingsSelector)
  const [viewIndexToDelete, setViewIndexToDelete] = useState<number | null>(null)
  const deleteViewModal = useModal()

  const addNewView = () => dispatch(addView)

  const handleDeleteViewModalClose = () => {
    setViewIndexToDelete(null)
    deleteViewModal.close()
  }

  const handleDeleteView = () => {
    if (viewIndexToDelete != null && viewIndexToDelete <= currentView) {
      customizerDispatch(twoDDisplayerActions.switchView(Math.max(currentView - 1, 0)))
    }
    dispatch(deleteView(viewIndexToDelete))
    handleDeleteViewModalClose()
  }

  const handleReorder = ({ sourceIndex, destinationIndex }: ReorderDetails) => {
    dispatch(reorderViews(sourceIndex, destinationIndex))
  }

  const handleViewCardClick = (index: number) => {
    if (index !== currentView) {
      customizerDispatch(twoDDisplayerActions.switchView(index))
    }
  }

  return (
    <div className="py-6">
      <div className="flex font-medium mx-4 items-center mb-4">
        <span className="mr-auto">Product views</span>
        <IconButton onClick={addNewView} smallIcon Icon={AddIcon} />
      </div>
      <CardList
        className="space-y-2"
        onReorder={handleReorder}
        CardComponent={ProductViewCard}
        items={times(productSettings.views, i => ({
          id: String(i + 1),
          props: {
            id: String(i + 1),
            selected: i === currentView,
            onDelete:
              productSettings.views > 1
                ? (e: React.MouseEvent) => {
                    e.preventDefault()
                    e.stopPropagation()
                    setViewIndexToDelete(i)
                    deleteViewModal.open()
                  }
                : undefined,
            handleClick: () => handleViewCardClick(i),
          },
        }))}
      />
      {deleteViewModal.isVisible && (
        <Modal onBackdropClick={handleDeleteViewModalClose} {...deleteViewModal.modalProps}>
          <Modal.CloseButton onClick={handleDeleteViewModalClose} />
          <Modal.Title>Delete view {viewIndexToDelete! + 1}</Modal.Title>

          <Modal.Details>
            Are you sure you want to delete view {viewIndexToDelete! + 1}? This action cannot be undone.
          </Modal.Details>

          <Modal.Actions>
            <Button type="button" variant="default" className="px-4" onClick={handleDeleteViewModalClose}>
              Cancel
            </Button>
            <Button type="button" variant="error" className="px-4" onClick={handleDeleteView}>
              Delete
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </div>
  )
}

export default ProductSettingsViewsSection
