import React from 'react'

import { hooks as customizerHooks } from 'builder/build/customizer'
import { selectors as printAreasSelectors } from 'builder/build/printAreas'
import { useSelector } from 'cms/hooks'
import { Tooltip, IconButton } from 'common/components'
import { twoDDisplayerActions, twoDDisplayerSelectors } from 'customizer/2dDisplayer'
import Checkmark from 'icons/bold/01-Interface Essential/33-Form-Validation/check-1.svg'
import MoveIcon from 'icons/core-solid/interface-essential/interface-arrows-all-direction.svg'

import PrintAreaVisibilitySetting from './PrintAreaVisibilitySetting'

export interface EditPrintAreaActionBarProps {
  printAreaId: string
}

const EditPrintAreaActionBar = ({ printAreaId }: EditPrintAreaActionBarProps) => {
  const customizerDispatch = customizerHooks.useCustomizerDispatch()
  const editedPrintArea = customizerHooks.useCustomizerSelector(twoDDisplayerSelectors.editedPrintAreaSelector)
  const printArea = useSelector(state => printAreasSelectors.printAreaSelector(state, printAreaId))!
  const isEditing = editedPrintArea === printAreaId

  const startEditing = () => customizerDispatch(twoDDisplayerActions.editPrintArea(printAreaId))

  const stopEditing = () => customizerDispatch(twoDDisplayerActions.editPrintArea(null))

  return (
    <>
      <PrintAreaVisibilitySetting printArea={printArea} />
      <Tooltip content={isEditing ? 'Stop editing' : 'Edit'}>
        <IconButton
          variant="subtle"
          Icon={isEditing ? Checkmark : MoveIcon}
          smallIcon={isEditing}
          onClick={isEditing ? stopEditing : startEditing}
          aria-label={isEditing ? 'Stop editing print area' : 'Edit print area'}
        />
      </Tooltip>
    </>
  )
}

export default EditPrintAreaActionBar
