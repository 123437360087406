import React from 'react'

import { Container } from 'common/components'
import { ClippingGroup as ClippingGroupType } from 'customizer/2dDisplayer/types'

import PrintArea from '../nodes/PrintArea'

export interface CanvasProps extends React.PropsWithChildren {
  clippingGroup: ClippingGroupType
}

const ClippingGroup = ({ clippingGroup, children }: CanvasProps) => {
  return (
    <Container
      x={clippingGroup.x * clippingGroup.scale}
      y={clippingGroup.y * clippingGroup.scale}
      width={(clippingGroup.width + clippingGroup.bleed * 2) * clippingGroup.scale}
      height={(clippingGroup.height + clippingGroup.bleed * 2) * clippingGroup.scale}
      rotation={clippingGroup.rotation}
      scale={clippingGroup.groupScale}
    >
      {children}
      <PrintArea
        color={clippingGroup.outlineColor}
        width={clippingGroup.width}
        height={clippingGroup.height}
        bleed={clippingGroup.bleed}
        margins={clippingGroup.margins}
      />
    </Container>
  )
}

export default ClippingGroup
