import { BulkOrder } from '@packages/types'
import React from 'react'
import { useDispatch } from 'react-redux'

import { HorizontalField, VerticalField } from 'builder/build/common/components'
import { Alert, PatchTextInput, PatchTextInputChangeEvent } from 'common/components'

import { patchGroup } from '../../actions'

interface BulkOrderQuantitySectionProps {
  bulkOrderGroup: BulkOrder
}

const BulkOrderQuantitySection = ({ bulkOrderGroup }: BulkOrderQuantitySectionProps) => {
  const dispatch = useDispatch()
  const { min, max } = bulkOrderGroup.quantity

  const isQuantityInvalid = !!max && !!min && max < min

  const handleUpdateMinQuantity = (e: PatchTextInputChangeEvent) => {
    const newMin = Math.abs(Number(e.target.value.replace(/[^0-9.]/g, '')))
    dispatch(patchGroup(bulkOrderGroup.id, { quantity: { max, min: newMin } }))
  }

  const handleUpdateMaxQuantity = (e: PatchTextInputChangeEvent) => {
    const newMax = Math.abs(Number(e.target.value.replace(/[^0-9.]/g, '')))
    dispatch(patchGroup(bulkOrderGroup.id, { quantity: { min, max: newMax } }))
  }

  return (
    <div className="panel__section">
      <div className="panel__title panel__title--with-subtitle">
        <div className="panel__text">Quantity settings</div>
      </div>
      <VerticalField>
        <HorizontalField>
          <label className="panel__field-label w-2/3">Minimum</label>
          <PatchTextInput
            className="w-16"
            type="number"
            min="1"
            onChange={handleUpdateMinQuantity}
            value={min ? min.toString() : ''}
            placeholder="1"
          />
        </HorizontalField>
        <HorizontalField>
          <label className="panel__field-label w-2/3">Maximum</label>
          <PatchTextInput
            className="w-16"
            type="number"
            min="0"
            onChange={handleUpdateMaxQuantity}
            value={max ? `${max}` : ''}
            placeholder="∞"
          />
        </HorizontalField>
      </VerticalField>

      {isQuantityInvalid && (
        <Alert variant="warning" className="mt-4">
          <Alert.Body>Invalid values</Alert.Body>
          <Alert.Details className="mb-4">The minimum value exceeds the maximum value.</Alert.Details>
        </Alert>
      )}
    </div>
  )
}

export default BulkOrderQuantitySection
