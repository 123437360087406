import { ImagePart, LogoPart, Part, Question, QuestionType, TextPart } from '@packages/types'
import React, { useState } from 'react'

import { Panel } from 'builder/build/common/components'
import isNullOrEmpty from 'utils/isNullOrEmpty'

import ImageQuestionPanel from './ImageQuestionPanel'
import LogoQuestionPanel from './LogoQuestionPanel'
import QuestionPanel from './QuestionPanel'
import QuestionPanelScrim, { QuestionPanelScrimContext } from './QuestionPanelScrim'
import QuestionTabs from './QuestionTabs'
import TextQuestionPanel from './TextQuestionPanel'

const getQuestionPanel = (question: Question, part?: Part) => {
  switch (question.type) {
    case QuestionType.Image:
      return <ImageQuestionPanel part={part as ImagePart} question={question} />
    case QuestionType.Logo:
      return <LogoQuestionPanel part={part as LogoPart} question={question} />
    case QuestionType.Text:
      return <TextQuestionPanel part={part as TextPart} question={question} />
    case QuestionType.Value:
    case QuestionType.Material:
    case QuestionType.Color:
    case QuestionType.Outline:
    case QuestionType.Font:
    case QuestionType.FontSize:
      return <QuestionPanel question={question} />
    default:
      return null
  }
}

interface QuestionPanelFactoryProps {
  question: Question
  part?: Part
}

const QuestionPanelFactory = ({ question, part }: QuestionPanelFactoryProps) => {
  const [showScrim, setShowScrim] = useState(false)

  if (isNullOrEmpty(question)) return <Panel side="right" />

  const panel = getQuestionPanel(question, part)

  if (panel === null) return null

  return (
    <QuestionPanelScrimContext.Provider value={{ showScrim, setShowScrim }}>
      <QuestionPanelScrim />
      <QuestionTabs question={question} />
      {panel}
    </QuestionPanelScrimContext.Provider>
  )
}

export default QuestionPanelFactory
