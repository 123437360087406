import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useFormik } from 'formik'
import React, { useContext } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import * as yup from 'yup'

import { TrackerContext } from 'cms/tracking/components'
import { Button, Alert, HelperText, Input, InputField, Label, PasswordInput, PublicFormFooter } from 'common/components'
import { TenantContext } from 'common/tenant'
import { usersUtils, useUserService } from 'common/users'
import setSession from 'utils/setSession'

import { useAuthService } from '../hooks'
import { LoginFormValues } from '../types'
import AuthFormContainer from './AuthFormContainer'
import AuthFormTitle from './AuthFormTitle'
import AuthLink from './AuthLink'

const formValidation = yup.object().shape({
  email: yup.string().required('Please fill the email field').email('Please enter a valid email'),
  password: yup.string().required('Please fill the password field'),
})

export interface LoginFormProps extends RouteComponentProps {
  initialValues?: Partial<LoginFormValues>
}

const LoginForm = ({ initialValues }: LoginFormProps) => {
  const history = useHistory()
  const queryClient = useQueryClient()
  const authService = useAuthService()
  const userService = useUserService()
  const tenant = useContext(TenantContext)
  const tracker = useContext(TrackerContext)

  const {
    mutate: login,
    reset: resetLogin,
    isSuccess: isLoginSuccess,
    isError: isLoginError,
  } = useMutation(authService.login, {
    onSuccess: async data => {
      localStorage.setItem('bearer', data.token)
      await setSession(`${location.protocol}//register.${DOCUMENT_DOMAIN}`, data.token)
      queryClient.invalidateQueries(userService.fetchCurrent.queryKeys)
    },
    retry: false,
  })

  const {
    isError: isFetchUserError,
    isSuccess: isFetchUserSuccess,
    isLoading: isFetchingUser,
    fetchStatus: fetchUserStatus,
    remove,
  } = useQuery(userService.fetchCurrent.queryKeys, userService.fetchCurrent, {
    enabled: isLoginSuccess,
    onSuccess: currentUser => {
      if (tenant) {
        document.cookie = `brand=${tenant}; domain=${COOKIE_DOMAIN}; path=/`
      }

      tracker.send('user_login_success')

      const redirectAfterLogin = sessionStorage.getItem('redirectAfterLogin')

      if (redirectAfterLogin) {
        sessionStorage.removeItem('redirectAfterLogin')
        history.push(redirectAfterLogin)
      } else {
        history.push(usersUtils.isMCZRUser(currentUser) ? '/brands' : '/products')
      }
    },
    retry: false,
  })

  const formik = useFormik<LoginFormValues>({
    initialValues: { email: '', password: '', ...initialValues },
    onSubmit: (values, { setSubmitting }) => login(values, { onSettled: () => setSubmitting(false) }),
    validationSchema: formValidation,
    validateOnMount: true,
  })

  return (
    <AuthFormContainer>
      <AuthFormTitle>Log in</AuthFormTitle>
      {(isLoginError || (isLoginSuccess && isFetchUserError)) && (
        <Alert
          variant="error"
          className="mb-8"
          onDismiss={() => {
            resetLogin()
            remove()
          }}
        >
          <Alert.Title>Incorrect username or password</Alert.Title>
        </Alert>
      )}
      <form onSubmit={formik.handleSubmit} noValidate>
        <InputField className="mb-6">
          <Label htmlFor="email">Email</Label>
          <Input
            id="email"
            name="email"
            type="email"
            autoComplete="true"
            placeholder="email@example.com"
            value={formik.values.email}
            onChange={formik.handleChange}
            hasError={formik.touched.email && formik.errors.email != null}
          />
          {formik.touched.email && formik.errors.email != null && (
            <HelperText hasError>{formik.errors.email}</HelperText>
          )}
        </InputField>

        <InputField className="mb-6">
          <Label htmlFor="password">Password</Label>
          <PasswordInput
            id="password"
            name="password"
            placeholder="Enter password"
            value={formik.values.password}
            onChange={formik.handleChange}
            hasError={formik.touched.password && formik.errors.password != null}
          />
          {formik.touched.password && formik.errors.password != null && (
            <HelperText hasError>{formik.errors.password}</HelperText>
          )}
        </InputField>

        <PublicFormFooter>
          <Button
            id="submit-button"
            isLoading={
              formik.isSubmitting ||
              (isFetchingUser && fetchUserStatus !== 'idle') ||
              (isLoginSuccess && isFetchUserSuccess)
            }
            variant="primary"
            type="submit"
            className="w-full mb-4 lg:w-fit lg:mb-0"
          >
            Log in
          </Button>

          <AuthLink to="/login/forgot">Forgot password?</AuthLink>
        </PublicFormFooter>
      </form>
    </AuthFormContainer>
  )
}

export default LoginForm
