import { useContext } from 'react'

import { apiClient, createQuery } from 'common/api'
import { TenantContext } from 'common/tenant'
import { Plugin } from 'plugins/types'

export type PluginCreateParams = Omit<Plugin, 'id' | 'installDate'>

const usePluginService = () => {
  const tenant = useContext(TenantContext)

  return {
    fetchAll: createQuery([tenant, 'plugins'], async () => {
      const { data } = await apiClient.get<Plugin[]>(`/brands/${tenant}/plugins`)
      return data
    }),
    fetch: createQuery([tenant, 'plugin'], async (id: string) => {
      const { data } = await apiClient.get<Plugin>(`/brands/${tenant}/plugins/${id}`)
      return data
    }),
    create: async (plugin: PluginCreateParams) => {
      const { data } = await apiClient.post<Plugin>(`/brands/${tenant}/plugins`, plugin)
      return data
    },
    update: async (id: string, plugin: Partial<Plugin>) => {
      const { data } = await apiClient.post<Plugin>(`/brands/${tenant}/plugins/${id}`, plugin)
      return data
    },
    delete: (id: string) => {
      return apiClient.delete<void, void>(`/brands/${tenant}/plugins/${id}`)
    },
  }
}

export default usePluginService
