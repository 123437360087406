import { CustomizerProduct, StartingPoint, DenormalizedProduct, Product } from '@packages/types'
import { Operation } from 'fast-json-patch'
import { useContext } from 'react'

import { apiClient, createQuery } from 'common/api'
import { TenantContext } from 'common/tenant'
import { Variant } from 'common/variants/types'

export type ProductFetchAllOptions = {
  params?: Partial<{
    limit: number
    lastIndex: number
    sortKey: string | null
    sortOrder: number | null
    archived: boolean
    deleted: boolean
    complexity: string[]
    search: string | null
    fields: string[]
    name: string | string[]
  }>
}

export type ProductFetchAllResponse = {
  pagination: {
    collectionSize: number
    lastIndex: number
    resultSize: number
    sortKey: 'createdAt'
    sortOrder: 'descending'
  }
  results: Product[] | DenormalizedProduct[]
}

export type ProductFetchOptions = { params?: { fields?: string[] } }

export type ProductAutoConnectResponse = {
  product: Product
  status: string
}

export type ProductExport = {
  product: Product
  translations?: {
    translationId: string
    translation: Record<string, { key: string; from: string; text: string }>
  }[]
  startingPoints?: StartingPoint[]
  variants?: Variant[]
}

const useProductService = () => {
  const tenant = useContext(TenantContext)

  return {
    fetchAll: createQuery([tenant, 'products'], async (options?: ProductFetchAllOptions) => {
      const { data } = await apiClient.get<ProductFetchAllResponse>(`/brands/${tenant}/products`, options)
      return data
    }),
    fetch: createQuery([tenant, 'product'], async (productId: string, options?: ProductFetchOptions) => {
      const { data } = await apiClient.get<DenormalizedProduct | Product>(
        `/brands/${tenant}/products/${productId}`,
        options
      )
      return data
    }),
    create: async () => {
      const { data } = await apiClient.post<Product>(`/brands/${tenant}/products`)
      return data
    },
    update: async (productId: string, body: Partial<Product>) => {
      const { data } = await apiClient.post<Product>(`/brands/${tenant}/products/${productId}`, body)
      return data
    },
    duplicate: async (productId: string) => {
      const { data } = await apiClient.post<Product>(`/brands/${tenant}/products/${productId}/duplicate`)
      return data
    },
    publish: async (productId: string) => {
      const { data } = await apiClient.post<DenormalizedProduct>(`/brands/${tenant}/products/${productId}/publish/2d`)
      return data
    },
    discard: async (productId: string) => {
      const { data } = await apiClient.post<Product>(`/brands/${tenant}/products/${productId}/discard`)
      return data
    },
    autoConnect: async (productId: string) => {
      const { data } = await apiClient.post<ProductAutoConnectResponse>(
        `/brands/${tenant}/products/${productId}/auto-connect`
      )
      return data
    },
    count: createQuery([tenant, 'products', 'count'], async (params: Partial<Product>) => {
      const { data } = await apiClient.get<{ count: number }>(`/brands/${tenant}/products/count`, { params })
      return data
    }),
    createDraft: async (productId: string, draft: CustomizerProduct) => {
      const { data } = await apiClient.post<{ productName: string; customizerProduct: CustomizerProduct }>(
        `/brands/${tenant}/products/${productId}/draft`,
        draft
      )
      return data
    },
    updateDraft: async (productId: string, draft: CustomizerProduct) => {
      const { data } = await apiClient.put<{ productName: string; customizerProduct: CustomizerProduct }>(
        `/brands/${tenant}/products/${productId}/draft`,
        draft
      )
      return data
    },
    updateVariants: async (productId: string) => {
      const { data } = await apiClient.post<{
        exceededMaxVariantsCount: boolean
        hasNewVariants: boolean
      }>(`/brands/${tenant}/products/${productId}/update-variants`)
      return data
    },
    patchDraft: async (productId: string, patches: Operation[]) => {
      return apiClient.patch(`/brands/${tenant}/products/${productId}/draft`, patches)
    },
    restoreVersion: async (productId: string, versionId: string) => {
      const { data } = await apiClient.post<Product>(
        `/brands/${tenant}/products/${productId}/versions/${versionId}/restore`
      )
      return data
    },
    export: async (productId: string, options?: ProductFetchOptions) => {
      const { data } = await apiClient.get<ProductExport>(`/brands/${tenant}/products/${productId}/export`, options)
      return data
    },
    import: async (body: ProductExport) => {
      const { data } = await apiClient.post<Product>(`/brands/${tenant}/products/import`, body)
      return data
    },
  }
}

export default useProductService
