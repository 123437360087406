import classNames from 'classnames'
import React from 'react'

import { IconButton, NumberInput, usePopover } from 'common/components'
import ResetIcon from 'icons/core-solid/interface-essential/interface-arrows-round-left.svg'
import SettingSlider from 'icons/custom/adjust.svg'

import StockAdjustPopover from './StockAdjustPopover'

export interface StockCellProps {
  variantId: string
  initialValue: number | null
  value: number | null
  onChange: (stock: number | null) => void
  className?: string
  inputClassName?: string
  sharedCount: number
}

const StockCell = ({
  variantId,
  initialValue,
  value,
  onChange,
  className,
  inputClassName,
  sharedCount,
}: StockCellProps) => {
  const stockPopover = usePopover({ placement: 'bottom', useArrow: true })

  const isDirty = value !== undefined && value !== initialValue

  return (
    <span className="group/stock relative">
      {sharedCount > 1 && (
        <div className="absolute h-[41px] rounded ring-primary-100 group-focus-within/stock:ring group-focus-within/stock:z-[8] pointer-events-none w-full"></div>
      )}
      <NumberInput
        small
        showArrows={false}
        className={classNames(className, {
          'group-focus-within/stock:rounded-b-none group-focus-within/stock:border-b-neutral-100 ring-0':
            sharedCount > 1,
        })}
        inputClassName={classNames('text-right', inputClassName, {
          'placeholder-primary-500 text-primary-500': isDirty,
        })}
        aria-label="stock"
        id={variantId}
        placeholder="N/A"
        value={value === null ? '' : value}
        onValueChange={value => onChange(value ?? null)}
        autoComplete="off"
        leftAddon={
          <IconButton
            small
            Icon={SettingSlider}
            iconClassName="!w-5 !fill-neutral-500"
            aria-label="Adjust stock"
            variant="text"
            className={classNames('opacity-0 group-hover/stock:opacity-100', {
              'opacity-100': isDirty || stockPopover.isOpen,
            })}
            {...stockPopover.referenceProps}
          />
        }
      />
      {sharedCount > 1 && (
        <div className="hidden absolute bg-white group-focus-within/stock:block w-full rounded rounded-t-none border-t-0 border-primary-200 border z-[8]">
          <div className="text-[10px] py-[2px] px-1 w-full text-neutral-600 ">
            <span className="text-primary-500">{sharedCount}</span> variant(s)
          </div>
        </div>
      )}

      <StockAdjustPopover
        popover={stockPopover}
        initialValue={initialValue === null ? undefined : initialValue}
        value={value}
        onChange={onChange}
      >
        <div className="text-neutral-500 w-[160px] text-xs flex justify-between items-center">
          <span>Original stock: {initialValue ?? 'N/A'}</span>
          <IconButton small Icon={ResetIcon} onClick={() => onChange(initialValue as number)} disabled={!isDirty} />
        </div>
      </StockAdjustPopover>
    </span>
  )
}

export default StockCell
