import { Formik, FormikHelpers } from 'formik'
import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { ArrayParam, StringParam, withDefault } from 'use-query-params'

import { Header, SideMenu, Page } from 'cms/layout'
import { BetaTag, NumberFilter } from 'common/components'
import { useDataTable, useDataTableQuery, useTableFilters, useTableSearch, useTableSort } from 'common/hooks/dataTable'
import LoadingIcon from 'icons/custom/builder-loading.svg'

import { EmptyBlankState } from './blankStates'
import { InventoryTable } from './table'
import { InventoryPersistence, InventoryFormValues } from './types'
import useInventory from './useInventory'
import { formValuesToMutationInput, getHasNoAppliedFilters } from './utils'

const Inventory = ({ history, match }: RouteComponentProps) => {
  const persistence = useDataTableQuery<InventoryPersistence['state']>(
    {
      combinations: [],
      stock: undefined,
      filter: '',
      sortKey: undefined,
      sortOrder: undefined,
    },
    {
      filter: withDefault(StringParam, ''),
      sortKey: StringParam,
      sortOrder: StringParam,
      combinations: withDefault(ArrayParam, []),
      //@ts-expect-error we need to return object from encode
      stock: withDefault(NumberFilter.URLParam, undefined),
      //@ts-expect-error we need to return object from encode
      committed: withDefault(NumberFilter.URLParam, undefined),
      //@ts-expect-error we need to return object from encode
      available: withDefault(NumberFilter.URLParam, undefined),
      status: withDefault(ArrayParam, []),
      various: withDefault(ArrayParam, []),
    }
  )

  const dataTable = useDataTable(persistence, [
    useTableSearch({}),
    useTableSort({}),
    useTableFilters<InventoryPersistence['state']>({}),
  ])

  const {
    location,
    variants,
    inventoryItems,
    groups,
    variantIdsByGroup,
    isFetchingInventory,
    isLoadingInventory,
    fetchNextPage,
    updateInventoryLevels,
    getVariantsInput,
  } = useInventory(persistence.state)

  const handleSubmit = (
    values: InventoryFormValues,
    { setSubmitting, setStatus }: FormikHelpers<InventoryFormValues>
  ) => {
    if (location == null) throw new Error('No inventory location')

    setStatus('submitted')

    updateInventoryLevels(formValuesToMutationInput(location.id, values, inventoryItems), {
      onSettled: () => {
        setSubmitting(false)
      },
    })
  }

  const isFullyEmpty = variants.length === 0 && getHasNoAppliedFilters(dataTable)

  return (
    <main>
      <Header />
      <SideMenu />
      <Page>
        <div className="flex justify-between pb-5">
          <h1 role="heading">
            Inventory
            <BetaTag className="ml-2" />
          </h1>
        </div>
        {isLoadingInventory && (
          <div className="h-80 flex flex-col items-center justify-center">
            <LoadingIcon className="fill-neutral-700 w-6" aria-label="Loading variants" />
          </div>
        )}
        {!isLoadingInventory && isFullyEmpty && (
          <EmptyBlankState onGoToProductsClick={() => history.push(match.url.replace('inventory', 'products'))} />
        )}
        {!isLoadingInventory && !isFullyEmpty && (
          <Formik initialValues={inventoryItems} onSubmit={handleSubmit}>
            <InventoryTable
              locationId={location!.id}
              variants={variants}
              dataTable={dataTable}
              isFetching={isFetchingInventory}
              loadMore={fetchNextPage}
              combinationFiltersData={groups}
              variantIdsByCombination={variantIdsByGroup}
              getVariantsInput={getVariantsInput}
            />
          </Formik>
        )}
      </Page>
    </main>
  )
}

export default Inventory
