import { Part } from '@packages/types'
import React from 'react'

import {
  selectors as customizerSelectors,
  useCustomizerSelector,
  useCustomizerDispatch,
} from 'builder/build/customizer'
import { actions as partsActions } from 'builder/build/parts'
import { selectors as printAreasSelectors } from 'builder/build/printAreas'
import { useDispatch, useSelector } from 'cms/hooks'
import { Tooltip, IconButton } from 'common/components'
import { twoDDisplayerActions } from 'customizer/2dDisplayer'
import AddGridIcon from 'icons/core-solid/interface-essential/interface-edit-grid.svg'
import CenterIcon from 'icons/core-solid/interface-essential/interface-layout-border-center.svg'

interface ExtraSettingsProps {
  part: Part
  visibility?: boolean
}

const ExtraSettings = ({ part, visibility }: ExtraSettingsProps) => {
  const dispatch = useDispatch()
  const customizerDispatch = useCustomizerDispatch()
  const currentView = useCustomizerSelector(customizerSelectors.currentViewSelector) ?? 0
  const isGridDisplayed = useCustomizerSelector(state => state.displayer.displayGrid)
  const printAreas = useSelector(printAreasSelectors.printAreasSelector)
  const printArea = part.printArea ? printAreas[part.printArea] : null

  return (
    <>
      <div className="flex">
        <Tooltip content={isGridDisplayed ? 'Hide grid' : 'Show grid'}>
          <IconButton
            aria-label={isGridDisplayed ? 'Hide grid' : 'Show grid'}
            variant={isGridDisplayed ? 'toggle-selected' : 'toggle'}
            onClick={() => customizerDispatch(twoDDisplayerActions.displayGrid(!isGridDisplayed))}
            disabled={!visibility}
            Icon={AddGridIcon}
          />
        </Tooltip>

        {printArea && (
          <Tooltip content="Center in print area">
            <IconButton
              aria-label="Center in print area"
              variant="subtle"
              onClick={() => dispatch(partsActions.centerPartPrintAreaPosition(part, printArea))}
              disabled={printArea?.productPreview.designView !== currentView}
              Icon={CenterIcon}
            />
          </Tooltip>
        )}
      </div>
    </>
  )
}

export default ExtraSettings
