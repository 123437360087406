import { PrintArea } from '@packages/types'
import React from 'react'

import { selectors as customizerSelectors, useCustomizerSelector } from 'builder/build/customizer'
import { actions as printAreasActions } from 'builder/build/printAreas'
import { useDispatch } from 'cms/hooks'
import { Checkbox, Tooltip } from 'common/components'

export interface PrintAreaVisibilitySettingProps {
  printArea: PrintArea
}

const PrintAreaVisibilitySetting = ({ printArea }: PrintAreaVisibilitySettingProps) => {
  const dispatch = useDispatch()
  const currentView = (useCustomizerSelector(customizerSelectors.currentViewSelector) as number) ?? 0

  const printAreaVisibility = !printArea?.productPreview.views[currentView].hidden

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    dispatch(
      printAreasActions.patchPrintArea(printArea, {
        productPreview: {
          views: {
            [currentView]: { hidden: !e.target.checked },
          },
        },
      })
    )

  return (
    <>
      <Tooltip content={`Show on view ${currentView + 1}`}>
        <Checkbox className="mr-2" id="show-on-view" checked={printAreaVisibility} onChange={handleChange} />
      </Tooltip>
      <div className="h-8 w-[1px] bg-neutral-75 ml-1 mr-1" />
    </>
  )
}

export default PrintAreaVisibilitySetting
