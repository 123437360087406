import { selectors as answersSelectors } from 'builder/build/answers'
import { useCustomizerSelector } from 'builder/build/customizer'
import { useSelector } from 'cms/hooks'
import { selectors as customizationSelectors } from 'customizer/customization'

const useSelectPartAnswer = (entityId?: string) => {
  const answer = useSelector(state => answersSelectors.answerByIdSelector(state, { id: entityId }))
  const customizerSelectedAnswerId = useCustomizerSelector(state =>
    customizationSelectors.selectedAnswerIdSelector(state, entityId)
  )
  const customizerSelectedAnswer = useSelector(state =>
    answersSelectors.answerByIdSelector(state, { id: customizerSelectedAnswerId })
  )

  return [answer, customizerSelectedAnswer]
}

export default useSelectPartAnswer
