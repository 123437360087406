import countryLanguages from '@packages/data/countryLanguages.json'
import { ThemeName } from '@packages/types'
import React from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { Link } from 'react-router-dom'

import { themeUtils } from 'cms/theme'
import { Translation } from 'cms/translations/types'
import { BlankState, IconButton, Table, Tooltip } from 'common/components'
import EyeIcon from 'icons/core-solid/interface-essential/interface-edit-view.svg'
import TranslateIcon from 'icons/regular/01-Interface Essential/35-Text-Options/translate.svg'

import { getNumberOfFilledTexts } from '../utils'

interface TranslationsTableProps {
  translations: Translation[]
  activeTheme: ThemeName
  isLoading?: boolean
  isFetching?: boolean
  onAddTranslationClick: () => void
}

const TranslationsTable = ({
  translations,
  activeTheme,
  isLoading,
  isFetching,
  onAddTranslationClick,
}: TranslationsTableProps) => {
  const history = useHistory()
  const match = useRouteMatch<{ brandName?: string }>()
  const baseUrl = match.params.brandName ? `/brands/${match.params.brandName}` : ''
  const { texts } = themeUtils.getThemeConfig(activeTheme)
  const numberOfThemeTexts = Object.keys(texts).length
  return (
    <Table>
      <Table.Header>
        <Table.HeaderRow>
          <Table.HeaderCell className="w-2/12">
            <span>Code</span>
          </Table.HeaderCell>
          <Table.HeaderCell className="w-4/12">
            <span>Language</span>
          </Table.HeaderCell>
          <Table.HeaderCell className="w-4/12">
            <span>Theme</span>
          </Table.HeaderCell>
          <Table.HeaderCell className="w-2/12" />
        </Table.HeaderRow>
      </Table.Header>

      {isLoading && <Table.BodyLoader numberOfColumns={5} numberOfRows={5} />}

      {!isLoading && (
        <Table.Body>
          {isFetching && <Table.Loader colSpan={5} />}
          {translations.map(translation => {
            const language = countryLanguages.find(({ iso639_1 }) => iso639_1 === translation.code)
            const numberOfFilledThemeTexts = getNumberOfFilledTexts(texts, translation.static[activeTheme])

            return (
              <Table.Row
                key={translation.code}
                className="cursor-pointer"
                onClick={() => history.push(`${match.url}/${translation.code}`)}
              >
                <Table.Cell>{translation.code.toUpperCase()}</Table.Cell>
                <Table.Cell>{`${language?.name} / ${language?.nativeName}`}</Table.Cell>
                <Table.Cell aria-label="theme translation count">{`${numberOfFilledThemeTexts}/${numberOfThemeTexts}`}</Table.Cell>
                <Table.Cell>
                  <div className="flex justify-end">
                    <Tooltip content="Preview" containerClassName="flex">
                      <Link to={`${baseUrl}/preview?lang=${translation.code}`} target="_blank">
                        <IconButton
                          variant="subtle"
                          Icon={EyeIcon}
                          onClick={e => e.stopPropagation()}
                          aria-label="Preview"
                        />
                      </Link>
                    </Tooltip>
                  </div>
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      )}

      {!isLoading && translations.length === 0 && (
        <Table.Body>
          <Table.Row className="hover:bg-white border-b-0">
            <Table.Cell colSpan={5}>
              <div className="flex items-center justify-center py-32">
                <BlankState>
                  <BlankState.Icon Icon={TranslateIcon} />
                  <BlankState.Title>There are no translations, yet</BlankState.Title>
                  <BlankState.Details>
                    Translate your customizers to make them available in the languages your customers speak.
                  </BlankState.Details>
                  <BlankState.Action onClick={onAddTranslationClick}>Add translation</BlankState.Action>
                </BlankState>
              </div>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      )}
    </Table>
  )
}

export default TranslationsTable
