import { LogoPart, LogoQuestion } from '@packages/types'
import React, { Suspense, useState } from 'react'

import { Panel, MaskSection } from 'builder/build/common/components'
import { useThemeSection } from 'builder/build/core'
import { hooks as customizerHooks } from 'builder/build/customizer'
import { actions as partsActions } from 'builder/build/parts'
import { DisplaySettingsSection } from 'builder/build/parts/components'
import { useDispatch, useSelector } from 'cms/hooks'
import { twoDDisplayerSelectors } from 'customizer/2dDisplayer'

import { useIsClosing } from '../hooks'
import { linkedQuestionsSelector } from '../selectors'
import {
  QuestionSettingsSection,
  DescriptionSection,
  AnswerListSection,
  SwitchViewSection,
  OrderSheetSettingsSection,
  BlendingOptionSection,
  FiltersSection,
  PrintAreaSection,
  LinkedQuestionSection,
  DisplayTypeSection,
  TitleSection,
  HeaderSection,
} from './sections'

interface LogoQuestionPanelProps {
  part: LogoPart
  question: LogoQuestion
}

const LogoQuestionPanel = ({ part, question }: LogoQuestionPanelProps) => {
  const dispatch = useDispatch()
  const [tab, setTab] = useState<'question' | 'settings'>('question')
  const isClosing = useIsClosing(question.id)
  const isEditing = customizerHooks.useCustomizerSelector(twoDDisplayerSelectors.isEditingSelector)
  const isMainAndLinkedQuestion = useSelector(state => linkedQuestionsSelector(state, question.id)).length > 0
  const ThemeSettingsSection = useThemeSection('settings')
  const InputSection = useThemeSection('input')

  const handleMaskApply = (maskIds: string[]) => dispatch(partsActions.setMask(part.id, maskIds))

  return (
    <Panel side="right">
      <HeaderSection selectedTab={tab} setTab={setTab} question={question} showPrintTab />
      <div className="panel__content">
        {tab === 'question' && (
          <>
            <TitleSection question={question} />
            <hr className="panel__divider" />
            <AnswerListSection question={question} />
            <hr className="panel__divider" />
            {InputSection && (
              <Suspense fallback={null}>
                <InputSection question={question} />
              </Suspense>
            )}
            <hr className="panel__divider" />
            <DisplayTypeSection
              question={question}
              settings={
                <>
                  <DisplaySettingsSection part={part} />
                  <hr className="panel__divider" />
                  <FiltersSection part={part} isDisabled={isEditing} />
                  <hr className="panel__divider" />
                  {!part.printArea && (
                    <>
                      <div className="panel__section">
                        <MaskSection
                          partId={part.id}
                          onApply={handleMaskApply}
                          entityMasks={part.modifiers?.masks || []}
                          isDisabled={isEditing}
                          maskMessage={`Your logo will be masked if it goes \nout of the selected layers.`}
                        />
                      </div>
                      <hr className="panel__divider" />
                    </>
                  )}
                  <BlendingOptionSection
                    part={part}
                    isDisabled={isEditing}
                    multiplyMessage="This blends the logo with the image under it."
                  />
                </>
              }
            >
              <PrintAreaSection part={part} question={question} isClosing={isClosing} />
            </DisplayTypeSection>
          </>
        )}
        {tab === 'settings' && (
          <>
            <DescriptionSection question={question} />

            <hr className="panel__divider" />
            {ThemeSettingsSection && (
              <>
                <Suspense fallback={null}>
                  <ThemeSettingsSection step={question} />
                </Suspense>
                <hr className="panel__divider" />
              </>
            )}
            <LinkedQuestionSection
              disabled={isMainAndLinkedQuestion}
              question={question}
              linkedQuestionMessage="Link this question to another question so both have the same answer. The main question's answer will be displayed."
            />
            <hr className="panel__divider" />
            <SwitchViewSection entity={question} />
            <hr className="panel__divider" />
            <QuestionSettingsSection question={question} />
            <hr className="panel__divider" />
            <OrderSheetSettingsSection question={question} />
          </>
        )}
      </div>
    </Panel>
  )
}

export default LogoQuestionPanel
