import React from 'react'

import { Price, Card } from 'common/components'

interface OrderCardFooterProps {
  totalPrice: number
  currency: string
  isLoading: boolean
}

const OrderCardFooter = ({ totalPrice, currency, isLoading }: OrderCardFooterProps) => {
  return (
    <Card.Section className="justify-end print:hidden">
      <div className="max-w-fit flex flex-col pl-20 text-right border-l border-neutral-100">
        <span className="text-xs font-medium text-neutral-600 mb-4">Order Total</span>
        {isLoading && <div className="animate-pulse h-6 bg-neutral-75 rounded max-w-xs w-full" />}
        {!isLoading && <Price amount={totalPrice} currency={currency} />}
      </div>
    </Card.Section>
  )
}

export default OrderCardFooter
