import { Asset, FontType } from '@packages/types'
import { GroupBase } from 'react-select'
import { LoadOptions } from 'react-select-async-paginate'

import { FontService } from 'utils/loaders'

import { Font, FontOption } from '../types'
import { formatFontOptions } from '../utils'

const FONT_LIMIT = 30

const useFontOptionsAsync = (googleFonts: Font[], customFonts?: Asset[], fontType?: FontType) => {
  const options = formatFontOptions(googleFonts, customFonts)

  const loadOptions: LoadOptions<FontOption, GroupBase<FontOption>, { page: number }> = async (
    searchQuery,
    loadedOptions,
    additional
  ) => {
    let fontsOptions = options

    if (fontType) {
      fontsOptions = fontsOptions.filter(font => font.type === fontType)
    }

    if (searchQuery) {
      fontsOptions = fontsOptions.filter(font => font.label.toLowerCase().includes(searchQuery.toLowerCase()))
    }

    const start = loadedOptions.length ?? 0
    fontsOptions = fontsOptions.slice(start, start + FONT_LIMIT)

    fontsOptions.forEach(font => {
      FontService.loadFont({ ...font, fontFamily: font.value, preview: font.value })
    })

    const page = additional?.page || 0

    return {
      options: fontsOptions,
      hasMore: fontsOptions.length >= 1,
      additional: {
        page: searchQuery ? 2 : page + 1,
      },
    }
  }

  return { loadOptions, options }
}

export default useFontOptionsAsync
