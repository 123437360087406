import { CustomizationDesign, LogoAnswer, AnswerType } from '@packages/types'
import JSZip from 'jszip'
import { compact } from 'lodash'

import { useAssetService } from 'common/assets'
import { useCustomizerProductService } from 'common/customizerProducts'
import { twoDDisplayerActions } from 'customizer/2dDisplayer'
import { reducer as customizationReducer, actionTypes as customizationActionTypes } from 'customizer/customization'
import { RootState } from 'customizer/store'
import downloadContent from 'utils/downloadContent'
import imageToDataURL from 'utils/imageToDataURL'
import imageLoader from 'utils/loaders/ImageLoader'

import useDesignService from './useDesignService'

interface UseDesignFilesProps {
  design?: CustomizationDesign
}

const useDesignFiles = ({ design }: UseDesignFilesProps) => {
  const assetService = useAssetService()
  const designService = useDesignService()
  const customizerProductService = useCustomizerProductService()

  const downloadPrintFiles = (zip: JSZip) => {
    if (design && design.printFiles?.length > 0) {
      const printFolder = zip.folder('Print files')!
      design?.printFiles.forEach(url => printFolder.file(url.split('/').pop()!, assetService.fetchPublic(url)))
    }
  }

  const generateAllDesignImages = async ({
    id,
    customizerProductId,
    configuration,
    personalisations,
  }: CustomizationDesign) => {
    const customizerProduct = await customizerProductService.fetch(customizerProductId)

    const customizationState = {
      customization: customizationReducer(undefined, {
        type: customizationActionTypes.START_DESIGN_CUSTOMIZATION,
        payload: { customizerProduct, configuration, personalisations },
      }),
      displayer: {},
    } as RootState

    const images = await Promise.all(
      new Array(customizerProduct.views)
        .fill(null)
        .map((_, i) => twoDDisplayerActions.generateProductImage(i)(undefined, () => customizationState))
    )

    await designService.saveDesignImages(id, images)

    return images
  }

  const downloadViews = async (zip: JSZip) => {
    if (!design) return

    let designImagesDataURLs: string[] = []

    const imgFolder = zip.folder('Views')
    if (design.designImages.length === 0) {
      designImagesDataURLs = await generateAllDesignImages(design)
    } else {
      for (const designImage of design.designImages) {
        const image = await imageLoader.loadImage(designImage.url, { preventResize: true })
        designImagesDataURLs.push(imageToDataURL(image))
      }
    }

    designImagesDataURLs.forEach((viewImageDataURL, i) => {
      const imageNumber = i + 1
      const imageData = viewImageDataURL.split(',')[1]

      imgFolder!.file(`view_${imageNumber}.png`, imageData, { base64: true })
    })
  }

  const downloadOriginalAssets = (zip: JSZip) => {
    if (!design) return

    const personalisationsAssets = design.personalisations ? Object.values(design.personalisations) : []
    const logoAnswersAssets = personalisationsAssets.filter(({ type }) => type === AnswerType.Logo) as LogoAnswer[]
    const logoAssets = logoAnswersAssets.map(answer => answer.views?.[0]?.logo)

    const compactPersonalisationsAssets = compact(logoAssets)

    if (compactPersonalisationsAssets.length > 0) {
      const originalAssetsFolder = zip.folder('Original Assets')

      compactPersonalisationsAssets.forEach(({ url, filename }) =>
        originalAssetsFolder!.file(filename, assetService.fetchPublic(url))
      )
    }
  }

  const download = async () => {
    if (!design) return

    const designFilesZip = new JSZip()

    downloadPrintFiles(designFilesZip)
    await downloadViews(designFilesZip)
    downloadOriginalAssets(designFilesZip)

    const content = await designFilesZip.generateAsync({ type: 'blob' })

    return downloadContent(content, 'application/octet-stream', `design-${design?.designId}-files.zip`)
  }

  return { download, generateAllDesignImages }
}

export default useDesignFiles
