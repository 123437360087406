import classNames from 'classnames'
import React, { useEffect } from 'react'

import { ResizablePanel, PanelHeaderSection, IconTab } from 'builder/build/common/components'
import { selectors as groupsSelectors } from 'builder/build/groups'
import { NewQuestionMenu } from 'builder/build/groups/components'
import { LayersOrderingPanel } from 'builder/build/layers/components'
import { ProductSettingsPanel, QuestionTreePanel } from 'builder/build/leftPanel/components'
import { actions as navigationActions, selectors as navigationSelectors } from 'builder/build/navigation'
import { LeftPanel } from 'builder/build/navigation/types'
import { useDispatch, useSelector } from 'cms/hooks'
import { Tooltip, IconButton, usePopover, Popover } from 'common/components'
import AddIcon from 'icons/bold/01-Interface Essential/43-Remove-Add/add.svg'
import ReorderDownIcon from 'icons/core-solid/interface-essential/interface-align-layers-1.svg'
import SettingsIcon from 'icons/core-solid/interface-essential/interface-setting-cog.svg'
import ListIcon from 'icons/core-solid/interface-essential/interface-setting-menu-1.svg'

const BuilderLeftPanel = () => {
  const dispatch = useDispatch()
  const { tab, options } = useSelector(navigationSelectors.leftPanelSelector)
  const dropdownButton = usePopover({ placement: 'right-start', offsetConfig: 8 })
  const rootId = useSelector(groupsSelectors.rootGroupIdSelector)

  const setTabAndCloseMenu = (tab: LeftPanel) => {
    dropdownButton.close()
    dispatch(navigationActions.setLeftPanel(tab))
  }

  useEffect(() => {
    if (options.initialFocusInputName) {
      document.querySelector<HTMLInputElement>(`[name='${options.initialFocusInputName}']`)?.focus()
    }
  }, [options.initialFocusInputName])

  return (
    <>
      <ResizablePanel side="left" defaultWidth={252} minWidth={252} maxWidth={380} onDragEnd={undefined}>
        <div className="flex flex-col h-full">
          <PanelHeaderSection>
            <div className="flex space-x-3 mr-auto">
              <Tooltip content="Questions">
                <IconTab
                  isSelected={tab === LeftPanel.questions}
                  onClick={() => setTabAndCloseMenu(LeftPanel.questions)}
                >
                  <ListIcon aria-label="Questions" />
                </IconTab>
              </Tooltip>
              <Tooltip content="Layers">
                <IconTab isSelected={tab === LeftPanel.layers} onClick={() => setTabAndCloseMenu(LeftPanel.layers)}>
                  <ReorderDownIcon aria-label="Layers" />
                </IconTab>
              </Tooltip>
              <Tooltip content="Settings">
                <IconTab isSelected={tab === LeftPanel.settings} onClick={() => setTabAndCloseMenu(LeftPanel.settings)}>
                  <SettingsIcon aria-label="Settings" />
                </IconTab>
              </Tooltip>
            </div>
            {tab === 'questions' && (
              <IconButton
                aria-label="Add question"
                Icon={AddIcon}
                iconClassName={classNames('transition-transform', {
                  'rotate-45': dropdownButton.isOpen,
                })}
                smallIcon
                variant="primary"
                {...dropdownButton.referenceProps}
              />
            )}
            <Popover {...dropdownButton.floatingProps} isOpen={dropdownButton.isOpen}>
              <NewQuestionMenu onClose={dropdownButton.close} groupId={rootId} gifPlacement="right" />
            </Popover>
          </PanelHeaderSection>
          {tab === 'questions' && <QuestionTreePanel onCreateQuestionClick={dropdownButton.open} />}
          {tab === 'layers' && (
            <LayersOrderingPanel onGoToQuestionsClick={() => setTabAndCloseMenu(LeftPanel.questions)} />
          )}
          {tab === 'settings' && <ProductSettingsPanel />}
        </div>
      </ResizablePanel>
    </>
  )
}

export default BuilderLeftPanel
