import React from 'react'

import { useDispatch } from 'cms/hooks'
import { PathTransformer } from 'common/components'
import { TextOnPathNode } from 'customizer/2dDisplayer/types'

import { applyBezierTextEdition, applyPrintAreaBezierTextEdition } from '../../actions'
import TargetClipContainer from '../containers/TargetClipContainer'
import SVGPathText from '../nodes/SVGPathText'
import NodeInfo from './NodeInfo'
import { EditorProps } from './types'
import usePathEditor from './usePathEditor'

const PathTextEditor = ({ node, entity, currentView }: EditorProps<TextOnPathNode>) => {
  const { bezier, scale, getTransformedBezier, pathRef, onTransform } = usePathEditor({
    bezier: node.position.bezier,
    scale: node.scale,
  })

  const dispatch = useDispatch()

  const handleTransformEnd = () => {
    const bezier = getTransformedBezier()
    entity && 'printArea' in entity && entity.printArea
      ? dispatch(applyPrintAreaBezierTextEdition(entity.id, bezier))
      : dispatch(applyBezierTextEdition(entity.id, bezier, currentView))
  }

  return (
    <>
      <TargetClipContainer>
        <SVGPathText
          pathRef={pathRef}
          bezier={bezier}
          fontFamily={node.font.family}
          color={node.color.hex}
          text={node.text.value}
          textAlign={node.position.textAlign as 'left' | 'center' | 'center'}
          outlineColor={node.outline?.hex}
          outlineWidth={node.outline?.width ? parseFloat(node.outline.width) * scale : 0}
          fontSize={parseFloat(node.font.size) * scale}
          fontAssets={node.font.assets}
        />
      </TargetClipContainer>
      <PathTransformer
        target={pathRef}
        onTransform={onTransform}
        onTransformEnd={handleTransformEnd}
        info={<NodeInfo entity={entity} node={node} />}
      />
    </>
  )
}

export default PathTextEditor
