import React from 'react'

import { BlankState } from 'common/components'

export interface EmptyBlankStateProps {
  handleChangeQuestionsClick: (e: React.MouseEvent) => void
}

const EmptyBlankState = ({ handleChangeQuestionsClick }: EmptyBlankStateProps) => {
  return (
    <BlankState className="max-w-sm">
      <BlankState.Title>No variants</BlankState.Title>
      <BlankState.Details>Your question combination doesn't have any answers</BlankState.Details>
      <BlankState.Action onClick={handleChangeQuestionsClick}>Change question combination</BlankState.Action>
    </BlankState>
  )
}

export default EmptyBlankState
