import { Addon, CustomPlan, Plan } from '@packages/types'
import React from 'react'

import { IconButton } from 'common/components'
import LeftArrow from 'icons/bold/52-Arrows-Diagrams/01-Arrows/arrow-left-1.svg'

import { StripeActivationCard } from '../cards'
import TotalCardContent from '../cards/totalCard/TotalCardContent'

export interface StripeActivationPageModalProps {
  plan: Plan | CustomPlan
  addons: Addon[]
  goBack?: () => void
  onSuccess: () => void
}

const StripeActivationPageModal = ({ plan, addons, goBack, onSuccess }: StripeActivationPageModalProps) => {
  return (
    <div className="flex flex-col">
      <div className="flex justify-between items-center pt-4 pb-10">
        <div className="flex items-center">
          {goBack != null && <IconButton Icon={LeftArrow} onClick={goBack} className="mr-3" aria-label="go back" />}
          <h1>Payment</h1>
        </div>
      </div>
      <div className="flex items-center space-x-20">
        <div className="flex flex-col md:flex-col w-[310px] space-y-6">
          <TotalCardContent plan={plan} addons={addons} />
        </div>
        <StripeActivationCard plan={plan} addons={addons} onSuccess={onSuccess} />
      </div>
    </div>
  )
}

export default StripeActivationPageModal
