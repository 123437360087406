import { ValueAnswer } from '@packages/types'
import React from 'react'

import { useDispatch } from 'cms/hooks'

import { patchAnswer } from '../actions'
import { GeneralSettingsSection, TitleSection } from './sections'

interface OptionAnswerPanelProps {
  answer: ValueAnswer
}

export const OptionAnswerPanel = ({ answer }: OptionAnswerPanelProps) => {
  const dispatch = useDispatch()

  const updateAnswer: GeneralSettingsSection['onUpdate'] = (field, value) => {
    dispatch(patchAnswer(answer, { [field]: value }))
  }

  return (
    <div className="panel__content" aria-label="Value answer panel">
      <TitleSection answer={answer} />
      <hr className="panel__divider" />
      <GeneralSettingsSection answer={answer} onUpdate={updateAnswer} />
    </div>
  )
}

export default OptionAnswerPanel
