import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { ArrayParam, DateParam, NumberParam, StringParam, withDefault } from 'use-query-params'

import { Header, SideMenu, Page } from 'cms/layout'
import {
  useDataTable,
  useDataTableQuery,
  useTableDateFilters,
  useTableFilters,
  useTablePagination,
  useTableSearch,
  useTableSort,
} from 'common/hooks/dataTable'

import useDesignService from '../hooks/useDesignService'
import { DesignsFilters, DesignsSortKeys } from '../types/datatable'
import { defaultPersistenceState } from '../utils'
import DesignsFilterList from './DesignsFilterList'
import DesignsTable from './DesignsTable'

const Designs = () => {
  const designService = useDesignService()
  const location = useLocation()
  const history = useHistory()
  const { brandName } = useParams<{ brandName?: string }>()

  const persistence = useDataTableQuery(defaultPersistenceState, {
    startDate: DateParam,
    endDate: DateParam,
    count: withDefault(NumberParam, 25),
    lastIndex: withDefault(NumberParam, 0),
    filter: withDefault(StringParam, ''),
    sortKey: StringParam,
    sortOrder: StringParam,
    products: withDefault(ArrayParam, []),
  })

  const baseUrl = brandName ? `/brands/${brandName}` : ''

  const goToFirstPage = () => persistence.setState(state => ({ ...state, lastIndex: 0 }))

  const dataTable = useDataTable(persistence, [
    useTableSearch({ onChange: goToFirstPage }),
    useTableSort<DesignsSortKeys>({ onChange: goToFirstPage }),
    useTablePagination(),
    useTableFilters<DesignsFilters>({ onChange: goToFirstPage }),
    useTableDateFilters({ onChange: goToFirstPage }),
  ])

  const { data, isLoading, isFetching } = useQuery(
    [...designService.fetchAll.queryKeys, persistence.state],
    () => designService.fetchAll(persistence.state),
    {
      keepPreviousData: true,
      onSuccess: result => {
        dataTable.setCollectionSize(result.pagination.collectionSize)
        dataTable.setResultSize(result.pagination.resultSize)
      },
    }
  )

  const handleRowClick = (id: string) => history.push(`${baseUrl}/designs/${id}`, { persistence: persistence.state })

  return (
    <main>
      <Header />
      <SideMenu />
      <Page>
        <Page.Header>
          <h1>Designs</h1>
        </Page.Header>
        {(!!data?.results.length || location.search) && (
          <DesignsFilterList clearAll={persistence.reset} hasAppliedFilters={!!location.search} {...dataTable} />
        )}
        <DesignsTable
          data={data}
          isLoading={isLoading}
          isFetching={isFetching}
          onClearFilters={persistence.reset}
          hasAppliedFilters={!!location.search}
          onRowClick={handleRowClick}
          {...dataTable}
        />
      </Page>
    </main>
  )
}

export default Designs
