import React from 'react'

import { ModalProps, Modal, Button } from 'common/components'

interface BulkUnsavedChangesModalProps {
  onCancelClick: () => void
  onContinueClick: () => void
  modalProps: Omit<ModalProps, 'children'>
}

const BulkUnsavedChangesModal = ({ onCancelClick, onContinueClick, modalProps }: BulkUnsavedChangesModalProps) => {
  return (
    <Modal {...modalProps} aria-labelledby="unsaved-changes-title">
      <Modal.Title id="unsaved-changes-title">Unsaved changes</Modal.Title>
      <Modal.Details>Your changes will be overriden uppon saving.</Modal.Details>
      <Modal.Actions>
        <Button type="button" variant="default" className="px-4" onClick={onCancelClick}>
          Cancel
        </Button>
        <Button id="create-brand" variant="error" className="px-4" onClick={onContinueClick}>
          Continue
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default BulkUnsavedChangesModal
