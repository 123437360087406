import { reducer as choicePanel } from 'customizer/questionPanel'

import { UPDATE_PREVIEW } from './actionTypes'

const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_PREVIEW:
      if (
        !Object.keys(action.payload.questions).includes(state.currentStep) &&
        !Object.keys(action.payload.groups).includes(state.currentStep)
      )
        return {
          ...state,
          currentStep: action.payload.customizerProduct.tree,
        }

    default:
      return choicePanel(state, action)
  }
}

export default reducer
