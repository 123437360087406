import React from 'react'

import { PatchTextInput } from 'builder/build/common/components'
import { actions as groupsActions, selectors as groupsSelectors } from 'builder/build/groups'
import { useSelector, useDispatch } from 'cms/hooks'
import { InputField, PatchTextInputChangeEvent } from 'common/components'

const ProductSettingsTitleSection = () => {
  const dispatch = useDispatch()
  const groups = useSelector(groupsSelectors.groupsSelector)
  const rootGroupId = useSelector(groupsSelectors.rootGroupIdSelector)
  const rootGroup = groups[rootGroupId]

  const updateName = (e: PatchTextInputChangeEvent) =>
    dispatch(groupsActions.patchGroup(rootGroupId, { name: e.target.value }))

  return (
    <div className="py-6 px-4">
      <InputField>
        <label className="font-medium mb-4">Customizer title</label>
        <PatchTextInput name="customizerTitle" placeholder="Title" value={rootGroup?.name} onChange={updateName} />
      </InputField>
    </div>
  )
}

export default ProductSettingsTitleSection
