import React, { useEffect } from 'react'
import { SingleValueProps, components } from 'react-select'

import { FontService } from 'utils/loaders'

import { FontOption } from '../types'

export type FontMenuSingleValueProps = SingleValueProps<FontOption>

const FontMenuSingleValue = (props: FontMenuSingleValueProps) => {
  const { value, type, asset } = props.data || {}

  useEffect(() => {
    FontService.loadFont({ fontFamily: value, type, asset })
  }, [value, type, asset])

  return <components.SingleValue {...props} />
}

export default FontMenuSingleValue
