import { Product } from '@packages/types'
import { useQuery } from '@tanstack/react-query'
import classNames from 'classnames'
import { Field, FieldProps, FormikHelpers, FormikProvider, useFormik } from 'formik'
import { orderBy } from 'lodash'
import React, { useContext, useRef } from 'react'
import { RouteComponentProps } from 'react-router'
import * as yup from 'yup'

import GlobalRouterContext, { MatchParams } from 'builder/common/GlobalRouterContext'
import { TopBar, TopBarBackButton, TopBarSection } from 'builder/topBar/components'
import { useOnlineStoreService } from 'cms/onlineStores'
import { BlankState, Button, HelperText, Input, InputField, Label, Select, useToast } from 'common/components'
import { ToastType } from 'common/components/toast/types'
import { trpc } from 'common/hooks/trpc'
import { useProductService } from 'common/products'
import { TenantContext } from 'common/tenant'
import { withFlag } from 'common/users/components'
import { actions as designActions } from 'customizer/designs'

const createQuoteSchema = () =>
  yup.object().shape({
    name: yup.string().required('Please enter customer name'),
    email: yup.string().email('Please enter a valid email').required('Please enter an email'),
    productId: yup.string().nullable().required('Please select a product'),
    onlineStoreId: yup.string().nullable().required('Please select an online store'),
  })

interface FormValues {
  name: string
  email: string
  productId: string | null
  onlineStoreId: string | null
}

const CreateQuote = ({ location, history, match }: RouteComponentProps<MatchParams>) => {
  const customizerRef = useRef<HTMLIFrameElement>(null)
  const tenant = useContext(TenantContext)
  const { brandName } = match.params
  const baseUrl = brandName ? `/brands/${brandName}` : ''
  const { openToast, openGenericErrorToast } = useToast()

  const productService = useProductService()
  const onlineStoreService = useOnlineStoreService()

  const { data: products, isLoading: isLoadingProducts } = useQuery(
    productService.fetchAll.queryKeys,
    () => productService.fetchAll({ params: { archived: false } }),
    {
      select: data => {
        return orderBy(data?.results || [], 'updatedAt', 'desc') as Product[]
      },
    }
  )

  const { data: onlineStores, isLoading: isLoadingOnlineStores } = useQuery(
    onlineStoreService.fetchAll.queryKeys,
    onlineStoreService.fetchAll,
    {
      initialData: [],
    }
  )

  const { mutate: createQuote } = trpc.quote.create.useMutation({
    onSuccess: quote => {
      openToast('Quote successfully created!', ToastType.success)
      history.push(`${baseUrl}/quotes/${quote.id}`)
    },
    onError: () => {
      openGenericErrorToast('Quote has not been created.')
    },
  })

  const productsOptions = products?.map(product => ({
    value: product.id,
    label: product.name,
  }))

  const onlineStoresOptions = onlineStores?.map(onlineStore => ({
    value: onlineStore.id,
    label: onlineStore.name,
  }))

  const getIframeSrc = () => {
    const productId = formik.values.productId
    const shopId = formik.values.onlineStoreId
    if (!productId) return undefined

    const searchParams = new URLSearchParams()
    if (!window.location.origin.split('.').includes(tenant!)) searchParams.set('tenant', tenant!)
    if (shopId) searchParams.set('shopid', shopId)

    const queryString = Array.from(searchParams).length > 0 ? `?${searchParams.toString()}` : ''

    return `${window.location.origin}/customize/quote/${productId}/${queryString}`
  }

  const cancel = () => {
    history.push(`${baseUrl}/quotes`)
  }

  const onSubmit = async (values: FormValues, { resetForm, setSubmitting }: FormikHelpers<FormValues>) => {
    if (!customizerRef.current || typeof customizerRef.current == 'string') {
      setSubmitting(false)
      return
    }

    const { id } = await customizerRef.current.contentWindow?.customizerApp?.store.dispatch(
      designActions.generateDesign() as any
    )

    return createQuote(
      {
        designId: id,
        quoteFormId: null,
        quoteFormValues: {
          name: values.name,
          email: values.email,
          message: '',
        },
      },
      {
        onSuccess: () => {
          resetForm()
          setSubmitting(false)
        },
        onError: () => {
          setSubmitting(false)
        },
      }
    )
  }

  const isOnlyOneOnlineStore = onlineStores?.length === 1

  const formik = useFormik<FormValues>({
    initialValues: {
      name: '',
      email: '',
      productId: null,
      onlineStoreId: isOnlyOneOnlineStore ? onlineStores?.[0].id : null,
    },
    validationSchema: createQuoteSchema(),
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit,
  })

  const hasError = (field: keyof FormValues) => formik.touched[field] && formik.errors[field] != null

  return (
    <GlobalRouterContext.Provider value={{ location, history, match }}>
      <FormikProvider value={formik}>
        <form className="flex flex-col h-screen" onSubmit={formik.handleSubmit}>
          <TopBar>
            <TopBarSection className="pl-5">
              <TopBarBackButton />
            </TopBarSection>
            <TopBarSection right className="mr-4 space-x-2">
              <Button type="button" onClick={cancel}>
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
                isLoading={formik.isSubmitting}
              >
                Create quote
              </Button>
            </TopBarSection>
          </TopBar>
          <div className="flex justify-center w-full pt-12 px-4 flex-1 mt-[52px]">
            <div className="max-w-7xl w-full flex flex-col">
              <h1 className="mb-4">Creating quote</h1>
              <hr className="h-[1px] w-full border-neutral-100 mb-4" />
              <div className="flex flex-row gap-x-6 w-full">
                <Field name="name">
                  {({ field }: FieldProps) => (
                    <InputField className="flex-1">
                      <Label className="font-medium mb-4" htmlFor="name">
                        Customer name
                      </Label>
                      <Input
                        {...field}
                        id="name"
                        name="name"
                        placeholder="Enter name"
                        hasError={hasError('name')}
                        className={classNames({ 'mb-6': !hasError('name') })}
                      />
                      {hasError('name') && <HelperText hasError>{formik.errors.name}</HelperText>}
                    </InputField>
                  )}
                </Field>
                <Field name="email">
                  {({ field }: FieldProps) => (
                    <InputField className="flex-1">
                      <Label className="font-medium mb-4" htmlFor="email">
                        Email
                      </Label>
                      <Input
                        {...field}
                        id="email"
                        name="email"
                        placeholder="example@email.com"
                        hasError={hasError('email')}
                        className={classNames({ 'mb-6': !hasError('email') })}
                      />
                      {hasError('email') && <HelperText hasError>{formik.errors.email}</HelperText>}
                    </InputField>
                  )}
                </Field>
                <Field name="onlineStoreId">
                  {({ field }: FieldProps) => (
                    <InputField className="flex-1">
                      <Label className="font-medium mb-4" htmlFor="onlineStoreId">
                        Online store
                      </Label>
                      <Select
                        {...field}
                        id="onlineStoreId"
                        name="onlineStoreId"
                        aria-label="Online store"
                        options={onlineStoresOptions}
                        isSearchable
                        placeholder="Select online store"
                        menuPlacement="auto"
                        disabled={isLoadingOnlineStores || isOnlyOneOnlineStore}
                        value={
                          onlineStoresOptions?.find(option => option.value === formik.values.onlineStoreId) || null
                        }
                        onChange={option => formik.setFieldValue('onlineStoreId', option?.value || null)}
                        hasError={hasError('onlineStoreId')}
                        className={classNames({ 'mb-6': !hasError('onlineStoreId') })}
                      />
                      {hasError('onlineStoreId') && <HelperText hasError>{formik.errors.onlineStoreId}</HelperText>}
                    </InputField>
                  )}
                </Field>
                <Field name="productId">
                  {({ field }: FieldProps) => (
                    <InputField className="flex-1">
                      <Label className="font-medium mb-4" htmlFor="productId">
                        Product
                      </Label>
                      <Select
                        {...field}
                        id="productId"
                        name="productId"
                        aria-label="Product"
                        options={productsOptions}
                        isSearchable
                        placeholder="Select product"
                        menuPlacement="auto"
                        disabled={isLoadingProducts}
                        value={productsOptions?.find(option => option.value === formik.values.productId) || null}
                        onChange={option => formik.setFieldValue('productId', option?.value || null)}
                        hasError={hasError('productId')}
                        className={classNames({ 'mb-6': !hasError('productId') })}
                      />
                      {hasError('productId') && <HelperText hasError>{formik.errors.productId}</HelperText>}
                    </InputField>
                  )}
                </Field>
              </div>
              <div className="mt-6 mb-14 flex-1">
                {!formik.values.productId ? (
                  <div className="flex items-center justify-center py-32">
                    <BlankState>
                      <BlankState.Title>There's no product selected</BlankState.Title>
                      <BlankState.Details>Select the product you want to customize for this quote.</BlankState.Details>
                    </BlankState>
                  </div>
                ) : (
                  <iframe
                    ref={customizerRef}
                    src={getIframeSrc()}
                    className="shadow rounded-lg w-full h-full"
                    allow="clipboard-write"
                    data-testid="customizer-iframe"
                  />
                )}
              </div>
            </div>
          </div>
        </form>
      </FormikProvider>
    </GlobalRouterContext.Provider>
  )
}

export default withFlag({
  Component: CreateQuote,
  feature: 'quote_phase_2',
})
