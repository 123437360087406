import { keyBy, mergeWith, isArray } from 'lodash'
import { AnyAction, Reducer } from 'redux'

import * as types from './actionTypes'

export default (type: string) => {
  return <T extends Reducer>(reducer?: T) => {
    return (state: ReturnType<T> = {} as ReturnType<T>, action: AnyAction): ReturnType<T> => {
      switch (action.type) {
        case types.START_EDITING:
          return action.payload.customizerProduct[type]
        case types.PATCH:
          if (!action.payload[type]) return state

          return mergeWith({}, state, keyBy(action.payload[type], 'id'), (_, src) => {
            return isArray(src) && src.length === 0 ? src : undefined
          })
        case types.RESET_PRODUCTBUILDER:
          return {} as ReturnType<T>
        default:
          return reducer ? reducer(state, action) : state
      }
    }
  }
}
