import { useContext } from 'react'

import { ConnectedStartingPoint } from 'cms/onlineStores/types'
import { apiClient, createQuery } from 'common/api'
import { TenantContext } from 'common/tenant'

export type ConnectParams = { onlineStoreId: string; shopUrl: string }

const useWooCommerceProxyService = () => {
  const tenant = useContext(TenantContext)

  return {
    connect: async (params: ConnectParams) => {
      const { data } = await apiClient.post<void>(`/brands/${tenant}/woocommerce/connect`, params)
      return data
    },
    test: createQuery(['test-woocommerce'], async (onlineStoreId: string) => {
      const { data } = await apiClient.get<void>(
        `/brands/${tenant}/woocommerce/proxy/${onlineStoreId}?path=?rest_route=/mczr/test`
      )
      return data
    }),
    fetchStartingPoint: createQuery(
      [tenant, 'fetch-woocommerce-starting-point'],
      async (onlineStoreId: string, startingPointId: string) => {
        const path = encodeURIComponent(`?rest_route=/mczr/startingpoints/${startingPointId}`)
        const { data } = await apiClient.get<{ data: ConnectedStartingPoint }>(
          `/brands/${tenant}/woocommerce/proxy/${onlineStoreId}?path=${path}`
        )
        return data.data
      }
    ),
    removeStartingPoint: async (onlineStoreId: string, startingPointId: string) => {
      const path = encodeURIComponent(`?rest_route=/mczr/startingpoints/${startingPointId}`)
      const { data } = await apiClient.delete<void>(`/brands/${tenant}/woocommerce/proxy/${onlineStoreId}?path=${path}`)
      return data
    },
  }
}

export default useWooCommerceProxyService
