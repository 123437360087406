import { Group, GroupType, Question } from '@packages/types'
import { createSelector } from 'reselect'

import { utils as coreUtils } from 'builder/build/core'
import createCustomizerProductEntitySelectors from 'builder/build/core/createCustomizerProductEntitySelectors'
import { RootState } from 'cms/store'
import isNullOrEmpty from 'utils/isNullOrEmpty'

import { isDescendant } from './utils'

const productBuilderSelector = (state: RootState) => state.productBuilder
const propsSelector = (_: RootState, props: any) => props

const { groupsSelector, groupsAsArraySelector, groupByIdSelector } = createCustomizerProductEntitySelectors('groups')
export { groupsSelector, groupsAsArraySelector, groupByIdSelector }

export const rootGroupIdSelector = createSelector(
  productBuilderSelector,
  ({ customizerProduct }) => customizerProduct.tree
)

export const groupChildrenSelector = createSelector(
  groupByIdSelector,
  productBuilderSelector,
  (group, productBuilder) => {
    if (isNullOrEmpty(group)) return []

    return group.children.map(childId => coreUtils.getEntityById(productBuilder, childId) as Group | Question)
  }
)

export const isDescendantSelector = createSelector([groupsSelector, propsSelector], (groups, { groupId, entityId }) => {
  return isDescendant(Object.values(groups), groupId, entityId)
})

export const isMoveAllowedSelector = (
  state: RootState,
  {
    source,
    destination,
  }: { source: { parentId: string; index: number }; destination: { parentId: string; index: number } }
) => {
  const groups = groupsAsArraySelector(state)

  const sourceParentGroup = groups.find(group => group.id === source.parentId)
  const destinationParentGroup = groups.find(group => group.id === destination.parentId)

  if (!sourceParentGroup || !destinationParentGroup) return

  const sourceEntityId = sourceParentGroup.children[source.index]
  const isSourceAGroup = !!groups.find(({ id }) => id === sourceEntityId)
  const isDestinationBulkOrder = destinationParentGroup.type === GroupType.BulkOrder

  return (
    !isDescendantSelector(state, { groupId: sourceEntityId, entityId: destination.parentId }) &&
    !(isSourceAGroup && isDestinationBulkOrder)
  )
}

export const parentSelector = createSelector(groupsAsArraySelector, propsSelector, (groupsArray, entityId) =>
  groupsArray.find(group => group.children.includes(entityId))
)

export const bulkOrderGroupSelector = createSelector(groupsAsArraySelector, groupsArray =>
  groupsArray.find(group => group.type === GroupType.BulkOrder)
)

export const bulkOrderGroupChildrenSelector = createSelector(
  groupsAsArraySelector,
  groupsArray => groupsArray.find(group => group.type === GroupType.BulkOrder)?.children || []
)
