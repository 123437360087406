import React from 'react'

import { DatesFilter, SearchFilter, ClearFiltersButton, FilterContainer, FilterList } from 'common/components'

import DesignsProductFilter from './DesignsProductFilter'

export interface DesignsFilterListProps {
  setSearch: (value: string) => void
  search?: string
  startDate?: Date | null
  setStartDate: (value?: Date | null) => void
  endDate?: Date | null
  setEndDate: (value?: Date | null) => void
  clearAll: () => void
  hasAppliedFilters?: boolean
  products?: string[]
  toggleFilter: (filter: 'products', name: string) => void
  clearFilters: (filter: 'products') => void
}

const DesignsFilterList = ({
  setSearch,
  search,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  clearAll,
  hasAppliedFilters,
  products,
  toggleFilter,
  clearFilters,
}: DesignsFilterListProps) => {
  return (
    <FilterContainer>
      <FilterList>
        <SearchFilter name="Design" setSearch={setSearch} search={search} />
        <DatesFilter
          name="Created"
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
        <DesignsProductFilter
          clearFilters={() => clearFilters('products')}
          toggleProducts={name => toggleFilter('products', name)}
          filteredProducts={products}
        />
      </FilterList>
      <ClearFiltersButton onClick={clearAll} disabled={!hasAppliedFilters} />
    </FilterContainer>
  )
}

export default DesignsFilterList
