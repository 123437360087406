import { Question } from '@packages/types'
import React from 'react'

import { PatchTextInput, PatchTextInputChangeEvent } from 'builder/build/common/components'
import { useDispatch } from 'cms/hooks'
import { InputField } from 'common/components'

import { patchQuestion } from '../../actions'

interface TitleSectionProps {
  question: Question
}

const TitleSection = ({ question }: TitleSectionProps) => {
  const dispatch = useDispatch()

  const handleNameChange = (e: PatchTextInputChangeEvent) =>
    dispatch(patchQuestion(question.id, { name: e.target.value }))

  return (
    <div className="panel__section">
      <InputField>
        <label className="font-medium mb-4">Title</label>
        <PatchTextInput placeholder="Title" value={question.name} onChange={handleNameChange} />
      </InputField>
    </div>
  )
}

export default TitleSection
