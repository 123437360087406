import React from 'react'
import { Link } from 'react-router-dom'

import { Alert, Button, PublicFormFooter } from 'common/components'

import AuthFormContainer from './AuthFormContainer'
import AuthFormTitle from './AuthFormTitle'

const ChangePasswordSuccess = () => {
  return (
    <AuthFormContainer>
      <AuthFormTitle>Change password</AuthFormTitle>
      <Alert variant="success">Your password has been changed successfully!</Alert>
      <PublicFormFooter>
        <Link to="/login" className="w-full">
          <Button variant="primary" className="w-full">
            Back to login
          </Button>
        </Link>
      </PublicFormFooter>
    </AuthFormContainer>
  )
}

export default ChangePasswordSuccess
