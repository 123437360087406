import { Question } from '@packages/types'
import React from 'react'
import { SingleValue } from 'react-select'

import { actions as groupsActions } from 'builder/build/groups'
import { useDispatch, useSelector } from 'cms/hooks'
import { WithInformation, InputField, Select } from 'common/components'
import { Option } from 'common/components/inputs/select/Select'

import { linkQuestion, unlinkQuestion } from '../../actions'
import { questionsSelector } from '../../selectors'

import './LinkedQuestionSection.scss'

export interface LinkedQuestionSectionProps {
  question: Question
  linkedQuestionMessage: string
  disabled: boolean
}

const { moveBehindTheScene } = groupsActions

const UNLINK = 'unlink'

const generateLinkedQuestionOptions = (questions: Question[]) =>
  questions.map(question => ({
    label: question.name,
    value: question.id,
  }))

const LinkedQuestionSection = ({ question, linkedQuestionMessage, disabled }: LinkedQuestionSectionProps) => {
  const dispatch = useDispatch()
  const questions = useSelector(questionsSelector)
  const otherQuestions = Object.values(questions).filter(
    anotherQuestion => anotherQuestion.id !== question.id && anotherQuestion.type === question.type
  )
  const linkedQuestionOptions = generateLinkedQuestionOptions(otherQuestions)

  const handleLinkedQuestionChange = (option: SingleValue<Option<string>>) => {
    if (option!.value === UNLINK) return dispatch(unlinkQuestion(question.id))

    dispatch(linkQuestion(question.id, option!.value))
    dispatch(moveBehindTheScene(question.id))
    return
  }

  const options = [{ label: 'Not linked', value: UNLINK }, ...linkedQuestionOptions]

  const value = options.find(option => option.value === question?.linkedQuestionId) ?? {
    label: 'Not linked',
    value: UNLINK,
  }

  return (
    <div className="panel__section">
      <InputField>
        <WithInformation tooltip={linkedQuestionMessage} className="mb-4 font-medium">
          Link to another question
        </WithInformation>
        {!disabled && <Select options={options} onChange={handleLinkedQuestionChange} value={value} />}
        {disabled && <div className="text-xs text-neutral-600">Main question can't be linked to another question.</div>}
      </InputField>
    </div>
  )
}

export default LinkedQuestionSection
