import React from 'react'

import { useModal } from 'common/components'
import { useUserActivityLog } from 'common/hooks'
import { useCurrentUser } from 'common/users'

import OnBoardingBuilderModal from './OnBoardingBuilderModal'
import OnBoardingVideoModal from './OnBoardingVideoModal'

const OnBoardingBuilder = () => {
  const onBoardingBuilderModal = useModal()
  const onBoardingVideoModal = useModal()
  const { isMCZRUser } = useCurrentUser()
  const [createActivityLog] = useUserActivityLog('firstBuilderVisit', {
    onSuccess: activityLog => !isMCZRUser && !activityLog && onBoardingBuilderModal.open(),
  })

  if (onBoardingBuilderModal.isVisible) {
    return (
      <OnBoardingBuilderModal
        onClose={() => {
          createActivityLog({ type: 'firstBuilderVisit' })
          onBoardingBuilderModal.close()
        }}
        openVideoModal={() => {
          createActivityLog({ type: 'firstBuilderVisit' })
          onBoardingBuilderModal.close()
          onBoardingVideoModal.open()
        }}
        {...onBoardingBuilderModal.modalProps}
      />
    )
  }

  if (onBoardingVideoModal.isVisible) {
    return (
      <OnBoardingVideoModal
        onClose={() => {
          onBoardingVideoModal.close()
        }}
        {...onBoardingVideoModal.modalProps}
      />
    )
  }

  return null
}

export default OnBoardingBuilder
