import classNames from 'classnames'
import React from 'react'
import { useSelector } from 'react-redux'

import { TypeIcon } from 'builder/build/common/components'
import { selectors as partsSelectors } from 'builder/build/parts'
import { WithInformation, IconButton, Popover, usePopover } from 'common/components'
import EditIcon from 'icons/bold/01-Interface Essential/22-Edit/pencil-write-alternate.svg'

import MaskMenu from './MaskMenu'

import './MaskSection.scss'

const { masksSelector } = partsSelectors

export type Props = {
  partId?: string
  entityMasks: string[]
  isDisabled?: boolean
  maskMessage: string
  onApply: (masks: string[]) => void
  className?: string
}

const MaskSection = ({ partId, className, entityMasks, isDisabled, maskMessage, onApply }: Props) => {
  const masks = useSelector(masksSelector)

  const editButton = usePopover({
    placement: 'left',
    shiftConfig: { mainAxis: true, crossAxis: false, padding: 16 },
  })

  return (
    <>
      <div
        className={classNames('panel__row', className, { 'panel__row--no-margin-bottom': entityMasks.length === 0 })}
        role="heading"
      >
        <WithInformation tooltip={maskMessage}>
          <label className="panel__field-label">Mask</label>
        </WithInformation>
        <IconButton {...editButton.referenceProps} disabled={isDisabled} role="button" Icon={EditIcon} />
        <Popover isOpen={editButton.isOpen} {...editButton.floatingProps} className="w-[252px] builder z-40">
          <MaskMenu
            masks={masks.filter(mask => mask.id !== partId)}
            initialSelectedMasks={entityMasks}
            onApply={maskIds => {
              editButton.close()
              onApply(maskIds)
            }}
          />
        </Popover>
      </div>
      {entityMasks.map(maskId => {
        const mask = masks.find(mask => mask.id === maskId)

        if (!mask) return null

        return (
          <div className="mask" role="row" key={maskId}>
            <TypeIcon entityType="DISPLAY_TYPE" specificType={mask.type} className="mask-item__icon" />
            <div className="mask__label">{mask.name}</div>
          </div>
        )
      })}
    </>
  )
}

export default MaskSection
