import { GroupType } from '@packages/types'
import { Middleware, Store } from 'redux'

import { actionTypes as groupTypes } from 'builder/build/groups'
import { actionTypes as questionTypes } from 'builder/build/questions'
import { RootState } from 'cms/store'
import { resetRows } from 'customizer/bulkOrder/actions'
import { bulkOrderDenormalizedGroupSelector } from 'customizer/bulkOrder/selectors'
import { RootState as CustomizerRootState } from 'customizer/store'

const actionsThatShouldResetBulkOrder = [...Object.values(groupTypes), ...Object.values(questionTypes)]

const builderToPreviewBulkOrderMiddleware =
  (previewStore: Store): Middleware<Record<string, never>, RootState> =>
  _store =>
  next =>
  action => {
    const result = next(action)

    const previewState: CustomizerRootState = previewStore.getState()

    const hasABulkOrderGroup = !!Object.values(previewState.customization.groups).find(
      ({ type }) => type === GroupType.BulkOrder
    )

    if (hasABulkOrderGroup && actionsThatShouldResetBulkOrder.includes(action.type)) {
      const bulkOrderDenormalizedGroup = bulkOrderDenormalizedGroupSelector(previewState)

      if (bulkOrderDenormalizedGroup) {
        previewStore.dispatch(resetRows(bulkOrderDenormalizedGroup))
      }
    }

    return result
  }

export default builderToPreviewBulkOrderMiddleware
