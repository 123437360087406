import React, { useEffect, useState } from 'react'

import { TranslationText } from 'cms/translations/types'
import { TextArea } from 'common/components'
import ArrowRight from 'icons/regular/01-Interface Essential/40-Keyboard/keyboard-arrow-right.svg'

interface TranslationRowProps {
  text: TranslationText
  value: string
  onChange: (value: string) => void
}

const TranslationRow = React.forwardRef<HTMLLabelElement, TranslationRowProps>(({ text, value, onChange }, ref) => {
  const [internalValue, setInternalValue] = useState(value)

  useEffect(() => {
    if (value !== internalValue) setInternalValue(value)
  }, [value])

  return (
    <li className="flex flex-col md:flex-row py-4 px-6">
      <label
        className="flex-1 text-sm font-medium text-neutral-400 md:self-center mb-4 md:mb-0"
        htmlFor={text.keys[0]}
        ref={ref}
      >
        {text.text}
      </label>
      <ArrowRight className="h-4 ml-4 mr-4 text-neutral-500 self-center hidden md:visible" />
      <div className="flex-1">
        <TextArea
          id={text.keys[0]}
          value={internalValue}
          onChange={e => setInternalValue(e.target.value)}
          onBlur={e => onChange(e.target.value)}
          placeholder={text.text}
        />
      </div>
    </li>
  )
})

export default TranslationRow
