import { Part, TextPart } from '@packages/types'
import classNames from 'classnames'
import React from 'react'

import { actions as partsActions } from 'builder/build/parts'
import { useDispatch } from 'cms/hooks'
import { WithInformation, Switch } from 'common/components'

const { toggleMultiply, toggleTextNeon } = partsActions

export interface BlendingOptionSectionProps {
  part: Part
  isDisabled: boolean
  multiplyMessage: string
  allowNeon?: boolean
  neonMessage?: string
}

const BlendingOptionSection = ({
  part,
  isDisabled,
  multiplyMessage,
  allowNeon = false,
  neonMessage,
}: BlendingOptionSectionProps) => {
  const dispatch = useDispatch()

  const handleMultiplyToggle = () => dispatch(toggleMultiply(part.id))

  const handleTextNeonToggle = () => dispatch(toggleTextNeon(part.id))

  return (
    <div className="panel__section">
      <div className={classNames('panel__row', { 'panel__row--no-margin-bottom': !allowNeon })}>
        <WithInformation tooltip={multiplyMessage}>
          <label className="panel__field-label">Multiply</label>
        </WithInformation>
        <Switch
          disabled={isDisabled}
          onChange={handleMultiplyToggle}
          checked={part.modifiers?.blendMode === 'multiply'}
        />
      </div>
      {allowNeon && (
        <div className="panel__row panel__row--no-margin-bottom">
          <WithInformation tooltip={neonMessage!}>
            <label className="panel__field-label">Neon effect</label>
          </WithInformation>
          <Switch disabled={isDisabled} onChange={handleTextNeonToggle} checked={(part as TextPart).modifiers?.neon} />
        </div>
      )}
    </div>
  )
}

export default BlendingOptionSection
