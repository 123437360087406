import { Placement } from '@floating-ui/react-dom'
import { Part, QuestionType, Question } from '@packages/types'
import React from 'react'

import { TypeIcon, MultiIconsButton } from 'builder/build/common/components'
import { hooks as partsHooks, actions as partsActions } from 'builder/build/parts'
import { selectors as questionsSelectors } from 'builder/build/questions'
import { useDispatch, useSelector } from 'cms/hooks'
import { usePopover, Popover } from 'common/components'
import { constants } from 'common/customizerProducts'
import { trpc } from 'common/hooks/trpc'

import { useCreateQuestion, useCreateQuestionFromDefaultAnswer } from '../../hooks'
import SelectOrCreateQuestionMenu from './SelectOrCreateQuestionMenu'

export interface AddQuestionButtonProps {
  part: Part
  type: QuestionType
  onClick?: () => void
  menuPosition?: Placement
  fromDefaultAnswer?: boolean
}

const getQuestionField = (part: Part, type: QuestionType) =>
  constants.parts.questionFields[part.type].find(fieldData => fieldData.type === type)!.field

const AddQuestionButton = ({
  onClick = () => {},
  part,
  type,
  menuPosition = 'bottom',
  fromDefaultAnswer = true,
}: AddQuestionButtonProps) => {
  const dispatch = useDispatch()
  const questions = useSelector(questionsSelectors.questionsAsArraySelector)
  const addQuestionButton = usePopover({ placement: menuPosition })
  const [answer, customizerSelectedAnswer] = partsHooks.useSelectPartAnswer(
    part[getQuestionField(part, type)] as string
  )
  const createQuestion = useCreateQuestion(part, type)

  const createQuestionFromDefaultAnswer = useCreateQuestionFromDefaultAnswer(
    part,
    answer != null ? answer.id : customizerSelectedAnswer?.id,
    type
  )

  const { mutate: createActivityLog } = trpc.activityLog.create.useMutation()

  const handleCreateQuestion = () => {
    fromDefaultAnswer ? createQuestionFromDefaultAnswer() : createQuestion()
    createActivityLog({ type: 'createQuestion' })
    if (onClick) onClick()
  }

  const handleSelectQuestion = (question: Question) => {
    dispatch(partsActions.assignQuestionToADifferentPart(part, question))
    onClick()
  }

  const selectableQuestions = questions.filter(question => question.type === type)

  return (
    <>
      <MultiIconsButton
        {...(selectableQuestions.length === 0 ? { onClick: handleCreateQuestion } : addQuestionButton.referenceProps)}
        Icon={<TypeIcon entityType="DISPLAY_TYPE" specificType={type} className="w-5 h-5 p-1 rounded" />}
        variant="subtle"
      >
        {constants.questions.questionTypeNames[type]} question
      </MultiIconsButton>
      <Popover {...addQuestionButton.floatingProps} className="builder" isOpen={addQuestionButton.isOpen}>
        <SelectOrCreateQuestionMenu
          questionType={type}
          questions={selectableQuestions}
          onSelectQuestion={handleSelectQuestion}
          onCreateQuestion={handleCreateQuestion}
        />
      </Popover>
    </>
  )
}

export default AddQuestionButton
