import React from 'react'

import { BlankState } from 'common/components'
import ForbiddenIcon from 'icons/bold/01-Interface Essential/23-Delete/delete.svg'

export interface EmptyConnectProps {
  hasNoOnlineStores?: boolean
}

interface ComponentProps {
  title: string
  details: string
}

const Component = ({ title, details }: ComponentProps) => {
  return (
    <div className="flex justify-center">
      <BlankState className="mt-[20%]">
        <BlankState.Icon Icon={ForbiddenIcon} />
        <BlankState.Title>{title}</BlankState.Title>
        <BlankState.Details>{details}</BlankState.Details>
      </BlankState>
    </div>
  )
}

const EmptyConnect = ({ hasNoOnlineStores }: EmptyConnectProps) => {
  if (hasNoOnlineStores) {
    return (
      <Component
        title="You have no online stores"
        details="You have to add online stores to your account to connect to them"
      />
    )
  }

  return <Component title="Product not published" details="You have to publish your product before connecting it" />
}

export default EmptyConnect
