import { CustomizerProduct, Product } from '@packages/types'
import { useIsMutating, useMutation } from '@tanstack/react-query'
import fastJSONPatch, { Operation } from 'fast-json-patch'
import { debounce } from 'lodash'
import { useEffect, useCallback, useRef } from 'react'

import { selectors as coreSelectors, utils as coreUtils } from 'builder/build/core'
import { utils as customizerProductsUtils } from 'builder/build/customizerProducts'
import { useDispatch, useSelector } from 'cms/hooks'
import { useAssetService } from 'common/assets'
import { useToast } from 'common/components'
import { useProductService } from 'common/products'
import dataURItoBlob from 'utils/dataURItoBlob'

import { startEditing, replaceDraft, updatePristine } from '../actions'

const useAutoSave = (productId: string, disable: boolean) => {
  const dispatch = useDispatch()
  const { openGenericErrorToast } = useToast()
  const isDirty = useSelector(coreSelectors.isDirtySelector)
  const shouldUpdateThumbnail = useRef(false)
  const productService = useProductService()
  const assetService = useAssetService()
  const isPublishing = useIsMutating(['product_publish']) > 0

  const { mutateAsync: patchDraft, isLoading: isPatching } = useMutation((patches: Operation[]) =>
    productService.patchDraft(productId, patches)
  )

  const { mutateAsync: updateDraft, isLoading: isUpdating } = useMutation((draft: CustomizerProduct) =>
    productService.updateDraft(productId, draft)
  )

  const { mutateAsync: createDraft, isLoading: isCreating } = useMutation((draft: CustomizerProduct) =>
    productService.createDraft(productId, draft)
  )

  const { mutateAsync: uploadImage } = useMutation(assetService.upload)

  const { mutate: updateProduct } = useMutation((values: Partial<Product>) => productService.update(productId, values))

  const isPending = isPublishing || isPatching || isUpdating || isCreating

  const updateThumbnailIfNeeded = () => {
    dispatch(async (_, getState) => {
      if (shouldUpdateThumbnail.current !== true) return

      const upToDateProduct = coreSelectors.customizerProductSelector(getState())
      const customizationState = customizerProductsUtils.buildCustomizationState(
        upToDateProduct,
        upToDateProduct.defaultConfiguration
      )
      const thumb = await coreUtils.generateThumbnail(customizationState)
      const assets = await uploadImage([dataURItoBlob(thumb)])
      if (assets?.[0]?.url) updateProduct({ thumbnail: assets[0].url })
    })
  }

  const handleSave = useCallback(
    () =>
      dispatch(async (dispatch, getState) => {
        const state = getState()

        const product = coreSelectors.normalizedCustomizerProductSelector(state)
        const customizerProduct = coreSelectors.customizerProductSelector(state)
        const pristineProduct = coreSelectors.pristineCustomizerProductSelector(state)

        try {
          if (customizerProduct.isDraft) {
            try {
              const patches = fastJSONPatch.compare(pristineProduct, product)

              await patchDraft(patches)
              dispatch(updatePristine(product))
            } catch (error) {
              const data = await updateDraft(customizerProduct)
              dispatch(startEditing(data))
            }
          } else {
            const data = await createDraft(customizerProduct)

            dispatch(replaceDraft(data))
            dispatch(updatePristine(product))
          }

          shouldUpdateThumbnail.current = true
        } catch (error) {
          dispatch(updatePristine(product))
          openGenericErrorToast('Product was not saved.')
        }
      }),
    []
  )

  const debouncedSave = useCallback(debounce(handleSave, 1000), [])

  useEffect(() => {
    if (isDirty && !disable && !isPending) debouncedSave()
  }, [isDirty, disable, isPending])

  useEffect(() => {
    window.addEventListener('beforeunload', updateThumbnailIfNeeded)

    return () => {
      window.removeEventListener('beforeunload', updateThumbnailIfNeeded)
      updateThumbnailIfNeeded()
      debouncedSave.flush()
    }
  }, [])
}

export default useAutoSave
