import React, { ComponentPropsWithRef, forwardRef, MutableRefObject, useCallback, useRef } from 'react'
import ReactDOM from 'react-dom'
import { useCallbackRef } from 'use-callback-ref'

import { Table } from 'common/components'

const useStickyHeader = (top = 0) => {
  const headerRef = useCallbackRef<HTMLTableSectionElement | null>(null, newValue => {
    if (newValue && headerContainerRef.current)
      headerContainerRef.current.style.marginBottom = `-${newValue.getBoundingClientRect().height}px`
  })
  const headerContainerRef: MutableRefObject<HTMLDivElement | null> = useRef(null)

  const StickyHeader = useCallback(
    forwardRef<HTMLTableSectionElement, Pick<ComponentPropsWithRef<'thead'>, 'style' | 'ref' | 'children'>>(
      (props, ref) => {
        if (!props.children) return null

        return (
          <>
            <HeaderPortal>
              <Table>
                <Table.Header className="border-b-0" ref={headerRef}>
                  {props.children}
                </Table.Header>
              </Table>
            </HeaderPortal>
            <Table.Header
              {...props}
              ref={el => {
                if (typeof ref === 'function') ref(el)
              }}
              style={{ ...props.style, zIndex: 1, opacity: 0, visibility: 'hidden' }}
            >
              {props.children}
            </Table.Header>
          </>
        )
      }
    ),
    []
  )

  const HeaderPortal = useCallback(({ children }: { children: React.ReactNode }) => {
    if (!headerContainerRef.current) return null
    return ReactDOM.createPortal(children, headerContainerRef.current)
  }, [])

  const StickyHeaderContainer = useCallback(() => {
    return (
      <div
        className="w-full overflow-x-hidden shadow-border"
        style={{ position: 'sticky', top: `${top}px`, zIndex: 3 }}
        ref={headerContainerRef}
      />
    )
  }, [])

  return {
    StickyHeader,
    StickyHeaderContainer,
    setStickyScrollerRef: (el: HTMLElement) => {
      if (el)
        el.onscroll = () => {
          if (headerContainerRef.current) headerContainerRef.current.scrollLeft = el.scrollLeft
        }
    },
  }
}

export default useStickyHeader
