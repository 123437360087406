import React from 'react'

import { Button, Card } from 'common/components'
import { trpc } from 'common/hooks/trpc'

import ProductsList from '../ProductsList'

export type ExploreDemosProps = {
  openDemo: () => void
}

const DEMO_NAMES_TO_LOAD = ['Soccer Ball', 'Sunglasses', 'PS5 Controller', 'Gaming Chair', 'Bomber Jacket', 'Watch']

const ExploreDemos = ({ openDemo }: ExploreDemosProps) => {
  const { data, isLoading } = trpc.demoProduct.list.useQuery({ name: DEMO_NAMES_TO_LOAD })

  return (
    <div className="flex flex-col">
      <span className="pb-6 text-sm font-bold">Explore with our demos</span>
      <ProductsList
        products={data?.results || []}
        isLoading={isLoading}
        lastCard={
          <Card className="bg-neutral-50 w-[240px] h-[234px] justify-center items-center m-[10px] ml-0" shadow={false}>
            <Button variant="default" onClick={openDemo}>
              View all demo
            </Button>
          </Card>
        }
      />
    </div>
  )
}

export default ExploreDemos
