import { StoreTheme, ThemeName } from '@packages/types'
import { useQuery } from '@tanstack/react-query'
import { capitalize } from 'lodash'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { useThemeService } from 'cms/theme'
import { Button, FullPageModal, ModalProps, Tag, useToast } from 'common/components'
import { ToastType } from 'common/components/toast/types'
import { trpc } from 'common/hooks/trpc'
import EyeIcon from 'icons/core-solid/interface-essential/interface-edit-view.svg'
import Skater from 'icons/custom/skater-rotate.svg'

const themeOrder: Record<StoreTheme['name'], number> = {
  [ThemeName.Booklike]: 0,
  [ThemeName.Barebones]: 1,
}

interface SelectThemeModalProps extends Omit<ModalProps, 'children'> {
  onClose: () => void
  onNewThemeSelected: () => void
}

const SelectThemeModal = ({ onNewThemeSelected, onClose, ...modalProps }: SelectThemeModalProps) => {
  const history = useHistory()
  const params = useParams<{ brandName?: string; themeId: string }>()
  const { openToast, openGenericErrorToast } = useToast()
  const themeService = useThemeService()
  const trpcUtils = trpc.useContext()

  const { data: activeTheme, isLoading: isLoadingActiveTheme } = trpc.theme.getCurrent.useQuery()

  const { data: themes, isLoading: isLoadingStoreThemes } = useQuery(
    themeService.fetchAll.queryKeys,
    themeService.fetchAll,
    {
      initialData: [],
      select: themes =>
        themes.sort((themeA, themeB) => themeOrder[themeA.name as ThemeName] - themeOrder[themeB.name as ThemeName]),
    }
  )

  const { mutate: createActivityLog } = trpc.activityLog.create.useMutation({
    onSuccess: () => trpcUtils.activityLog.listLatest.invalidate(),
  })

  const { mutate: selectTheme, isLoading: isSelectingTheme } = trpc.theme.select.useMutation({
    onSuccess: theme => {
      trpcUtils.theme.getCurrent.invalidate()
      trpcUtils.theme.get.invalidate()
      onNewThemeSelected()

      const baseUrl = params.brandName ? `/brands/${params.brandName}` : ''
      history.push(`${baseUrl}/theme`)

      openToast(`${capitalize(theme.name)} was successfully selected as the new customizer theme!`, ToastType.success)
    },
    onError: () => openGenericErrorToast('The theme was not selected.'),
  })

  const { mutate: openThemeForEdition, isLoading: isOpeningTheme } = trpc.theme.select.useMutation({
    onSuccess: theme => {
      const baseUrl = params.brandName ? `/brands/${params.brandName}` : ''
      history.push(`${baseUrl}/themes/${theme.id}`)
      onClose()
    },
    onError: () => openGenericErrorToast('Could not retrieve the theme.'),
  })

  const previewTheme = (theme: StoreTheme) => {
    const queryParams = params.brandName ? `?tenant=${params.brandName}` : ''
    window.open(`${window.location.origin}/themes/${theme.name}/preview${queryParams}`, '_blank')
  }

  const handleSelectTheme = async (theme: StoreTheme) => {
    if (!activeTheme) selectTheme({ name: theme.name, shouldUpdateBrand: true })
    else openThemeForEdition({ name: theme.name, shouldUpdateBrand: false })
    createActivityLog({ type: 'selectTheme', details: { theme: theme.name } })
  }

  const handleClose = () => {
    if (!activeTheme) return history.goBack()
    onClose()
  }

  if (isLoadingActiveTheme || isLoadingStoreThemes) {
    return (
      <FullPageModal onClose={handleClose} {...modalProps}>
        <div className="w-full h-full flex flex-col items-center justify-center pb-48">
          <Skater width={40} aria-label="Skater loading" />
        </div>
      </FullPageModal>
    )
  }

  return (
    <FullPageModal onClose={handleClose} {...modalProps}>
      <div className="flex flex-col pb-16 w-[780px] min-[1120px]:w-[1040px] min-[1380px]:w-[1300px] min-[1640px]:w-[1560px]">
        <div className="border-b border-neutral-100 py-6">
          <h1 className="font-black text-xl text-neutral-900 mb-2">Themes</h1>
          <span className="text-neutral-600 font-semibold text-sm">
            Choose a theme that fit your needs and tweak it to reflect your brand.
          </span>
        </div>
        <div className="divide-y divide-neutral-100">
          {themes.map(theme => (
            <div key={theme.name} className="flex py-12">
              <div className="w-[30%] pr-20">
                <div className="flex items-center">
                  <h2 className="text-neutral-900 font-black text-xl capitalize mb-4">{theme.name}</h2>
                  {activeTheme && activeTheme.name === theme.name && (
                    <Tag className="rounded bg-neutral-75 mb-4 ml-2">Current</Tag>
                  )}
                </div>
                <span className="text-neutral-600 text-sm font-semibold block mb-8">{theme.description}</span>
                <div className="flex justify-end flex-col xl:flex-row gap-x-3 gap-y-2">
                  <Button
                    type="button"
                    icon={<EyeIcon className="w-3 h-3 fill-black" />}
                    onClick={() => previewTheme(theme)}
                  >
                    Preview
                  </Button>

                  <Button
                    type="button"
                    variant="primary"
                    onClick={() => handleSelectTheme(theme)}
                    disabled={isSelectingTheme || isOpeningTheme}
                    isLoading={isSelectingTheme || isOpeningTheme}
                  >
                    Select
                  </Button>
                </div>
              </div>
              <div className="w-[53%] mr-3">
                <img
                  src={theme.desktopPreviewImage}
                  alt={`${theme.name} desktop`}
                  className="shadow rounded-lg xl:h-full"
                />
              </div>
              <div className="w-[17%] ml-3">
                <img
                  src={theme.mobilePreviewImage}
                  alt={`${theme.name} mobile`}
                  className="shadow rounded-lg xl:h-full"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </FullPageModal>
  )
}

export default SelectThemeModal
