import { FontSizeAnswer } from '@packages/types'
import React from 'react'

import { PatchTextInput } from 'builder/build/common/components'
import { selectors as customizerSelectors, hooks as customizerHooks } from 'builder/build/customizer'
import { ViewButtons } from 'builder/build/customizer/components'
import { useDispatch } from 'cms/hooks'
import { min } from 'utils/validators'

import { patchAnswer } from '../actions'
import { getFontSize } from '../utils'
import { GeneralSettingsSection, TitleSection } from './sections'

interface FontSizeAnswerPanelProps {
  answer: FontSizeAnswer
}

export const FontSizeAnswerPanel = ({ answer }: FontSizeAnswerPanelProps) => {
  const dispatch = useDispatch()

  const currentView = customizerHooks.useCustomizerSelector(customizerSelectors.currentViewSelector) ?? 0

  const updateAnswer: GeneralSettingsSection['onUpdate'] = (field, value) => {
    dispatch(patchAnswer(answer, { [field]: value }))
  }

  const updateFontSize = (value: string) => {
    dispatch(patchAnswer(answer, { views: { [currentView]: { size: `${value}px` } } }))
  }

  return (
    <div className="panel__content" aria-label="Font size answer panel">
      <TitleSection answer={answer} />
      <hr className="panel__divider" />
      <div className="panel__section">
        <div className="mb-4">
          <ViewButtons currentView={currentView} label={<label className="font-medium mr-auto">Width in view</label>} />
        </div>
        <PatchTextInput
          className="font-size__input"
          filled={false}
          value={getFontSize(answer, currentView) || ''}
          onChange={e => updateFontSize(e.target.value)}
          type="number"
          min="0"
          validator={min(0)}
          rightAddon="px"
        />
      </div>
      <hr className="panel__divider" />
      <GeneralSettingsSection answer={answer} onUpdate={updateAnswer} />
    </div>
  )
}

export default FontSizeAnswerPanel
