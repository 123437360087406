import { EntityType, Folder, Question, BulkOrder } from '@packages/types'

import { twoDDisplayerActions } from 'customizer/2dDisplayer'
import { selectors as customizationSelectors } from 'customizer/customization'
import { setQuestionHasRequiredError } from 'customizer/customization/actions'
import { SET_SHOW_FINISH_CUSTOMIZATION } from 'customizer/customization/actionTypes'
import { wordFilteredQuestionsSelector } from 'customizer/customization/selectors'
import { actions as designsActions } from 'customizer/designs'
import { AppDispatch, RootState } from 'customizer/store'

import * as actionTypes from './actionTypes'
import {
  invalidQuestionsSelector,
  treeEntitySelector,
  nextStepSelector,
  rootStepSelector,
  outOfStockStepsSelector,
  questionsOverAvailableStocksSelector,
} from './selectors'
import { MobileDescription } from './types'

export const selectStep = (stepId: string) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState()
    if (state.questionPanel.currentStep === stepId) return

    const entity = treeEntitySelector(state, { id: stepId })
    if (!entity) return

    const step = customizationSelectors.firstLevelAncestorSelector(state, entity.id)!

    dispatch({
      type: actionTypes.SELECT_STEP,
      payload: {
        stepId: entity.entityType === EntityType.Question ? entity.id : step.id,
      },
    })

    entity.forceView != null && dispatch(twoDDisplayerActions.switchView(entity.forceView))

    step.forceView != null && entity.forceView === null && dispatch(twoDDisplayerActions.switchView(step.forceView))

    dispatch(highlightStep(entity))
  }
}

export const highlightStep = (step: BulkOrder | Question | Folder) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState()
    const rootId = rootStepSelector(state).id

    if (step.id !== rootId) {
      const highlightableParts = customizationSelectors.highlightablePartsSelector(
        state,
        step.entityType === EntityType.Group ? step.children : [step.id]
      )

      if (highlightableParts.length !== 0) return dispatch(twoDDisplayerActions.forceHighlight(highlightableParts))
    }

    return dispatch(twoDDisplayerActions.setForceHighlight(null))
  }
}

export const selectNextStep = () => (dispatch: AppDispatch, getState: () => RootState) =>
  dispatch(selectStep(nextStepSelector(getState())))

export const selectRootStep = () => (dispatch: AppDispatch, getState: () => RootState) =>
  dispatch(selectStep(rootStepSelector(getState()).id))

export const validateQuestions = () => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState()
    const questionsRequiringInteraction = customizationSelectors.questionsRequiringInteractionSelector(state)
    const wordFilteredQuestions = wordFilteredQuestionsSelector(state)
    const invalidQuestions = invalidQuestionsSelector(state)
    const outOfStockSteps = outOfStockStepsSelector(state)
    const questionsOverAvailableStocks = questionsOverAvailableStocksSelector(state)
    const isCustomizingDesign = customizationSelectors.isCustomizingDesignSelector(state)

    const invalidSteps = [
      ...wordFilteredQuestions,
      ...invalidQuestions,
      ...outOfStockSteps,
      ...questionsOverAvailableStocks,
      ...questionsRequiringInteraction,
    ]

    if (
      wordFilteredQuestions.length === 0 &&
      invalidQuestions.length === 0 &&
      outOfStockSteps.length === 0 &&
      questionsOverAvailableStocks.length === 0 &&
      (questionsRequiringInteraction.length === 0 || isCustomizingDesign)
    ) {
      return []
    }

    dispatch({
      type: actionTypes.SET_SHOW_INTERACTION_REQUIRED_WARNINGS,
      payload: { showInteractionRequiredWarnings: true },
    })

    invalidQuestions.forEach((question: any) => dispatch(setQuestionHasRequiredError(question.id, true)))

    return invalidSteps
  }
}

export const addToCart = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const isSubmitEnabled = customizationSelectors.isSubmitEnabledSelector(getState())
    if (!isSubmitEnabled) {
      return
    }

    dispatch({ type: actionTypes.ADD_TO_CART })
    try {
      const design = await dispatch(designsActions.generateDesign())
      const handleAddToCartCompleted = (event: MessageEvent) => {
        if (event.data.eventName === 'mczrAddToCartCompleted') {
          window.removeEventListener('message', handleAddToCartCompleted)
          if (event.data.error) dispatch({ type: actionTypes.SHOW_SUBMIT_ERROR, payload: true })
          setTimeout(() => dispatch({ type: actionTypes.SET_IS_SUBMITTING, payload: false }), 1000)
        }
      }
      window.addEventListener('message', handleAddToCartCompleted, false)
      parent.postMessage({ eventName: 'mczrAddToCart', detail: design }, '*')
    } catch (error: any) {
      if (!error.message || (error.message && !error.message.includes('Unprocessable Entity'))) {
        dispatch({ type: actionTypes.SHOW_SUBMIT_ERROR, payload: true })
        dispatch({ type: actionTypes.SET_IS_SUBMITTING, payload: false })
      }
    }
  }
}

export const hideSubmitError = () => {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: actionTypes.SHOW_SUBMIT_ERROR,
      payload: false,
    })
  }
}

export const setShowFinishCustomization = (showFinishCustomization: boolean) => ({
  type: SET_SHOW_FINISH_CUSTOMIZATION,
  payload: showFinishCustomization,
})

export const setMobileDescription = (payload?: MobileDescription | null) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState()
    dispatch({
      type: actionTypes.SET_MOBILE_DESCRIPTION,
      payload: state.questionPanel.mobileDescription?.id === payload?.id ? null : payload,
    })
  }
}
export const addVisitedQuestions = (questionId: string) => ({
  type: actionTypes.ADD_VISITED_QUESTIONS,
  payload: questionId,
})
