import { Product } from '@packages/types'
import JSZip from 'jszip'
import { omit } from 'lodash'

import { useProductService } from 'common/products'
import downloadContent from 'utils/downloadContent'
import isNullOrEmpty from 'utils/isNullOrEmpty'

const useProductExportImport = () => {
  const productService = useProductService()

  const exportProduct = async (productId: string, field: 'live' | 'draft') => {
    const { product, translations, startingPoints, variants } = await productService.export(productId, {
      params: { fields: [field] },
    })

    const zip = new JSZip()

    zip.file('product.json', JSON.stringify(product))
    if (!isNullOrEmpty(translations)) zip.file('translations.json', JSON.stringify(translations))
    if (!isNullOrEmpty(startingPoints)) zip.file('startingPoints.json', JSON.stringify(startingPoints))
    if (!isNullOrEmpty(variants)) zip.file('variants.json', JSON.stringify(variants))

    const zipBlob = await zip.generateAsync({ type: 'blob' })

    if (zipBlob) downloadContent(zipBlob, 'application/zip', `${product.name}.zip`)
  }

  const parseZIP = async (file: File) => {
    const zip = new JSZip()
    const zipData = await zip.loadAsync(file)
    const promises = ['product.json', 'translations.json', 'startingPoints.json', 'variants.json'].map(async file => {
      if (!zipData.files[file]) return null

      const fileContent = await zipData.files[file].async('text')
      return JSON.parse(fileContent)
    })

    const [product, translations, startingPoints, variants] = await Promise.all(promises)

    return { product, translations, startingPoints, variants }
  }

  const parseJSON = async (file: File) => {
    const fileContent = await file.text()
    return { product: JSON.parse(fileContent) }
  }

  const importProduct = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (!file) throw new Error('No file selected')

    const fileExport = await (file.type === 'application/json' ? parseJSON(file) : parseZIP(file))

    const importedProduct = await productService.import({
      ...fileExport,
      product: {
        ...omit(typeof fileExport.product.live === 'object' ? fileExport.product.live : fileExport.product.draft, [
          'id',
          '_id',
          '__v',
          'isDraft',
        ]),
        name: fileExport.product.name,
        thumbnail: fileExport.product.thumbnail,
        variantsCombinations: fileExport.product.variantsCombinations,
      } as Product,
    })

    return importedProduct
  }

  return {
    exportProduct,
    importProduct,
  }
}

export default useProductExportImport
