import { OnlineStore } from '@packages/types'
import { useQuery } from '@tanstack/react-query'
import classNames from 'classnames'
import React from 'react'

import { useOnlineStoreService } from 'cms/onlineStores'
import { Paginated } from 'common/api/types'
import { Price, Table, Tag } from 'common/components'
import { SortOrder } from 'common/hooks/dataTable/useTableSort'
import { formatSmallDate } from 'utils/dateUtils'

import { Order, OrdersSortKeys } from '../types'
import { PAYMENT_STATUS_FILTERS, STATUS_FILTERS, TEST_FILTER } from './OrdersFilterList'
import OrdersFiltersBlankState from './OrdersFiltersBlankState'

type OrderColumn = {
  key: OrdersSortKeys
  title: string
  className: string
  isSortable?: boolean
  filterClassName?: string
  render: (order: Order, onlineStore?: OnlineStore) => React.ReactNode
}

export const orderColums: OrderColumn[] = [
  {
    key: 'orderId',
    title: 'Order',
    className: 'w-1/12',
    isSortable: true,
    render: order => (order.orderId != null ? order.orderId : <Table.EmptyCellIndicator />),
  },
  {
    key: 'eCommerceOrderName',
    title: 'eCommerce order',
    className: 'w-2/12',
    isSortable: true,
    render: order => order.eCommerceOrderName || <Table.EmptyCellIndicator />,
  },
  {
    key: 'store',
    title: 'Online store',
    className: 'w-2/12',
    isSortable: true,
    render: (_order, onlineStore) => onlineStore?.name || <Table.EmptyCellIndicator />,
  },
  {
    key: 'isTest',
    title: '',
    className: classNames('w-0/12'),
    isSortable: true,
    render: order => {
      return order?.isTest && <Tag className={classNames('rounded', TEST_FILTER[0]?.className)}>Test</Tag>
    },
  },
  {
    key: 'paymentStatus',
    title: 'Payment',
    className: 'w-2/12',
    isSortable: true,
    render: order => {
      const status = PAYMENT_STATUS_FILTERS.find(({ name }) => name === order.paymentStatus)

      return (
        <Tag className={classNames('rounded', status?.className)}>{status?.text || <Table.EmptyCellIndicator />}</Tag>
      )
    },
  },
  {
    key: 'status',
    title: 'Status',
    className: 'w-2/12',
    isSortable: true,
    render: order => {
      const status = STATUS_FILTERS.find(({ name }) => name === order.status)

      return (
        <Tag className={classNames('rounded', status?.className)}>{status?.text || <Table.EmptyCellIndicator />}</Tag>
      )
    },
  },
  {
    key: 'totalPrice',
    title: 'Total',
    className: 'w-1/12',
    isSortable: true,
    render: order =>
      order.totalPrice != null ? (
        <Price amount={order.totalPrice} currency={order.currency ?? 'USD'} />
      ) : (
        <Table.EmptyCellIndicator />
      ),
  },
  {
    key: 'date',
    title: 'Date',
    className: 'w-2/12',
    isSortable: true,
    render: order => (order.date ? formatSmallDate(order.date) : <Table.EmptyCellIndicator />),
  },
]

export interface OrdersTableProps {
  sortKey?: OrdersSortKeys
  sortOrder?: SortOrder
  handleSort: (key: OrdersSortKeys) => void
  setCount: (value: number) => void
  nextPage: () => void
  previousPage: () => void
  count: number
  lastIndex: number
  resultSize?: number
  collectionSize?: number
  data?: Paginated<Order>
  isLoading?: boolean
  isFetching?: boolean
  onOrderClick: (order: Order) => void
  onClearFilters: () => void
  hasAppliedFilters: boolean
}

const OrdersTable = ({
  sortKey,
  sortOrder,
  handleSort,
  setCount,
  count,
  previousPage,
  nextPage,
  data,
  lastIndex,
  collectionSize,
  resultSize,
  isLoading,
  isFetching,
  onOrderClick,
  onClearFilters,
  hasAppliedFilters,
}: OrdersTableProps) => {
  const isEmpty = !data?.results.length
  const numberOfColumns = orderColums.length
  const onlineStoreService = useOnlineStoreService()
  const { data: onlineStores } = useQuery(onlineStoreService.fetchAll.queryKeys, onlineStoreService.fetchAll, {
    initialData: [],
  })

  const showFiltersBlankState = !isLoading && isEmpty && hasAppliedFilters
  const showResults = !isLoading && !isEmpty

  return (
    <Table>
      {(!isEmpty || hasAppliedFilters) && (
        <Table.Header>
          <Table.HeaderRow>
            {orderColums.map(column => (
              <Table.HeaderCell key={column.key} className={column.className}>
                <Table.HeaderFilter
                  aria-label={`${column.title} header`}
                  onClick={() => handleSort(column.key)}
                  sortOrder={sortOrder}
                  isSortable={!!column.isSortable}
                  isSorted={sortKey === column.key}
                  className={column.filterClassName}
                >
                  {column.title}
                </Table.HeaderFilter>
              </Table.HeaderCell>
            ))}
          </Table.HeaderRow>
        </Table.Header>
      )}

      {isLoading && <Table.BodyLoader numberOfColumns={numberOfColumns} numberOfRows={count} />}
      {showFiltersBlankState && (
        <OrdersFiltersBlankState onClearFilters={onClearFilters} numberOfColumns={numberOfColumns} />
      )}

      {showResults && (
        <Table.Body>
          {isFetching && <Table.Loader colSpan={numberOfColumns} />}
          {data?.results.map(order => {
            const onlineStore = onlineStores.find(onlineStore => onlineStore.id === order.store)

            return (
              <Table.Row className="cursor-pointer" key={order.orderId} onClick={() => onOrderClick(order)}>
                {orderColums.map(column => (
                  <Table.Cell key={column.key}>{column.render(order, onlineStore)}</Table.Cell>
                ))}
              </Table.Row>
            )
          })}
        </Table.Body>
      )}

      {!isEmpty && (
        <Table.Footer>
          <Table.FooterRow>
            <Table.FooterCell colSpan={numberOfColumns}>
              <Table.Pagination>
                <Table.PaginationRowsPerPage onChange={setCount} rowsPerPage={count} />
                <Table.PaginationNavigation
                  onNextPage={nextPage}
                  onPreviousPage={previousPage}
                  lastIndex={lastIndex}
                  resultSize={resultSize || 0}
                  count={count}
                  collectionSize={collectionSize || 0}
                />
              </Table.Pagination>
            </Table.FooterCell>
          </Table.FooterRow>
        </Table.Footer>
      )}
    </Table>
  )
}

export default OrdersTable
