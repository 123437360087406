import React from 'react'
import { toast } from 'react-hot-toast'
import { useHistory, useLocation } from 'react-router'

import { Toast } from 'common/components'
import { ToastType } from 'common/components/toast/types'

const UpdateVariantResultToast = ({
  id,
  visible,
  type,
  message,
}: {
  id: string
  visible: boolean
  message: string
  type: ToastType
}) => {
  const history = useHistory()
  const location = useLocation()
  const splittedPath = location.pathname.split('/')
  const splittedPathWithoutBuilder = splittedPath.slice(0, splittedPath.length - 1).join('/')
  const variantsPath = `${splittedPathWithoutBuilder}/variants`

  return (
    <Toast visible={visible}>
      <Toast.Icon type={type} />
      <Toast.Message>{message}</Toast.Message>
      <Toast.Action
        onClick={() => {
          toast.dismiss(id)
          history.push(variantsPath)
        }}
      >
        Open variants
      </Toast.Action>
      <Toast.CloseButton onClick={() => toast.dismiss(id)} />
    </Toast>
  )
}

export default UpdateVariantResultToast
