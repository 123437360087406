import React from 'react'
import { toast } from 'react-hot-toast'

import Toast from './Toast'
import { ToastType } from './types'

interface DimensionsUpdatedToastProps {
  id: string
  visible?: boolean
  onView: () => void
}

const DimensionsUpdatedToast = ({ id, visible, onView }: DimensionsUpdatedToastProps) => {
  return (
    <Toast visible={visible}>
      <Toast.Icon type={ToastType.default} />
      <Toast.Message>The canvas size has been updated from the image you just uploaded!</Toast.Message>
      <Toast.Action
        onClick={() => {
          toast.dismiss(id)
          onView()
        }}
      >
        View
      </Toast.Action>
      <Toast.CloseButton onClick={() => toast.dismiss(id)} />
    </Toast>
  )
}

export default DimensionsUpdatedToast
