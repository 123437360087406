const setSession = (url: string, token: string) => {
  return new Promise<void>(resolve => {
    const blankUrl = `${new URL(url).origin}/blank`

    window.addEventListener('message', event => {
      if (event.data === 'BLANK:READY') {
        const frame = document.getElementById('frame') as HTMLIFrameElement | null
        frame?.contentWindow?.postMessage(
          { type: 'SET_LOCAL_STORAGE', payload: { key: 'bearer', value: token } },
          blankUrl
        )
        resolve()
      }
    })

    const iframe = document.createElement('iframe')
    iframe.setAttribute('src', blankUrl)
    iframe.setAttribute('id', 'frame')
    iframe.style.display = 'none'
    document.body.insertBefore(iframe, document.body.firstChild)

    // There is no way to tell if an error occured with the iframe
    // The promise resolve after 2.5s to avoid further problems
    setTimeout(resolve, 2500)
  })
}

export default setSession
