import { NormalizedCustomizerProduct } from '@packages/types'
import { FieldArray, useFormikContext } from 'formik'
import { get } from 'lodash'
import React from 'react'

import { Card, Input, InputField, Switch, Tooltip } from 'common/components'
import AlertCircle from 'icons/regular/01-Interface Essential/14-Alerts/alert-circle.svg'

import { PricingFormValues } from '../types'
import AdditionalPricing from './AdditionalPricing'

interface PricingCardProps {
  customizerProduct: NormalizedCustomizerProduct
  currenciesInputProps: { leftAddon?: string; rightAddon?: string }
  currencyKey: string
}

const PricingCard = ({ currenciesInputProps, customizerProduct, currencyKey }: PricingCardProps) => {
  const formik = useFormikContext<PricingFormValues>()

  const handleTaxesToggle = () => {
    const hasTaxes = !formik.values.applyTaxes
    formik.setFieldValue('applyTaxes', hasTaxes)
    if (!hasTaxes) formik.setFieldValue('taxes', 0)
  }

  return (
    <Card className="w-full overflow-visible animate-form-show mb-8 flex-[3] min-w-[30rem] h-fit">
      <div className="flex flex-col grow relative">
        <div className="flex">
          <div className="px-6 my-8 border-r border-neutral-100 basis-5/12">
            <div className="mb-4 font-medium">
              <label htmlFor="basePrice">Base price</label>
            </div>

            <div className="w-24">
              <InputField>
                <Input
                  id="basePrice"
                  name="basePrice"
                  placeholder="0"
                  value={get(formik.values, 'basePrice')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  hasError={get(formik.errors, 'basePrice') != null}
                  {...currenciesInputProps}
                  {...(get(formik.errors, 'basePrice') != null
                    ? {
                        rightAddon: (
                          <Tooltip
                            className="bg-error-default text-white"
                            content={get(formik.errors, 'basePrice')}
                            placement="bottom"
                          >
                            <AlertCircle className="w-3 h-3 fill-error-default" aria-label="Warning required" />
                          </Tooltip>
                        ),
                      }
                    : {})}
                />
              </InputField>
            </div>
          </div>

          <div className="px-6 my-8 border-r border-neutral-100 pr-6 flex flex-col basis-4/12">
            <div className="flex justify-between mb-4">
              <h6 className="font-medium mr-4">
                <label htmlFor="applyTaxes">Display taxes</label>
              </h6>
              <Switch id="applyTaxes" onChange={handleTaxesToggle} checked={formik.values.applyTaxes} />
            </div>

            {formik.values.applyTaxes ? (
              <div className="w-24">
                <InputField>
                  <Input
                    id="taxes"
                    name="taxes"
                    aria-label="Taxes"
                    placeholder="0"
                    value={get(formik.values, 'taxes')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    hasError={get(formik.errors, 'taxes') != null}
                    rightAddon={
                      get(formik.errors, 'taxes') != null ? (
                        <Tooltip
                          className="bg-error-default text-white"
                          content={get(formik.errors, 'taxes')}
                          placement="bottom"
                        >
                          <AlertCircle aria-label="Warning required" className="w-3 h-3 fill-error-default" />
                        </Tooltip>
                      ) : (
                        '%'
                      )
                    }
                  />
                </InputField>
              </div>
            ) : (
              <span className="h-8 flex items-center text-neutral-400 text-xs">
                Enable this to include taxes with the customization price.
              </span>
            )}
          </div>

          <div className="px-6 my-8 flex flex-col items-end basis-3/12">
            <div className="flex justify-between mb-4">
              <h6 className="font-medium">Currency</h6>
            </div>
            <span className="flex items-center font-medium text-lg">{currencyKey}</span>
          </div>
        </div>

        <FieldArray name="extraPrices">
          {extraPricesFieldArrayHelpers => (
            <FieldArray name="equations">
              {equationsFieldArrayHelpers => (
                <AdditionalPricing
                  extraPricesFieldArrayHelpers={extraPricesFieldArrayHelpers}
                  equationsFieldArrayHelpers={equationsFieldArrayHelpers}
                  name="extraPrices"
                  currenciesInputProps={currenciesInputProps}
                  customizerProduct={customizerProduct}
                />
              )}
            </FieldArray>
          )}
        </FieldArray>
      </div>
    </Card>
  )
}

export default PricingCard
