import { Answer, EntityType, FontSizeView, FontView, Group, Question } from '@packages/types'
import { flatten } from 'lodash'

import { utils as coreUtils } from 'builder/build/core'
import { RootState } from 'cms/store'
import getFontSizeFromFontStyle from 'utils/getFontSizeFromFontStyle'

export const getFontAssets = (answer: Omit<Answer, 'views'> & { views: FontView[] }) =>
  answer?.views ? answer.views[0].assets : []

export const getFontSize = (answer: Omit<Answer, 'views'> & { views: FontSizeView[] }, view: number) =>
  getFontSizeFromFontStyle(answer.views[view].size)

export const getDescendantAnswers = (productBuilder: RootState['productBuilder'], id: string): Answer[] => {
  const entity = coreUtils.getEntityById(productBuilder, id) as Answer | Question | Group | undefined

  if (!entity) return []

  if (entity.entityType === EntityType.Answer) {
    return [entity]
  }

  if (entity.entityType === EntityType.Question) {
    return entity.answers.map(answerId => productBuilder.answers[answerId])
  }

  if (entity.entityType === EntityType.Group) {
    const answersLists = entity.children.map(childrenId => getDescendantAnswers(productBuilder, childrenId))
    return flatten(answersLists)
  }

  return []
}
