import { User } from '@packages/types'
import React from 'react'

import { Button, Modal, ModalProps } from 'common/components'
import { usersUtils } from 'common/users'

interface DeleteInviteModalProps extends ModalProps {
  userToDelete: User | null
  isLoading?: boolean
  onDelete: () => void
  onClose: () => void
}

const DeleteInviteModal = ({
  userToDelete,
  isLoading,
  onDelete,
  onClose,
  ...modalProps
}: Omit<DeleteInviteModalProps, 'children'>) => {
  const isUserToDelete = userToDelete?.role === 'admin' || userToDelete?.role === 'mczr-admin'
  return (
    <Modal onBackdropClick={onClose} aria-label="Delete user" {...modalProps}>
      <Modal.CloseButton onClick={onClose} />
      <Modal.Title>{isUserToDelete ? 'Delete user' : 'Delete invitation'}</Modal.Title>

      <Modal.Details>
        {isUserToDelete
          ? `Are you sure you want to delete the user ${usersUtils.getFullName(
              userToDelete
            )}? This action cannot be undone.`
          : 'Are you sure you want to delete this invitation? This action cannot be undone.'}
      </Modal.Details>
      <Modal.Actions>
        <Button type="button" variant="default" className="px-4" onClick={onClose} disabled={isLoading}>
          Cancel
        </Button>
        <Button
          type="button"
          variant="error"
          className="px-4"
          onClick={onDelete}
          isLoading={isLoading}
          disabled={isLoading}
        >
          Delete
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default DeleteInviteModal
