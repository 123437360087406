import { Answer } from '@packages/types'
import React from 'react'

import { PatchTextInput, PatchTextInputChangeEvent } from 'builder/build/common/components'
import { useDispatch } from 'cms/hooks'
import { InputField } from 'common/components'

import { patchAnswer } from '../../actions'

interface TitleSectionProps {
  answer: Answer
}

const TitleSection = ({ answer }: TitleSectionProps) => {
  const dispatch = useDispatch()

  const handleNameChange = (e: PatchTextInputChangeEvent) => dispatch(patchAnswer(answer, { name: e.target.value }))

  return (
    <div className="panel__section">
      <InputField>
        <label className="font-medium mb-4">Title</label>
        <PatchTextInput placeholder="Title" value={answer.name} onChange={handleNameChange} />
      </InputField>
    </div>
  )
}

export default TitleSection
